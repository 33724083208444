<script>
import Layout from '../../components/layout.svelte';
import { fade } from 'svelte/transition';
import Fa from 'svelte-fa'
import { faSkullCrossbones, faMagic, faSave, faUndo} from '@fortawesome/free-solid-svg-icons';
import {axiosAuth, commons, authenticate, ready} from "../../auth.js";
import {calledSave} from '../../components/settings-store.js';

import Spinner from 'svelte-spinner';

authenticate();


//show sonfirm reindex dialog
let confirmReindex = false;
let reIndexPhrase = "";
let reindexButtonMessage = "REINDEX";
let disabledReindex = false;

let refreshOrgchartButtonMessage = "REFRESH ORG CHART";
let disabledOrgRefresh = false;

//show/hide reindex and orgchart refresh buttons
let secret = true;

//tukanga human is focused, waiting on input to show reindex and orgchart refresh options
let waiting = false;

const clickOutside = node => {
  //checks if what was clicked was the element, or not. Used to toggle focus on tukanga human button
  
  const handleClick = event => {
    if (node && !node.contains(event.target) && !event.defaultPrevented) {
      node.dispatchEvent(
        new CustomEvent('click_outside', node)
      )
    }
  }

	document.addEventListener('click', handleClick, true);
  
  return {
    destroy() {
      document.removeEventListener('click', handleClick, true);
    }
	}
}

const handleClickOutside = (event) => {
    //if the click happened outside the node, and it was focused, make it lose its focus
    if (waiting) waiting = false;
	}

const handleKeydown = (event) => {
    if (event.keyCode===84&&waiting) secret = false;
	}

const waitForIt = () => {
    //focus on tukanga human, waiting for input to show buttons
    waiting = !waiting;
}

const reIndex = async () => {
  //send reindex request <!-- /api/ir/{orgId}/reindex -->

  /* try {
         const res = await $axiosAuth.post('/api/ir'+$commons.orgId+'/reindex');
        
         if(res.status===200){
            if(res.data.errors.length>0) {
              alert('something went wrong');
              reindexButtonMessage = "REINDEX request ABORTED";
            }
            else {
              console.log("successful call");
              reindexButtonMessage = "REINDEX request sent";
            }
         }
         else alert('something went wrong. response code: ', res.status);    

      } catch (e) {
        console.log(e);
    }
  */ 

    reindexButtonMessage = "REINDEX request sent";
    disabledReindex = true;
    close();
}

const refreshOrgchart = async () => {
  //send organization clear cache request  <!-- /api/orgchart/refreshCache -->

  /* try {
         const res = await $axiosAuth.post('/api/orgchart/refreshCache');
        
         if(res.status===200){
            if(res.data.errors.length>0) {
              alert('something went wrong');
              refreshOrgchartButtonMessage = "REFRESH ORG CHART request ABORTED";
            }
            else {
              console.log("successful call");
              refreshOrgchartButtonMessage = "REFRESH ORG CHART request sent";
            }
         }
         else alert('something went wrong. response code: ', res.status);    

      } catch (e) {
        console.log(e);
    }
  */ 

    refreshOrgchartButtonMessage = "REFRESH ORG CHART request sent";
    disabledOrgRefresh = true;

}
const cancel = () =>{
    //reset changes (if there are any) and close modal
    confirmReindex = false;
}

const close = () =>{
    //close modal
    confirmReindex = false;
}
</script>

<style>
.centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fixed {
  right: 0;
  left:0;
  bottom: 0;
  position:fixed;
  display:flex;
}

.borderless{
  border-color: transparent;
  }

.borderless:hover {
    background-color:gainsboro !important;
    border-color: transparent;
  } 
/* 
.modal-card-head, .modal-card-foot {
    background-color: white;
} */
</style>
<svelte:window on:keydown={handleKeydown}/>
<Layout>
    <div slot="navigation-buttons">
    </div>
    {#if !$ready}
    
    <progress class="progress is-small is-info" max="100">15%</progress> 

    <div class="hero"></div>
    <div class="columns is-desktop is-centered centered">
           <div class="colum has-text-centered">
              <div class="is-size-5-mobile is-size-4">Ανακατεύθυνση....</div>
            </div>   
    </div>   
    {:else}
    <slot/>
    {/if}
    <footer class="fixed has-background-light">
    <div class="container px-3 py-3 level">
      <div class="level-left">
        
        <!-- <button class="md-button" ng-show="reindexHide"><i class="fa fa-skull-crossbones fa-lg"></i> &nbsp; REINDEX request sent 
          <i class="fa fa-check"></i></button>
        <button class="md-button md-primary" ng-show="refreshHide"> &nbsp; REFRESH ORG CHART request sent <i class="fa fa-check"></i></button> -->

        <!-- /api/ir/{orgId}/reindex -->
        <button class="{secret?'is-hidden':''} level-item button is-medium has-background-dark has-text-white has-text-medium" on:click={()=>{confirmReindex=true}} disabled={disabledReindex}><span class="icon is-large"> <Fa icon={faSkullCrossbones} aria-hidden="true"/></span> &nbsp; {reindexButtonMessage}</button>

        <!-- /api/orgchart/refreshCache -->
        <button class="{secret?'is-hidden':''} level-item button is-medium has-text-white has-text-medium" on:click={refreshOrgchart} style="background-color:indigo; opacity:0.8;" disabled={disabledOrgRefresh}><span class="icon is-large"> <Fa icon={faMagic} aria-hidden="true"/></span> &nbsp; {refreshOrgchartButtonMessage}</button>

         <a class="level-item button is-large has-text-large has-background-light borderless {waiting?'is-focused':''}" 
          use:clickOutside on:click_outside={handleClickOutside} on:click={waitForIt}><span class="icon is-large">
         <img src="https://tukanga-staging.open1.eu/img/tukanga_human_3.svg" alt="TUKANGA" /> </span></a>
        
        <!-- /api/{orgId}/params -->
         <button class="level-item button is-medium has-text-medium has-background-light borderless"><span class="icon is-medium"><Fa icon={faUndo} aria-hidden="true"/></span>&nbsp; Επαναφορά</button>
      </div>

      <div class="level-right">
        
        <button class="level-item button is-medium has-background-dark has-background-link-dark has-text-white has-text-medium"
        on:click={()=>{$calledSave=true}}><span class="icon is-large"> <Fa icon={faSave} aria-hidden="true"/></span> &nbsp; Αποθήκευση</button>
      </div>
      
    </div>
  </footer>
</Layout>

<!-- ============================================= MODALS ======================================================== -->
{#if confirmReindex}
<div class="modal is-active">
  <div in:fade|local class="modal-background"></div>
    <div class="modal-card">
    <header class="modal-card-head">
      <h1 class="modal-card-title">Επιβεβαίωση Επανευρετηρίασης</h1>
      <button class="delete" aria-label="close" on:click={close}></button>
    </header>
    <section class="modal-card-body is-size-5-desktop is-size-6">
     <div class="container">
      <h2 class="subtitle is-size-5 mb-2">
        Παρακαλώ πληκτρολογήστε πρώτα τη λέξη "reindex":
      </h2> 
      <input autofocus = true
                    name="reindexconfirm"
                    class="input"
                    type="text"
                    bind:value={reIndexPhrase}
                  />
      </div>
     </section>
    <footer class="modal-card-foot is-grouped level">
      <button class="button level-item " on:click={cancel}>Ακύρωση</button>
      <button class="button has-text-white has-text-weight-semibold level-item box" style="background-color:rgb(63,81,181);" on:click={reIndex} disabled={!(reIndexPhrase.toString()==="reindex")}>Επανευρετηρίαση τώρα</button>
    </footer>
</div>
    </div>      

{/if}


