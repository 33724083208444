<script>
  import { prepareGsisLoginUrl, axiosInst } from "../credentials.js";
  import { url, params, goto, beforeUrlChange } from "@sveltech/routify";
  import {
    clientGsisId,
    GSISClientName,
    clientFooterName,
    selectedOrgDTO,
  } from "../ui-store.js";
  import { CookiesApi } from "../utils/helpers";
  export let labelSize = "is-size-6";
  export let label;
  export let state;
  
  let org = $selectedOrgDTO;

  const getSessionId = () => {
    let sessionId;
    console.log("Generating session...");
    return new Promise(async (resolve, reject) => {
      try {
        await $axiosInst
          .get("externalEntities/getSessionId")
          .then(function (response) {
            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              reject();
            } else {
              //If status OK, see if we have errors
              console.log(response.data);
              if (response.data.errors.length > 0) {
                console.log("Error" + ": " + response.data.errors);
                // add more actions...
                reject();
              } else {
                //no errors, get sessionId
                console.log("Success!");
                sessionId = response.data.data;
                resolve(sessionId);
              }
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  const gsisLogin = prepareGsisLoginUrl(org, state);

  let logging_in = false;

  let login = () => {
    logging_in = true;
    console.log(gsisLogin);
    //$goto(gsisLogin, {static:true});
    // let cont = confirm("Θα ανακατευθυνθείτε στη σελίδα εισόδου της Γ.Γ.Π.Σ. \nΘέλετε να συνεχίσετε;");
    // if (cont) {
    getSessionId().then(
      (res) => {
        //set sessionId in axios headers
        //EDIT: no need to set the header since we set the cookie now
        //and the headers are set dynamically in each request(see credentials.js)
        //console.log($axiosInst);
        //$axiosInst.defaults.headers.common['SessionID'] = res;
        //also set a cookie
        // convert expires_in to GMT String
        let expires = new Date();
        expires.setMinutes(expires.getMinutes() + 61);
        // expires.setSeconds(
        //     expires.getSeconds() + parseInt(userdata.gsisTokenInfo.expires_in)
        // );
        // expires = expires.toGMTString();

        let sessionCookie = {
          id: res,
          // expires: expires
        };

        // save cookie
        // ** With a path parameter, you can tell the browser what path the cookie belongs to. By default, the cookie belongs to the current page. **
        // ** we set the cookie to root path: '/'

        // document.cookie = "sessionCookie=" + encodeURIComponent(JSON.stringify(sessionCookie)) + "; path=/";
        CookiesApi.set("sessionCookie", JSON.stringify(sessionCookie), {
          expires: expires,
          path: "/",
        });

        //set gsis client properties in localStorage from storeValue (it should be the last org visited, anyway)
        localStorage.setItem("clientGsisId", JSON.stringify($clientGsisId));
        localStorage.setItem(
          "GSISClientName",
          JSON.stringify($clientFooterName)
        );
        window.location.href = gsisLogin;

        setTimeout(() => {
          logging_in = false;
        }, 1000);
      },
      (error) => {
        logging_in = false;
        console.log(JSON.stringify(error));
      }
    );
    // }
    // else return false;
  };
</script>

{#if $clientGsisId}
  <section>
    <div class="container">
      <div class="level">
        <div class="level-item has-text-centered">
          <button
            on:click={login}
            class="button is-link has-background-link-dark {labelSize}"
          >
            {label}
          </button>
        </div>
      </div>
    </div>
  </section>
{/if}
