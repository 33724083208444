<script>
    import Select from 'svelte-select';
    import SearchIcon  from "./SearchIcon.svelte";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import { XIcon } from "svelte-feather-icons";
    import { removeAccents, debounce } from "../utils/helpers.js";
    import AutoComplete from "simple-svelte-autocomplete";
    import {searchWorker} from "../ui-store.js";
    
    export let items = [];
    //identification field of options
    export let optionIdentifier;
    //what field is the option label?
    export let labelIdentifier;
    //how do we filter the results? (property array). searchString will be created according to the filters' order
    export let filtersArray = [];

    export let theme = "green";
 
    let inputSearchElement;

    let searchQuery = ""; //A variable that stores the search query given by the user
    let filteredItems = []; //The result(s) that match the search query
    let loadingSearch = false;


    const handleInput = (event) => {
        loadingSearch = true;
        calledFind = true;
    
        //add animaion class only after the html is rendered( loadingSearch is true)
        // document.getElementById('dotflashing').classList.add("dot-flashing");
        searchWithCriteria(searchQuery);
    }

    const searchWithCriteria = (searchQuery) => {
        dispatchLoadingStatus();
        // Hook up to the onMessage event, so you can receive messages
        // from the worker.
        $searchWorker.onmessage = receivedWorkerMessage;
        
        //call worker and send arguments
        $searchWorker.postMessage(
        { 
            searchCriteria: searchQuery,
            requests: items,
            filtersArray: filtersArray
        }
        );
    
        // Let the user know that things are on their way.
        console.log("A web worker is on the job");
      
    }

    const receivedWorkerMessage = (event) => {
        // Get the filtered requests list.
        filteredItems = event.data;
        console.log("WEB WORKER DONE");
        //search is done
        loadingSearch = false;
        // document.getElementById("dot-flashing").classList.remove("dot-flashing");
    }

    const allAreTrue = (arr) => {
            return arr.every(element => element === true);
    }


    $:console.log("filteredItems: ", filteredItems);
    // $:console.log("labelIdentifier: ", labelIdentifier, items[0][labelIdentifier]);

    let calledFind = false;

    $: if (calledFind && ((searchQuery === "") || !searchQuery)) {
        closeSearch();
    }

    const closeSearch = (event) => {
        loadingSearch = true;
        searchQuery = "";
        calledFind = false;
        // wait to call search
        // setTimeout(searchWithCriteria(searchQuery), 500);
        searchWithCriteria(searchQuery);
        //close all open requests
        // toggle(-1);
    }

    const dispatch = createEventDispatcher();

    const dispatchSearchResults = (filteredItems) => {
        dispatch("ready", {
        results: filteredItems,
      });
    }

    const dispatchLoadingStatus = () => {
        dispatch("loading");
    }

    //if there are results, dispatch them
    $: dispatchSearchResults(filteredItems);

    onMount(() => {
        searchWithCriteria(searchQuery);
    });

</script>
<style>
  /* 	
      CSS variables can be used to control theming.
      https://github.com/rob-balfre/svelte-select/blob/master/docs/theming_variables.md
  */

  .input-search {
    height: 50px;
    border-style: solid;
    border-width: 1px;
    opacity: 0.7;
    border-radius: 0.25rem;
    text-align: left;
    text-indent: 52px;
    font-size: large;
    background-color: transparent;
    position: relative;
    /* transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2); */
  }

  .input-search:focus {
    outline: none;
    border: 1px solid var(--theme);
    box-shadow: 0 0 10px #719ece;
    text-align: left;
    text-indent: 52px;
  }

  .input-search::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  .btn-search {
    width: 35px;
    height: 35px;
    /* margin: 9px 0 0 5px; */
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    color: black;
    background-color: transparent;
    /* pointer-events: painted; */
  }


  /* for line length readability */
  span,
  em,
  p {
    display: inline-block; 
    max-width: 34em;
  }
</style>

    <!-- Search box -->
        <div class="text-center w-full is-flex-direction-row is-align-items-flex-start">
            {#if searchQuery === ''}
            <button class="btn-search mx-4 my-2 flex items-center" style="z-index: 10;">
                <SearchIcon size="24" />
            </button>
            {:else}
            <button
            class="btn-search mx-4 my-2 flex items-center"
                style="z-index: 10;"
                title="Εκκαθάριση Αναζήτησης"
                on:click={() => {
                closeSearch();
                inputSearchElement.focus();
                }}>
                <XIcon size="30" />
            </button>
            {/if}

            <input bind:value={searchQuery} bind:this={inputSearchElement} 
                class="input-search" name="search-box" 
                style="--theme: blue" autocomplete="off"
                on:input={debounce(handleInput, 500)}/>
        </div>
