<script>
    import {clientFooterName, clientPrivacy, selectedOrgDTO} from "../../../ui-store.js";
    import { onMount } from "svelte";

    $:console.log($clientFooterName);

  </script>
  <style>
    li {
      margin-top: 1rem;
    }
  </style>
  <div class="container app-container">
    <div class="columns is-vcentered">
  
      <div class="column mt-2">
  
  
        <!-- <h2 class="subtitile is-size-4-mobile is-size-3 my-6">
            Περιφέρεια Στερεάς Ελλάδας: Πολιτική απορρήτου
          </h2> -->
        <div class=" my-6">
          <div class="is-size-4">
            {$clientFooterName}
          </div>
          <h3 class="title">Πολιτική Ορθής Χρήσης Διαδικτυακών Υπηρεσιών (web services) του Υπουργείου Ψηφιακής Διακυβέρνησης</h3>
        </div>

        {#if $selectedOrgDTO}
        <div class="mb-6">
          <h3 class="is-size-5-desktop is-size-6-touch px-4 is-italic">
            Στην ενότητα αυτή παρουσιάζεται η Πολιτική Ορθής Χρήσης Διαδικτυακών Υπηρεσιών (web services) του Υπουργείου Ψηφιακής Διακυβέρνησης για τον Φορέα <strong>{$selectedOrgDTO.title}</strong> 
            (στο εξής αναφερόμενος ως <strong>Φορέας καταναλωτής</strong>).
          </h3>
        </div>
        {/if}
  
      <div class="columns is-vcentered">
          <div class="column">
              <p>Ο Φορέας καταναλωτής (Φορέας / Νομικό Πρόσωπο ή Νομική Οντότητα) των διαδικτυακών υπηρεσιών που προσφέρονται μέσω του Κέντρου Διαλειτουργικότητας της Γ.Γ.Π.Σ.Δ.Δ. (ΚΕΔ)
              αποδέχεται πλήρως, μέσω του Νόμιμου Εκπροσώπου και του Διαχειριστή διαδικτυκών
              υπηρεσιών, τους κάτωθι όρους χρήσης:</p>
               <li> Έχει την υποχρέωση να λαμβάνει τα κατάλληλα τεχνικά και οργανωτικά μέτρα ασφάλειας
              πληροφοριών, ώστε:
              <p>α) η χρήση της διαδικτυακής υπηρεσίας να γίνεται αποκλειστικά και μόνο για την
              εξυπηρέτηση του σκοπού για τον οποίο έχει χορηγηθεί η πρόσβαση,</p>
              <p>β) να μην κάνει κατάχρηση της διαδικτυακής υπηρεσίας, προκαλώντας υπερφόρτωση των
              συστημάτων του ΚΕΔ,</p>
              <p>γ) να μην επιχειρεί την απόκτηση στοιχείων πέρα των απολύτως απαραιτήτων για τη
              εξυπηρέτηση του νόμιμου σκοπού του.</p></li>
              <li>Έχει την υποχρέωση να τηρεί μυστικά τα διαπιστευτήρια πρόσβασης που του έχουν αποδοθεί και να ειδοποιεί άμεσα τη Γ.Γ.Π.Σ.Δ.Δ. για τυχόν διαρροή τους.</li>
              <li>Σε περίπτωση υποψίας συμβάντος ασφάλειας ή διαπίστωσης ευπάθειας των διαδικτυακών
              υπηρεσιών ή μη ορθής χρήσης του πληροφοριακού συστήματος που αξιοποιεί τις
              διαδικτυακές υπηρεσίες, υποχρεούται να ειδοποιεί άμεσα την Γ.Γ.Π.Σ.Δ.Δ. παρέχοντας όλες
              τις διαθέσιμες πληροφορίες. Επίσης, υποχρεούται να διατηρεί και να παρέχει κάθε στοιχείο
              που μπορεί να συμβάλει στην διερεύνηση τέτοιων περιστατικών. Σε κάθε περίπτωση,
              απαγορεύεται η εκμετάλλευση τυχόν ευπαθειών ασφάλειας της διαδικτυακής υπηρεσίας.</li>
              <li>Υποχρεούται να τηρεί αρχείο καταγραφής των κλήσεων της διαδικτυακής υπηρεσίας.
              <p>Στο αρχείο θα καταγράφονται κατ’ ελάχιστον :</p>
              <p>α) ο μοναδικός αύξων αριθμός κλήσης στο Πληροφοριακό Σύστημά του,</p>
              <p>β) η ημερομηνία και ώρα της κλήσης,</p>
              <p>γ) η περιγραφή της υπηρεσιακής μονάδας ή του Πληροφοριακού Συστήματος του φορέα που
              πραγματοποίησε την κλήση,</p>
              <p>δ) η αιτία κλήσης της διαδικτυακής υπηρεσίας (π.χ. Αριθμός Πρωτοκόλλου- Αριθμός
              Υπόθεσης) μέσω της οποίας ζητούνται δεδομένα.</p></li>
              <li>Ο Φορέας καταναλωτής Χρειάζεται να διατηρεί καταγραφές πρόσβασης με τις οποίες να
              μπορεί να ταυτοποιηθεί μοναδικά η κάθε κλήση και να περιλαμβάνει κατ’ ελάχιστον:
              <p>τις διευθύνσεις IP των σταθμών εργασίας / εξυπηρετητών.</p>
              <p>τα ονόματα χρηστών (usernames) που χρησιμοποιήθηκαν από τους φυσικούς χρήστες ή /
  και τα ονόματα εξυπηρετητών (machine names).</p></li>
              <li>Τα Αρχεία Καταγραφής, τόσο του Φορέα καταναλωτή των διαδικτυακών υπηρεσιών όσο και
              του Ενδιάμεσου Φορέα (εάν υπάρχει), Χρειάζεται να προστατεύονται από σκόπιμη ή τυχαία
              απώλεια, αλλοίωση ή διαρροή, με την λήψη πρόσθετων μέτρων ασφάλειας.</li>
              <li>Ο Φορέας καταναλωτής έχει την υποχρέωση να εφαρμόζει τον Κανονισμό (ΕΕ) 2016/679 του
              Ευρωπαϊκού Κοινοβουλίου και του Συμβουλίου της 27ης Απριλίου 2016 για την προστασία
              των φυσικών προσώπων έναντι της επεξεργασίας δεδομένων προσωπικού χαρακτήρα, σε
              συνδυασμό με τις διατάξεις του ν. 4624/2019 (ΦΕΚ Α’ 137/29-08/2019).</li>
              <li>Η Γ.Γ.Π.Σ.Δ.Δ. διατηρεί αρχεία πλήρους καταγραφής των ενεργειών χρήσης των διαδικτυακών
              υπηρεσιών σε επίπεδο κεντρικών υπολογιστικών υποδομών, με σκοπό την ιχνηλάτηση και
              απόδοση ευθυνών, σε περιπτώσεις ύποπτης ή παραβατικής συμπεριφοράς πιστοποιημένων
              ή μη χρηστών. Σε περίπτωση διαπίστωσης μη ορθής χρήσης διαδικτυακής υπηρεσίας,
              διατηρεί το δικαίωμα ανάκλησης των δικαιωμάτων πρόσβασης του υπαιτίου.</li>
              <li>Η Γ.Γ.Π.Σ.Δ.Δ. λαμβάνει όλα τα απαραίτητα μέτρα για να εξασφαλίσει την ασφαλή και
              αδιάλειπτη λειτουργία των διαδικτυακών υπηρεσιών που παρέχει. Η Γ.Γ.Π.Σ.Δ.Δ. και το
              Υπουργείο Ψηφιακής Διακυβέρνησης δεν ευθύνονται για οποιαδήποτε αρνητική συνέπεια ή
              ζημία που τυχόν υποστεί ο Φορέας καταναλωτής αυτών από τη χρήση ή την αδυναμία
              χρήσης αυτής, από καθυστερήσεις κατά τη χρήση αυτής, από λάθη ή παραλείψεις σε αυτή,
              από διακοπές της τηλεφωνικής γραμμής, βλάβες του δικτύου ηλεκτροδότησης ή για άλλους
              λόγους (πχ. απεργίες, τρομοκρατικές ενέργειες κλπ) που δεν ανήκουν στην σφαίρα επιρροής
              τους.</li>
              <li>Η Γ.Γ.Π.Σ.Δ.Δ. και το Υπουργείο Ψηφιακής Διακυβέρνησης δεν ευθύνονται για τυχόν νομικές
              επιπτώσεις σε περίπτωση που ο Φορέας καταναλωτής των διαδικτυακών υπηρεσιών
              συλλέγει και επεξεργάζεται μαζικά προσωπικά ή φορολογικά δεδομένα ή προβαίνει σε άλλες
              ενέργειες οι οποίες εν γένει πιθανώς παραβιάζουν το ισχύον το θεσμικό πλαίσιο περί
              προστασίας δεδομένων προσωπικού χαρακτήρα ή/και προστασίας του φορολογικού
              απορρήτου. </li>
              <li>Η Γ.Γ.Π.Σ.Δ.Δ. μετά την εξασφάλιση των τυχόν -κατά περίπτωση- απαιτούμενων εγκρίσεων
              του Υπουργείου Ψηφιακής Διακυβέρνησης, διατηρεί το δικαίωμα να τροποποιεί νομίμως το
              περιεχόμενο και τους όρους χρήσης των διαδικτυακών υπηρεσιών, ενημερώνοντας έγκαιρα
              τον Φορέα καταναλωτή.</li>
              
  
          <!-- <div class="column is-half">
              <h3 class="subtitile is-size-6-mobile is-size-4 mt-6"><b> Είστε Φορέας; </b></h3>
              <a role="button" class="{$highContrast?'has-background-link-dark has-text-white':'is-info'} button is-size-5-mobile is-size-4 mt-1" href="{$url('/admin')}">
                <strong>Συνδεθείτε εδώ</strong>
              </a>
            </div> -->
        </div>
  
  
        <!-- div below only exists to push the text divs up a bit -->
        
      </div>
      <div class="hero mt-6 is-hidden-mobile"></div>
        <div class="hero mt-6 is-hidden-mobile"></div>
        <div class="hero mt-6 is-hidden-mobile"></div>
  
  
  
    </div>
  </div>
  </div>
  
  <!-- <hr> -->