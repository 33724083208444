<script>
  import { slide } from "svelte/transition";
  export let title;
  let isOpen = false;
  const toggle = () => (isOpen = !isOpen);
</script>

<div
  on:click={toggle}
  aria-expanded={isOpen}
  style="width:100%"
  class="my-hoverable columns is-mobile is-vcentered is-size-4-desktop is-size-5-tablet has-text-weight-medium is-size-6-mobile p-4 has-text-left"
>
  <div class="column">
     {@html title}
  </div>

  <div class="column is-narrow has-text-link">
    <svg
      style="tran"
      width="24"
      height="24"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.5"
      viewBox="0 0 24 24"
      stroke="currentColor">
      <polyline points="6 9 12 15 18 9"></polyline>
      </svg
    >
  </div>
</div>
{#if isOpen}
  <div class="px-4">
    <slot />
  </div>
{/if}

<style>
  button {
    border: none;
    color: inherit;
    cursor: pointer;
  }
  
  [aria-expanded="true"] svg {
    transform: rotate(0.5turn);
  }
</style>
