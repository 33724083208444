<script>
    import { getCookie, logout } from "./auth.js";
    import { axiosInst} from './credentials.js';
    import moment from "moment";
    import { fade, scale } from "svelte/transition";
    import { onMount } from "svelte";
    import { fifteenMinuteMark, fiveMinuteMark, logging_out, isSystemUp, selectedOrgDTO } from "./ui-store.js";


    // $:console.log("sessioncheck selectedOrgDTO :", $selectedOrgDTO)
    let open = false;
    let hours = 0;
    let minutes = 0;

    function cancel() {
        open = false;
    }

    

    function logoutNow() {
        open = false;
        $logging_out = true;
        logout();
    }

    // let fifteenMinutesWarning = false;
    // let fiveMinutesWarning = false;

    //periodically checks if gsis cookie exists
    const checkAuthCookie = () => {
        //console.log("checking cookies...");

        let gsisAuth = getCookie("gsisAuth");
        let sessionCookie = getCookie("sessionCookie");

        if (!gsisAuth || !gsisAuth.expires) {
            //console.log("cookie not found");
            //logoutNow();
        } else {
            // console.log("cookie is OK", ssoAuth);
            // check if it expires soon or is expired already: ssoAuth.expires
            if (gsisAuth.expires) {
                let now = moment();
                minutes = moment(gsisAuth.expires).diff(now, "minutes");
                console.log("minutes remaining:", minutes);
                if (!minutes||minutes<0) {
                    logoutNow();
                }
                if (minutes == 15 && !$fifteenMinuteMark) {
                    $fifteenMinuteMark = true;
                    hours = Math.round(gsisAuth.duration / 3600);
                    open = true;
                }
                if (minutes == 5 && !$fiveMinuteMark) {
                    $fiveMinuteMark = true;
                    hours = Math.round(gsisAuth.duration / 3600);
                    open = true;
                }
            }
        }

        if (!sessionCookie || !sessionCookie.expires) {
            // console.log("cookie not found");
            //logoutNow();
        } else {
            // console.log("cookie is OK", ssoAuth);
            // check if it expires soon or is expired already: ssoAuth.expires
            // if (sessionCookie.expires) {
            //     let now = moment();
            //     minutes = moment(sessionCookie.expires).diff(now, "minutes");
            //     console.log("minutes remaining:", minutes);
            //     if (!minutes||minutes<0) {
            //         logoutNow();
            //     }
            //     else{
            //         //set axios store
            //         $axiosInst.defaults.headers.common['SessionID'] = sessionCookie.id;
            //     }
            //}
        }

        // no use in checking for Entrance
        window.setTimeout(checkAuthCookie, 5000); // check every 5 seconds
    };
    const checkAPIs = async () => {
        return new Promise(async (resolve, reject) => {
            if (!$selectedOrgDTO || !$selectedOrgDTO.id) $isSystemUp = true;
            else {
            $axiosInst.get('open1process/'+ $selectedOrgDTO.id + '/systemIsUp')
                .then(
                function (response) {
                    if (response.status !== 200) {
                    console.log('Looks like apis are down. Status Code: ' + response.status);
                    $isSystemUp = false;
                    reject();
                    }
                    // Examine the text in the response
                    else {
                    console.log('systems are up and running');
                    $isSystemUp = true;
                    resolve();
                    }
                }
                )
                .catch(function (err) {
                console.log('Fetch Error :-S', err);
                $isSystemUp = false;
                reject();
                });
            }
            });
    }

    //periodically check if APIs are up and running
    const checkAPIsRecursive = async () => {
            checkAPIs(); 
            window.setTimeout(checkAPIsRecursive, 60000); // check every 1 minute    
    }

$:if (!$selectedOrgDTO || !$selectedOrgDTO.id) $isSystemUp = true;
else {
    checkAPIs(); 
    // checkAPIsRecursive();// check every 1 minute
}

    onMount(() => {
        checkAuthCookie(); 
        // checkAPIs(); 
        checkAPIsRecursive();// check every 1 minute
        //let sessionCookie = getCookie("sessionCookie");
        //if (sessionCookie) $axiosInst.defaults.headers.common['SessionID'] = sessionCookie.id;
    }); //end onMount

</script>

{#if logging_out}
    <div class="modal">
        <div in:fade|local class="modal-background" />
        <div out:scale|local class="box modal-card">
            <section class="modal-card-body has-text-centered is-size-3">
                Γίνεται αποσύνδεση...
            </section>
        </div>
    </div>
{/if}
<!--
{#if open}
    <div class="modal" class:is-active={open}>
        <div in:fade|local on:click={cancel} class="modal-background" />
        <div out:scale|local class="box modal-card" >
            <header class="modal-card-head">
                <p class="modal-card-title">Προσοχή</p>
                <button class="delete" on:click={cancel} aria-label="close" />
            </header>
            <section class="modal-card-body">
                {#if hours}
                    <p>
                        Έχουν περάσει σχεδόν
                        {hours}
                        ώρες από τη στιγμή που συνδεθήκατε.
                    </p>
                {/if}
                <p>
                    Θα γίνει αυτόματη αποσύνδεση σε
                    {minutes}
                    {#if minutes == 1}λεπτό{:else}λεπτά{/if}.
                </p>
                <!-- <p>
                    Για να συνεχίσετε περισσότερη ώρα παρακαλώ αποσυνδεθείτε και
                    συνδεθείτε ξανά.
                </p> -->
<!-- </section>
            <footer class="modal-card-foot">
                <button
                    on:click={logoutNow}
                    class="button is-danger is-light pull-right"
                >
                    Έξοδος τώρα
                </button>
                <button on:click={cancel} class="button is-light pull-left">
                    Συνέχιση για
                    {minutes}
                    {#if minutes == 1}λεπτό{:else}λεπτά{/if}
                </button>
            </footer>
        </div>
    </div>
{/if} -->
