<script>
import { url } from "@sveltech/routify";
import {clientFooterName, clientPrivacy, selectedOrgDTO} from "../../../ui-store.js";
import { onMount } from "svelte";

onMount(() => {
    //get store value from localStorage

    //clientFooterName.useLocalStorage();
    //clientPrivacy.useLocalStorage();
  });

</script>
<div class="container app-container">
  <div class="columns is-vcentered">

    <div class="column mt-2">


      <!-- <h2 class="subtitile is-size-4-mobile is-size-3 my-6">
          Περιφέρεια Στερεάς Ελλάδας: Πολιτική απορρήτου
        </h2> -->
      <div class=" my-6">
        <div class="is-size-4">
          <!-- Περιφέρεια Στερεάς Ελλάδας -->
          {#if $selectedOrgDTO.officialURL}
            <a class="ghost-link"
              href={$url($selectedOrgDTO.officialURL)}
              target="_blank">{@html $clientFooterName}
            </a>
          {:else}
            {@html $clientFooterName}
          {/if}
        </div>
        <h3 class="title">Πολιτική απορρήτου</h3>
      </div>

      <div class="columns is-vcentered">
        <div class="column">
          {@html $clientPrivacy}
          <!-- <p><b>Προσωπικά Δεδομένα</b></p>
          <p>Η ΠΣΤΕ, έχει υλοποιήσει μια υπηρεσία Διασύνδεσης προκειμένου να εξυπηρετήσει τα αιτήματα πολιτών που
            επιθυμούν να ολοκληρώσουν την συναλλαγή τους με συγκεκριμένες υπηρεσίες της Περιφέρειας ηλεκτρονικά . H
            ΠΣΤΕ, παρέχει τη δυνατότητα εγγραφής στις ηλεκτρονικές υπηρεσίες της ιστοσελίδας της.
            Σε αυτή την περίπτωση η ΠΣΤΕ, μπορεί να συλλέξει και να αποθηκεύσει το πλήρες όνομά σας, το email σας, τη
            διεύθυνσή σας, στοιχεία επικοινωνίας και οποιεσδήποτε άλλες πληροφορίες μπορεί να μας έχετε διαθέσει. Αυτές
            οι πληροφορίες χρησιμοποιούνται αυστηρά για να ανταποκριθούμε στο αίτημα σας και δεν θα αποκαλυφθούν σε
            τρίτους εκτός από εκείνους που αναφέρονται στην παρούσα πολιτική ή όπου η δημοσιοποίηση απαιτείται ή
            επιτρέπεται από το νόμο.
            Για τη νόμιμη επεξεργασία των δεδομένων σας υπό τον Κανονισμό (ΕΕ) 2016/679 , αναγνωρίζουμε μια νόμιμη βάση
            πριν την αποθήκευση των δεδομένων προσωπικού χαρακτήρα. Στις παραπάνω περιπτώσεις η νόμιμη βάση για την
            επεξεργασία δεδομένων προσωπικού χαρακτήρα από πλευράς μας είναι η παραχώρηση της ρητής συγκατάθεσής σας για
            την αποθήκευση και επεξεργασία των προσωπικών δεδομένων σας, με την υποβολή της εκάστοτε φόρμας μόνο σε
            περίπτωση που συμφωνείτε με την πολιτική αυτή.
            Η ΠΣΤΕ προστατεύει τα δεδομένα προσωπικού χαρακτήρα με την κατάλληλη τεχνική και οργανωτική ασφάλεια.</p>
          <hr />
          <p><b>Χρήση Δεδομένων που συλλέγονται</b></p>
          <p>Θα χρησιμοποιούμε τις πληροφορίες που συλλέγουμε για κάποιους από τους ακόλουθους σκοπούς:
            * για την εγγραφή σας σε μια Υπηρεσία.
            * για την παροχή μιας Υπηρεσίας που έχετε ζητήσει.</p>
          <hr />
          <p><b>Επεξεργασία δεδομένων</b></p>
          <p>Η ΠΣΤΕ επεξεργάζεται προσωπικά δεδομένα για τους σκοπούς που περιγράφονται παραπάνω.
            Το νομικό πλαίσιο επεξεργασίας προσωπικών δεδομένων από την ΠΣΤΕ περιλαμβάνει επεξεργασία η οποία είναι
            απαραίτητη για την παροχή των Υπηρεσιών προς εσάς, την ταυτοποίηση και τον έλεγχο ταυτότητάς σας,
            προκειμένου να μπορείτε να χρησιμοποιείτε συγκεκριμένες Υπηρεσίες), απαραίτητη για τη συμμόρφωση με νομικές
            απαιτήσεις.</p>
          <hr />
          <p><b>Μεταβίβαση Δεδομένων Προσωπικού Χαρακτήρα</b></p>
          <p>Οποιαδήποτε διαβίβαση δεδομένων προσωπικού χαρακτήρα, θα είναι πάντοτε σε συμμόρφωση με την ισχύουσα
            νομοθεσία. Τέτοιες διαβιβάσεις θα πραγματοποιούνται αποκλειστικά για τους σκοπούς διεκπεραίωσης του
            αιτήματος του πολίτη, τηρώντας τις προϋποθέσεις που προβλέπονται στην παρούσα δήλωση και σύμφωνα με την
            εφαρμοστέα νομοθεσία προστασίας προσωπικών δεδομένων.</p>
          <hr />
          <p><b>Διατήρηση Δεδομένων Προσωπικού Χαρακτήρα</b></p>
          <p>Η ΠΣΤΕ δεσμεύεται να μην διατηρήσει δεδομένα προσωπικού χαρακτήρα για μια περίοδο μεγαλύτερη από ότι είναι
            αναγκαίο σύμφωνα με την εφαρμοστέα νομοθεσία.</p>
          <hr />
          <p><b>Δικαιώματα των Υποκειμένων</b></p>
          <p>Αυτά που πηγάζουν από τον Κανονισμό (ΕΕ) 2016/679 και την Ελληνική Νομοθεσία.</p> -->


        </div>

        <!-- <div class="column is-half">
            <h3 class="subtitile is-size-6-mobile is-size-4 mt-6"><b> Είστε Φορέας; </b></h3>
            <a role="button" class="{$highContrast?'has-background-link-dark has-text-white':'is-info'} button is-size-5-mobile is-size-4 mt-1" href="{$url('/admin')}">
              <strong>Συνδεθείτε εδώ</strong>
            </a>
          </div> -->
      </div>


      <!-- div below only exists to push the text divs up a bit -->
      <div class="hero my-6 is-hidden-mobile"></div>
    </div>



  </div>
</div>

<!-- <hr> -->