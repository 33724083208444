<script>
    import Formfield from "./Formfield.svelte";
    import { checkFiles, findErrors } from "../utils/helpers.js";
    import { onMount, createEventDispatcher } from "svelte";
    import Spinner from "svelte-spinner";
    import { validationOngoing, validationFileErrors, dependencyListStore, dependencyChangesStore, serviceVariables } from "../ui-store.js";
    export let filesArray = [];
    export let disabled; //should the fields in this layout be disabled?
    export let serviceuuid;

    // a "node" is an object that holds all inputs that belong to this layout.
    // we use it to store changes concerning requirement/visibility and dispatch them safely to upper level to be used in validation, etc
    export let layoutNode ={}; 
    $:console.log(layoutNode);
    filesArray.forEach(input => {console.log(input, layoutNode); layoutNode[input.mapToVariableName] = {};});

    const dispatch = createEventDispatcher();
  
    //array of arrays: each individual array is a row, and the elements are field per column
    // row 1 = fieldsInRows[0] = [field1 (row 1, column 1), field2 {row 1, column 2}, ...]
    // row 2 = fieldsInRows[1] = [field1 (row 2, column 1), field2 {row 2, column 2}, ...]
    let filesInRows = [];
  
    let ready = false; //ready to display files?
  
  
    const findFileIndex = (identifier) => {
      // file fields come in an Array and rely on their indeces to be validated correctly.
      // we need to be able to get the inputsArray index even after they've been split according to their row in the layout
      // and the #each iterators are of no help
  
      //we'll use the mapVariable as a unique identifier
  
      let filter = filesArray.filter(
        (el) => el.mapToVariableName === identifier
      );
      return filesArray.indexOf(filter[0]);
    };
  
    const prepareFileFields = (filesArray) => {
        // 1. Split inputs according to their respective "row"

        // 2. Return file fields in arrays, one for each row

        // the reducer function creates a new Array for each different "r" (row) attribute and pushes the elements in them according to row value
        // Each Array is then pushed to a mama Array ( [] ), which is returned as resulting, array-like object
        if (filesArray.length > 0) {
          let tempresult = filesArray.reduce((r, o) => {
          Object.entries(o).forEach(([key, val]) => {
              if (key === "r") {
              if (r) (r[val] = r[val] || []).push(o);
          }
          });
          return r;
          }, []);
          //this array may be sparse, fill holes
          const result = Array.from(tempresult, (v, i) => i in tempresult ? 
            v
            :
            null
          );
          return result;
        }
        else {
          console.log("EMPTY FILES ARRAY")
          ready = true;
          return [];
        }
    };

    const prepareFileFieldsHelper = async (filesArray) => {
        //cover case where file fields may not have set row value (r is null);
        //this may happen for processes published before the migration script that added row and column attributes to file fields
        //check the first element
        //if it has no row, prepare whole array and assign row values based on element index to avoid rendering issues
        if (filesArray.length > 0) {
          return new Promise(async (resolve, reject) => {
            if (!filesArray[0].r) {
                prepareFilesWithNoRows(filesArray).then((resultingArray) => {
                    let rows = prepareFileFields(resultingArray);
                    resolve(rows);
                });
            }
            else {
                let rows = prepareFileFields(filesArray);
                resolve(rows);
            }
          });
        }
        else resolve([]);

    }

    const prepareFilesWithNoRows = async (filesArray) => {
        return new Promise(async (resolve, reject) => {
            let result = filesArray.map((el, index) => {
                el.r = index; 
                el.c = 0;
                return el;
            });
            resolve(result);
        })
    }

    const deleteFileFromList = (event) => {
        filesArray.forEach((file) => {
        if (file.fileTitle === event.detail.title) {
            file.file = null;
            file = file;
        }
        });
    };
    
    onMount(() => {
      //1.Prepare fields
      if (filesArray.length > 0) {
        prepareFileFieldsHelper(filesArray).then(result => {
          filesInRows = result;
          ready = true; //set this flag to true to display fields
        });
      }
      else {
        console.log("EMPTY FILES ARRAY")
        ready = true; //set this flag to true to display fields
        filesInRows = [];
      }

    });
  </script>
  
  <div class="container">
    {#if ready && filesInRows}
      {#each filesInRows as rowWithFiles, rowIndex}
        <div class="layout-row">
        {#if rowWithFiles}
          <div class="columns">
            {#each rowWithFiles as file, columnIndex}
              {#if file && file.mapToVariableName}
                <div class="singlefield column">
                    <Formfield
                    varInfo={$serviceVariables.find(v => v.id === file.mapToVariableName)}
                    bind:layoutNodeElement={layoutNode[file.mapToVariableName]}
                    mappedToVariable={file.mapToVariableName}
                    obligatory={file.obligatory}
                    bind:dependencyList={$dependencyListStore[file.mapToVariableName]}
                    bind:dependencyChanges={$dependencyChangesStore[file.mapToVariableName]}
                    squeezed={rowWithFiles.length===3}
                    {serviceuuid}
                    {disabled}
                    bind:value={filesArray[
                        findFileIndex(file.mapToVariableName)
                      ].file}
                    type="file"
                    label={file.fileTitle}
                    errors={$validationFileErrors && $validationFileErrors.length > 0
                    ? findErrors(
                        $validationFileErrors,
                        file.mapToVariableName.toString()
                        )
                    : []}
                    hasTemplate={file.templateFileURI != null}
                    bind:description={file.fileDescription}
                    templateFileURI={file.templateFileURI}
                    on:deletefile={(e) => {
                      deleteFileFromList(e);
                      $validationOngoing = true;
                      dispatch("validate");
                      }}
                    on:valueChanged={() => {
                      $validationOngoing = true;
                      dispatch("validate");
                      }}
                    on:valueCleared={() => {
                      $validationOngoing = true;
                      dispatch("validate");
                      }}
                    on:validate
                    />
                </div>
              {/if}
            {/each}
          </div>
        {/if}
        </div>
      {/each}
    {:else}
      <div class="is-size-4-mobile is-size-3 ">
        <Spinner
          size="100"
          speed="750"
          color="rgba(0, 255, 0, 0.3)"
          thickness="5"
          gap="40"
        />
      </div>
    {/if}
  </div>
  