<script>
import { url } from "@sveltech/routify";
import {clientFooterName, clientTerms, selectedOrgDTO} from "../../../ui-store.js";
import { onMount } from "svelte";

onMount(() => {
    //get store value from localStorage

    //clientFooterName.useLocalStorage();
    //clientTerms.useLocalStorage();
  });

</script>

<style>
  li {
    margin-top: 1rem;
  }
</style>

<div class="container app-container">
  <div class="columns is-vcentered">

    <div class="column mt-2">


      <!-- <h2 class="subtitile is-size-4-mobile is-size-3 my-6">
          Περιφέρεια Στερεάς Ελλάδας: Πολιτική απορρήτου
        </h2> -->
      <div class=" my-6">
        <div class="is-size-4">
          <!-- Περιφέρεια Στερεάς Ελλάδας -->
          {#if $selectedOrgDTO.officialURL}
            <a class="ghost-link"
              href={$url($selectedOrgDTO.officialURL)}
              target="_blank">{@html $clientFooterName}
            </a>
          {:else}
            {@html $clientFooterName}
          {/if}
        </div>
        <h3 class="title">Όροι Χρήσης</h3>
      </div>

      <div class="columns is-vcentered">
        <div class="column">
          {@html $clientTerms}
          <!-- <p class="my-4">Οι χρήστες της υπηρεσίας ηλεκτρονικής διαχείρισης αιτήσεων έχουν την υποχρέωση να τη
            χρησιμοποιούν σύμφωνα με τους παρακάτω όρους και κανόνες:</p>
          <div>
            <li>Απαγορεύεται η υποβολή ηλεκτρονικών αρχείων με περιεχόμενο που μπορεί να χαρακτηριστεί παράνομο βάση
                της ισχύουσας Ελληνικής και Ευρωπαϊκής νομοθεσίας. Επίσης, το περιεχόμενο των αρχείων δεν επιτρέπεται να
                προσβάλει, να απειλεί, να δυσφημεί και γενικότερα να παρενοχλεί τρίτους.</li>
            <li>Απαγορεύεται η υποβολή αρχείων που περιέχουν κακόβουλο λογισμικό ή οποιασδήποτε μορφής αρχεία που
                μπορούν να δημιουργήσουν πρόβλημα στο λογισμικό ή/και το υλικό των υπολογιστικών και δικτυακών
                συστημάτων.</li>
            <li>Απαγορεύεται η μαζική υποβολή αρχείων καθώς μπορεί να προκαλέσει δυσλειτουργίες σε υπηρεσίες και
                δικτυακές συσκευές.</li>
            <li>Η Περιφέρεια Στεράς Ελλάδας διατηρεί το δικαίωμα να εφαρμόζει στατιστική επεξεργασία των στοιχείων
                των υποβληθέντων ηλεκτρονικών αρχείων για λόγους ελέγχου και ασφάλειας.</li>
            <li>Η χρήση της υπηρεσίας συνεπάγεται την ανεπιφύλακτη αποδοχή, από το χρήστη, των παραπάνω κανόνων και
                όρων χρήσης αυτής.</li>
          </div> -->
        </div>

        <!-- <div class="column is-half">
            <h3 class="subtitile is-size-6-mobile is-size-4 mt-6"> Είστε Φορέας; </h3>
            <a role="button" class="{$highContrast?'has-background-link-dark has-text-white':'is-info'} button is-size-5-mobile is-size-4 mt-1" href="{$url('/admin')}">
              <strong>Συνδεθείτε εδώ</strong>
            </a>
          </div> -->
      </div>


      <!-- div below only exists to push the text divs up a bit -->
      <div class="hero my-6 is-hidden-mobile"></div>
    </div>



  </div>
</div>