import {
  profile,
  loggedIn,
  loggedTaxis,
  axiosInst,
} from "../credentials.js";
import { CookiesApi } from "../utils/helpers";
import { logout } from "../auth.js";
import { get } from 'svelte/store';
import {
  loggedFromClient,
  logging_out
} from "../ui-store.js";


//Enter an external entity Email and find the Entity's Id
export const getEntityByEmail = async (taxId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "externalEntities/byTax";
      const res = await get(axiosInst).get(url, {
        params: { entityTaxId: taxId },
      });
      if (res.status === 200) {
        console.log("getEntityByEmail successful call", res.data);

        resolve(res.data);
      } else {
        alert("getEntityByEmail :: something went wrong", res);
        reject();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Get data about a registered organization
export const getRegisteredOrganizationData = async (clientId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "registeredOrganization/" + clientId;
      const res = await get(axiosInst).get(url);
      if (res.status === 200) {
        console.log(
          "getRegisteredOrganizationData successful call",
          res.data.data
        );

        resolve(res.data.data);
      } else {
        alert("getRegisteredOrganizationData :: something went wrong", res);
        reject();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//See if a user's email belongs to a user group
export const getIfEmailBelongstoGroup = async (
  orgId,
  email,
  taxId,
  groupType
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url =
        "open1process/" +
        orgId +
        "/userGroups/emailBelongsToUserGroup?userEmail=" +
        email +
        "&afm=" +
        taxId +
        "&userGroupType=" +
        groupType;
      const res = await get(axiosInst).get(url);
      if (res.status === 200) {
        console.log("getIfEmailBelongstoGroup successful call", res.data.data);

        resolve(res.data.data);
      } else {
        alert("getIfEmailBelongstoGroup :: something went wrong", res);
        reject();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Upload a new file and relate it with process and possible element into the process
export const uploadFileIntoProcess = async (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "open1process/files";
      const res = await get(axiosInst).post(url, formData, {
        transformRequest: (formData, headers) => {
          return formData;
        },
      });
      if (res.status === 200) {
        console.log("uploadFileIntoProcess successful call", res.data.data);

        resolve(res.data.data);
      } else {
        alert("uploadFileIntoProcess :: something went wrong", res);
        reject();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Associate a proccess instance with an external entity and create new request
export const createNewUserRequest = async (userRequestContextDTO) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "externalEntities/createRequest";
      const res = await get(axiosInst).post(url, userRequestContextDTO);
      if (res.status === 200) {
        console.log("createNewUserRequest successful call", res.data);

        resolve();
      } else {
        alert("createNewUserRequest :: something went wrong", res);
        reject();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Start a new process instance
export const startNewProcessInstance = async (request_body, setBKey,entranceBKey) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "open1process/processes/start";
      const res = await get(axiosInst).post(url, request_body);
      if (res.status === 200) {
        console.log("startNewProcessInstance successful call", res.data);

        if (res.data.errors.length > 0) {
          console.log("An error occured" + ": " + res.data.errors);
          // add more actions...
          reject("something went wrong");
        } else {
          //no errors, move to next and final step
          console.log("Success!");
          //resolved.processId = res.data.data[0].instanceId;
          //resolves sending the object with process instance Id, and businesskey (whiich includes onnBehalf's name)
          let resolved_object = {
            resolved_processId: res.data.data[0].instanceId,
            resolved_bkey: null,
          };
          //if bkey vars are set, save request with them
          //also remove onBehalf's name from bkey, since it's only useful in process
          if (setBKey) resolved_object.resolved_bkey = entranceBKey;
          resolve(resolved_object);
        }
      } else {
        alert("startNewProcessInstance :: something went wrong", res);
        reject("something went wrong");
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Get an external entity
export const getExternalEntity = async (entityId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "externalEntities/" + entityId;
      const res = await get(axiosInst).get(url);
      if (res.status === 200) {
        console.log("getExternalEntity successful call", res.data.data);

        if (res.data.errors.length > 0) {
          console.log("Error" + ": " + res.data.errors);
          // add more actions...
          reject();
        } else {
          //no errors, move to next and final step
          console.log("Success!");

          resolve(res.data.data);
        }
      } else {
        alert("getExternalEntity :: something went wrong", res);
        reject("something went wrong");
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Validate an email verification token
export const validateEmailVerificationToken = async (email, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "verification/verify";
      const res = await get(axiosInst).post(url, {
        email: email,
        token: token,
      });
      if (res.status === 200) {
        console.log(
          "validateEmailVerificationToken successful call",
          res.data.data
        );

        if (res.data.errors.length > 0) {
          console.log("Error" + ": " + res.data.errors);
          // add more actions...
          reject("something went wrong");
        } else {
          //no errors, move to next and final step
          console.log("Success!");

          resolve(res.data.data);
        }
      } else {
        alert("validateEmailVerificationToken :: something went wrong", res);
        reject("something went wrong");
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Request for an email validation
export const requestEmailValidation = async (
  email,
  physicalEntityDTO,
  legalEntityDTO
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = "verification/";
      const res = await get(axiosInst).post(url, {
        email: email,
        physicalEntityDTO: physicalEntityDTO,
        legalEntityDTO: legalEntityDTO,
      });
      if (res.status === 200) {
        console.log("requestEmailValidation successful call", res.data.data);

        if (res.data.errors.length > 0) {
          console.log("Error" + ": " + res.data.errors);
          // add more actions...
          reject();
        } else {
          //no errors, move to next and final step
          console.log("Success!");

          resolve();
        }
      } else {
        alert("requestEmailValidation :: something went wrong", res);
        reject();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//Get a user group info
export const getUserGroup = async (orgId, userGroupIssuer) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url =
        "open1process/" + orgId + "/userGroups/group/" + userGroupIssuer;
      const res = await get(axiosInst).get(url);
      if (res.status === 200) {
        console.log("getUserGroup successful", res.data.data);
        resolve(res.data.data);
      } else {
        alert("getUserGroup error", res);
        reject();
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

//force logout from anonymous service page and reload
export const logoutForAnonymousService = (clientId, serviceId) => {
  let answer = confirm("Η υπηρεσία που επιλέξατε έχει επισημανθεί ως Ανώνυμη.\nΘα πραγματοποιηθεί αποσύνδεση. Θέλετε να συνεχίσετε;");
  if (answer) {
    logging_out.set(true);
    loggedIn.set(false);
    loggedTaxis.set(false);
    let orgUuid = get(loggedFromClient);
    profile.set(null);
    localStorage.removeItem("clientGsisId");
    localStorage.removeItem("GSISClientName");
    localStorage.removeItem("loggedFromClient");
    localStorage.removeItem("loggedFromClientName");
    CookiesApi.remove("gsisAuth", { path: "/" });
    CookiesApi.remove("sessionCookie", { path: "/" });
    //clientGsisId.useLocalStorage();
    //log the event first
    logEntranceLogout()
      .then(() => {
        // forces reload
        return logout(clientId,serviceId);
        // $goto("/client/:id/action/:aid/user/unregistered/new", { id: orgUuid, aid: serviceId });
        //history.pushState({}, null, 'http://localhost:9999/');
        // $goto("/");
      });
  }
  else {
    //if user wishes to not proceed, redirect them to client welcome page
    window.location.href = '/client/'+ clientId + '/welcome';
  }
};

const logEntranceLogout = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await get(axiosInst)
        .get("externalEntities/logEntranceLogout")
        .then(function (response) {
          if (response.status === 200) {
            //If status OK, see if we have errors
            console.log(res.data);
            if (response.data.errors && response.data.errors.length > 0) {
              console.log("An error occured" + ": " + response.data.errors);
              //logout anyway
              resolve();
            } else {
              //no errors
              console.log("log event success");
              //no errors, remove sessionId
              resolve();
            }
          } else {
            console.log("something went wrong");
            //logout anyway
            resolve();
          }
        });
    } catch (err) {
      console.log("Fetch Error :-S", err);
      resolve();
    }
  });
};
