<script>
  import { onMount } from "svelte";
  import Formfield from "../../components/Formfield.svelte";
  import { findErrors } from "../../utils/helpers.js";
  import { isActive, url, goto } from "@sveltech/routify";
  import yup from "yup";
  import {
    axiosInst,
    loggedIn,
    loggedTaxis,
    profile,
  } from "../../credentials.js";
  import Spinner from "svelte-spinner";

  let userEmail;
  let userFound = false;
  let errors;
  let schema = null;
  let userId = null;
  let message = null;
  let buttonMessage = "Συνέχεια";
  let emailVerified = false;
  let form;
  let loading = false;
  let verifyingEmail = false;
  let errorWithEmail = false;

  let user = {
    userEmail: null,
    userId: null,
    token: null, //email verification token
  };

  const validateForm = async () => {
    errors = null;
    console.log("calling validateForm");
    try {
      await schema.validate(user, { abortEarly: false });
    } catch (err) {
      if (err.name === "ValidationError") {
        console.log(err);
        errors = err;
      }
    }
  };

  //6-digit pin sent by email has been verified? Go to user's profile
  $: if (emailVerified) {
    //$loggedin = true;
    //$loggedUser = user.userId;
    // set store values in localStorage
    $loggedIn = true;
    $profile.id = user.userId;

    $goto("/user/:id", { id: user.userId });
  }

  $: if (errors && errors.inner)
    console.log(findErrors(errors.inner, "userEmail"));
  //if user has typed in a token, remove spaces
  $: if (user.token) {
    user.token = user.token.trim();
  }

  const findUserId = () => {
    //reset:
    message = null;

    let entityEmail = user.userEmail;
    return new Promise(async (resolve, reject) => {
      try {
        await $axiosInst
          .get("externalEntities/byEmail/" + entityEmail)
          .then(function (response) {
            if (response.status === 204) {
              console.log("No content found. Status Code: " + response.status);
              userFound = false;
              //reset form
              user.userEmail = null;
              message =
                "Δεν βρέθηκε καταχωρισμένος χρήστης με αυτό το email. Παρακαλώ προσπαθήστε ξανά";
              reject();
            }
            // Examine the text in the response
            else if (response.status === 200) {
              console.log(response.data);
              user.userId = response.data;

              userFound = true;
              //if the user is found, send them a verification token
              resolve(user.userEmail);
              //requestEmailVerification();
            } else {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              userFound = false;
              message =
                "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
              reject();
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  const requestEmailVerification = (email) => {
    loading = true;
    console.log(message);
    $axiosInst
      .post("verification/", {
        email,
        physicalEntityDTO: null,
        legalEntityDTO: null,
      })
      .then(function (response) {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          message = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
          loading = false;
          return;
        }
        // Examine the text in the response
        else {
          console.log(message);
          message =
            "Για την είσοδό σας στο σύστημα, σας έχει αποσταλεί ένας 6-ψήφιος κωδικός επιβεβαίωσης στη διεύθυνση e-mail που καταχωρίσατε.\nΟ κωδικός ισχύει για τα επόμενα 5 λεπτά.";
          loading = false;
          console.log(response.data.data);
        }
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
        loading = false;
      });
  };
  const checkEmailandSendPin = async () => {
    findUserId().then((email) => {
      requestEmailVerification(email);
    });
  };
  const verifyEmail = () => {
    //openModal = true;
    message = null;
    verifyingEmail = true;
    $axiosInst
      .post("verification/verify/", {
        email: user.userEmail,
        token: user.token,
      })
      .then(function (response) {
        console.log(response);
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          verifyingEmail = false;
          errorWithEmail = true;
          return;
        } else {
          //If status OK, see if we have errors
          console.log(response.data);
          if (response.data.errors.length > 0) {
            console.log("Unauthorized User" + ": " + response.data.errors);
            // add more actions...
            verifyingEmail = false;
            errorWithEmail = true;
            return;
          } else {
            //no errors, move to next and final step
            console.log("Success! Moving to next step");
            emailVerified = true;
            verifyingEmail = false;
            //activeStep++;
            console.log(response.data.data);
          }
        }
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };

  onMount(() => {
    schema = yup.object().shape({
      userEmail: yup
        .string()
        .required("This field is required")
        .email("Valid email required"),
      token: yup
        .number()
        .required("Υποχρεωτικό πεδίο")
        .test(
          "len",
          "Παρακαλώ είσαγετε 6-ψήφιο κωδικό",
          (val) => val.toString().length === 6
        )
        .typeError("Παρακαλώ είσαγετε 6-ψήφιο αριθμό"),
    });
  });
</script>

<div class="hero has-background-info-light py-6 mt-6">
  <div class="columns is-centered mb-3">
    <div class="column container">
      <div class="columns is-vertical is-centered">
        <div class="column is-5 px-6 mx-5">
          <h1 class="is-size-3 has-text-weight-bold has-text-info-dark ">
            Σύνδεση στο σύστημα
          </h1>
          <form
            class="container is-centered"
            on:change={validateForm}
            on:input={validateForm}
            bind:this={form}
          >
            <Formfield
              obligatory={true}
              bind:value={user.userEmail}
              type="email"
              label="e-mail"
              errors={errors && errors.inner
                ? findErrors(errors.inner, "userEmail")
                : []}
            />
            <!-- type message response from findUserId -->
            {#if message}
              <p>{message}</p>
            {/if}

            {#if verifyingEmail}
              Επιβεβαίωση κωδικού...
              <div class="is-size-5-mobile is-size-5 has-text-centered">
                <Spinner
                  size="100"
                  speed="750"
                  color="rgba(0, 255, 0, 0.3)"
                  thickness="5"
                  gap="40"
                />
              </div>
            {:else}
              {#if errorWithEmail}
                Παρουσιάστηκε πρόβλημα κατά την επικύρωση. Παρακαλώ προσπαθήστε
                ξανά.
              {/if}
              {#if userFound && !loading}
                <Formfield
                  obligatory={true}
                  bind:value={user.token}
                  type="email"
                  label="Εισάγετε τον 6-ψήφιο κωδικό επιβεβαίωσης"
                  errors={errors && errors.inner
                    ? findErrors(errors.inner, "token")
                    : []}
                />
              {:else if loading}
                <div class="is-size-5-mobile is-size-5 has-text-centered">
                  <Spinner
                    size="100"
                    speed="750"
                    color="rgba(0, 255, 0, 0.3)"
                    thickness="5"
                    gap="40"
                  />
                </div>
              {/if}
            {/if}
          </form>

          <div class="container level py-2">
            <div class="level-left">
              <!-- BLANK AREA TO PUSH LEVEL ITEM BELOW TO RIGHT -->
              {#if errorWithEmail}
                <button
                  class="button is-primary is-light is-size-6-desktop is-size-6"
                  on:click={() => {
                    requestEmailVerification(user.userEmail);
                  }}>Επαναποστολή κωδικού</button
                >
              {/if}
            </div>
            <div class="level-right">
              {#if !userFound}
                <button
                  class="level-item button is-size-5-mobile is-size-6 is-link"
                  on:click={checkEmailandSendPin}>{buttonMessage}</button
                >
              {:else if userFound && !loading}
                <button
                  class="level-item button is-size-5-mobile is-size-6 is-link"
                  on:click={verifyEmail}>Υποβολή Κωδικού</button
                >
              {/if}
            </div>
          </div>
        </div>

        <div class="column mt-6">
          <figure class="image is-2by1">
            <!-- <img src="../../../images/splashimage.jpg"> -->
            <!-- <img src="../../../images/tomokanga.svg"> -->
            <img
              src="../../../../images/client2.svg"
              role="img"
              alt="Πύλη προς τις Δημόσιες Υπηρεσίες. Κάντε κλικ για να ξεκινήσετε"
              title="Ξεκινήστε εδώ"
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
  <div class="column is-4" />
</div>

<style>
  .margin-top-1 {
    margin-top: 1rem;
  }
</style>
