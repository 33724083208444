<script>
  import { url, params, goto, beforeUrlChange, ready } from "@sveltech/routify";
  import { organizations } from "../../../../../../../_clients.js";
  import {
    isSystemUp,
    selectedClient,
    selectedOrgDTO,
    isUserInGroup,
    currentUserGroup,
    userCheckedIfInGroup,
    validationOngoing,
    fileSizeLimitOK,
    mainValidationSchema,
    validationErrors,
    validationFileErrors,
    serviceFieldNodes,
  } from "../../../../../../../ui-store.js";
  import {
    checkFiles,
    findErrors,
    checkFilesTotalSize,
    findNestedObjectByKeyValue,
    isObject
  } from "../../../../../../../utils/helpers.js";
  import { onMount, setContext } from "svelte";
  import yup from "yup";
  import {
    profile,
    loggedIn,
    loggedTaxis,
  } from "../../../../../../../credentials.js";
  import Taxis from "../../../../../../../components/taxis-login.svelte";
  import Stepper from "../../../../../../../components/Stepper.svelte";
  import Formfield from "../../../../../../../components/Formfield.svelte";
  import Dropzone from "../../../../../../../components/Dropzone.svelte";
  import FormLayout from "../../../../../../../components/FormLayout.svelte";
  import { fade, blur } from "svelte/transition";
  import Spinner from "svelte-spinner";
  import Fa from "svelte-fa";
  import {
    faChevronLeft,
    faChevronRight,
  } from "@fortawesome/free-solid-svg-icons";
  import { CheckSquareIcon, CheckIcon } from "svelte-feather-icons";
  import { faFrown } from "@fortawesome/free-regular-svg-icons";
  import { getCookie } from "../../../../../../../auth.js";
  import {
    getRegisteredOrganizationData,
    getEntityByEmail,
    getIfEmailBelongstoGroup,
    uploadFileIntoProcess,
    createNewUserRequest,
    startNewProcessInstance,
    getExternalEntity,
    validateEmailVerificationToken,
    requestEmailValidation,
    getUserGroup,
    logoutForAnonymousService
  } from "../../../../../../../services/newUserRequestService.js";

  $: console.log("mainValidationSchema: ", $mainValidationSchema);
  // this object holds and is filled with all info about input fields from database. 
  // keeps the original values of visibility and requirement for each field
  let emailVerificationInfo = {
    email: null,
    legalEntityDTO: {
      address: "Μοναστηρίου 60",
      title: "ots",
    },
    physicalEntityDTO: {
      address: "",
      firstName: null,
      lastName: null,
      //uncomment below and comment line above to use first name as fullname field
      //"lastName": '.' //made it an empty string in order to use firstName as a fullName
    },
    inputs: [],
    files: [],
    file: null,
    comments: null,
  };

  //if service is anonymous, edit properties:
  $: if (selectedProcess && selectedProcess.authenticationRequired === "ANONYMOUS") {
    emailVerificationInfo.email = "anon@email.com";
    emailVerificationInfo.taxId = "00000000";
    emailVerificationInfo.physicalEntityDTO.firstName = "ΑΝΩΝΥΜΟΣ";
    emailVerificationInfo.physicalEntityDTO.lastName = "ΧΡΗΣΤΗΣ";
  }

  //if service is anonymous, edit properties:
  $: if (selectedProcess && selectedProcess.authenticationRequired === "NO_VERIFICATION") {
    emailVerificationInfo.email = "anon@email.com";
    emailVerificationInfo.taxId = "00000000";
    emailVerificationInfo.physicalEntityDTO.firstName = "ΑΝΩΝΥΜΟΣ";
    emailVerificationInfo.physicalEntityDTO.lastName = "ΧΡΗΣΤΗΣ";
  }

  // the serviceFieldNodes have all input fields bound with information about groups (each node is a field group)
  // and current visibility/requirement values for each input
  // initialize mail input in nodes
  $serviceFieldNodes["email"] = {};
  // more initialization
  // update nodes, if following input fields exist
  $:if ($serviceFieldNodes["email"] && emailVerificationInfo && emailVerificationInfo.email) $serviceFieldNodes["email"] =
      {
        "obligatory": true,
        "isInvisible": false,
        "value": emailVerificationInfo.email
      };      

  // $: if ( selectedProcess && selectedProcess.authenticationRequired === "NO_VERIFICATION")
  //   {
  //     $loggedIn = false;
  //   }
    
  $: if ( selectedProcess && !selectedProcess.userGroupIssuer )
    {
      // $currentUserGroup = null;
      $isUserInGroup = true;
    }

  // is there another service used to provide extra authentication for the users?
  //  (eg make sure that users belong in a group, or a service that lets them request access to a certain group)
  let authenticationServiceId = null;

  // IF SERVICE IS TO BE STARTED BY SPECIFIC USER GROUP (userGroupIssuer has a value)? Find it
  let gotUserGroupInfo = false;
  $: if (
    selectedProcess &&
    selectedProcess.userGroupIssuer &&
    !gotUserGroupInfo
  ) {
    getUserGroup($selectedOrgDTO.id, selectedProcess.userGroupIssuer).then(
      (group) => {
        gotUserGroupInfo = true;
        $currentUserGroup = group;
        $userCheckedIfInGroup = false;

        if (selectedProcess.authenticationServiceUuid) {
          //find service's entrance id:
          authenticationServiceId = $selectedOrgDTO.servicesProvided.filter(
            (s) => s.serviceUuid === selectedProcess.authenticationServiceUuid
          )[0].id;
        }
      }
    );
  }

  $: console.log("are we logged in? ", $loggedIn);
  $: console.log("userid ", $params.tid);

  function gotoClient() {
    $goto("/client/:id/welcome", { id: $params.id });
  }

  let opentermsmodal = false; //user wants to see the terms and conditions
  let user = null;

  const toggletermsmodal = () => {
    opentermsmodal = !opentermsmodal;
  };

  //FOR TUKANGA: Upload a new file and relate it with process and possible element into the process
  let testUploadFile;
  let processNum = null;

  //issuer data is needed in headers to call updateVars
  let headerValues = {
    Open1SSOOrg: null,
    Open1SSOStruct: null,
    Open1SSOUnit: null,
    Open1SSORole: null,
    Open1SSOPerson: null,
  };

  let isLoading = true;
  // let fileErrors = [];
  let form;
  let isValid = false; //are fields valid? (sans file uploads)

  let processIsStarting = true;
  let errorWithProcess = false;
  let verifyingEmail = false;
  let errorWithEmail = false;
  let emailVerified = false;

  // let selectedClient = null;
  $: console.log($selectedClient);
  // let selectedOrgDTO = null;
  $: console.log("fileErrors: ", $validationFileErrors);
  let selectedProcess = null;

  //documents uploaded by the user
  let documentsUploaded = [];
  // they're an array of such objects:
  // = [
  //   {
  //   "fileTitle" : null,
  //   "fileDescription" : null,
  //   "versionedFileId" : null
  //   }
  // ]
  $: console.log(documentsUploaded);

  //if the process initialization isn't complete, let user know before they leave the page
  $beforeUrlChange((event, store) => {
    //the user has completed the process initialization, or the service was not found, let them redirect
    if ((activeStep === 3 && !processIsStarting) || !selectedProcess) return true;
    //if the process demands taxis verification and user is not logged in, it means they didn't start anything. Let them go
    if (
      selectedProcess &&
      (selectedProcess.authenticationRequired ===
        ("TAXID_VERIFICATION" || "TAXID_OTP_VERIFICATION")) &&
      !$loggedTaxis
    )
      return true;
    //else make them confirm the redirect
    let cont = confirm("Θέλετε να αποχωρήσετε από τη συμπλήρωση της αίτησης;");
    return cont;
  });

  //FIXME
  // set some context about the org and service we're currently using
  $: if ($selectedClient && selectedProcess) {
    setContext(
      "state",
      $selectedClient.id
        .toString()
        .concat("_")
        .concat(selectedProcess.id.toString())
    );
    setContext("organization", $selectedOrgDTO);
  }
  let onBehalfOfDTO;
  let datasourcesReady;
  $: console.log("datasourcesReady", isValid);
  $: if (isValid) datasourcesReady = true;
  $: if ($selectedOrgDTO && emailVerificationInfo && selectedProcess) {
    // let forDatasources = {
    //                 "organizationId": selectedOrgDTO.open1ProcessOrganizationId,
    //                 "processDefinitionId":selectedProcess.id,
    onBehalfOfDTO = {
      emailAddress: emailVerificationInfo.email,
      firstName: emailVerificationInfo.physicalEntityDTO.firstName,
      lastName: emailVerificationInfo.physicalEntityDTO.lastName,
      phoneNumber: null,
      afm: emailVerificationInfo.taxId,
      fatherName: $profile.fathername,
      motherName: $profile.mothername,
      birthYear: $profile.birthyear,
    };
  }

  let activeStep = 1; //by default, when we start a process, the active step is the first one

  //6-digit pin sent by email has been verified? Close the modal and move to the next step
  $: if (emailVerified) {
    openVerificationModal = false;
    activeStep++;
    chainPromises();
  }

  // handle step2 of form submission (authentication etc)
  const handleStep2 = () => {
    if (
      (!$loggedIn && !(selectedProcess.authenticationRequired === "ANONYMOUS")) ||
      ($loggedIn &&
        selectedProcess.authenticationRequired === "TAXID_OTP_VERIFICATION")
    ) {
      openVerificationModal = true;
      requestEmailValidation(
        emailVerificationInfo.email,
        emailVerificationInfo.physicalEntityDTO,
        emailVerificationInfo.legalEntityDTO
      );
    } else emailVerified = true;
  };

  let token = null; //email verification token
  //if user has typed in a token, remove spaces
  $: if (token) {
    token = token.trim();
  }

  let openVerificationModal = false;

  const setRequestHeaders = (issuerResourceId) => {
    //mostly needed for updateProcessVars
    //tomokanga doesn't login via SSO for now, so no orgApi data can be set from commons.
    //here, we set headers with orgApi data for Tukanga to use, using info from the serviceIssuer (string path with org, struct, unit, role and person uuids)
    //the variables for the headers are automatically set as soon as the client and process id are set,
    //and are therefore ready to use when updateVars is called
    let orgdataArray = issuerResourceId.split(":");
    console.log(orgdataArray);
    headerValues.Open1SSOOrg = orgdataArray[0];
    headerValues.Open1SSOStruct = orgdataArray[1];
    headerValues.Open1SSOUnit = orgdataArray[2];
    headerValues.Open1SSORole = orgdataArray[3];
    headerValues.Open1SSOPerson = orgdataArray[4];
  };

  const verifyEmail = () => {
    //openModal = true;
    verifyingEmail = true;
    return validateEmailVerificationToken(
      emailVerificationInfo.email,
      token
    ).then((verificationToken) => {
      if (verificationToken === "something went wrong") {
        verifyingEmail = false;
        errorWithEmail = true;
      } else {
        emailVerified = true;
        verifyingEmail = false;
      }
      return;
    });
  };

  let userloading = false;
  const getUser = () => {
    userloading = true;
    return getExternalEntity($params.tid).then((externalEntity) => {
      userloading = false;
      return externalEntity;
    });
  };

  const findVarsThatTurnedInvisible = () => {
    // we need to compare serviceFieldNodes store with initial info from database (emailVerificationInfo)
    // console.log("serviceFieldNodes: ", $serviceFieldNodes);
    // console.log("emailVerificationInfo: ", emailVerificationInfo);

    let invisibleNow = [];
    let turnedInvisible = [];
    // search all nodes and see which of their fields are/have turned invisible
    Object.entries($serviceFieldNodes).forEach(([nodeKey, nodeValue]) => {
      if (isObject(nodeValue)) {
        // if the node has fields
        Object.entries(nodeValue).forEach(([key, val]) => {
          if (isObject(val)) {
            // if the field has properties
            // if the invisibility property is true, add the key (variableId) to array
            if (("isInvisible" in val) && val.isInvisible) invisibleNow.push(key);
            invisibleNow = invisibleNow;
          }
        });
      }
    });

    invisibleNow.forEach(invisibleVarName => {
      // find this variable in original object and if the original invisiblity was false, add it to "turnedInvisible" array
      let originalVarValues = findNestedObjectByKeyValue(emailVerificationInfo, "mapToVariableName", invisibleVarName);
      if (!originalVarValues.isInvisible) {
        turnedInvisible.push(invisibleVarName);
        turnedInvisible = turnedInvisible;
      }

    });

    return turnedInvisible;
  }

  //-------------------------------------------MOVE FILE UPLOAD HERE------------------------------------------------------------
  const startProcess = async (data_array) => {
    
    //this object holds the values needed for updateProcessVars which will be called after startProcess resolves
    let resolved = { processId: null, vars_values_array: [] };
    // push values regarding file variables.
    // We do this, because in order to get the input value, the files need to be uploaded via a different method
    resolved.vars_values_array.push(...data_array);
    resolved.vars_values_array = resolved.vars_values_array;

    // so now, we need to prevent file vars from being added again
    // //if the service uses a form, it is very possible that fileFields are scattered in various nodes 
    // // find them by searching TYPE_FILE in validationObject nodes
    // the file variables have already been added to vars_values_array
    let validationObject = prepareValidationNodes();

    let fieldsToSet = [];
    Object.entries(validationObject).forEach(([nodeKey, nodeValue]) => {
        if (Array.isArray(nodeValue)) {
          fieldsToSet = fieldsToSet.concat(
          nodeValue.filter((item) => !("file" in item)));
        }
    });

    //push values regarding inputs
    fieldsToSet.forEach((input) => {
      resolved.vars_values_array.push({
        value: input.inputvalue,
        var: input.mapToVariableName,
      });
      resolved.vars_values_array = resolved.vars_values_array;

      // remove fields that have become invisible by a user action
      let varsTurnedInvisible = findVarsThatTurnedInvisible();
      resolved.vars_values_array = resolved.vars_values_array.filter( v => {
        if (varsTurnedInvisible.indexOf(v.var) === -1) return v;
      });
      
    });
    //only pass values (ids) from uploaded files array (data_array, that is)
    let ids = data_array.map((el) => el.value);

    //does the business key have any nmore useful info that needs to be set via bkeyvariables?
    //this flag is used to send the bkey to createUserRequest
    let setBKey = false;
    let entranceBKey = null;

    //set some attributes to request body first

    let request_body = {
      associatedFiles: ids,
      businessKey: "string",
      comments: emailVerificationInfo.comments,
      onBehalfOfDTO: {
        emailAddress: emailVerificationInfo.email,
        firstName: emailVerificationInfo.physicalEntityDTO.firstName,
        lastName: emailVerificationInfo.physicalEntityDTO.lastName,
        phoneNumber: null,
        afm: emailVerificationInfo.taxId,
        fatherName: $profile.fathername,
        motherName: $profile.mothername,
        birthYear: $profile.birthyear,
      },
      priority: "normal",
      registeredOrganizationId: 0,
      serviceId: 0,
      variables: {},
    };

    //catch any input fields with non-null bkey positions and add them to array
    let bKeyVars = emailVerificationInfo.inputs
      .filter((field) => field.bkeyPos != null && field.bkeyPos >= 0)
      .sort((a, b) => a.bkeyPos - b.bkeyPos)
      .map((el) => el.inputvalue);

    //we have info from bkey vars that should be saved
    if (bKeyVars.length > 0) {
      setBKey = true;
      entranceBKey = selectedProcess.serviceName + " " + bKeyVars.join(" ");
    }

    request_body.businessKey =
      selectedProcess.serviceCategory +
      " > " +
      selectedProcess.serviceName +
      " ( " +
      request_body.onBehalfOfDTO.firstName +
      " " +
      request_body.onBehalfOfDTO.lastName +
      " ) " +
      bKeyVars.join(" ");
    request_body.registeredOrganizationId = $selectedClient.id;
    request_body.serviceId = selectedProcess.id;
    //set variables hashmap
    resolved.vars_values_array.forEach((entry) => {
      request_body.variables[entry.var] = entry.value;
    });

    console.log(request_body);

    return startNewProcessInstance(request_body, setBKey, entranceBKey).then(
      (newProcessStarted) => {
        if (newProcessStarted === "something went wrong") {
          errorWithProcess = true;
          processIsStarting = false;
        } else {
          errorWithProcess = false;
          processIsStarting = false;
        }
        return newProcessStarted;
      }
    );
  };

  const createUserRequest = async (
    uploads_data,
    processInstanceId,
    business_key
  ) => {
    //the data comes from file upload, it's fileIDs each associated with user uploads
    //we will then set values from service and onBehalfOf info
    //if business_key is null, we set nothing there
    //the requests's userId will be set from api
    let today = new Date();
    let date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + " " + time;
    //set some attributes to request body first
    let userRequestContextDTO = {
      attributesDTO: {
        emailAddress: emailVerificationInfo.email,
        firstName: emailVerificationInfo.physicalEntityDTO.firstName,
        lastName: emailVerificationInfo.physicalEntityDTO.lastName,
        phoneNumber: null,
      },
      requestDTO: {
        documentsProduced: [],
        documentsUploaded: uploads_data,
        orgTitle: $selectedClient.title,
        orgId: $params.id,
        date: dateTime,
        processInstanceId: processInstanceId,
        serviceName: selectedProcess.serviceName,
        serviceUuid: selectedProcess.serviceUuid,
        businessKey: business_key,
        status: "Σε εξέλιξη",
        protocolNumber: null,
      },
      taxId: user.taxId,
    };
    console.log(userRequestContextDTO);
    await createNewUserRequest(userRequestContextDTO);
    return;
  };

  const fileUpload = async (filedata, vars_values_array) => {
    console.log(filedata);
    //  filedata =
    // {
    //   file: FileList {0: File, length: 1}
    //   fileDescription: "Φωτοτυπία ταυτότητας"
    //   fileTitle: "Φωτοτυπία ταυτότητας"
    //   mapToVariableName: "IDphoto"
    //   obligatory: false
    //   templateFileURI: null
    // }
    let formData = new FormData();
    if (filedata && filedata.file && selectedProcess.serviceIssuer) {
      formData.append("file", filedata.file[0]);
      formData.append("alias", filedata.fileTitle);
      formData.append("orgUUId", selectedProcess.serviceIssuer.split(":")[0]);
      formData.append(
        "orgStructureUUId",
        selectedProcess.serviceIssuer.split(":")[1]
      );
      formData.append(
        "orgUnitUUId",
        selectedProcess.serviceIssuer.split(":")[2]
      );
      formData.append("roleUUId", selectedProcess.serviceIssuer.split(":")[3]);
      formData.append(
        "personUUId",
        selectedProcess.serviceIssuer.split(":")[4]
      );
    }

    formData.append("registeredOrganizationId ", $selectedOrgDTO.id);

    return uploadFileIntoProcess(formData).then((uploadedFile) => {
      let doc = {
        fileTitle: filedata.fileTitle,
        fileDescription: filedata.fileTitle,
        versionedFileId: uploadedFile.versionedFileId,
      };
      documentsUploaded.push(doc);
      // ===============
      vars_values_array.push({
        value: uploadedFile.versionedFileId,
        var: filedata.mapToVariableName,
      });
      vars_values_array = vars_values_array;
      return uploadedFile;
    });
  };

  const setRequestedFilesToVariables = (requested_inputs, requested_files) => {
    //set a variables array to bind to field and file inputs, according to info from the requested Service
    //runs every time we call a new Process from a Client
    console.log(requested_files);

    emailVerificationInfo.files = []; //set to empty, to avoid duplicating requested inputs
    emailVerificationInfo.inputs = []; //set to empty, to avoid duplicating requested inputs
    return new Promise(async (resolve, reject) => {
      requested_files.forEach((file) => {
        file.file = null;
        file.isInvisible = false;
        emailVerificationInfo.files.push(file);
      });
      requested_inputs.forEach((input) => {
        input.isInvisible = false;
        emailVerificationInfo.inputs.push(input);
      });
      emailVerificationInfo.files = emailVerificationInfo.files;
      emailVerificationInfo.inputs = emailVerificationInfo.inputs;

      resolve(emailVerificationInfo);
    });
  };

  const findProcess = (selectedClient) => {
    return new Promise(async (resolve, reject) => {
      let filter = $selectedOrgDTO.servicesProvided.find(
        (action) => action.id.toString() === $params.pid
      );
      console.log(filter);
      if (filter) {
        selectedProcess = filter;
      } 
      else {
        selectedProcess = null;
        reject("invalid service id")
      }

      //fill out file upload fields in userInfo object
      setRequestedFilesToVariables(
        selectedProcess.inputFields,
        selectedProcess.filesRequested
      );
      resolve(selectedProcess);
    });
  };
  $: console.log("Files to be filled: ", emailVerificationInfo.files);

  function findClient() {
    return new Promise(async (resolve, reject) => {
      // let filter1 = $clients.filter((client) => client.id === $params.id);
      let filter2 = $organizations.filter(
        (client) => client.id.toString() === $params.id
      );

      if (filter2.length > 0) {
        console.log("found client in registered organizations ...");
        $selectedClient = filter2[0];

        //api call to get registered organization data
        $selectedOrgDTO = await getRegisteredOrganizationData(
          $selectedClient.id
        );
        isLoading = false;
        resolve($selectedOrgDTO);
        //end api call, continue in
      }
    });
    // else throw new Error("an error occured"); //disabled because of routing trouble after the error is thrown
  }

  $: if ($params && $organizations) init();

  $: console.log(selectedProcess);
  //------------------------------------------THE MAGIC ------------------------------------------------------
  const chainUploads = async () => {
    //resolves all file uploads. By the end of it, the [{id, var}] array is complete, and ready to be used by startProcess and updateVars

    var ids = [];
    var promises = [];

    let filesToUpload = [];
    let validationObject = prepareValidationNodes();
    // usually, all fileFields are in a separate node (no form, no nested layouts)
    // but if the service uses a form, it is very possible that fileFields are scattered in various nodes 
    // find them by searching TYPE_FILE in validationObject nodes (with updated properties)

    Object.entries(validationObject).forEach(([nodeKey, nodeValue]) => {
      if (Array.isArray(nodeValue)) {
        filesToUpload = filesToUpload.concat(
          nodeValue.filter((item) => ("file" in item) && (item.file != null))
        );
      }
    });

    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < filesToUpload.length; i++) {
        promises.push(fileUpload(filesToUpload[i], ids));
        promises = promises;
        console.log("resolving file " + i);
      }
      Promise.all(promises)
        .then((results) => {
          console.log("Resolved all files " + JSON.stringify(results));
          console.log(ids);
          resolve(ids);
        })
        .catch((e) => {
          // handle errors here
          errorWithProcess = true;
          processIsStarting = false;
          reject(e);
        });
    });
  };

  let errorFindingService = false;

  const init = async () => {
    findClient().then((result) => {
      console.log(result);
      return findProcess(result).then(async (res) => {
        console.log(res);
        errorFindingService = false;
        setRequestHeaders(res.serviceIssuer);
        if (res.authenticationRequired === "ANONYMOUS" && $loggedTaxis) logoutForAnonymousService($params.id, $params.pid);
      })
      .catch ((e) => {
        errorFindingService = true;
        console.log("Specified service could not be found. Id: ", $params.pid);
      });
    });
  };

  const chainPromises = async () => {
    chainUploads().then((result) => {
      console.log(result);
      return startProcess(result).then((resolved_object) => {
        processNum = resolved_object.resolved_processId;
        return createUserRequest(
          documentsUploaded,
          resolved_object.resolved_processId,
          resolved_object.resolved_bkey
        );
      });
    });
  };
  //----------------------------------------VALIDATION PREPARATION -----------------------------------------------
  let schema;

  onMount(() => {
    console.log("running GETUSER ");

    if ($params.tid !== "unregistered") {
      getUser().then((userdata) => {
        console.log("GetUser Resolve", userdata);
        user = userdata;
        console.log("GetUser user", user);
        emailVerificationInfo.physicalEntityDTO.firstName =
          userdata.physicalEntityDTO.firstName;
        emailVerificationInfo.physicalEntityDTO.lastName =
          userdata.physicalEntityDTO.lastName;
        emailVerificationInfo.taxId = userdata.taxId;
        console.log(userdata);
        //sort requests
        user.requests = user.requests.sort(
          (a, b) => parseInt(b.id) - parseInt(a.id)
        );
        user.requests = user.requests;
        userloading = false;
      });
    } else {
      console.log("user is unregistered, ", $profile);
      //if user param is "unregistered", run a check that no user is logged in anyway.
      // profile store is filled by cookie data via authenticate method.
      //if there is a logged user, find them. Redirect to new request page with user data in url

      let gsisAuth = getCookie("gsisAuth");
      if (gsisAuth && gsisAuth.accessToken) {
        console.log("Got the cookie!");
        //get user info

        console.log("Getting user info....");
        console.log("user is unregistered, ", gsisAuth.info);
        $profile = gsisAuth.info;

        return new Promise(async (resolve, reject) => {
          try {
            await getEntityByEmail($profile.taxId).then((id) => {
              $profile.id = id;
              console.log("SUCCESSFULLY GOT USER", $profile.id);
              if ($profile.id) {
                window.location.href =
                  window.location.href.split("/user")[0] +
                  "/user/" +
                  $profile.id +
                  "/new";
              }
              resolve();
            });
          } catch (err) {
            console.log("Fetch Error :-S", err);
            reject(err);
          }
        });
      }
    }

    // init();

    const requiredSchema = yup
      .string()
      .required("Αυτό το πεδίο είναι υποχρεωτικό");
    // ------------------------------------------------ VALIDATION ---------------------------------------
    // schema to validate userInfo. The validation schema for inputs and files will be appeneded to this
    schema = yup.object().shape({
      email: yup
        .string()
        .required("Αυτό το πεδίο είναι υποχρεωτικό")
        .email("Μη έγκυρη διεύθυνση e-mail"),
      physicalEntityDTO: yup.object().shape({
        firstName: yup.string().concat(requiredSchema),
        lastName: yup.string().concat(requiredSchema),
      }),
    });

    $mainValidationSchema = schema;
    $validationErrors = {}; //reset errors

    //set onGoingValidaton to true, to avoid reactive statements running before validation is complete after first change
    $validationOngoing = true;
  }); //end onMount

  $: if (activeStep === 2) {
    window.scrollTo(0, 0);
  }

  const handleNewNode = (e) => {
    console.log("handleNewNode: ", e);
    // add new node to emailVerificationInfo object

    emailVerificationInfo[e.detail.node] = e.detail.inputs;
    // remove those inputs from main schema
    if (e.detail.node !== "inputs") {
      let inputIds = e.detail.inputs.map((i) => i.mapToVariableName);
      emailVerificationInfo.inputs = emailVerificationInfo.inputs.filter(
        (input) => !inputIds.includes(input.mapToVariableName)
      );
    }
  };

  const validateFileFields = (validationObject) => {
    //if the service uses a form, it is very possible that fileFields are scattered in various nodes 
    // find them by searching TYPE_FILE in validationObject nodes
    if (selectedProcess.formId) {
      let filesarr = [];
      Object.entries(validationObject).forEach(([nodeKey, nodeValue]) => {
        // ignore the default node "files" in this case
        if (!(nodeKey === "files") && Array.isArray(nodeValue))
          filesarr = filesarr.concat(
            nodeValue.filter((item) => item.type === "TYPE_FILE")
          );
      });
      console.log(filesarr);
      $validationFileErrors = checkFiles(filesarr);
    } else {
      // validate all files from default "files" node
      $validationFileErrors = checkFiles(validationObject.files);
    }
  };


  const prepareValidationNodes = () => {
    // what happens here is that we match every field's invisible and obligatory properties that may have changed due to dependencies
    // the object for validation is emailVerificationInfo
    // the invisible/obligatory dependency changes are held in store
    // we return a new, modified validation object
    // use js native structuredClone to deepCopy fileLists as well and return an immutableobject to edit obligatory/inbisible properties
    // without altering original object
    let modifiedValidationObj = structuredClone(emailVerificationInfo);
    console.log(emailVerificationInfo, modifiedValidationObj);

    Object.entries(modifiedValidationObj).forEach(([nodeKey, nodeValue]) => {
      // ignore the default node "files" if the service uses a form
      if ( !(selectedProcess.formId && nodeKey==="files") && Array.isArray(nodeValue)) {
        for (let i=0; i<nodeValue.length; i++) {
          let target = findNestedObjectByKeyValue($serviceFieldNodes, "mapToVariableName", nodeValue[i].mapToVariableName);
          // if the dependency changes note that the field should become obligatory, make it obligatory
          // else, keep its original requirement status
          nodeValue[i].obligatory = target.obligatory ? target.obligatory : nodeValue[i].obligatory;
          nodeValue[i].isInvisible = target.isInvisible;
          }
        nodeValue = nodeValue;
        }
      });
    return modifiedValidationObj;
  }

  const validateForm = async (validationObject) => {

    validateFileFields(validationObject);
    // $validationOngoing = true;
    return new Promise(async (resolve, reject) => {
    let errs = null;
      try {
        await $mainValidationSchema.validate(validationObject, { abortEarly: false });
      } catch (err) {
        if (err.name === "ValidationError") {
          console.log(err);
          errs = err;
        } else reject();
      } finally {
        resolve(errs);
      }
    });
  };

  const isFormValid = async (validationObject, fileErrors) => {
    //validate schema with yup, crosscheck with custom file validation errors (fileErrors array)
    let isValidLocal = null;
    return new Promise(async (resolve, reject) => {
      let promise = await $mainValidationSchema.isValid(validationObject).then((data) => {
        if (data && fileErrors.length === 0 && $isSystemUp && $isUserInGroup){
          isValidLocal = true;
        }
        else isValidLocal = false;

        //check attachments' total size
        if ($selectedOrgDTO.maxFileSizeMb) {
          $fileSizeLimitOK = checkFilesTotalSize(
            validationObject.files,
            $selectedOrgDTO.maxFileSizeMb
          );
        }
        resolve(isValidLocal);
      });      

    });
  };

  const chainValidations = () => {
    //general validation in upper level ALWAYS triggers file validation in all filefields
    let validationObject = prepareValidationNodes();
    // let promise1 = await validateForm();
    // let promise2 = await isFormValid(checkFiles(emailVerificationInfo.files));
    Promise.all([validateForm(validationObject), isFormValid(validationObject, $validationFileErrors)]).then((values) => {
      $validationErrors = values[0];
      isValid = values[1];
      $validationOngoing = false;
    });
  }

  //onBehalf's email field might need to be validated against a user group
  let userEmailGroupValidation = null;
  $: if (!$isUserInGroup)
    userEmailGroupValidation =
      "Αυτό το e-mail δεν έχει πιστοποιηθεί ακόμη για την ομάδα: " +
      $currentUserGroup.groupName;
  else userEmailGroupValidation = null;

  const handleUserGroupValidation = () => {
    $userCheckedIfInGroup = false;
    getIfEmailBelongstoGroup(
      $selectedOrgDTO.id,
      emailVerificationInfo.email,
      emailVerificationInfo.taxId,
      $currentUserGroup.groupType
    ).then((response) => {
      $isUserInGroup = response;
      $userCheckedIfInGroup = true;
    });
  };
</script>

<!---------------------------------------------------------------------- HTML STARTS --------------------------------------------------------->

<div class="container app-container mb-6">
  <!-- if we're awaiting for a confirm message (redirection for anonymous service), 
    don't show error message just yet -->
  {#if errorFindingService}
    <!--  -->
    <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
      <p class="is-size-4">
        Η υπηρεσία που αιτηθήκατε δεν βρέθηκε. 
      </p>
      <div class="level mt-3">
        <div class="level-left">
          <div class="level-item">
            <button
                style="white-space: normal;"
                class="button is-link has-background-link-dark is-size-5-desktop is-size-6-mobile"
                on:click={() => {
                  $goto("/client/:id/welcome", {
                    id: $selectedClient.id,
                  });
                }}
              >
              <Fa icon={faChevronLeft} size="1x" /> &nbsp; Επιστροφή σε {$selectedClient.title}
            </button>
          </div>
        </div>
      </div>
    </div>
  {:else if selectedProcess}
    {#if ($loggedIn && $profile.id == $params.tid) || $params.tid == "unregistered"}
      {#if !isLoading && $selectedClient && !userloading}
        <!-- control: if the process demands taxis verification and we're not logged in, show a message -->
        {#if !((selectedProcess.authenticationRequired === "TAXID_VERIFICATION" || selectedProcess.authenticationRequired === "TAXID_OTP_VERIFICATION") && !$loggedTaxis)}
          <div in:fade|local id="container1" class="columns">
            <div class="column">
              <!-- ------------------------------------------------------------------------------------- -->
              <Stepper bind:activeStep {selectedProcess} />

              <div style="height:1rem"></div>

              {#if activeStep === 1 || activeStep === 2}
                {#if activeStep === 1}
                  <Dropzone bind:file={testUploadFile} />
                {/if}
                <div class="container">
                  <form
                    class="container is-centered"
                    on:change={() => {
                      $validationOngoing = true;
                      chainValidations();
                    }}
                    on:input={() => {
                      $validationOngoing = true;
                      chainValidations();
                    }}
                    bind:this={form}
                  >
                    <!-- ====================================================== -->
                    {#if !((selectedProcess.authenticationRequired === "ANONYMOUS") || (selectedProcess.authenticationRequired === "NO_VERIFICATION"))}
                      <hr />
                      <div class="" style="max-width: 720px;">
                          <div class="columns is-3">
                            <div class="column">
                              <!-- if we're logged with taxis,this is a locked field, user cannot interact -->
                              {#if $loggedTaxis}
                                <label class="label mt-4">Όνομα</label>
                                <div class="control">
                                  {emailVerificationInfo.physicalEntityDTO
                                    .firstName}
                                </div>
                              {:else}
                                <Formfield
                                  locked={false}
                                  disabled={activeStep === 2}
                                  obligatory={true}
                                  bind:value={emailVerificationInfo
                                    .physicalEntityDTO.firstName}
                                  label="Όνομα"
                                  type="text"
                                  errors={$validationErrors &&
                                  $validationErrors.inner
                                    ? findErrors(
                                        $validationErrors.inner,
                                        "physicalEntityDTO.firstName"
                                      )
                                    : []}
                                />
                              {/if}
                            </div>
                            <div class="column">
                              <!-- if we're logged with taxis,this is a locked field, user cannot interact -->
                              {#if $loggedTaxis}
                                <label class="label mt-4">Επώνυμο</label>
                                <div class="control">
                                  {emailVerificationInfo.physicalEntityDTO.lastName}
                                </div>
                              {:else}
                                <Formfield
                                  locked={false}
                                  disabled={activeStep === 2}
                                  obligatory={true}
                                  bind:value={emailVerificationInfo
                                    .physicalEntityDTO.lastName}
                                  label="Επώνυμο"
                                  type="text"
                                  errors={$validationErrors &&
                                  $validationErrors.inner
                                    ? findErrors(
                                        $validationErrors.inner,
                                        "physicalEntityDTO.firstName"
                                      )
                                    : []}
                                />
                              {/if}
                            </div>
                            {#if emailVerificationInfo.taxId}
                              <!-- this is a locked field, no need to show if it's obligatory or not -->
                              <div class="column">
                                <label class="label mt-4">ΑΦΜ</label>
                                <div class="control">
                                  {emailVerificationInfo.taxId}
                                </div>
                              </div>
                            {/if}
                          </div>

                          <div class="columns">
                            <div class="column">
                              <Formfield
                                obligatory={true}
                                disabled={activeStep === 2}
                                bind:value={emailVerificationInfo.email}
                                on:checkIfEmailBelongstoGroup={handleUserGroupValidation}
                                mappedToVariable={"emailVerificationInfo.email"}
                                serviceuuid={selectedProcess.serviceUuid}
                                type="email"
                                label="e-mail"
                                bind:customValidationMessage={userEmailGroupValidation}
                                errors={$validationErrors && $validationErrors.inner
                                  ? findErrors($validationErrors.inner, "email")
                                  : []}
                              />
                            </div>
                            <!-- <Formfield disabled={activeStep===2} type="tel" label="Αριθμός κινητού τηλεφώνου" hint="Η υπηρεσία επικύρωσης μέσω sms δεν είναι ακόμα διαθέσιμη"/> -->
                          </div>
                      </div>
                      {#if !$isUserInGroup && authenticationServiceId && $validationErrors && $validationErrors.inner && findErrors($validationErrors.inner, "email").length === 0}
                        <!-- the flag for user in group might have remaining false value in invalid email addresses -->
                        <!-- se check if the email is invalid, to make sure you don't show the authentication prompt-->
                        <a
                          transition:fade
                          role="button"
                          class="mt-2 is-size-5-desktop is-size-6-tablet is-size-6-mobile button is-link has-background-link-dark"
                          href={$url(
                            "/client/:id/action/:serviceId/user/:userId/new",
                            {
                              id: $params.id,
                              serviceId: authenticationServiceId,
                              userId: $params.tid,
                            }
                          )}
                          target="_blank"
                        >
                          Αίτηση πιστοποίησης</a
                        >
                      {/if}
                    {/if}
                    <!-- ====================================================== -->
                    <!-- <hr/> -->
                    <div class="container is-centered">
                      <div id="container-inner2">
                        <div>
                          {#if emailVerificationInfo.inputs.length > 0 || emailVerificationInfo.files.length > 0}
                            <div class="form-container">
                              <FormLayout
                                taxIdVerifiedService={selectedProcess.authenticationRequired ===
                                  "TAXID_VERIFICATION"}
                                formId={selectedProcess.formId}
                                bind:datasourcesReady
                                bind:onBehalfOfDTO
                                orgTukangaId={$selectedOrgDTO.open1ProcessOrganizationId}
                                serviceuuid={selectedProcess.serviceUuid}
                                bind:inputsArray={emailVerificationInfo.inputs}
                                bind:filesArray={emailVerificationInfo.files}
                                bind:fileErrors={$validationFileErrors}
                                bind:validationSchema={$mainValidationSchema}
                                bind:layoutNode={$serviceFieldNodes["inputs"]}
                                on:validate={() => {
                                  $validationOngoing = true;
                                  chainValidations();
                                }}
                                disabled={activeStep === 2}
                                layoutId={"inputs"}
                                on:addNodeToEmailVerificationInfo={handleNewNode}
                              />
                            </div>
                          {/if}
                        </div>
                        <!-- disabled because we need comments to be connected to a process variable -->
                        <!-- <Formfield disabled={activeStep===2} bind:value={emailVerificationInfo.comments} type="textarea" label="Σχόλιο"/> -->

                        <hr />
                        <div class="container level mt-5">
                          <!-- don't let user start the service if no fields or files are requested -->
                          {#if !(emailVerificationInfo.files.length === 0 && emailVerificationInfo.inputs.length === 0)}
                            {#if activeStep === 1}
                              <div class="level-left">
                                <button
                                  class="level-item button is-size-6-mobile is-size-5 is-link"
                                  on:click|preventDefault={() => {
                                    activeStep++;
                                  }}
                                  disabled={!isValid || !$fileSizeLimitOK}
                                  >Επόμενο &nbsp;
                                  <Fa icon={faChevronRight} size="1x" />
                                </button>
                              </div>
                            {:else if activeStep === 2}
                              <div class="level-left">
                                <button
                                  class="level-item button is-light is-size-6-mobile is-size-5"
                                  on:click|preventDefault={() => {
                                    activeStep--;
                                  }}
                                >
                                  <Fa icon={faChevronLeft} size="1x" /> &nbsp; Διόρθωση
                                </button>
                                <button
                                  class="level-item button is-size-6-mobile is-size-5 is-link"
                                  on:click|preventDefault={handleStep2}
                                  >Επιβεβαίωση
                                  <!-- &nbsp;
                              <Fa icon={faChevronRight} size="1x" /> -->
                                </button>
                              </div>
                            {/if}
                          {/if}
                        </div>

                        {#if activeStep === 2}
                          <p class="mt-6 is-size-6">
                            <span class="my-icon">
                              <CheckSquareIcon size="1.5x" />
                            </span>
                            &nbsp; Υποβάλλοντας την αίτησή σας, συμφωνείτε με τους
                            <a on:click={toggletermsmodal}>όρους χρήσης</a>.
                          </p>
                        {/if}

                        {#if activeStep === 1}
                          <p class="mt-6 is-size-6 is-italic">
                            <span class="has-text-danger">*</span> &nbsp; Υποχρεωτικό
                            πεδίο
                          </p>
                        {/if}
                        <!-- add some space under the buttons to not get overlapped by the footer -->
                        <div class="my-4">&nbsp;</div>
                      </div>
                    </div>
                  </form>
                </div>
                <!-- ========================================== ===========================================-->

                <!-- LAST STEP -->
              {:else if activeStep === 3}
                {#if processIsStarting}
                  <div class="mt-6">
                    <div class="is-size-5-mobile is-size-4">
                      Αποστολή αίτησης....
                    </div>
                    <div class="is-size-4-mobile is-size-3 p-4">
                      <Spinner
                        size="100"
                        speed="750"
                        color="rgba(0, 255, 0, 0.3)"
                        thickness="5"
                        gap="40"
                      />
                    </div>
                  </div>
                {:else if errorWithProcess}
                  <div in:fade|local class="has-text-centered">
                    <div class="is-size-4-mobile is-size-3">
                      <Fa icon={faFrown} size="4x" aria-hidden="true" />
                    </div>
                    <div class="columns is-centered mt-5">
                      <div class="column is-half">
                        <div class="is-size-6-mobile is-size-5">
                          Παρουσιάστηκε πρόβλημα κατά τη δημιουργία της αίτησής
                          σας.
                        </div>
                        <div class="is-size-6-mobile is-size-5">
                          Παρακαλώ προσπαθήστε ξανά.
                        </div>
                      </div>
                    </div>
                    <div class="is-size-4-mobile is-size-3">
                      <button
                        class="button is-link has-background-link-dark is-size-5-desktop is-size-6"
                        on:click={() => {
                          $goto("/client/:id/welcome", {
                            id: $selectedClient.id,
                          });
                        }}
                      >
                        <Fa icon={faChevronLeft} size="1x" /> &nbsp; Επιστροφή σε {$selectedClient.title}
                      </button>
                    </div>
                  </div>
                {:else}
                  <div in:fade|local class="mt-5 p-4">
                    <div class="has-text-success">
                      <CheckIcon size="150" />
                    </div>
                    <div class="columns">
                      <div class="column is-half">
                        <div class="is-size-6-mobile is-size-5">
                          H αίτησή σας καταχωρήθηκε.
                          <!-- με αριθμό υπόθεσης
                        <strong>{processNum}</strong> -->
                        </div>
                        <div class="is-size-6-mobile is-size-5">
                          Θα ενημερωθείτε σύντομα για την εξέλιξή της με e-mail.
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        style="white-space: normal;"
                        class="button is-link has-background-link-dark is-size-5-desktop is-size-6-mobile"
                        on:click={() => {
                          $goto("/client/:id/welcome", {
                            id: $selectedClient.id,
                          });
                        }}
                      >
                        <Fa icon={faChevronLeft} size="1x" /> &nbsp; Επιστροφή σε {$selectedClient.title}
                      </button>
                    </div>
                  </div>
                {/if}
              {:else}
                <p>A problem occured</p>
              {/if}
            </div>
          </div>
        {:else}
          <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
            <p class="is-size-4">
              Για να δείτε αυτήν την αίτηση χρειάζεται Σύνδεση με λογαριασμό
              TaxisNet
            </p>
            <div class="level mt-3">
              <div class="level-left">
                <div class="level-item">
                  <Taxis
                    label="Σύνδεση"
                    labelSize="is-size-5 is-size-6-mobile"
                    state={$params.id.concat("_").concat($params.pid)}
                  />
                </div>
              </div>
            </div>
          </div>
        {/if}
      {/if}
    {:else}
      <!-- make sure org data is loaded before loading taxis component: it uses org params -->
      {#if $selectedOrgDTO.id}
        <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
          <p class="is-size-4">
            Για να δείτε αυτήν την αίτηση χρειάζεται Σύνδεση με λογαριασμό
            TaxisNet.
          </p>
          <div class="level mt-3">
            <div class="level-left">
              <div class="level-item">
                <Taxis
                  label="Σύνδεση"
                  labelSize="is-size-5 is-size-6-mobile"
                  state={$params.id.concat("_").concat($params.pid)}
                />
              </div>
            </div>
          </div>
        </div>
      {/if}
    {/if}
  {/if}
  <div
    id="size-limit-warning"
    class="fixed-notification-bottom-left notification is-danger is-light is-size-6 is-size-6-mobile"
    hidden={$fileSizeLimitOK}
  >
    <section class="my-2">
      <p>
        Το συνολικό μέγεθος των συνημμένων αρχείων δεν επιτρέπεται να ξεπερνάει
        τα {$selectedOrgDTO.maxFileSizeMb} MB!
      </p>
    </section>
  </div>
</div>

<!-- MODALS -->

{#if openVerificationModal}
  <div class="modal is-active">
    <div in:fade|local class="modal-background" />
    <div class="modal-card">
      <section class="modal-card-body is-size-5-desktop is-size-6">
        <div class="container">
          <div class="mt-4 mb-6">
            {#if verifyingEmail}
              Επιβεβαίωση κωδικού...
            {:else if errorWithEmail}
              <div class="notification is-warning is-light">
                Παρουσιάστηκε πρόβλημα κατά την επικύρωση. Παρακαλώ προσπαθήστε
                ξανά.
              </div>
            {:else if emailVerified}
              <div class="notification is-success">Επιτυχής υποβολή</div>
            {:else}
              Για την ολοκλήρωση υποβολής της αίτησης, σας έχει αποσταλεί ένας
              6-ψήφιος κωδικός επιβεβαίωσης στη διεύθυνση e-mail που
              καταχωρίσατε.
            {/if}
          </div>
          {#if verifyingEmail}
            <progress class="progress is-small is-primary" max="100"
              >15%</progress
            >
          {:else if emailVerified}
            <p class="has-text-centered">
              H διεύθυνση e-mail σας επικυρώθηκε επιτυχώς
            </p>
          {:else}
            <input
              autofocus
              name="code"
              class="input"
              type="text"
              label="Εισάγετε τον 6-ψήφιο κωδικό επιβεβαίωσης"
              bind:value={token}
            />
          {/if}
        </div>
      </section>
      {#if !verifyingEmail}
        <!-- style="justify-content: space-between;" -->
        <footer class="modal-card-foot">
          {#if !emailVerified}
            <!-- empty div to pull buttons to the right -->
            <!-- <div class="flex" style="justify-content: flex-end;"> -->
            <!-- </div> -->
            <button
              class="button is-link has-background-link-dark is-size-5-desktop is-size-6"
              on:click={() => {
                verifyEmail();
                verifyingEmail = true;
              }}>Υποβολή κωδικού</button
            >
            <button
              class="button is-light is-size-5-desktop is-size-6"
              on:click={() => {
                openVerificationModal = false;
              }}
              aria-label="close">Άκυρο</button
            >

            {#if errorWithEmail}
              <button
                class="button is-primary is-light is-size-5-desktop is-size-6"
                on:click={() => {
                  requestEmailValidation(
                    emailVerificationInfo.email,
                    emailVerificationInfo.physicalEntityDTO,
                    emailVerificationInfo.legalEntityDTO
                  );
                  console.log("sending email...");
                }}>Επαναποστολή κωδικού</button
              >
            {/if}
          {:else}
            <!-- empty div to pull button to the right -->

            <!-- the following happens automaticaly -->
            <div />
            <button
              class="button is-link has-background-link-dark is-size-5-desktop is-size-6 is-pulled-right"
              on:click={() => {
                openVerificationModal = false;
                activeStep++;
                chainPromises();
              }}>Συνέχεια</button
            >
          {/if}
        </footer>
      {/if}
    </div>
  </div>
{/if}

{#if opentermsmodal}
  <div class="modal is-active">
    <div class="modal-background" on:click={toggletermsmodal} />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {@html $selectedOrgDTO.title}&nbsp;-&nbsp;Όροι Χρήσης
        </p>
      </header>

      {#if $selectedOrgDTO.termsMessage}
        <section class="modal-card-body px-6">
          {@html $selectedOrgDTO.termsMessage}
        </section>
      {/if}

      <footer class="modal-card-foot is-grouped level">
        <button
          class="button is-link has-background-link-dark is-size-5-desktop is-size-6"
          on:click={toggletermsmodal}>Εντάξει</button
        >
      </footer>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      on:click={toggletermsmodal}
    />
  </div>
{/if}

<style>
  button {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    color: white;
  }
</style>
