<script>
</script>
<style>
  li {
    margin-top: 1rem;
  }
</style>
<div class="container app-container">
  <div class="columns is-vcentered">

    <div class="column mt-2">


      <!-- <h2 class="subtitile is-size-4-mobile is-size-3 my-6">
          Περιφέρεια Στερεάς Ελλάδας: Πολιτική απορρήτου
        </h2> -->
      <div class=" my-6">
        <div class="is-size-4">
          <!-- Περιφέρεια Στερεάς Ελλάδας -->
        </div>
        <h3 class="title">Πολιτική Cookies</h3>
      </div>

    <div class="columns is-vcentered">
        <div class="column">
        <p><b>Cookies</b></p>
          <p>Για να εξασφαλίσουμε τη σωστή λειτουργία του ιστότοπου, μερικές φορές τοποθετούμε μικρά αρχεία δεδομένων στον υπολογιστή σας, τα λεγόμενα «cookies». 
          Οι περισσότεροι μεγάλοι ιστότοποι κάνουν το ίδιο.</p>
          <hr />
          <p><b>Τι είναι τα cookies;</b></p>
          <p>Τα cookies είναι μικρά αρχεία κειμένου τα οποία ένας ιστότοπος αποθηκεύει στον υπολογιστή σας ή στην κινητή σας συσκευή όταν επισκέπτεστε αυτόν τον ιστότοπο. 
          Με τον τρόπο αυτό, ο ιστότοπος θυμάται τις ενέργειές σας και τις προτιμήσεις σας (όπως κωδικός Σύνδεσης, γλώσσα, μέγεθος γραμματοσειράς και άλλες προτιμήσεις απεικόνισης) για ένα χρονικό διάστημα, κι έτσι δεν χρειάζεται να εισάγετε τις προτιμήσεις αυτές κάθε φορά που επισκέπτεστε τον ιστότοπο ή φυλλομετρείτε τις σελίδες του.</p>
          <hr />
          <p><b>Πως χρησιμοποιούμε τα cookies;</b></p>
          <p>Αυτά τα cookies είναι απαραίτητα για την σωστή λειτουργία της ιστοσελίδας και δεν μπορούν να αφαιρεθούν. Συνήθως δημιουργούνται μετά από κάποια δική σας ενέργεια, όπως να συμπληρώσετε κάποια φόρμα ή να επιλέξετε τις προτιμήσεις σας για την ιστοσελίδα.</p>
           <li> Όνομα: <i>gsisAuth</i>
            <p>Δημιουργείται μετά τη σύνδεσή σε έναν φορέα μέσω κωδικών TaxisNet. Αποθηκεύονται στοιχεία που διευκολύνουν τη διαδικασία ταυτοποίησής σας στο σύστημα.</p></li>
          <hr />
            

        <!-- <div class="column is-half">
            <h3 class="subtitile is-size-6-mobile is-size-4 mt-6"><b> Είστε Φορέας; </b></h3>
            <a role="button" class="{$highContrast?'has-background-link-dark has-text-white':'is-info'} button is-size-5-mobile is-size-4 mt-1" href="{$url('/admin')}">
              <strong>Συνδεθείτε εδώ</strong>
            </a>
          </div> -->
      </div>


      <!-- div below only exists to push the text divs up a bit -->
      
    </div>
    <div class="hero mt-6 is-hidden-mobile"></div>
      <div class="hero mt-6 is-hidden-mobile"></div>
      <div class="hero mt-6 is-hidden-mobile"></div>



  </div>
</div>
</div>

<!-- <hr> -->