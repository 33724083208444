<script>
import {onMount, onDestroy} from 'svelte';

export let value; //theme_color attribute that comes from object data. Default is white
export let language = 'el';

let previous_color; //store the color value onMount here in case the user previews others, but cancels


let pickr; //the picker element, as initialised in onMount from addPicker()

const addPicker = () => {
  const newElement = document.getElementById('pkr');


  pickr = new Pickr({
    el: newElement,
    default: value,
    theme: 'classic',
    lockOpacity: true,

    swatches: [
      'rgba(244, 67, 54, 1)',
      'rgba(233, 30, 99, 0.95)',
      'rgba(156, 39, 176, 0.9)',
      'rgba(103, 58, 183, 0.85)',
      'rgba(63, 81, 181, 0.8)',
      'rgba(33, 150, 243, 0.75)',
      'rgba(3, 169, 244, 0.7)',
      'rgba(0, 188, 212, 0.7)',
      'rgba(0, 150, 136, 0.75)',
      'rgba(76, 175, 80, 0.8)',
      'rgba(139, 195, 74, 0.85)',
      'rgba(205, 220, 57, 0.9)',
      'rgba(255, 235, 59, 0.95)',
      'rgba(255, 193, 7, 1)'
    ],

    components: {
      preview: true,
      opacity: true,
      hue: true,

      interaction: {
        hex: true,
        rgba: true,
        hsva: true,
        input: true,
        clear: true,
        save: true
      }
    },

    i18n: {

        // Strings visible in the UI
       'ui:dialog': 'Διάλογος επιλογής χρώματος',
       'btn:toggle': 'Εμφάνιση/απόκρυψη διαλόγου επιλογής χρώματος',
       'btn:swatch': 'Δείγμα χρώματος',
       'btn:last-color': 'Χρήση προηγούμενου χρώματος',
       'btn:save': 'Αποθήκευση',
       'btn:cancel': 'Ακύρωση',
       'btn:clear': 'Καθαρισμός',

       // Strings used for aria-labels
       'aria:btn:save': 'αποθήκευση και έξοδος',
       'aria:btn:cancel': 'ακύρωση και έξοδος',
       'aria:btn:clear': 'καθαρισμός και έξοδος',
       'aria:input': 'εισαγωγή τιμής χρώματος',
       'aria:palette': 'περιοχή επιλογής χρώματος',
       'aria:hue': 'επιλογέας απόχρωσης',
       'aria:opacity': 'επιλογέας διαφάνειας χρώματος'
    }
  });
}

onMount(()=>{
    //if no base color is set in prop, default to white
    if(!value) value = '#FFFFFF';
    addPicker();
    previous_color = value;
});

onDestroy(()=>{
    pickr.destroy();
    pickr.destroyAndRemove();
});

//events
$:if(pickr) pickr.on('save', (color, pickr) => {
            value = pickr.getColor().toHEXA().toString();
            previous_color = value; //user saved, store the color so when the user closes the picker, the preview doesn't revert
            })
            .on('change', (color, pickr) => {
            value = pickr.getColor().toHEXA().toString();
            })
            .on('hide', (color, pickr) => {
            value = previous_color;
            })
            .on('cancel', (color, pickr) => {
            value = previous_color;
            })
            .on('clear', (color, pickr) => {
            // previous_color = '#FFFFFF';
            value = null;
            });
</script>

<style>


</style>

  <div id="pkr" style="z-index:2;"></div>
  <!-- <button on:click={addPicker}>Add another pickr</button> -->
