<script>
  import { _ } from "svelte-i18n";
  import {
    sizeOf,
    finder,
    formatMimeType,
    removeAccents,
    convertDateForVariable,
  } from "../utils/helpers.js";
  import { createEventDispatcher, onMount } from "svelte";
  import {
    faPhone,
    faFile,
    faCheck,
    faExclamationTriangle,
    faPaperclip,
    faUndo,
  } from "@fortawesome/free-solid-svg-icons";
  import {
    PaperclipIcon,
    DownloadIcon,
    SearchIcon,
    CheckCircleIcon,
    CircleIcon,
  } from "svelte-feather-icons";
  import { axiosInst } from "../credentials.js";
  import { url, params } from "@sveltech/routify";
  import Select from "svelte-select";
  import A11Yautocomplete from "./A11Y-autocomplete.svelte";
  import Fa from "svelte-fa";
  import {
    dependencyChangesStore,
    dependencyConditionsStore,
    selectedOrgDTO,
    isUserInGroup,
    currentUserGroup,
    userCheckedIfInGroup,
    validationOngoing,
    formUsed,
    flattenedFormVars,
    mainValidationSchema,
    dependencyListStore,
    serviceFieldNodes,
    serviceVariables,
  } from "../ui-store.js";
  import FormLayout from "./FormLayout.svelte";
  import { fade, blur } from "svelte/transition";
  import Map from "./Map.svelte";
  import autosize from "svelte-autosize";

  export let value = "";
  export let errors = [];
  export let label = "";
  export let description = null;
  export let type; //just for icon and addons, don't use in input, svelte complains bc it determines type in its own way
  export let hint = null; //a hint message for the user
  export let disabled;
  export let hasTemplate = false;
  export let templateFileURI = null;
  export let obligatory = false;
  export let serviceuuid = null;
  export let mappedToVariable = null;
  export let forDatasources;
  export let datasourcesReady = false;
  //for any additional, non-yup validation that may be going on
  export let customValidationMessage = "";
  // if this field is in a multifield line (3 fields in same row) we should be aware for styling reasons
  // Short field inputs (dates, integers, emails) should not be further squeezed and instead extend to full width 
  // when sharing a full row with other fields
  export let squeezed = false;

  //if this is a locked field (just to display data, no user interaction), no need to show if it's obligatory or not
  export let locked = false;

  //needed to know if we will send a taxId along with other userInfo to get datasource values
  export let taxIdVerifiedService;

  //every field should dispatch its dependency list
  export let dependencyList;
  export let nestedFields = [];
  export let dependencyChanges = [];

  // this field is an element in a layout node (the group variable or input array it belngs into)
  export let layoutNodeElement;

  // additional variableInfo to get (eg dependencies, etc)
  export let varInfo;
  let multiSelect;
  $:if (varInfo && varInfo.valueSelectionDetails) multiSelect = varInfo.valueSelectionDetails.multiSelection;

  // update node element every time one of these properties changes
  $: layoutNodeElement = {
    "mapToVariableName": mappedToVariable,
    "obligatory": obligatory,
    "isInvisible": isInvisible,
    "value": value
  }

  $: if (mappedToVariable) {
    // initialize dependencyList to be apppended to main store via prop binding
    dependencyList = { id: mappedToVariable, dependencies: [] };
  }

  let setValue = undefined;
  let originalDescription = undefined;
  let isInvisible = false;

  let touched = false;
  let successful = true;

  // is field mini? (some data types don't need big inputs, eg integers, emails)
  // BUT if the field is in a fuil row, (squeezed) it should extend to full width
  let miniField = false;
  $: miniField = (!squeezed) && (type === "double" || type === "integer" || type === "date" || type === "email");
  // let serviceHasUserGroup = false;

  // $:if (serviceuuid && $selectedOrgDTO.servicesProvided) {
  //   //check if this service has to be initiliazed by a specific user group

  //   let filtered = $selectedOrgDTO.servicesProvided.filter(s => s.serviceUuid.toString() === serviceuuid.toString());
  //   if (filtered.length>0) serviceHasUserGroup = filtered[0].userGroupIssuer;
  //   else serviceHasUserGroup = filtered.userGroupIssuer;

  // }

  $: successful = touched && errors && errors.length === 0;
  // $:if(value&&value[0])console.log("Value :"+ JSON.stringify(value[0]));
  $: if (mappedToVariable === "emailVerificationInfo.email")
    console.log("Successful " + JSON.stringify(errors), errors.length);
  $: console.log(
    "Successful var " +
      mappedToVariable +
      " " +
      successful +
      " " +
      touched +
      " " +
      errors
  );
  // $: console.log("Errors length for "+ label+ ":"+ errors.length);

  $: if (type === "date" && value)
    console.log("date value, ", value, convertDateForVariable(value));
  //if the user email has been input correctly, and the service has a userGroup property
  //check if the userEmail belongs to this group. If not, do not let them proceed
  //and set value in store.

  // $:if (!serviceHasUserGroup) {
  //   $currentUserGroup = null;
  //   $isUserInGroup = true;
  // }

  $: if (
    mappedToVariable === "emailVerificationInfo.email" &&
    $currentUserGroup &&
    $currentUserGroup.groupType &&
    successful &&
    touched &&
    !$validationOngoing &&
    value
  ) {
    //dispatch event to parent to check if user belongs to userGroup
    dispatch("checkIfEmailBelongstoGroup");
  }

  // $:console.log("isInvisibile ",mappedToVariable, isInvisible);

  const handleSetValue = (value) => {
    if (value != undefined) setValue = filterSourceValues(value);
    else setValue = undefined;
  };
  //some input changes may not trigger the form's on:input and on:change evens
  //so this dispatcher has also been created
  const dispatch = createEventDispatcher();

  const filterSourceValues = (value) => {
    let filtered = valuesFromSource.filter((v) => v.id === value);
    if (filtered.length > 0) return filtered[0];
    else return filtered;
  };

  const handleValueChanged = () => {
    dispatch("valueChanged", {
      groupDependencies: false,
      varId: mappedToVariable,
      value: type === "boolean" ? (value ? "true" : "false") : value,
      id: type === "datasource" ? value : null,
      title:
        type === "datasource"
          ? isRestApiDs
            ? filterSourceValues(value).fullDescription
            : filterSourceValues(value).title
          : null,
      description:
        type === "datasource"
          ? isRestApiDs
            ? null
            : filterSourceValues(value).description
          : null,
    });
  }

  const handleValueCleared = () => {
    dispatch("valueCleared", {
      groupDependencies: false,
      varId: mappedToVariable,
      value: type === "boolean" ? "false" : null,
    });
  }

  $: if (value) handleValueChanged();    

  $: if (!value) handleValueCleared();
    

  $: if (dependencyChanges && mappedToVariable) {
    for (let i = 0; i < dependencyChanges.length; i++) {
      switch (dependencyChanges[i].type.toString()) {
        case "VALUE_FROM_VALUE":
          // console.log("TEST1 change value");
          if (type === "datasource") {
            if (dependencyChanges[i].changeToValue.toString() === "NULL") {
              value = undefined;
              handleSetValue(undefined);
            } else {
              value = dependencyChanges[i].changeToValue;
              handleSetValue(value);
            }
          }
          //if not datasource
          else {
            if (dependencyChanges[i].changeToValue.toString() === "NULL") {
              value = null;
            } else {
              value = dependencyChanges[i].changeToValue;
            }
          }
          break;
        case "DESCRIPTION_FROM_VISIBLE_VALUE":
          console.log("change description");
          if (dependencyChanges[i].changeToValue)
            description = dependencyChanges[i].changeToValue;
          else description = originalDescription;
          break;
        case "VALUE_FROM_VISIBLE_VALUE":
          console.log("change value");
          value = dependencyChanges[i].changeToValue;
          break;
        case "REQUIRED_IF_VALUE_EQUALS":
          console.log("change required");
          obligatory = dependencyChanges[i].changeToValue;
          // dispatch event for any changes that may happen to nested vars
          if (type === "group")
            dispatch("requirementChanged", {
              groupDependencies: true,
              varId: mappedToVariable,
              obligatory: obligatory,
            });
          break;
        case "VISIBLE_IF_VALUE_EQUALS":
          console.log("change visibility");
          isInvisible = !dependencyChanges[i].changeToValue;
          if (type === "group")
            dispatch("visibilityChanged", {
              groupDependencies: true,
              varId: mappedToVariable,
              invisible: isInvisible,
            });
          break;
        // console.log($dependencyChangesStore[mappedToVariable][i].type);
        case "REQUIREMENT_FROM_LAYOUT":
          console.log("change requirement layout");
          obligatory = dependencyChanges[i].changeRequirementTo;
          // dispatch event for any changes that may happen to nested vars
          if (type === "group")
            dispatch("requirementChanged", {
              groupDependencies: true,
              varId: mappedToVariable,
              obligatory: obligatory,
            });
          break;
        case "VISIBILITY_FROM_LAYOUT":
          console.log("change visibility layout");
          isInvisible = dependencyChanges[i].changeInvisibilityTo;
          if (type === "group")
            dispatch("visibilityChanged", {
              groupDependencies: true,
              varId: mappedToVariable,
              invisible: isInvisible,
            });
          break;
        default:
          break;
      }
    }
    // after changes have been implemented, reset list
    resetVariableDependencyChangesStore(mappedToVariable);
  }

  const resetVariableDependencyChangesStore = (variableId) => {
    $dependencyChangesStore[variableId] = [];
  }

  //for use with possible radio button input
  let question = {
    title: label,
    id: mappedToVariable,
    value: null,
    options: [],
  };

  if (type === "datasource")
    hint = "Χρειάζεται να συμπληρώσετε πρώτα τα στοιχεία επικοινωνίας σας!";

  //trim spaces from number inputs
  $: if ((type === "double" || type === "integer") && value) {
    value = value.trim();
  }

  $: isReady =
    $params.id &&
    serviceuuid &&
    mappedToVariable &&
    !(mappedToVariable === "emailVerificationInfo.email");

  let isDone = false;

  $: console.log("datasourcesReady", datasourcesReady);
  //is the variable mapped to this formfield a rest api datasource?
  //need to know, because values array is slightly different
  let isRestApiDs = false;

  $: console.log("forDatasources: ", forDatasources);
  let externalVariableValues;

  $: if (forDatasources)
    externalVariableValues = {
      ...forDatasources.onBehalfOfDTO,
      organizationId: forDatasources.organizationId,
      processDefinitionId: forDatasources.processDefinitionId,
    };
  $: console.log("externalVariableValues: ", externalVariableValues);

  let templateURI;
  let downloadlink;
  let blob;
  let valuesFromSource = [];
  let nestedVars = [];
  let datasourceInfo = null;
  let mapId = null;
  // let blob = fetch(templateFileURI).then(r => r.blob());

  // $:console.log(blob);

  var file = {
    templateURI: null,
    type: null,
  };

  $: if (templateFileURI) {
    //templateURI = templateFileURI.concat('/content');
  }

  const getDatasourceValues = (datasourceInfo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await $axiosInst.post(
          "open1process/datasource/" +
            $params.id +
            "/" +
            datasourceInfo.datasourceId +
            "/execute/" +
            datasourceInfo.datasourceActionId,
          { externalVariableValues: externalVariableValues, taskId: null }
        );
        console.log(res.data);
        console.log(res.status);
        if (res.status === 200) {
          console.log("successful");
          if (res.data.restApiResult) {
            //if datasource is restApi
            valuesFromSource = finder(res.data.resultJsonObject);
            isRestApiDs = res.data.restApiResult;
            question.options = valuesFromSource;
          } else {
            //if fixed list (only other option currently)
            if (res.data.columnNames.length > 1) {
              //if it's an array with more than one columns
              //map columns(keys array) to results (values array)
              // let temp = [res.data.valuesOfRows.length];
              // for (let i=0; i<res.data.valuesOfRows.length; i++){
              //   temp[i] = [];
              //   for (let k=0; k<res.data.columnNames.length; k++){
              //     let temp1 = null;
              //     temp1 = [res.data.columnNames[k] , res.data.valuesOfRows[i][k]];
              //     temp[i].push(temp1);
              //   }
              //   valuesFromSource.push(Object.fromEntries(temp[i]));
              //   valuesFromSource = valuesFromSource;
              // }

              //or another solution (assumes there's always id, title, description columns)
              //pushing in arraysc created issues resulting in multiple duplicates of the source data ending up in the ui
              valuesFromSource = res.data.valuesOfRows.map(function (val) {
                return {
                  [res.data.columnNames[0]]: val[0],
                  [res.data.columnNames[1]]: val[1],
                  [res.data.columnNames[2]]: val[2],
                };
              });
              question.options = valuesFromSource;
            }
            //if there are no key values (columns) then simply return the values as they are, extracting them from their individual arrays
            else
              valuesFromSource = res.data.valuesOfRows.map(function (val) {
                return val[0];
              });
            question.options = valuesFromSource;
          }
          resolve();
        } else {
          hint =
            "Παρακαλώ ελέγξτε τα στοιχεία επικοινωνίας σας. Αν το πρόβλημα παραμένει, επικοινωνήστε με τον Φορέα.";
          reject("something went wrong");
        }
      } catch (e) {
        hint =
          "Παρακαλώ ελέγξτε τα στοιχεία επικοινωνίας σας. Αν το πρόβλημα παραμένει, επικοινωνήστε με τον Φορέα.";
        console.log(e);
        reject(e);
      }
    });
  };

  //make sure that getVariable and getDatasourceValues will only run once
  let gotDatasourceData = false;

  //before getting the values from a non-fixed list datasource, make sure all onBehalf fields have been filled
  $: if (
    datasourceInfo &&
    datasourceInfo.datasourceSelection !== "FIXED_LIST_DATASOURCE" &&
    !gotDatasourceData &&
    (!taxIdVerifiedService ||
      (taxIdVerifiedService && externalVariableValues.afm)) &&
    externalVariableValues.firstName &&
    externalVariableValues.lastName &&
    externalVariableValues.emailAddress &&
    externalVariableValues.organizationId &&
    externalVariableValues.processDefinitionId
  ) {
    // if(result.datasourceSelection==="FIXED_LIST_DATASOURCE")
    getDatasourceValues(datasourceInfo).then(() => {
      gotDatasourceData = true;
      //hide hint
      hint = null;
    });
    // if(result.datasourceSelection==="REST_API_DATASOURCE") getDatasourceValuesFromRestApi(result, headersAndBodyDTO);
  }

  const deleteFile = () => {
    dispatch("deletefile", {
      title: label,
    }); //update parent component to delete file with this title
  };

  const createDownloadLink = (file) => {
    //response needs to be an arraybuffer, in order to build the blob url
    let result = {
      downloadLink: null,
      type: null,
    };

    return new Promise(async (resolve, reject) => {
      //create the download URL, set the type of file it's sending
      let mimeType = formatMimeType(file[0].type);
      let blob = URL.createObjectURL(
        new Blob([file[0]], {
          type: file[0].type,
        })
      );
      //if mimetype is of certain type, add property to file (we need it for a download bug)
      if (mimeType === "DOC" || mimeType === "DOCX" || mimeType === "ODT")
        result.type = mimeType.toLowerCase();

      result.downloadLink = blob;
      resolve(result);
    });
  };

  const downloadFile = (file) => {
    console.log(file);
    // getURL(doc).then((res) => {
    createDownloadLink(file).then((result) => {
      var a = document.createElement("a");
      a.href = result.downloadLink;
      //if result.type has been set, add extension to filename (for download  bug)
      //NO NEED TO ADD EXTENSION IN THIS CASE
      //the extension is already part of the filename, bc we take our data from the input
      a.setAttribute("download", file[0].name);
      a.click();
    });
    // });
  };

  const createDownloadLinkForTemplate = (templateFileURI) => {
    //response needs to be an arraybuffer, in order to build the blob url
    let fileType;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await $axiosInst.get(
          templateFileURI.concat("/content"),
          { responseType: "arraybuffer" }
        );
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          reject();
        } else {
          console.log(response);
          //create the download URL, set the type of file it's sending
          let mimeType = formatMimeType(response.headers["content-type"]);
          file.templateURI = URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          //if mimetype is of certain type, add property to file (we need it for a download bug)
          if (mimeType === "DOC" || mimeType === "DOCX" || mimeType === "ODT")
            file.type = mimeType.toLowerCase();

          resolve();
        }
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  const autoExpand = (field) => {
    field.style.height = "inherit";
    // Reset field height

    // Get the computed styles for the element
    let computed = window.getComputedStyle(field);

    // Calculate the height
    console.log(computed.getPropertyValue("padding-top"));
    let height =
      parseInt(computed.getPropertyValue("padding-top"), 10) / 2 +
      field.scrollHeight;

    field.style.height = height + "px";
  };

  const handleSelect = (event) => {
    touched = true;
    // is this a multi-select field?
    if (multiSelect) {
      // event.detail which holds the value is going to be an array of values
      // they may be objects with ids, or just strings
      if (event.detail) {
          value = event.detail.map(val => {
          if (val.id) return val.id;
          else return val;
        });
        // convert to json string
        value = JSON.stringify(value);
      }
      else value = null;
    }
    else {
      if (event.detail.id) value = event.detail.id;
      else value = event.detail.value;
    }

    handleValueChanged();
    
    //trigger validation in form
    dispatch("validate");
  };

  const handleClear = (event) => {
    touched = true;
    value = null;

    handleValueCleared();
    //trigger validation in form
    dispatch("validate");
  };

  onMount(() => {
    if (templateFileURI) createDownloadLinkForTemplate(templateFileURI);
    //keep the original description here:
    originalDescription = description;
    console.log(isReady, mappedToVariable);
    if (isReady && varInfo) {
      dependencyList = {
        id: mappedToVariable,
        dependencies: varInfo.dependentVariablesList,
      };
      //if there are dependence conditions, add them to store
      if (varInfo.variableDependenceList.length > 0) {
        let conditionsToAdd = {
          id: mappedToVariable,
          conditions: varInfo.variableDependenceList.filter((d) =>
            d.dependenceType.toString().includes("CONDITION")
          ),
        };
        // add property to store
        $dependencyConditionsStore[mappedToVariable] = conditionsToAdd;
      }
      isInvisible = varInfo.invisible;
      if (type === "datasource") {
        //get datasource variable info
        datasourceInfo = varInfo.valueSelectionDetails;
        if (datasourceInfo.datasourceSelection === "FIXED_LIST_DATASOURCE") {
          // if datasource is fixed list, get values immediately
          getDatasourceValues(datasourceInfo).then(() => {
            gotDatasourceData = true;
            //hide hint
            hint = null;
          });
        }
      }
      if (type === "point") {
        //get map variable info
        mapId = varInfo.mapDetails.mapId;
      }
      if (type === "group") {
        //get group variable info
        // if this variable is a group, add dependencies to its nested vars as well
        // if the group is invisible/required/visible/non-required, the nested should be too
        for (let i = 0; i < nestedFields.length; i++) {
          console.log(i);
          let newVisDep = {
            dependenceType: "VISIBILITY_FROM_LAYOUT",
            variableId: nestedFields[i].mapToVariableName,
          };
          let newVisCondition = {
            dependenceType: "VISIBILITY_CONDITION",
            variableId: mappedToVariable,
          };
          let newReqDep = {
            dependenceType: "REQUIREMENT_FROM_LAYOUT",
            variableId: nestedFields[i].mapToVariableName,
          };
          let newReqCondition = {
            dependenceType: "REQUIREMENT_CONDITION",
            variableId: mappedToVariable,
          };
          console.log(
            "GROUP: ",
            nestedFields,
            dependencyList,
            newVisDep,
            newReqDep
          );
          dependencyList.dependencies.push(newVisDep);
          dependencyList.dependencies.push(newReqDep);
          // create conditions
          $dependencyConditionsStore[
            nestedFields[i].mapToVariableName
          ].conditions.push(newVisCondition);
          $dependencyConditionsStore[
            nestedFields[i].mapToVariableName
          ].conditions.push(newReqCondition);
        }
      }
      isDone = true;
    }
  });
</script>

<div class="{isInvisible ? 'is-invisible ' : ''}">
  {#if type === "tel"}
    <div class="field has-addons">
      <p class="control">
        <a class="button is-static">+30</a>
      </p>
      <p class="control is-expanded has-icons-left has-icons-right">
        <input
          class="input"
          readonly={disabled || locked}
          type="tel"
          placeholder="Αριθμός κινητού τηλεφώνου"
        />
        <Fa icon={faPhone} class="is-small is-left" aria-hidden="true" />
      </p>
    </div>
  {:else if type === "file"}
    {#if disabled}
      <div class="field">
        <label class="label">
          {label}
        </label>
        <div class="control">
          {#if value && value[0]}
            <!-- <span class="has-text-weight-bold">
      <Fa icon={faFile} aria-hidden="true" />&nbsp;
      {value[0].name}
      </span>
      &nbsp; -->
            <a on:click={downloadFile(value)}>
              <span class="has-text-weight-bold">
                <Fa icon={faFile} aria-hidden="true" />&nbsp;
                {value[0].name}&nbsp;
              </span>
              <!-- <span class="my-icon"> 
          <DownloadIcon size="1.5x" />
        </span> -->
            </a>
          {:else}
            Χωρίς επισυναπτόμενο αρχείο
          {/if}
        </div>
      </div>
    {:else}
      <div class="field">
        <label class="label"
          >{label}
          <span
            class="has-text-weight-medium {obligatory
              ? 'has-text-danger '
              : 'tag is-lowercase'}"
            style="{locked ? 'visibility: hidden; display: none;' : ''}"
            >{obligatory ? " * " : $_("LABELS.OPTIONAL")}</span
          >
          {#if hasTemplate}
            &nbsp;
            <a
              class="is-primary is-light has-text-weight-normal"
              href={file.templateURI}
              data-tooltip="tooltip"
              download={file.type ? label + "." + file.type : label}
            >
              <span class="my-icon"><DownloadIcon size="1.5x" /></span> (Πρότυπο
              έγγραφο)</a
            >
          {/if}
        </label>
        <div class="columns">
          <div
            class="column is-narrow mt-2 mb-0 {value && value[0]
              ? successful
                ? 'mx-2 notification is-success is-light'
                : 'mx-2 notification is-danger is-light'
              : ''}"
          >
            <div class="level is-mobile">
              <label class="file-label">
                <!-- <span class="file-label">Επιλογή αρχείου…</span> -->
                {#if value && value[0]}
                  <a
                    on:click={downloadFile(value)}
                    style="word-wrap:anywhere; max-width:250px"
                  >
                    <span class="has-text-weight-bold">
                      {value[0].name}
                      ({sizeOf(value[0].size)})
                    </span>
                    <span in:fade class="icon is-small is-right my-1 mx-2">
                      {#if touched && errors.length > 0}
                        <Fa
                          icon={faExclamationTriangle}
                          size="lg"
                          aria-hidden="true"
                        />
                      {:else if successful}
                        <Fa icon={faCheck} size="lg" aria-hidden="true" />
                      {/if}
                    </span>
                    <!-- <span class="my-icon">
              <DownloadIcon size="1.5x" />&nbsp;
            </span> -->
                  </a>
                {:else}
                  <input
                    class="file-input readonly={disabled || locked} {touched
                      ? successful
                        ? 'is-success'
                        : 'is-danger'
                      : ''}"
                    type="file"
                    bind:files={value}
                    on:input={() => {
                      touched = true;
                      dispatch("validate");
                    }}
                    name="attachment"
                    accept={label.includes("ωτογ") || label.includes("ΩΤΟΓ")
                      ? ".jpeg, .png, image/*;capture=camera"
                      : ".doc, .docx, .pdf, .jpeg, .png"}
                  />
                  <div in:fade class="level is-mobile">
                    <span class="button" title="Επισύναψη αρχείου">
                      <!-- <Fa icon={faPaperclip}  size="lg" aria-hidden="true"/> -->
                      <div class="icon">
                        <PaperclipIcon size="1.5x" />
                      </div>
                      <span class="file-label mx-2"> Επιλογή αρχείου... </span>
                    </span>
                  </div>
                {/if}
              </label>
              {#if value && value[0]}
                <a
                  class="delete is-large"
                  on:click={() => {
                    value = null;
                    deleteFile();
                  }}
                />
              {/if}
            </div>
          </div>
        </div>
        <div>
          <!-- {#if value && value[0]}
    <a on:click={downloadFile(value)}>
      <b>Προβολή αρχείου</b>&nbsp;
      <span class="my-icon">
        <DownloadIcon size="1.5x" />
      </span>
    </a>
    {/if} -->
        </div>
      </div>
      {#if hint !== null && !disabled}
        <p class="help">{hint}</p>
      {/if}
      {#if touched && errors.length > 0}
        <p transition:fade class="help is-danger">{errors[0].message}</p>
      {/if}
    {/if}
    <!-- NOT IMPLEMENTED VAR IN PROCESS -->
  {:else if type === "textarea"}
    <div class="field">
      <label class="label">{label}</label>
      <div class="control">
        <textarea
          readonly={disabled || locked}
          class="textarea {disabled || locked ? 'is-static' : ''}"
          bind:value
        />
      </div>
    </div>
  {:else if type === "password"}
    <div class="field">
      <label class="label">{label}</label>
      <div class="control">
        <input
          type="password"
          readonly={disabled || locked}
          class="input {touched
            ? successful
              ? 'is-success'
              : ' is-danger'
            : ''}{disabled || locked ? 'is-static' : ''}"
          bind:value
          on:input={() => (touched = true)}
        />
      </div>
    </div>
  {:else if type === "search"}
    <div class="field">
      <label class="label">{label}</label>
      <p class="control has-icons-left">
        <input class="input is-rounded" bind:value type="text" />
        <span class="icon is-small is-left"><SearchIcon size="1.5x" /></span>
      </p>
    </div>
    {:else if ((type === "boolean") || (type === "checkbox"))}
      <div class="field">
        <input
        disabled={disabled || locked}
        style="
          width: 1.3rem;
          height: 1.3rem;
          position: absolute;"
        type="checkbox"
        bind:checked={value}
        >
        <label for={mappedToVariable}> 
          <div class="ml-5 px-2">{label}</div>
        </label>
      </div>
  {:else if type === "datasource"}
    <div class="field">
      <label for={label} class="label">
        {label}
        <span
          class="has-text-weight-medium {obligatory
            ? 'has-text-danger '
            : 'tag is-lowercase'}"
          style="{locked ? 'visibility: hidden; display: none;' : ''}"
          >{obligatory ? " * " : $_("LABELS.OPTIONAL")}</span
        ></label
      >
      {#if valuesFromSource.length > 7 || multiSelect}
        <div>
          {#if isRestApiDs}
            <Select
              items={valuesFromSource}
              isMulti={multiSelect}
              optionIdentifier="id"
              getOptionLabel={(option) => option.fullDescription}
              getSelectionLabel={(option) => option.fullDescription}
              itemFilter={(label, filterText, option) =>
                removeAccents(label).includes(removeAccents(filterText))}
              on:select={handleSelect}
              on:clear={handleClear}
              isDisabled={disabled || locked}
              containerClasses={touched
                ? successful
                  ? "is-success-select"
                  : " is-danger-select"
                : ""}
              containerStyles={disabled || locked
                ? "border: none; background-color: transparent; resize: none; outline: none;padding: 0; color: black !important;"
                : ""}
              placeholder=""
              showChevron={true}
            />
          {:else}
            {#if valuesFromSource[0] && valuesFromSource[0].id}
              <Select
                items={valuesFromSource}
                isMulti={multiSelect}
                optionIdentifier="id"
                getOptionLabel={(option) =>
                  option.title +
                  (option.description ? ": " : "") +
                  option.description}
                getSelectionLabel={(option) => option.title}
                itemFilter={(label, filterText, option) =>
                  removeAccents(label).includes(removeAccents(filterText))}
                value={setValue}
                on:select={handleSelect}
                on:clear={handleClear}
                isDisabled={disabled || locked}
                containerClasses={touched
                  ? successful
                    ? "is-success-select"
                    : " is-danger-select"
                  : ""}
                containerStyles={disabled || locked
                  ? "border: none; background-color: transparent; resize: none; outline: none;padding: 0; color: black !important;"
                  : ""}
                placeholder=""
                showChevron={true}
              />
            {:else}
              <Select
                items={valuesFromSource}
                isMulti={multiSelect}
                on:select={handleSelect}
                on:clear={handleClear}
                itemFilter={(label, filterText, option) =>
                  removeAccents(label).includes(removeAccents(filterText))}
                isDisabled={disabled || locked}
                containerClasses={touched
                  ? successful
                    ? "is-success-select"
                    : " is-danger-select"
                  : ""}
                containerStyles={disabled || locked
                  ? "border: none; background-color: transparent; resize: none; outline: none;padding: 0; color: black !important;"
                  : ""}
                placeholder=""
                showChevron={true}
              />
            {/if}
          {/if}
        </div>
      {:else}
        <!--  -->
        {#if isRestApiDs}
          {#if !disabled}
            <div class="radio-container">
              {#each valuesFromSource as radiovalue (radiovalue.id)}
                <label
                  class="radio-item"
                  class:checked-class={value &&
                    value.toString() === radiovalue.id.toString()}
                >
                  <input
                    type="radio"
                    x-webkit-speech
                    id={radiovalue.id}
                    bind:group={value}
                    name={question.title}
                    value={radiovalue.id}
                    checked={value
                      ? value.toString() === radiovalue.id.toString()
                      : false}
                  />
                  <div class="icon flex-item">
                    {#if value && value.toString() === radiovalue.id.toString()}
                      <!-- if selected -->
                      <CheckCircleIcon size="2x" />
                    {:else}
                      <!-- if not selected -->
                      <CircleIcon size="2x" />
                    {/if}
                  </div>
                  <div class="flex-item">{radiovalue.fullDescription}</div>
                </label>
              {/each}
            </div>
          {:else}
            <!-- if field is disabled, only show selected option -->
            {#if value}
              <p class="is-size-6">
                {question.options.filter((option) => option.id === value)[0]
                  .fullDescription}
              </p>
            {:else}
              Δεν έχει επιλεγεί τιμή
            {/if}
          {/if}
        {:else if question.options[0] && question.options[0].id}
          {#if !disabled}
            <div class="radio-container">
              {#each question.options as radiovalue (radiovalue.id)}
                <label
                  class="radio-item"
                  class:checked-class={value &&
                    value.toString() === radiovalue.id.toString()}
                >
                  <input
                    type="radio"
                    x-webkit-speech
                    id={radiovalue.id}
                    bind:group={value}
                    name={question.title}
                    value={radiovalue.id}
                    checked={value
                      ? value.toString() === radiovalue.id.toString()
                      : false}
                  />
                  <div class="icon flex-item">
                    {#if value && value.toString() === radiovalue.id.toString()}
                      <!-- if selected -->
                      <CheckCircleIcon size="2x" />
                    {:else}
                      <!-- if not selected -->
                      <CircleIcon size="2x" />
                    {/if}
                  </div>
                  <div class="flex-item">
                    <p class="is-size-6">{radiovalue.title}</p>
                    <p class="font-tiny">{radiovalue.description}</p>
                  </div>
                </label>
              {/each}
            </div>
          {:else}
            <!-- if field is disabled, only show selected option -->
            {#if value}
              <p class="is-size-6">
                {question.options.filter((option) => option.id === value)[0]
                  .title}
              </p>
              <p class="font-tiny">
                {question.options.filter((option) => option.id === value)[0]
                  .description}
              </p>
            {:else}
              Δεν έχει επιλεγεί τιμή
            {/if}
          {/if}
        {:else if !disabled}
          <div class="radio-container">
            {#each question.options as radiovalue}
              <label
                class="radio-item"
                class:checked-class={value &&
                  value.toString() === radiovalue.toString()}
              >
                <input
                  type="radio"
                  x-webkit-speech
                  id={radiovalue}
                  bind:group={value}
                  name={question.title}
                  value={radiovalue}
                  checked={value
                    ? value.toString() === radiovalue.toString()
                    : false}
                />
                <div class="icon flex-item">
                  {#if value && value.toString() === radiovalue.toString()}
                    <!-- if selected -->
                    <CheckCircleIcon size="2x" />
                  {:else}
                    <!-- if not selected -->
                    <CircleIcon size="2x" />
                  {/if}
                </div>
                <div class="flex-item">{radiovalue}</div>
              </label>
            {/each}
          </div>
        {:else}
          <!-- if field is disabled, only show selected option -->
          {#if value}
            {value}
          {:else}
            Δεν έχει επιλεγεί τιμή
          {/if}
        {/if}
      {/if}
      {#if hint !== null && !disabled}
        <p class="help">{hint}</p>
      {/if}
      {#if touched && errors.length > 0}
        <p transition:fade class="help is-danger">{errors[0].message}</p>
      {/if}
    </div>
  {:else if type === "text" || type === "email"}
    <div class="field {miniField ? 'mini-field' : ''}">
      <label for={label} class="label">
        {label}
        <span
          class="has-text-weight-medium {obligatory
            ? 'has-text-danger '
            : 'tag is-lowercase'}"
          style="{locked ? 'visibility: hidden; display: none;' : ''}"
          >{obligatory ? " * " : $_("LABELS.OPTIONAL")}</span
        >
      </label>
      <div class="control has-icons-right">
        <textarea
          use:autosize
          readonly={disabled || locked}
          name="reply"
          rows="1"
          bind:value
          style="overflow:auto; resize: none;"
          class="textarea {touched
            ? successful && !customValidationMessage
              ? 'is-success'
              : ' is-danger'
            : ''} {disabled || locked ? 'is-static' : ''}"
          on:input={(event) => {
            touched = true;
            // autoExpand(event.target);
          }}
        />
        <span class="icon is-small is-right">
          {#if (touched && errors && errors.length && errors.length > 0) || customValidationMessage}
            <Fa icon={faExclamationTriangle} aria-hidden="true" />
          {:else if successful && !disabled}
            <Fa icon={faCheck} aria-hidden="true" />
          {/if}
        </span>
      </div>
      {#if hint !== null && !disabled}
        <p class="help">{hint}</p>
      {/if}
      {#if touched && errors && errors.length && errors.length > 0}
        <p transition:fade class="help is-danger">{errors[0].message}</p>
      {:else if customValidationMessage}
        <p class="help is-danger">{customValidationMessage}</p>
      {/if}
    </div>
  {:else if type === "double" || type === "integer"}
    <div class="field {miniField ? 'mini-field' : ''}">
      <label for={label} class="label">
        {label}
        <span
          class="has-text-weight-medium {obligatory
            ? 'has-text-danger '
            : 'tag is-lowercase'}"
          style="{locked ? 'visibility: hidden; display: none;' : ''}"
          >{obligatory ? " * " : $_("LABELS.OPTIONAL")}</span
        >
      </label>
      <div class="control has-icons-right">
        <input
          bind:value
          type="text"
          readonly={disabled || locked}
          class="input {touched
            ? successful
              ? 'is-success'
              : ' is-danger'
            : ''} {disabled || locked ? 'is-static' : ''}"
          on:input={() => {
            touched = true;
          }}
        />
        <span class="icon is-small is-right">
          {#if touched && errors.length > 0}
            <Fa icon={faExclamationTriangle} aria-hidden="true" />
          {:else if successful && !disabled}
            <Fa icon={faCheck} aria-hidden="true" />
          {/if}
        </span>
      </div>
      {#if hint !== null && !disabled}
        <p class="help">{hint}</p>
      {/if}
      {#if touched && errors.length > 0}
        <p transition:fade class="help is-danger">{errors[0].message}</p>
      {/if}
    </div>
  {:else if type === "date"}
    <div class="field {miniField ? 'mini-field' : ''}">
      <label for={label} class="label">
        {label}
        <span
          class="has-text-weight-medium {obligatory
            ? 'has-text-danger '
            : 'tag is-lowercase'}"
          style="{locked ? 'visibility: hidden; display: none;' : ''}"
          >{obligatory ? " * " : $_("LABELS.OPTIONAL")}</span
        >
      </label>
      <div class="control has-icons-right">
        <!-- <input type=date bind:value={dateString}> -->
        <input
          bind:value
          type="date"
          readonly={disabled || locked}
          class="input {touched
            ? successful
              ? 'is-success'
              : ' is-danger'
            : ''} {disabled || locked ? 'is-static' : ''}"
          on:input={() => {
            value = convertDateForVariable(value);
            touched = true;
          }}
        />
        <span class="icon is-small is-right">
          {#if touched && errors.length > 0}
            <Fa icon={faExclamationTriangle} aria-hidden="true" />
          {:else if successful && !disabled}
            <Fa icon={faCheck} aria-hidden="true" />
          {/if}
        </span>
      </div>
      {#if hint !== null && !disabled}
        <p class="help">{hint}</p>
      {/if}
      {#if touched && errors.length > 0}
        <p transition:fade class="help is-danger">{errors[0].message}</p>
      {/if}
    </div>
  {:else if type === "point" && mapId}
    <div class="field">
      <label for={label} class="label">
        {label}
        <span
          class="has-text-weight-medium {obligatory
            ? 'has-text-danger '
            : 'tag is-lowercase'}"
          style="{locked ? 'visibility: hidden; display: none;' : ''}"
          >{obligatory ? " * " : $_("LABELS.OPTIONAL")}</span
        >
      </label>

      <div class="has-icons-right">
        <Map
          {mapId}
          {disabled}
          on:getvalue={(event) => {
            value = JSON.stringify(event.detail.value);
          }}
        />

        <span class="icon is-small is-right">
          {#if touched && errors.length > 0}
            <Fa icon={faExclamationTriangle} aria-hidden="true" />
          {:else if successful && !disabled}
            <Fa icon={faCheck} aria-hidden="true" />
          {/if}
        </span>
      </div>
      {#if hint !== null && !disabled}
        <p class="help">{hint}</p>
      {/if}
      {#if touched && errors.length > 0}
        <p class="help is-danger">{errors[0].message}</p>
      {/if}
    </div>
    <!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
  {:else if type === "group"}
    <div class="form-group">
      <div class="form-group-label">
        <label for={label} class="is-size-4 has-text-weight-semibold">
          {label}
          <!-- <span
            class="is-italic has-text-weight-normal {obligatory
              ? 'has-text-danger '
              : 'tag is-primary is-light'}"
            style={locked ? "visibility: hidden; display: none;" : ""}
            >{obligatory ? " *" : " Προαιρετικό "}</span
          > -->
        </label>
      </div>
      <!-- group box -->
      <div class="form-group-body">
        {#key $formUsed}
          <FormLayout
            formId={$formUsed ? $formUsed.id : null}
            {taxIdVerifiedService}
            bind:datasourcesReady
            bind:onBehalfOfDTO={forDatasources.onBehalfOfDTO}
            orgTukangaId={$selectedOrgDTO.open1ProcessOrganizationId}
            {serviceuuid}
            bind:validationSchema={$mainValidationSchema}
            bind:layoutNode={$serviceFieldNodes[mappedToVariable]}
            bind:inputsArray={nestedFields}
            bind:errorsArray={errors}
            isNested={true}
            layoutId={mappedToVariable}
            {disabled}
            on:validate
            on:addNodeToEmailVerificationInfo
          />
        {/key}
      </div>     

    </div>
    <hr/>
  {/if}
  {#if description && !(description.trim() === label.trim())}
    <div class="is-flex ml-0">
      <div class="mt-2 mb-3 is-flex is-flex-direction-column">
        {@html description}
      </div>
    </div>
  {/if}
</div>
<!-- {/if} -->

<!-- </div> -->
<style>
  :global(.field) {
    margin-top: 1.5rem;
  }

  /* label:first-of-type {
    margin-top: 0px !important;
} */

:global(.item, .selectContainer, .selectedItem, .selection) {
    line-height: auto !important;
    overflow-y: visible;
    height: auto !important;
    white-space: normal !important;
  }



  div.radio-container label:first-child {
    margin-top: 0px !important;
  }

  div.is-invisible {
    /* it's very important to render the element even when it's not visible, because props need to be passed */
    height: 0.1rem;
  }
</style>
