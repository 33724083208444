<script>
  //this component is loading and displaying organization data
  import {
    alphabetically,
    formatMimeType,
    organization,
    gsisAuthUrl,
  } from "../../../utils/helpers.js";
  import { url, params, goto } from "@sveltech/routify";
  import {
    organizations,
    clients,
    actions,
    currentCategories,
    selectedCategory,
  } from "../../../_clients.js";
  import {
    onMount,
    setContext,
    getContext,
    createEventDispatcher,
  } from "svelte";
  import { fade, blur } from "svelte/transition";
  import {
    axiosInst,
    loggedIn,
    profile,
    loggedTaxis,
    userId,
  } from "../../../credentials.js";
  import Spinner from "svelte-spinner";
  import Fa from "svelte-fa";
  import { DownloadIcon, PrinterIcon } from "svelte-feather-icons";
  import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
  import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
  import Taxis from "../../../components/taxis-login.svelte";
  import {
    showCategories,
    clientFooterName,
    clientTerms,
    clientPrivacy,
    clientA11Y,
    clientGsisId,
    selectedOrgDTO,
    selectedClient,
  } from "../../../ui-store.js";
  import { getCookie } from "../../../auth.js";

  const baseUrl = process.env.BASE_URL;
  console.log("base url: ", baseUrl);

  // export let scoped
  // $: ({user} = scoped)
  const dispatch = createEventDispatcher();

  const showAllCategories = () => {
    // console.log("toggle");
    $showCategories = true;
    $selectedCategory = null;
  };

  const hideCategories = () => {
    // console.log("toggle");
    $showCategories = false;
  };

  $: console.log("showCategories: ", $showCategories);

  //get values from local storage
  // loggedin.useLocalStorage();
  // loggedUser.useLocalStorage();
  // loggedTaxis.useLocalStorage();

  let isMock = true; //flag to see whether we've loaded a client from mock clients or registered organizations

  let openQuickView = false;
  let quickviewReady = false;
  // let selectedClient = null;
  let selectedAction = null;
  let islogged = false;

  //local testing ONLY-------------------
  // $loggedIn = true;
  // $profile.id = 1;
  // $loggedTaxis = true;
  // $profile.firstname = "ΕΥΤΥΧΙΑ";
  // $profile.lastname = "ΒΑΒΟΥΛΑ";

  let userid;
  $: if ($profile.id) {
    userid = $profile.id;
  } else {
    userid = "unregistered";
  }

  //once we get the logoURL parameter form org data, we need to build the url so that img src gets the content
  let logo_content;
  $: if ($selectedOrgDTO) logo_content = $selectedOrgDTO.logoURL + "/content";

  $: if ($selectedOrgDTO) {
    setContext("organization", $selectedOrgDTO);
    setContext("state", $selectedClient.id.toString().concat("_").concat("0"));

    console.log("Context: ", getContext("state"));
  }
  // let $selectedOrgDTO = null;
  $: console.log("$selectedOrgDTO: ", $selectedOrgDTO);

  function go(process_id) {
    if ($selectedClient) {
      let type_id;
      if ($profile.id) type_id = $profile.id;
      else type_id = "unregistered";
      $goto("/client/:id/action/:pid/user/:tid/new", {
        id: $params.id,
        pid: process_id,
        tid: type_id,
      });
    }
  }

  const findProcess = (id) => {
    openQuickView = false;
    let filter = $actions.filter((action) => action.id === id);
    console.log(filter);
    if (filter.length) {
      selectedAction = filter[0];
    } else selectedAction = filter;
  };

  function findProcessName(id) {
    let filter = $actions.filter((p) => p.id === id);

    let name = "not found";

    if (filter.length) {
      name = filter[0].name;
    } else name = filter;

    return name;
  }

  const createDownloadLink = (file) => {
    let fileType;
    console.log(file.templateFileURI);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await $axiosInst.get(
          file.templateFileURI.concat("/content"),
          { responseType: "arraybuffer" }
        );
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          reject();
        } else {
          console.log(response);
          //create the download URL, set the type of file it's sending
          let mimeType = formatMimeType(response.headers["content-type"]);
          file["templateURI"] = URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          //if mimetype is of certain type, add property to file (we need it for a download bug)
          if (mimeType === "DOC" || mimeType === "DOCX" || mimeType === "ODT")
            file["type"] = mimeType.toLowerCase();
          resolve(file["templateURI"]);
        }
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  function print() {
    printJS("toprint", "html");
  }

  let promises = [];

  let ready = false; //ready to display data?
  let serviceCategories;
  $selectedCategory = null; //by default, no category is selected
  let sortedServices;

  const sortServices = () => {
    // 1. Split services accoording to their respective "category", keep categories in a Set (unigue values)
    serviceCategories = [
      ...new Set(
        $selectedOrgDTO.servicesProvided.map((el) => el.serviceCategory)
      ),
    ];

    if (serviceCategories.length == 1) {
      $selectedCategory = 0;
    }

    // 2. Return services in an object, one for each category, which is {catogory name, services array}
    // the reducer function creates a new Αρραυ for each different "serviceCategory" attribute.
    // Each Array is then pushed to a mama Array ( [] ), which is returned as result
    console.log("services provided: ", $selectedOrgDTO.servicesProvided);
    let result = $selectedOrgDTO.servicesProvided.reduce((r, o) => {
      Object.entries(o).forEach(([key, val]) => {
        if (key === "serviceCategory") (r[val] = r[val] || []).push(o);
      });
      return r.sort();
    }, []);

    ready = true; //set dipslay flag to true
    return result;
  };

  $: if ($selectedOrgDTO) {
    sortedServices = sortServices();
    $currentCategories = serviceCategories;
  }

  onMount(() => {
    $showCategories = true;
  });
</script>

<!-- <div out:fade|local class="has-text-centered" style="z-index: 1; position: absolute !important; left:50%;
 margin-left:-100px;">
              <div class="is-size-5-mobile is-size-4"></div>
              <div class="is-size-4-mobile is-size-3 ">
                <Spinner
                size="100"
                speed="750"
                color="rgba(0, 255, 0, 0.3)"
                thickness="5"
                gap="40"/>
              </div>
            </div>        -->
<!-- {#if selectedClient} -->
<div in:fade|local class="container mt-1 pt-2 app-container">
  <div class="column pt-3">
    <div>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div>
              {#if $selectedOrgDTO.title}
                {#if $selectedOrgDTO.officialURL}
                  <a
                    class="ghost-link"
                    href={$url($selectedOrgDTO.officialURL)}
                    target="_blank"
                  >
                    <img
                      class="logo is-clickable"
                      src={logo_content}
                      alt="Logo Φορέα - {$selectedOrgDTO.title}"
                      title={$selectedOrgDTO.title}
                      style="max-width:400px; max-height:300px;"
                    />
                  </a>
                {:else}
                  <img
                    class="logo"
                    src={logo_content}
                    alt="Logo Φορέα - {$selectedOrgDTO.title}"
                    title={$selectedOrgDTO.title}
                    style="max-width:400px; max-height:300px;"
                  />
                {/if}
              {/if}
            </div>
          </div>

          <!-- ονομασία φορέα -->
          <!-- <div
                class="level-item has-text-weight-bold is-size-5 is-size-mobile-3"
              >
               &nbsp; {selectedOrgDTO.title}
              </div> -->
        </div>
        <!-- <div class="level-right ">
            {#if selectedOrgDTO.gsisClientId && !$loggedTaxis}<div class="level-item">
                <Taxis label="Σύνδεση με κωδικούς TaxisNet" state={selectedClient.id.toString().concat("_").concat(0)} />
              </div>{/if}
          </div> -->
      </div>
    </div>

    <div class="mt-1">
      {#if $selectedOrgDTO.welcomeMessage}
        <div class="is-size-6 mb-2 px-5 pb-5">
          {@html $selectedOrgDTO.welcomeMessage}
        </div>
      {/if}

      <div class="container px-3 my-2">
        <div>
          <!-- ------------------------------------------------------------------------------ -->
          <!-- {#if selectedOrgDTO.servicesProvided && selectedOrgDTO.servicesProvided.length > 0} -->
          <div class="container mt-2">
            {#if ready}
              <entrance-services
                dark="false"
                client={$selectedClient.id}
                locale="el"
                theme="blue"
                entranceurl={process.env.BASE_URL}
                gsisauthurl={process.env.GSIS_AUTH_URL}
                {userid}
              />

              <!-- extra space at the bottom -->
              <div style="height:10rem"></div>

              <!-- <div class="columns is-variable is-7"> -->
              <!-- <div class="column is-one-third is-narrow"> -->

              <!-- {#if serviceCategories && serviceCategories.length > 1}
                      <aside
                        class="column is-fullheight {$selectedCategory === null ? '' : 'is-hidden-mobile'} {$showCategories
                          ? ''
                          : 'is-hidden-mobile'}"> -->
              <!-- <p class="is-capitalized has-text-weight-semibold is-size-4 my-2"> Κατηγορίες Υπηρεσίων </p> -->
              <!-- <ul class="menu-list">
                          {#each serviceCategories as cat, i}
                            <li class="is-size-6">
                              <a
                                on:click={() => {
                                  $selectedCategory = i;
                                  hideCategories();
                                }}
                                class="{i === $selectedCategory
                                  ? 'has-text-white has-background-link-dark'
                                  : 'is-link '} p-4 has-text-weight-bold"
                              >
                                {cat}
                                <div class="column"><Fa icon={faChevronRight} size="1x"/></div>
                              </a>
                                class="{i === $selectedCategory ? 'is-link has-background-link-light' : 'has-text-black '} p-4">
                                {cat} -->
              <!-- <div class="column"><Fa icon={faChevronRight} size="1x"/></div> -->
              <!-- </a>
                            </li>
                          {/each}
                        </ul>
                      </aside>
                    {/if} -->

              <!-- </div> -->
              <!-- {$showCategories
                        ? 'is-hidden-mobile'
                        : 'box'}" -->
              <!-- <div class="column is-size-5 {$selectedCategory === null ? 'is-hidden-mobile' : ''} ">
                      {#if $selectedCategory || $selectedCategory === 0}
                        {#if serviceCategories.length == 1}
                          <p class="column is-size-5-desktop is-size-6-mobile">
                            {serviceCategories[$selectedCategory]}
                          </p>
                        {:else}
                          <div
                            class="columns is-mobile is-vcentered mt-1 is-capitalized is-size-6 is-unclickable-desktop px-3"
                            on:click={showAllCategories}
                          >
                            Επιλεγμένη κατηγορία: &nbsp
                            <span class="is-hidden-desktop" style="cursor:pointer">
                            on:click={showAllCategories}> -->
              <!-- Επιλεγμένη κατηγορία: &nbsp -->
              <!-- <span class="is-hidden-desktop">
                              <Fa icon={faChevronLeft} size="1x" /> &nbsp;
                            </span>
                            <p class="column is-size-5-desktop is-size-6-mobile">
                              {serviceCategories[$selectedCategory]}
                            </p>
                          </div>
                        {/if}
                        <hr class="my-2" />
                        <ul class="menu-list">
                          {#each sortedServices[serviceCategories[$selectedCategory]].sort( (a, b) => alphabetically(a.serviceName, b.serviceName) ) as service} -->
              <!-- <div style="mt-1"> -->
              <!-- <li>
                              <a
                                class="has-text-link-dark is-bold p-3 pb-5"
                                on:click={() => {
                                  openQuickView = true;
                                  selectedType = service;
                                }}
                                ><p class="is-size-5-desktop is-size-6-mobile">
                                  {service.serviceName}
                                </p>
                              </a>
                            </li> -->
              <!-- </div> -->
              <!-- {/each}
                        </ul>
                      {/if}
                    </div>
                  </div> -->
              <!-- {:else}
                <div class="is-size-4-mobile is-size-3 ">
                  <Spinner size="100" speed="750" color="rgba(0, 255, 0, 0.3)" thickness="5" gap="40" />
                </div> -->
            {/if}
          </div>
          <!-- {/if} -->
          <!-- --------------------------------------------------------------------------------------- -->
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .menu-list a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
</style>
