<script>
import {axiosAuth, commons, ready} from '../auth.js';
import {charts} from './settings-store.js';
import { createEventDispatcher } from 'svelte';
import AutoComplete from 'simple-svelte-autocomplete';
import Select from 'svelte-select';
import Formfield from './Formfield.svelte';
import { removeAccents } from "../utils/helpers.js";

export let sourceArray;
let loading = true;
let auth = "NO_VERIFICATION";

let itemToCheck; //here we pass every selection from the autocompletes and reactively check its type

$:console.log(auth);

// let pathForChartData;


const resetPath = (chart, unit, role, person) => {
    // console.log("before path reset", pathForChartData);
    
    return  $commons.orgUuid+':'+(chart?chart.uuid:'')+':'+(unit?unit.uuid:'')+':'+(role?role.uuid:'')+':'+(person?person.uuid:'');
   
}

// $:pathForChartData = $commons.orgUuid+':'+(selectedChart?selectedChart.uuid:'')+':'+(selectedUnit?selectedUnit.uuid:'')+':'+
//                                         (selectedRole?selectedRole.uuid:'')+':'+(selectedPerson?selectedPerson.uuid:'')+':';

//if the org-chart=unit-role-person path is updated, call to get new data from organization                                      
//$:if(pathForChartData) getAllDataFromChart();


//check what each selected item's type is, and assign to variable, so as to update the org-chart=unit-role-person path and then call for data.
//the reactive statement was firing as it should have, but I'm stupid since I wanted it to fire after I set the path correctly
$:if(itemToCheck&&itemToCheck.orgType){
    switch (itemToCheck.orgType.valueOf()){
        case ("CORPORATE_STRUCTURE".valueOf()):
        console.log("chose a chart");
        selectedChart = null; selectedUnit = null; selectedRole = null; selectedPerson = null; //reset path for all 'descedants'. It's the chart, it's ok
        selectedChart = itemToCheck;
        // resetPath(itemToCheck, null, null, null)
        // .then(path_result => {
             getAllDataFromChart(resetPath(itemToCheck, selectedUnit, selectedRole, selectedPerson))
            .then(data_result => {
                updateAutocompleteLists(data_result, "CORPORATE_STRUCTURE");
            })
        // }) 
        break;
        case( "CORPORATE_UNIT".valueOf()):
        console.log("chose a unit");
        //selectedUnit = null; selectedRole = null; selectedPerson = null; //reset path for all 'descedants'
        selectedUnit = itemToCheck;
        // resetPath(selectedChart, itemToCheck, null, null)
        // .then(path_result => {
             getAllDataFromChart(resetPath(selectedChart, itemToCheck, selectedRole, selectedPerson))
            .then(data_result => {
                updateAutocompleteLists(data_result, "CORPORATE_UNIT");
            })
        // })
        break;
        case ("JOB_DESCRIPTION".valueOf()):
        console.log("chose a job");
        //selectedRole = null; selectedPerson = null; //reset path for all 'descedants'
        selectedRole = itemToCheck;
        // resetPath(selectedChart, selectedUnit, itemToCheck, null)
        // .then(path_result => {
             getAllDataFromChart(resetPath(selectedChart, selectedUnit, itemToCheck, selectedPerson))
            .then(data_result => {
                updateAutocompleteLists(data_result, "JOB_DESCRIPTION");
            })
        // })
        break; 
        case ("PERSONNEL_UNIT".valueOf()):
        console.log("chose a person");
        //selectedPerson = null; //reset path 
        selectedPerson = itemToCheck;
        // resetPath(selectedChart, selectedUnit, selectedRole, itemToCheck)
        // .then(path_result => {
             getAllDataFromChart(resetPath(selectedChart, selectedUnit, selectedRole, itemToCheck))
            .then(data_result => {
                updateAutocompleteLists(data_result, "PERSONNEL_UNIT");
            })
        // })
        break;
    }
}
//each autocomplete has its own list of items.
//the contents depend on the item and the type of the item selected in the previous autocomplete
// We need to check which dropdown autocomplete updated its selected item and update the lists accordingly
let updatedDropdown = null;
let list2;
let list3;
let list4;


// const resetValues = (arrayOfValues) => {
//     //when you need to quickly nullify values of an array
//     forEach(arrayOFValues)
// }

$:console.log("list2: " +(list2?list2.length:'null') +"\nlist3: "+(list3?list3.length:'null')+"\nlist4: "+(list4?list4.length:'null'));

//little hook to store results in correct list and modify the others accordingly
const updateAutocompleteLists = (results, type) => {
    //if the selected item was a CORPORATE_UNIT type, prevent its descendant CORPORATE_UNITS from showing up in the results
    //all corporate units have shown up already, under the previously selected chart, along with roles and persons. 
    //it was the same list this 'offending' CORPORATE_UNIT was selected from. No need to show them again. 
    //UPDATE: It seems JOB_DESCRIPTION types do the same thing as well. Generalizing:
    console.log("results before: ", results);
    results = results.filter(item => !(item.orgType.valueOf()===type.valueOf()));
    console.log("results after: ", results);
    let filtered_results = filterCorpJobPersonResults(results);
    console.log(filtered_results.units);
    console.log(filtered_results.jobs);
    console.log(filtered_results.personnel);
    switch (updatedDropdown){
        case 1:
            console.log("updated chart")
            list2 = null; //reset
            list2 = results;
            selectedItem2=null;
            selectedItem3=null;
            list3 = null; 
            list4 = null;
        break;
        case 2:
            console.log("updated 2nd list item")
            //that's the list whose selecteed item determines the other two
            list3 = null; //reset
            list4 = null; //reset
            selectedItem3=null;
            selectedItem4=null;
            if(type.valueOf()==="CORPORATE_UNIT".valueOf()) list3 = filtered_results.jobs; else list3 = filtered_results.units;
            if(type.valueOf()==="PERSONNEL_UNIT".valueOf()) list4 = filtered_results.jobs; else list4 = filtered_results.personnel;
            if (list3.length===1) {
                selectedItem3 = list3[0]; 
                if (selectedItem2.orgType.valueOf()==="CORPORATE_UNIT".valueOf()) selectedRole = selectedItem3;
                else selectedUnit = selectedItem3;
                }
            if (list4.length===1) {
                selectedItem4 = list4[0];
                if (selectedItem2.orgType.valueOf()==="PERSONNEL_UNIT".valueOf()) selectedRole = selectedItem4;
                else selectePerson = selectedItem4;
                }
        break; 
        case 3:
            console.log("updated 3rd list itam")
            list4 = null; //reset
            selectedItem4=null;
            if(type.valueOf()==="PERSONNEL_UNIT".valueOf()) list4 = filtered_results.jobs; else list4 = filtered_results.personnel;
            if (list4.length===1) {
                selectedItem4 = list4[0];
                if (selectedItem2.orgType.valueOf()==="PERSONNEL_UNIT".valueOf()) selectedRole = selectedItem4;
                else selectedPerson = selectedItem4;
                }
        default: //do nothing
        
    }
}


let selectedService = null;
let selectedIssuer = null;


let selectedChart;
let selectedUnit;
let selectedRole;
let selectedPerson;

//if chart, unit and role have been chosen, assign the Issuer as the path for the corpjobperson call to orgApi
$:{if(selectedChart&&selectedUnit&&selectedRole&&selectedPerson) selectedIssuer = resetPath(selectedChart, selectedUnit, selectedRole, selectedPerson); else selectedIssuer = null;}
$:if(selectedIssuer)console.log("Issuer has been selected!: ", selectedIssuer);
let selectedItem2;
let selectedItem3;
let selectedItem4;

// $:console.log("selectedItem2: ", selectedItem2);
// $:console.log("selectedItem3: ", selectedItem3);


let everythingAndEveryone; //this is where we store data from the corp-job-person call 

$:console.log(selectedService);
$:console.log("chart", selectedChart);
$:console.log("unit", selectedUnit);
$:console.log("role", selectedRole);
$:console.log("person:", selectedPerson);
// $:console.log(everythingAndEveryone);

const dispatch = createEventDispatcher();
$:console.log("itemToAdd: ", itemToAdd);

let itemToAdd = {
    "processDefinitionId" : null,
    "authenticationRequired": null,
    "issuerResourceId" : null
};

$:if (selectedService&&auth&&selectedIssuer) {
        itemToAdd.processDefinitionId = selectedService.id;
        itemToAdd.authenticationRequired = auth;
        itemToAdd.issuerResourceId = selectedIssuer;
    }  else {
        itemToAdd.processDefinitionId = null;
        itemToAdd.authenticationRequired = null;
        itemToAdd.issuerResourceId = null;
    }

$:console.log(itemToAdd);

const saveChanges = () =>{
    //save changes and publish them

    //dispatch modal close event
    dispatch('saveAndPublish', {
			publishedService: itemToAdd
		});
	
}

const cancel = () =>{
    //reset changes (if there are any) and dispatch modal close event
    dispatch('cancel');
}

const close = () =>{
    //dispatch modal close event
    dispatch('cancel');
}


// const handleClick = (event) =>{
//     let id = event.target.getAttribute("pid");
//     //find the process with this id. I only expect one
//     let result = sourceArray.filter(el => el.id===id);
//     console.log(result[0]);
//     if(event.target.checked){
//         targetArray.push(result[0]);
//         //force ui update
//         targetArray = targetArray;
//     }
//     else {
//         //process was unchecked. Filter items that are not this process
//         let filter = targetArray.filter(el => el.id!==id.toString())
//         console.log(filter);
//         //force ui update
//         targetArray = filter;
//     }
// }

const filterCorpJobPersonResults = (data) => {
    //gets all data and splits it in 3 types: unit, job, or person
    let units = data.filter(item => item.orgType.valueOf()==="CORPORATE_UNIT".valueOf());
    let jobs =  data.filter(item => item.orgType.valueOf()==="JOB_DESCRIPTION".valueOf());
    let personnel = data.filter(item => item.orgType.valueOf()==="PERSONNEL_UNIT".valueOf());

    return {units, jobs, personnel};

}

const getAllDataFromChart = async (path) => {
    //set path
    //let path = uuid οργανισμού : uuid οργανογράμματος : uuid οργ. μονάδας (τμήμα) : uuid ρόλου (θέσης εργασίας) : uuid προσώπου
     //we need to use axios here
     console.log("path: ", path);
    return new Promise(async (resolve, reject) => {
     loading = true;
     everythingAndEveryone = null;
     try {
         const res = await $axiosAuth.get('/api/orgchart/corpjobperson/'+path);
        //  console.log(res.data);
        //  console.log(res.status);
         if(res.status===200){
            everythingAndEveryone = res.data.data;           
            // updateAutocompleteLists(everythingAndEveryone);
            resolve(res.data.data);
            console.log("successful call");
            loading = false;
            
         }
         else 
        //  alert('something went wrong');
         reject('something went wrong');
         }
         catch (e) {
         console.log(e);
         reject('something went wrong');
        }
    })
}

</script>
<style>

.modal-card-head, .modal-card-foot {
    background-color: white;
}

</style>

<!-- <div class="container box pt-4 px-4 mt-6"> -->

<div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Προσθήκη Υπηρεσίας</p>
      <button class="delete" aria-label="close" on:click={close}></button>
    </header>
    <section class="modal-card-body px-6">
    <section class="my-5 py-3">
        <h2 class="subtitle is-size-5 mb-2">
        Επιλογή Υπηρεσίας
        </h2>
            <AutoComplete  items={sourceArray} bind:selectedItem = {selectedService}                
                  textCleanFunction={(text)=> {if
                  (text===undefined) selectedService=null; else return removeAccents(text);}}
                  keywordsFunction={item => removeAccents(item.name) + '' + item.lastPublishedVersionId}
                  labelFunction={item => item.name+ ' ' + item.lastPublishedVersionId}
                  placeholder="Αναζήτηση Υπηρεσίας..." 
                />
     </section>
     <hr>
     <section>
        <h2 class="subtitle is-size-5 mb-2">
            Επιλογή Εκκινητή
            <!-- <p>{pathForChartData}</p> -->
        </h2>
        <!-- <Formfield label="Εκκινητής"/> -->
                <!-- the moment we select a chart, we get all data associated with it -->
                <!-- onChange=(getAllDataFromChart) -->
                <p>Επιλογή Οργανογράμματος</p>
                <!-- So, onChange in the Autocomplete component below fires when the selected value CHANGES, not when it's SELECTED by the user
                and that results in unexpected firing when clearing 'descendant' lists of the other autocompletes, 
                thus for the app thinking that a different list needs to be updated, due to the reactive statement that checks the updated dropdowns
                It shouldn't be a problem when the list items are not dependent on other autocomplete components, but keep it in mind.
                Since I nullify descendant lists and choices, I added an if statement that checks for non-null selected item.
                The general changes are handled in resetPath and updateAutocompleteLists functions.
                The svelte-select component exports an on:select event and could be another choice to overcome the issue. Uncomment at will -->

                <!-- <AutoComplete debug={false} items={$charts} bind:selectedItem = {selectedChart} 
                  onChange={(selectedItem)=> {if(selectedChart){updatedDropdown = 1; itemToCheck = selectedChart; console.log("chart: ", selectedItem);}}}                  
                  textCleanFunction={(text)=> {if
                  (text===undefined) selectedChart=null; else return removeAccents(text);}}
                  keywordsFunction={item => removeAccents(item.name)}
                  labelFunction={item => item.name}
                  placeholder="Αναζήτηση Οργανογράμματος..." 
                /> -->
                <Select items={$charts} bind:selectedValue = {selectedChart} optionIdentifier = 'uuid'
                on:select={(selectedValue)=> {updatedDropdown = 1; itemToCheck = selectedChart; console.log(selectedValue);}}
                on:clear={()=>{selectedChart = null;}}
                getOptionLabel={item => item.name} 
                getSelectionLabel={item => item.name} 
                itemFilter={(label, filterText, option) => removeAccents(option.name).includes(removeAccents(filterText))}
                inputStyles="box-sizing: border-box;"></Select> 
           
           {#if selectedChart&&list2&&list2.length>0}
           <p>Οργανωτική μονάδα, Θέση Εργασίας ή Πρόσωπο</p>
           <!-- this is the 'everything and everyone list', once the selection changes
            the descendant dropdown lists and their selected items' values should all reset to null -->
           <Select items={list2} bind:selectedValue = {selectedItem2} optionIdentifier = 'uuid'
                on:select={(selectedValue)=> {updatedDropdown = 2; itemToCheck = selectedItem2; console.log(selectedValue);
                            if(selectedItem2.orgType.valueOf()==="CORPORATE_UNIT".valueOf()) {selectedRole = null; selectedPerson = null;} 
                            else if (selectedItem2.orgType.valueOf()==="JOB_DESCRIPTION".valueOf()) {selectedUnit = null; selectedPerson = null;}
                            else if (selectedItem2.orgType.valueOf()==="PERSONNEL_UNIT".valueOf()){selectedUnit = null; selectedRole = null;}
                            }}
                on:clear={() => { selectedUnit = null; selectedRole = null; selectedPerson = null; selectedItem3 = null; selectedItem4 = null;}}
                getOptionLabel={item => item.name} 
                getSelectionLabel={item => item.name} 
                itemFilter={(label, filterText, option) => removeAccents(option.name).includes(removeAccents(filterText))}
                inputStyles="box-sizing: border-box;"></Select> 
                <!-- <AutoComplete debug={false} items={list2} bind:selectedItem = {selectedItem2} 
                  onChange={()=>{if(selectedItem2){updatedDropdown = 2; itemToCheck = selectedItem2; console.log("2nd item: ", selectedItem2);}}}     
                  textCleanFunction={(text)=> {if
                  (text===undefined) selectedItem2=null; else return removeAccents(text);}}
                  keywordsFunction={item => removeAccents(item.name)}
                  labelFunction={item => item.name}
                  placeholder="Πρόσωπο, Ρόλος, Οργανωτική Μονάδα" 
                /> -->
           {/if}
           {#if selectedChart&&selectedItem2&&list2&&list3&&list3.length>0}
            <p>{#if selectedItem2.orgType.valueOf()==="CORPORATE_UNIT".valueOf()}Επιλογή Θέσης Εργασίας{/if}
                {#if selectedItem2.orgType.valueOf()==="JOB_DESCRIPTION".valueOf()}Επιλογή Οργανωτικής Μονάδας{/if}
                {#if selectedItem2.orgType.valueOf()==="PERSONNEL_UNIT".valueOf()}Επιλογή Οργανωτικής Μονάδας{/if}</p>
           <Select items={list3} bind:selectedValue = {selectedItem3} optionIdentifier = 'uuid'
                on:select={(selectedValue)=> {updatedDropdown = 3; itemToCheck = selectedItem3; console.log(selectedValue);}}
                on:clear={() => { if(selectedItem2.orgType.valueOf()==="CORPORATE_UNIT".valueOf()) {selectedRole = null; selectedPerson = null;} 
                                else if (selectedItem2.orgType.valueOf()==="JOB_DESCRIPTION".valueOf()) {selectedUnit = null; selectedPerson = null;}
                                else if (selectedItem2.orgType.valueOf()==="PERSONNEL_UNIT".valueOf()){selectedUnit = null; selectedRole = null;}
                                selectedItem4 = null;}}
                getOptionLabel={item => item.name} 
                getSelectionLabel={item => item.name} 
                itemFilter={(label, filterText, option) => removeAccents(option.name).includes(removeAccents(filterText))}
                inputStyles="box-sizing: border-box;"></Select> 
                <!-- <AutoComplete debug={false} items={list3} bind:selectedItem = {selectedItem3} 
                  onChange={()=>{if(selectedItem3){updatedDropdown = 3; itemToCheck = selectedItem3; console.log("3rd item: ", selectedItem3);}}}         
                  textCleanFunction={(text)=> {if
                  (text===undefined) selectedItem3=null; else return removeAccents(text);}}
                  keywordsFunction={item => removeAccents(item.name)}
                  labelFunction={item => item.name}
                  placeholder="Πρόσωπο, Ρόλος, Οργανωτική Μονάδα" 
                /> -->
           {/if}
           {#if selectedItem2&&list3&&list4&&list4.length>0}
            <p>{#if selectedItem2.orgType.valueOf()==="CORPORATE_UNIT".valueOf()}Επιλογή Προσώπου{/if}
                {#if selectedItem2.orgType.valueOf()==="JOB_DESCRIPTION".valueOf()}Επιλογή Προσώπου{/if}
                {#if selectedItem2.orgType.valueOf()==="PERSONNEL_UNIT".valueOf()}Επιλογή Θέσης Εργασίας{/if}</p>
           <Select items={list4} bind:selectedValue = {selectedItem4} optionIdentifier = 'uuid' isDisabled={!selectedItem3}
                on:select={(selectedValue)=> {updatedDropdown = 4; itemToCheck = selectedItem4; console.log(selectedValue);}}
                on:clear={()=>{if(selectedItem2.orgType.valueOf()==="PERSONNEL_UNIT".valueOf()) selectedRole = null; else selectedPerson = null;}}
                getOptionLabel={item => item.name} 
                getSelectionLabel={item => item.name} 
                itemFilter={(label, filterText, option) => removeAccents(option.name).includes(removeAccents(filterText))}
                inputStyles="box-sizing: border-box;"></Select> 
                <!-- <AutoComplete debug={false} items={list3} bind:selectedItem = {selectedItem3} 
                  onChange={()=>{if(selectedItem3){updatedDropdown = 3; itemToCheck = selectedItem3; console.log("3rd item: ", selectedItem3);}}}         
                  textCleanFunction={(text)=> {if
                  (text===undefined) selectedItem3=null; else return removeAccents(text);}}
                  keywordsFunction={item => removeAccents(item.name)}
                  labelFunction={item => item.name}
                  placeholder="Πρόσωπο, Ρόλος, Οργανωτική Μονάδα" 
                /> -->
           {/if}
        
        {#if selectedIssuer} <h3>Ο Εκκινητής έχει επιλεχθεί!</h3>{/if}
     </section>
    <hr>
    <section class="my-5 py-3">
        <h2 class="subtitle is-size-5 mb-2">
        Eπικύρωση στοιχείων χρήστη
        </h2>
           <div >
                <label class="is-size-6">
                <input type="radio" bind:group={auth} value="NO_VERIFICATION">
                    Χωρίς επικύρωση
                </label>
                <label class="is-size-6">
                <input type="radio" bind:group={auth} value="EMAIL_VERIFICATION">
                    Μέσω e-mail
                </label>
                <label class="is-size-6">
                    <input type="radio" bind:group={auth} value="GSIS_VERIFICATION">
                    Μέσω κωδικών TAXISnet
                </label>
                <label class="is-size-6">
                <input type="radio" bind:group={auth} value="SMS_VERIFICATION">
                    Μέσω SMS
                </label>
                
                </div>
     </section>

    <footer class="modal-card-foot is-grouped level">
      <button class="button level-item " on:click={cancel}>Ακύρωση</button>
      <button class="button has-text-white has-text-weight-semibold level-item box" style="background-color:rgb(63,81,181);" on:click={saveChanges} disabled={!(selectedIssuer&&selectedService&&auth)}>Δημοσίευση</button>
    </footer>
</div>



<!-- PRIMARY CODE FOR ADDING MULTIPLE SERVICES. NEEDS FIXES-->
<!-- <div class="columns px-5 py-5 clearfix" style="height:80%;">


    <div class="column px-5 clearfix" style="max-width:45%">
    <h1 class="is-size-5 is-size-6-mobile has-text-centered my-4">Διαθέσιμες Υπηρεσίες προς Δημοσίευση</h1>
        <table class="table is-fullwidth is-hoverable">

                <tbody style="height:80%;  overflow-y:scroll; position:absolute;">
                    {#each sourceArray as service}
                    <tr><td><p> <input class="checkbox is-medium" pid={service.id} on:change={handleClick} type="checkbox">&nbsp;{service.name}&nbsp;<b>{service.lastPublishedVersionId}</b></p></td></tr>
                    {/each}
                </tbody>
        </table>
    </div>


    <div class="column px-1" style="width:10%;">
        <div class="buttons are-medium" style="display:flex; flex-direction: column; margin-top:50%;">
            <button class="button" on:click={saveChanges}>Αποθήκευση αλλαγών</button>
            <button class="button" on:click={cancel}>Ακύρωση</button>
            <button class="button" on:click=>Κλείσιμο</button>
        </div>
    </div>
    

    <div class="column px-5 clearfix" style="width:45%">
    <h1 class="is-size-5 is-size-6-mobile has-text-centered my-4">Επιλεγμένες Υπηρεσίες προς Δημοσίευση</h1>
        <table class="table is-fullwidth is-hoverable">
                
                <tbody style="height:80%; overflow-y:scroll; position:absolute;">
                    {#each targetArray as service}
                    <tr><td>{service.name}</td><td class="has-text-centered">{service.lastPublishedVersionId}</td></tr>
                    {/each}
                </tbody>
        </table>
    </div>
    
</div> -->


<!-- 
<div class="container fixed">

Επιλέξτε μια υπηρεσία για να προβάλλετε πληροφορίες σχετικά με αυτήν
</div> -->

<!-- </div> -->
