<script>
  import { isActive, url, goto, params } from "@sveltech/routify";
  import { logout, profile } from "../auth.js";
  import { fade } from "svelte/transition";
  import { loggedIn } from "../credentials.js";
  import Taxis from "./taxis-login.svelte";

  import Fa from "svelte-fa";
  import {
    faArchway,
    faUniversalAccess,
  } from "@fortawesome/free-solid-svg-icons";
  import { onMount } from "svelte";
  // Import the toast function
  import bulmaToast from "bulma-toast";
  let ready = false;
  //get values from local storage
  loggedin.useLocalStorage();
  loggedUser.useLocalStorage();

  $: console.log("Layout says we're logged in: ", $loggedin);
  bulmaToast.setDoc(window.document);
  let message;
  let orgpath = "false";

  $: if ($params.id) orgpath = "true";

  $: if (message)
    bulmaToast.toast({
      message: message,
      position: "top-right",
      dismissible: false,
      animate: { in: "fadeIn", out: "fadeOut" },
    });

  //toggle high contrast flag for accessibility
  const toggleContrast = () => {
    $highContrast = !$highContrast;
    if ($highContrast)
      message = "<b>Λειτουργία Υψηλής Αντίθεσης: Ενεργοποιημένη</b>";
    else message = "<b>Λειτουργία Υψηλής Αντίθεσης: Απενεργοποιημένη</b>";
  };

  let isOpen = false;

  const logoutnow = () => {
    localStorage.setItem("loggedin", false);
    localStorage.setItem("loggedUser", "");
    window.location.href = "https://entrance-staging.open1.eu/";
  };

  onMount(() => {
    highContrast.useLocalStorage();
  });
</script>

<nav
  class="navbar {orgpath === 'true' ? 'has-background-info' : ''}"
  role="navigation"
  aria-label="main navigation"
>
  <div class="container">
    <div class="navbar-brand">
      <a href="https://entrance-staging.open1.eu/" class="navbar-item">
        <div class="title is-4 level ">
          <!-- <span class="icon is-large has-text-info">
                <i class="far fa-arrow-alt-circle-up has-text-link" />
            </span> -->
          <Fa class="level-item has-text-info" icon={faArchway} size="1x" />
          <p class="level-item">&nbsp;Open1 | Entrance</p>
          <!-- <Icon style="font-size: 3rem;" class="level-item has-text-info" icon={faArchway}/><p class="level-item mx-1">Open1 |  Entrance</p> -->
        </div>
      </a>

      <a
        role="button"
        class="navbar-burger burger {isOpen ? 'is-active' : ''}"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        on:click={() => (isOpen = !isOpen)}
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div
      id="navbarBasicExample"
      class="navbar-menu {isOpen ? 'is-active' : ''}"
      on:click={() => (isOpen = !isOpen)}
    >
      <div class="navbar-start" />

      <div class="navbar-end">
        <slot name="navigation-buttons">
          <div class="buttons">
            <!-- {#if !$isActive('/client')&&!$isActive('/admin')}
                    <div class="navbar-item">
                    <a class="button bold {$highContrast?'has-background-link-dark has-text-white	':' is-info'}" href="{$url('/client')}">
                        Ξεκινήστε εδώ
                    </a>
                    </div>
                {/if} -->
            <!-- {#if !$isActive('/client')} -->

            {#if !$loggedin}
              <div class="navbar-item">
                <!-- <a class="button bold {$highContrast?'has-background-link-dark has-text-white	':' is-info'}" href="{$url('/user')}">
                        Σύνδεση
                    </a> -->
                <!-- {#if $isActive('/client/:id')&&ready}
                    <Taxis label="Σύνδεση"/>
                    {/if} -->
              </div>
            {:else}
              <div class="navbar-item">
                <a
                  class="button bold {$highContrast
                    ? 'has-background-link-dark has-text-white	'
                    : ' is-info'}"
                  on:click={$goto("/user/:id", { id: $loggedUser })}
                >
                  To προφίλ μου
                </a>
              </div>
              <div class="navbar-item">
                <a
                  class="button bold {$highContrast
                    ? 'has-background-link-dark has-text-white	'
                    : ' is-info'}"
                  on:click={logoutnow}
                >
                  Έξοδος
                </a>
              </div>
            {/if}

            <!-- {/if} -->
          </div>
        </slot>
      </div>
    </div>
  </div>
</nav>

<div class="my-4">
  <!-- <div class="columns is-centered"><div class="column is-10"> -->
  <slot />
  <!-- </div></div> -->
</div>

<button
  role="button"
  on:click={toggleContrast}
  class="button fixed align-middle"
>
  <Fa class="icon" icon={faUniversalAccess} size="6x" />
</button>

<style>
  .fixed {
    right: 0;
    bottom: 0;
    margin: 3rem;
    position: fixed;
    display: flex;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    line-height: 3rem;
    justify-content: center;
    align-items: center;
  }

  .fixed-notification {
    right: 0;
    bottom: 50%;
    margin: 3rem;

    position: fixed;
  }

  .icon {
    position: relative;
  }
</style>
