<script>
import {axiosAuth, commons, ready} from '../auth.js';
import { createEventDispatcher } from 'svelte';
import {updateTags} from '../components/settings-store.js'

export let tagToDelete;
let deleting = false;
let successful = null;

let taxonomy = () => { 
    switch(tagToDelete.tagTarget[0]){
            case "TEMPLATE_FILE":
                return "Πρότυπα"
                break;
            case "FILE":
                return "Αρχεία Υπόθεσης";
                break;
            case "PROCESS_DEFINITION":
               return "Διαδικασία";
                break;
            case "PROCESS_INSTANCE":
                return "Υπόθεση";
                break;
        }
    };

const dispatch = createEventDispatcher();

const close = () => {
		dispatch('close-modal');
    }
    
const deleteTag = async () => {
    deleting = true;
     try {
            // /api/{orgId}/taxonomies/{tagId}
            const res = await $axiosAuth.delete('/api/'+$commons.orgId+'/taxonomies/'+tagToDelete.id);
            console.log(res.data);
            console.log(res.status);
            if(res.status===200){
               if(!res.data.errors.length>0){
                deleting = false;
                successful = true;
                console.log("successful call");
                console.log(res.data);

                //successful call, force tags component update
                $updateTags = true; //set the flag to true
               }
               else {
                deleting = false;
                successful = false;
                console.log("An error occured");
                console.log(res.errorMessage);
                console.log(res.errors);
               }
            }
            else {
                console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                deleting = false;
                successful = false;
                
                }                   
            } catch (e) {
            console.log(e);
            
        }
}

</script>

<style>
.modal-card-head, .modal-card-foot {
    background-color: white;
}
</style>

<div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Διαγραφή Ετικέτας</p>
      {#if !deleting}<button class="delete" aria-label="close" on:click={close}></button>{/if}
    </header>
    <section class="modal-card-body px-6">
    {#if successful===true}
    <!-- successful deletion of tag -->
    <p class="is-size-5">Επιτυχής διαγραφή</p>
    {:else if successful===false}
    <!-- error occured during deletion of tag -->
    <p class="is-size-5">Ανεπιτυχής διαγραφή</p>
    {:else if (successful===null)}
    <!-- tag hasn't been deleted yet:   -->
        {#if deleting}
        <!-- case 1: deletion underway, display progress bar  -->  
        <progress class="progress is-small is-primary" max="100">15%</progress>
        {:else}
        <!-- case 2: waiting for confirmation from user, show dialog  -->
        <p class="is-size-5">Είστε σίγουροι πως θέλετε να διαγράψετε την ετικέτα <b>{tagToDelete.tag.toUpperCase()}</b> από την κατηγορία <b>{taxonomy()};</b></p>
        
        {/if}
    {/if}
    </section>

<footer class="modal-card-foot is-grouped level">
    {#if (successful===null)}
    <!-- if we have no result from deletion operation, show those buttons (enabled or disabled) -->
      <button class="button level-item " on:click={close} disabled={deleting}>Ακύρωση</button>
      <button class="button has-text-white has-text-weight-semibold level-item box" 
            on:click={deleteTag} disabled={deleting} style="background-color:rgb(63,81,181);" >Διαγραφή</button>
    {:else}
    <!-- if we have a result from deletion operation, show only a 'close' button in footer -->
    <button class="button has-text-white has-text-weight-semibold level-item box" on:click={close} 
            style="background-color:rgb(63,81,181);" disabled={deleting}>Κλείσιμο</button>
    {/if}
    </footer>
</div>