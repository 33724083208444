<script>
  import { params } from "@sveltech/routify";
  import Formfield from "./Formfield.svelte";
  import { axiosInst } from "../credentials.js";
  import FilesLayout from "./FilesLayout.svelte";
  import {
    findErrors,
    yupReturnValidationObject,
    checkFiles,
  } from "../utils/helpers.js";
  import { onMount, createEventDispatcher } from "svelte";
  import Spinner from "svelte-spinner";
  import {
    dependencyChangesStore,
    dependencyListStore,
    dependencyConditionsStore,
    validationOngoing,
    formUsed,
    flattenedFormVars,
    mainValidationSchema,
    validationErrors,
    validationFileErrors,
    serviceFieldNodes,
    serviceVariables
  } from "../ui-store.js";
  export let inputsArray = [];
  export let filesArray = [];
  export let disabled; //should the fields in this layout be disabled?
  export let serviceuuid;
  export let onBehalfOfDTO = null;
  export let orgTukangaId;
  export let datasourcesReady = false;
  export let taxIdVerifiedService;
  export let formId;
  export let isNested = false;
  export let layoutId = ""; //a name for this layout (defaults to "inputs" for upper level fields and to variableId for group variables)

  // a "node" is an object that holds all inputs that belong to this layout.
  // we use it to store changes concerning requirement/visibility and dispatch them safely to upper level to be used in validation, etc
  export let layoutNode ={}; 
  $:console.log(layoutId, inputsArray, filesArray, $dependencyConditionsStore, $serviceVariables, $serviceFieldNodes);
  inputsArray.forEach(input => {console.log(input, layoutNode); layoutNode[input.mapToVariableName] = {};});

  const dispatch = createEventDispatcher();

  const checkDep = (event) => {
    let clearMode = false;
    let changeToValue = "";
    let changeInvisibilityTo = false;
    let changeRequirementTo = false;
    if (!event.detail.groupDependencies) {
      // changes depend on value change
      if (!event.detail.value) {
      // console.log("FormLayout says this var's value was cleared: ", event.detail.varId);
      clearMode = true;
      changeToValue = null;
      // return;
      }
      // save value as string
      else changeToValue = event.detail.value.toString().replace(/^"(.*)"$/, "$1");
    }
    else {
      // changes depend on visibility/requirement of a group variable
      if(event.type === "visibilityChanged"){
        changeInvisibilityTo = event.detail.invisible;
      }
      if(event.type === "requirementChanged"){
        changeRequirementTo = event.detail.requirement;
      }
    }
   

    if (
      $dependencyListStore[event.detail.varId] === undefined ||
      typeof $dependencyListStore[event.detail.varId] === undefined
    )
      return;

    //find dependecies that need to be affected
    let dp = $dependencyListStore[event.detail.varId].dependencies;
    // let details = { varId: null, changeToValue: null, type: null, changeInvisibilityTo: null, changeRequirementTo: null };
    let condition = null;
    let conditionFulfilled = false;
    if (!(event.type === "requirementChanged" || event.type === "visibilityChanged")) {
      for (let i = 0; i < dp.length; i++) {
        if (!$dependencyChangesStore[dp[i].variableId]) $dependencyChangesStore[dp[i].variableId] = [];
        let details = {}; 
        switch (dp[i].dependenceType) {
          case "VALUE_FROM_VALUE":
            if (clearMode)
              details = {
                varId: dp[i].variableId,
                changeToValue: "NULL",
                type: "VALUE_FROM_VALUE",
              };
            else
              details = {
                varId: dp[i].variableId,
                changeToValue: changeToValue,
                type: "VALUE_FROM_VALUE",
              };
            $dependencyChangesStore[dp[i].variableId].push(details);
            $dependencyChangesStore[dp[i].variableId] = $dependencyChangesStore[dp[i].variableId];
            break;
          case "DESCRIPTION_FROM_VISIBLE_VALUE":
            details = {
              varId: dp[i].variableId,
              changeToValue: event.detail.description ? event.detail.description : null,
              type: "DESCRIPTION_FROM_VISIBLE_VALUE",
            };
            $dependencyChangesStore[dp[i].variableId].push(details);
            $dependencyChangesStore[dp[i].variableId] = $dependencyChangesStore[dp[i].variableId];
            break;
          case "VALUE_FROM_VISIBLE_VALUE":
            details = {
              varId: dp[i].variableId,
              changeToValue: event.detail.title,
              type: "VALUE_FROM_VISIBLE_VALUE",
            };
            $dependencyChangesStore[dp[i].variableId].push(details);
            break;
          case "REQUIRED_IF_VALUE_EQUALS":
            // search conditions store
            condition = $dependencyConditionsStore[dp[i].variableId].conditions
                        .find( c => c.dependenceType==="REQUIREMENT_CONDITION" && c.variableId.includes(event.detail.varId));
            // if condition is fulfilled, add changes to dependency store
            conditionFulfilled = !condition.isEmpty 
                                ? condition.variableId.split("=")[1].trim().replace(/^"(.*)"$/, '$1').toString()===(changeToValue.trim().toString())
                                : false ;
            details = {
              varId: dp[i].variableId,
              changeToValue: conditionFulfilled,
              type: "REQUIRED_IF_VALUE_EQUALS",
            };
            console.log("REQUIRED_IF_VALUE_EQUALS: ", condition);
            $dependencyChangesStore[dp[i].variableId].push(details);
            $dependencyChangesStore[dp[i].variableId] = $dependencyChangesStore[dp[i].variableId];
            break;
          case "VISIBLE_IF_VALUE_EQUALS":
            // search conditions store
            condition = $dependencyConditionsStore[dp[i].variableId].conditions
                        .find( c => c.dependenceType==="VISIBILITY_CONDITION" && c.variableId.includes(event.detail.varId));
            // if condition is fulfilled, add changes to dependency store
            conditionFulfilled = !condition.isEmpty 
                                ? (condition.variableId.split("=")[1].trim().replace(/^"(.*)"$/, '$1').toString()===(changeToValue.trim().toString()))
                                : false ;
            details = {
              varId: dp[i].variableId,
              changeToValue: conditionFulfilled,
              type: "VISIBLE_IF_VALUE_EQUALS",
            };
            $dependencyChangesStore[dp[i].variableId].push(details);
            break;
          default:
            break;
        }
      }
    }
    else {
      let details = {}; 
      if (event.type === "requirementChanged") {
        for (let i = 0; i < dp.length; i++) {
          if (dp[i].dependenceType === "REQUIREMENT_FROM_LAYOUT" ) {
            details = {
              varId: dp[i].variableId,
              changeRequirementTo: changeRequirementTo,
              type: "REQUIREMENT_FROM_LAYOUT",
            };
            $dependencyChangesStore[dp[i].variableId].push(details);
            $dependencyChangesStore[dp[i].variableId] = $dependencyChangesStore[dp[i].variableId]
          }
        }
      }
      if (event.type === "visibilityChanged") {
        for (let i = 0; i < dp.length; i++) {
          if (dp[i].dependenceType === "VISIBILITY_FROM_LAYOUT" ) {
            details = {
              varId: dp[i].variableId,
              changeInvisibilityTo: changeInvisibilityTo,
              type: "VISIBILITY_FROM_LAYOUT",
            };
            $dependencyChangesStore[dp[i].variableId].push(details);
            $dependencyChangesStore[dp[i].variableId] = $dependencyChangesStore[dp[i].variableId]
          }
        }
      }
    }
  }

  $: forDatasources = {
    onBehalfOfDTO: onBehalfOfDTO,
    organizationId: orgTukangaId,
    processDefinitionId: serviceuuid,
  };

  //array of arrays: each individual array is a row, and the elements are field per column
  // row 1 = fieldsInRows[0] = [field1 (row 1, column 1), field2 {row 1, column 2}, ...]
  // row 2 = fieldsInRows[1] = [field1 (row 2, column 1), field2 {row 2, column 2}, ...]
  let fieldsInRows;

  let ready = false; //ready to display fields?

  const findFieldIndex = (identifier) => {
    // input fields come in an Array and rely on their indeces to be validated correctly.
    // we need to be able to get the inputsArray index even after they've been split according to their row in the layout
    // and the #each iterators are of no help

    //we'll use the mapVariable as a unique identifier

    let filter = inputsArray.filter(
      (el) => el.mapToVariableName === identifier
    );
    return inputsArray.indexOf(filter[0]);
  };

  const findGroupIndex = (identifier) => {
    //we'll use the mapVariable as a unique identifier

    let filter = groupWithNestedRefs.filter((el) => el.groupId === identifier);
    console.log(identifier, groupWithNestedRefs.indexOf(filter[0]));
    return groupWithNestedRefs.indexOf(filter[0]);
  };

  const prepareFields = (inputsArray) => {
    // 1. Split inputs accoording to their respective "row"
    console.log(inputsArray)
    // 2. Return fields in arrays, one for each row

    // the reducer function creates a new Array for each different "r" (row) attribute and pushes the elements in them according to row value
    // Each Array is then pushed to a mama Array ( [] ), which is returned as resulting, array-like object
    if (inputsArray.length > 0) {
      let result = inputsArray.reduce((r, o) => {
        Object.entries(o).forEach(([key, val]) => {
          if (key === "r") (r[val] = r[val] || []).push(o);
        });
        return r;
      }, []);
      //sort every row element according to its column
      result.forEach((row) => {
        row = row.sort((a, b) => parseFloat(a.c) - parseFloat(b.c));
      });
      return result;
    } else {
      console.log("EMPTY ARRAY");
      // ready = true;
      return [];
    }
  };

  // return all form variables
  const flattenFormStructure = (entries) => {
    let flattenedForm = entries
      .flatMap((entry) => [
        entry,
        ...flattenFormStructure(entry.nestedVars || []),
      ])
      .flat();

    return flattenedForm;
  };

  // get info about a service variable
  const getVariable = async (mappedToVariable) => {
    return new Promise(async (resolve, reject) => {
      if (!$formUsed) {
        //if no form is used, call open1ProcessDefinition
        try {
          const res = await $axiosInst.get(
            "open1process/" +
              $params.id +
              "/processdefs/" +
              serviceuuid +
              "/versions/any/variables/" +
              mappedToVariable
          );
          console.log(res.data);
          console.log(res.status);
          if (res.status === 200) {
            console.log("successful");
            console.log(res.data.data.valueSelectionDetails);
            resolve(res.data.data);
          } else {
            reject("something went wrong");
          }
        } catch (e) {
          console.log(e);
          reject(e);
        }
      }
      else {
        // else get your info from formVariables store
        let varInfo = $flattenedFormVars.filter(fvar => fvar.id === mappedToVariable)[0];
        resolve(varInfo);
      }
    });
  };

  const prepareFormFieldsAndReturnInfoAboutGroups = (allGroupVars, allFormFields) => {
    // allFormFields = all input fields
    // inputsWithoutNested = all upper-level inputs
    let groupWithNestedRefs = [];

    let allNested = [];
    allGroupVars.forEach((g) => {
      let nested_ids = g.nestedVars.map((n) => n.id);
      let refObject = {
        groupId: g.id,
        nestedInputs: allFormFields.filter(
          (input) => nested_ids.indexOf(input.mapToVariableName) > -1
        ),
      };
      allNested = allNested.concat(nested_ids);
      groupWithNestedRefs.push(refObject);
    });
    console.log(allGroupVars, allNested, groupWithNestedRefs);
    //remove nested variables from input positioning array
    let inputsWithoutNested = allFormFields.filter((input) => {
      if (allNested.indexOf(input.mapToVariableName) === -1) return input;
    });

    if (isNested) fieldsInRows = prepareFields(allFormFields);
    else {
      // if no form is used, render file inputs in FilesLayoutComponent
      if (!formId) inputsWithoutNested = inputsWithoutNested.filter(input => input.type!=="TYPE_FILE");
      fieldsInRows = prepareFields(inputsWithoutNested);
      // IMPORTANT: last act: if this is not a nested layout, set the variable used for inputs correctly
      // this is used for index based finding of formfield info
      inputsArray = inputsWithoutNested;
    }
    // return info about groups
    return groupWithNestedRefs;
  };

  const getFieldType = (var_type) => {
    if (var_type === "TYPE_DATASOURCE") return "datasource";
    else if (var_type === "TYPE_DOUBLE") return "double";
    else if (var_type === "TYPE_INTEGER") return "integer";
    else if (var_type === "TYPE_EMAIL") return "email";
    else if (var_type === "TYPE_VAT_NUMBER") return "integer";
    else if (var_type === "TYPE_DATE") return "date";
    else if (var_type === "TYPE_POINT") return "point";
    else if (var_type === "TYPE_LAYOUT") return "group";
    else if (var_type === "TYPE_FILE") return "file";
    else if (var_type === "TYPE_BOOLEAN") return "boolean";
    else return "text";
  };

  const getFormById = async (id) => {
    console.log("tomokangaGetFormById() called");

    //we need to use axios here
    // return new Promise(async (resolve, reject) => {
    try {
      const res = await $axiosInst.get(
        "/open1process/" + $params.id + "/forms/" + id + "/tomokanga"
      );
      if (res.status === 200) {
        console.log("my res successful call");
        if (res.data.data) return res.data.data;
        else
          throw (
            "Form with id: " +
            id +
            " does not exist in organization with entrance id: " +
            $params.id
          );
      } else {
        alert("something went wrong while fetching form with id: " + id);
        throw "error";
      }
    } catch (e) {
      console.log("tomokangaGetFormById() error", e);
      throw e;
    }
    // });
  };

  let groupWithNestedRefs = [];
  onMount(async () => {
    //if this is not a nested layout, all the formvariables come from inputs + files (add type in file inputs, so that we'll be able to differentiate)
    //in nested layouts, we pass all variables in inputsArray prompt
    let allFormFields = !isNested
      ? inputsArray.concat(filesArray.map((x) => ({ ...x, type: "TYPE_FILE" })))
      : inputsArray;

    //check if formId has a value
    if (formId) {
      // if form is used
      if (!isNested) {
        $formUsed = await getFormById(formId);
        //flatten formVariables to reveal all nested, so that we'll have them in store. (only do that at upper level, so not in nested)
        $flattenedFormVars = flattenFormStructure($formUsed.formVariableList);
        $serviceVariables = $flattenedFormVars;
      }
    }
    else {
      //if no form is used, a node has to be explicitly created (not just bound) for all the files that will be in their own layout. Otherwise, UI may crash when trying to read and render field info
      if (filesArray.length > 0) $serviceFieldNodes["files"] = {};
      if (!isNested) {
        // no form is used
        // for each input, get variable info and pass to store
        let promises = [];
        allFormFields.forEach(field => {
          promises.push(getVariable(field.mapToVariableName));
          promises = promises;
        });
        // IMPORTANT! WAIT for all promises to resolve in order to get the correct value to store
        await Promise.all(promises)
          .then((results) => {
            console.log(results)
            // use serviceVariables store to hold info about all inputs required from service
            $serviceVariables = results;
          })
          .catch((e) => {
            // handle errors here
          });
        }
    }

    // if we're in upper level, initialize all stores
    if (!isNested) {
      $serviceVariables.forEach(v => {
        // initialize dependencyConditions to be added to store
        $dependencyChangesStore[v.id] = [];
        $dependencyConditionsStore[v.id] = {id: v.id, conditions: [] };
      });
    }

    //reveal all groups from the input array we have. We'll need to prepare the nested layout in each one
    let allGroupVars = $serviceVariables.filter(
        (input) => input.type === "TYPE_LAYOUT"
      );
    //if yes, load form and concat fieldsInfo
    //1.Prepare fields
      groupWithNestedRefs =
        prepareFormFieldsAndReturnInfoAboutGroups(allGroupVars, allFormFields);

    //2. Create validation schema for this layout

    let inputsSchema = yupReturnValidationObject(layoutId);

    //3. append to schema in main page in a new node
    // (every group creates its own node and knows where to look for errors by using the layoutId property)
    $mainValidationSchema = $mainValidationSchema.concat(inputsSchema);
    //dispatch a new node to emailVerificationInfo object in main page, so that it will be validated correctly
    dispatch("addNodeToEmailVerificationInfo", {
      node: layoutId,
      inputs: inputsArray,
    });
    ready = true; //set this flag to true to display fields
  });
</script>

<div class="container">
  {#if ready}
    {#if fieldsInRows.length > 0 && !isNested}
      <hr />
      <!-- <div class="is-size-4">Λοιπά στοιχεία</div> -->
    {/if}
    {#each fieldsInRows as rowWithFields, rowIndex}
      <div class="layout-row">
      {#if rowWithFields}
        <div class="columns">
          {#each rowWithFields as field, columnIndex}
            {#if getFieldType(field.type) === "file"}
              <div class="{rowWithFields.length > 1 ? 'singlefield ' : ''} column">
                <Formfield
                varInfo={$serviceVariables.find(v => v.id === field.mapToVariableName)}
                bind:layoutNodeElement={layoutNode[field.mapToVariableName]}
                {serviceuuid}
                mappedToVariable={field.mapToVariableName}
                obligatory={field.obligatory}
                {disabled}
                bind:value={inputsArray[
                  findFieldIndex(field.mapToVariableName)
                ].file}
                type={getFieldType(field.type)}
                label={field.fileTitle}
                hasTemplate={field.templateFileURI != null}
                bind:description={field.fileDescription}
                bind:dependencyList={$dependencyListStore[field.mapToVariableName]}
                bind:dependencyChanges={$dependencyChangesStore[field.mapToVariableName]}
                templateFileURI={field.templateFileURI}
                squeezed={rowWithFields.length===3}
                on:deletefile={(e) => {
                  field.file = null;
                  field = field;
                  $validationOngoing = true;
                  //trigger validation in form
                  dispatch("validate");
                  }}
                  on:valueChanged={() => {
                    $validationOngoing = true;
                    //trigger validation in form
                    dispatch("validate");
                  }}
                  on:valueCleared={() => {
                    $validationOngoing = true;
                    //trigger validation in form
                    dispatch("validate");
                  }}
                  on:addNodeToEmailVerificationInfo
                  errors={$validationFileErrors &&
                  $validationFileErrors.length > 0
                    ? findErrors(
                        $validationFileErrors,
                        field.mapToVariableName.toString()
                      )
                    : []}
                />

              </div>
            {:else}
              <div class="{rowWithFields.length > 1 ? 'singlefield ' : ''} column">
                <Formfield
                  varInfo={$serviceVariables.find(v => v.id === field.mapToVariableName)}
                  bind:layoutNodeElement={layoutNode[field.mapToVariableName]}
                  bind:forDatasources
                  bind:datasourcesReady
                  bind:taxIdVerifiedService
                  obligatory={field.obligatory}
                  {disabled}
                  bind:value={inputsArray[
                    findFieldIndex(field.mapToVariableName)
                  ].inputvalue}
                  type={getFieldType(field.type)}
                  label={field.fieldName}
                  errors={$validationErrors && $validationErrors.inner
                    ? findErrors(
                        $validationErrors.inner,
                        layoutId +
                          "[" +
                          findFieldIndex(field.mapToVariableName) +
                          "].inputvalue"
                      )
                    : []}
                  {serviceuuid}
                  mappedToVariable={field.mapToVariableName}
                  nestedFields={findGroupIndex(field.mapToVariableName) > -1
                    ? groupWithNestedRefs[
                        findGroupIndex(field.mapToVariableName)
                      ].nestedInputs
                    : []}
                  bind:description={field.fieldDescription}
                  bind:dependencyList={$dependencyListStore[field.mapToVariableName]}
                  bind:dependencyChanges={$dependencyChangesStore[field.mapToVariableName]}
                  squeezed={rowWithFields.length===3}
                  on:valueChanged={checkDep}
                  on:valueCleared={checkDep}
                  on:requirementChanged={checkDep}
                  on:visibilityChanged={checkDep}
                  on:validate
                  on:addNodeToEmailVerificationInfo
                />
              </div>
            {/if}
          {/each}
        </div>
      {/if}
      </div>
    {/each}
    {#if !formId && filesArray.length > 0}
      <hr />
      <div class="subtitle">Επισυναπτόμενα αρχεία</div>
      <FilesLayout
        {serviceuuid}
        bind:filesArray={filesArray}
        bind:layoutNode={$serviceFieldNodes["files"]}
        on:validate
        bind:disabled
        />
    {/if}
  {:else}
    <div class="is-size-4-mobile is-size-3">
      <Spinner
        size="100"
        speed="750"
        color="rgba(0, 255, 0, 0.3)"
        thickness="5"
        gap="40"
      />
    </div>
  {/if}
</div>
<style>
  /* if column in row with other columns has mini-field, but not a group, make it narrow */
  :global(.singlefield:has(div .mini-field):not(:has(.form-group))) {
    flex: none;
    width: 33.3333%;
  }

  /* if a mini-field is included in a row with other fields and is not alone, increase width to anoid margins */
  :global(.singlefield > div .mini-field ) {
    min-width: 100% !important;
  }
</style>
