<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   viewBox="0 0 369.85749 317.1683"
   version="1.1"
   id="svg4"
   sodipodi:docname="client3.svg"
   inkscape:version="0.92.3 (2405546, 2018-03-11)"
   >
  <metadata
     id="metadata10">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs8">
    <linearGradient
       inkscape:collect="always"
       id="linearGradient4650">
      <stop
         style="stop-color:#3273dc;stop-opacity:1"
         offset="0"
         id="stop4646" />
      <stop
         id="stop962"
         offset="0.05468861"
         style="stop-color:#5b8fe3;stop-opacity:1;" />
      <stop
         style="stop-color:#6f9ce6;stop-opacity:1;"
         offset="0.09766154"
         id="stop951" />
      <stop
         style="stop-color:#ffffff;stop-opacity:1"
         offset="0.89104265"
         id="stop998" />
      <stop
         style="stop-color:#ffffff;stop-opacity:1"
         offset="1"
         id="stop4648" />
    </linearGradient>
    <linearGradient
       inkscape:collect="always"
       id="linearGradient4644">
      <stop
         style="stop-color:#ffffff;stop-opacity:1"
         offset="0"
         id="stop4640" />
      <stop
         id="stop949"
         offset="0.10048813"
         style="stop-color:#dee8f9;stop-opacity:1;" />
      <stop
         id="stop947"
         offset="0.42777991"
         style="stop-color:#a6c3ef;stop-opacity:1;" />
      <stop
         id="stop960"
         offset="0.56602317"
         style="stop-color:#a4c1ef;stop-opacity:1;" />
      <stop
         style="stop-color:#ffffff;stop-opacity:1"
         offset="0.91730535"
         id="stop996" />
      <stop
         style="stop-color:#ffffff;stop-opacity:1"
         offset="1"
         id="stop4642" />
    </linearGradient>
    <linearGradient
       id="linearGradient972"
       inkscape:collect="always">
      <stop
         id="stop968"
         offset="0"
         style="stop-color:#25549a;stop-opacity:1" />
      <stop
         id="stop970"
         offset="1"
         style="stop-color:#5cc1dc;stop-opacity:1" />
    </linearGradient>
    <linearGradient
       id="linearGradient959"
       inkscape:collect="always">
      <stop
         id="stop955"
         offset="0"
         style="stop-color:#5cc1dc;stop-opacity:1" />
      <stop
         id="stop957"
         offset="1"
         style="stop-color:#3298dc;stop-opacity:1" />
    </linearGradient>
    <linearGradient
       id="linearGradient913"
       inkscape:collect="always">
      <stop
         id="stop909"
         offset="0"
         style="stop-color:#2d65a4;stop-opacity:1" />
      <stop
         id="stop911"
         offset="1"
         style="stop-color:#3298dc;stop-opacity:1" />
    </linearGradient>
    <linearGradient
       inkscape:collect="always"
       id="linearGradient907">
      <stop
         style="stop-color:#25549a;stop-opacity:1"
         offset="0"
         id="stop903" />
      <stop
         style="stop-color:#be7ec6;stop-opacity:1"
         offset="1"
         id="stop905" />
    </linearGradient>
    <linearGradient
       inkscape:collect="always"
       id="linearGradient885">
      <stop
         style="stop-color:#d498dc;stop-opacity:1"
         offset="0"
         id="stop881" />
      <stop
         style="stop-color:#3298dc;stop-opacity:1"
         offset="1"
         id="stop883" />
    </linearGradient>
    <linearGradient
       id="linearGradient875"
       inkscape:collect="always">
      <stop
         id="stop871"
         offset="0"
         style="stop-color:#2c5aa0;stop-opacity:1" />
      <stop
         id="stop873"
         offset="1"
         style="stop-color:#d498dc;stop-opacity:1" />
    </linearGradient>
    <linearGradient
       inkscape:collect="always"
       id="linearGradient963">
      <stop
         style="stop-color:#d498dc;stop-opacity:1"
         offset="0"
         id="stop959" />
      <stop
         style="stop-color:#3298dc;stop-opacity:1"
         offset="1"
         id="stop961" />
    </linearGradient>
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1166"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1154"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1148"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1142"
       is_visible="true" />
    <linearGradient
       id="linearGradient1136"
       inkscape:collect="always">
      <stop
         id="stop1132"
         offset="0"
         style="stop-color:#3298dc;stop-opacity:1" />
      <stop
         id="stop1134"
         offset="1"
         style="stop-color:#61c6dc;stop-opacity:1" />
    </linearGradient>
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1130"
       effect="spiro" />
    <linearGradient
       inkscape:collect="always"
       id="linearGradient1104">
      <stop
         style="stop-color:#3298dc;stop-opacity:1"
         offset="0"
         id="stop1100" />
      <stop
         style="stop-color:#d498dc;stop-opacity:1"
         offset="1"
         id="stop1102" />
    </linearGradient>
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1069"
       is_visible="true" />
    <linearGradient
       inkscape:collect="always"
       id="linearGradient1065">
      <stop
         style="stop-color:#d498dc;stop-opacity:1"
         offset="0"
         id="stop1061" />
      <stop
         style="stop-color:#a398eb;stop-opacity:0.00569801"
         offset="1"
         id="stop1063" />
    </linearGradient>
    <linearGradient
       id="linearGradient1029"
       inkscape:collect="always">
      <stop
         id="stop1025"
         offset="0"
         style="stop-color:#3298dc;stop-opacity:1" />
      <stop
         id="stop1027"
         offset="1"
         style="stop-color:#7fff2a;stop-opacity:1" />
    </linearGradient>
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1021"
       is_visible="true" />
    <linearGradient
       id="linearGradient1015"
       inkscape:collect="always">
      <stop
         id="stop1011"
         offset="0"
         style="stop-color:#61c6dc;stop-opacity:1" />
      <stop
         id="stop1013"
         offset="1"
         style="stop-color:#3298dc;stop-opacity:1" />
    </linearGradient>
    <linearGradient
       id="linearGradient989"
       inkscape:collect="always">
      <stop
         id="stop985"
         offset="0"
         style="stop-color:#3298dc;stop-opacity:1" />
      <stop
         id="stop987"
         offset="1"
         style="stop-color:#3737c8;stop-opacity:1" />
    </linearGradient>
    <linearGradient
       id="linearGradient948"
       inkscape:collect="always">
      <stop
         id="stop944"
         offset="0"
         style="stop-color:#3298dc;stop-opacity:1" />
      <stop
         id="stop946"
         offset="1"
         style="stop-color:#a398eb;stop-opacity:0.00569801" />
    </linearGradient>
    <inkscape:path-effect
       is_visible="true"
       id="path-effect869"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect865"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect841"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect829"
       effect="spiro" />
    <linearGradient
       gradientTransform="matrix(0.87219289,-0.44734289,0.44734289,0.87219289,-44.331952,164.91619)"
       gradientUnits="userSpaceOnUse"
       y2="184.04294"
       x2="231.6837"
       y1="144.71564"
       x1="245.30571"
       id="linearGradient991"
       xlink:href="#linearGradient1015"
       inkscape:collect="always" />
    <linearGradient
       gradientTransform="matrix(1.0186734,0,0,0.99927336,5.9827705,82.428761)"
       gradientUnits="userSpaceOnUse"
       y2="167.87975"
       x2="19.300507"
       y1="174.57767"
       x1="167.362"
       id="linearGradient995"
       xlink:href="#linearGradient948"
       inkscape:collect="always" />
    <linearGradient
       y2="164.40991"
       x2="268.15594"
       y1="163.25696"
       x1="236.1445"
       gradientTransform="matrix(1.2433316,0.44892351,-0.44892351,1.2433316,-85.235356,-84.450369)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient999"
       xlink:href="#linearGradient989"
       inkscape:collect="always" />
    <linearGradient
       y2="167.87975"
       x2="19.300507"
       y1="170.29825"
       x1="169.9857"
       gradientTransform="matrix(0.95402005,-0.35712917,0.35032785,0.93585129,-47.256603,161.79704)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1023"
       xlink:href="#linearGradient948"
       inkscape:collect="always" />
    <linearGradient
       gradientTransform="matrix(2.1262694,1.1393148,-1.1393148,2.1262694,-181.50732,-472.20308)"
       gradientUnits="userSpaceOnUse"
       y2="209.21414"
       x2="244.34079"
       y1="209.21414"
       x1="219.49908"
       id="linearGradient1031"
       xlink:href="#linearGradient1029"
       inkscape:collect="always" />
    <linearGradient
       y2="167.87975"
       x2="19.300507"
       y1="174.57767"
       x1="167.362"
       gradientTransform="matrix(-0.68132386,0.40645795,-0.3987172,-0.66834845,253.37057,205.39503)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1059"
       xlink:href="#linearGradient1065"
       inkscape:collect="always" />
    <linearGradient
       y2="174.58286"
       x2="224.31294"
       y1="169.29005"
       x1="251.71364"
       gradientTransform="matrix(0.45732973,0.21635133,-0.21635133,0.45732973,95.544479,69.062386)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1073"
       xlink:href="#linearGradient1104"
       inkscape:collect="always" />
    <linearGradient
       y2="203.6306"
       x2="-13.766739"
       y1="187.34579"
       x1="94.568954"
       gradientTransform="matrix(0.39099665,-0.30960386,-0.30370764,-0.38355035,148.88147,307.94924)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1093"
       xlink:href="#linearGradient1065"
       inkscape:collect="always" />
    <linearGradient
       y2="256.55722"
       x2="-33.236736"
       y1="200.97632"
       x1="87.231384"
       gradientTransform="matrix(0.41013659,-0.28376206,-0.27835798,-0.40232578,146.19774,316.66068)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1114"
       xlink:href="#linearGradient1065"
       inkscape:collect="always" />
    <linearGradient
       gradientTransform="rotate(-30.611208,223.37933,-42.367881)"
       gradientUnits="userSpaceOnUse"
       y2="171.24969"
       x2="121.79265"
       y1="171.24969"
       x1="100.04106"
       id="linearGradient1138"
       xlink:href="#linearGradient1136"
       inkscape:collect="always" />
    <linearGradient
       y2="171.24969"
       x2="121.79265"
       y1="171.24969"
       x1="100.04106"
       gradientTransform="rotate(-30.611208,253.7783,-50.378625)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1150"
       xlink:href="#linearGradient1136"
       inkscape:collect="always" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient875"
       id="linearGradient869"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.76469024,0,0,0.76469024,67.761518,102.53741)"
       x1="280.67715"
       y1="66.378929"
       x2="274.96545"
       y2="388.91721" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient885"
       id="linearGradient879"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.48550831,0,0,0.564559,148.16913,169.21027)"
       x1="244.239"
       y1="169.63324"
       x2="250.48679"
       y2="528.90686" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient963"
       id="linearGradient887"
       gradientUnits="userSpaceOnUse"
       gradientTransform="translate(-1,15.94447)"
       x1="277.76273"
       y1="100.88136"
       x2="297.15256"
       y2="368.81357" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient913"
       id="linearGradient894"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.35342556,0,0,0.41097048,130.98394,163.10187)"
       x1="260.87192"
       y1="424.40442"
       x2="267.95471"
       y2="267.4191" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient907"
       id="linearGradient896"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.76469024,0,0,0.76469024,67.761518,118.53741)"
       x1="280.67715"
       y1="66.378929"
       x2="274.96545"
       y2="388.91721" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient963"
       id="linearGradient898"
       gradientUnits="userSpaceOnUse"
       gradientTransform="translate(-1,31.94447)"
       x1="194.2373"
       y1="131.25424"
       x2="418.64407"
       y2="381.83051" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient972"
       id="linearGradient920"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.76469024,0,0,0.76469024,67.761518,118.53741)"
       x1="280.67715"
       y1="66.378929"
       x2="274.96545"
       y2="388.91721" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient959"
       id="linearGradient922"
       gradientUnits="userSpaceOnUse"
       gradientTransform="translate(-1,31.94447)"
       x1="194.2373"
       y1="131.25424"
       x2="418.64407"
       y2="381.83051" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1166-3"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1154-6"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1148-7"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1142-5"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1130-6"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1069-1"
       is_visible="true" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1021-3"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect869-8"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect865-7"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect841-9"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect829-2"
       effect="spiro" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient4650"
       id="linearGradient979"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.68593748,0,0,0.72556375,35.530849,98.611267)"
       x1="289.04663"
       y1="53.485336"
       x2="288.54877"
       y2="482.49219" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient959"
       id="linearGradient981"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.89701352,0,0,0.89701352,-26.46314,39.3595)"
       x1="194.2373"
       y1="131.25424"
       x2="418.64407"
       y2="381.83051" />
    <inkscape:path-effect
       end_linecap_type="butt"
       miter_limit="4"
       linejoin_type="extrp_arc"
       start_linecap_type="butt"
       interpolator_beta="0.2"
       interpolator_type="CubicBezierFit"
       sort_points="true"
       offset_points="0.0082304527,0.95484028 | 0.24441158,0.23617596 | 0.483198,0.60646301"
       is_visible="true"
       id="path-effect958"
       effect="powerstroke" />
    <inkscape:path-effect
       end_linecap_type="butt"
       miter_limit="4"
       linejoin_type="extrp_arc"
       start_linecap_type="butt"
       interpolator_beta="0.2"
       interpolator_type="CubicBezierFit"
       sort_points="true"
       offset_points="1.883869,1.0550237 | 5.7653801,0.97307405 | 12.116262,2.2481934"
       is_visible="true"
       id="path-effect935"
       effect="powerstroke" />
    <inkscape:path-effect
       end_linecap_type="butt"
       miter_limit="4"
       linejoin_type="extrp_arc"
       start_linecap_type="butt"
       interpolator_beta="0.2"
       interpolator_type="CubicBezierFit"
       sort_points="true"
       offset_points="0.98612287,0.39279429 | 12.667972,0.8471553 | 20.442743,2.0782065"
       is_visible="true"
       id="path-effect923"
       effect="powerstroke" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect967"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect957"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect955"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1166-6"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1154-1"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1148-3"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1142-2"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1130-9"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1069-4"
       is_visible="true" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1021-5"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect869-4"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect865-2"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect841-96"
       effect="spiro" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect829-1"
       effect="spiro" />
    <linearGradient
       y2="165.15948"
       x2="41.336403"
       y1="174.57767"
       x1="167.362"
       gradientTransform="matrix(0.79341241,0.07500221,-0.05743378,0.60756434,41.567583,116.44045)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1079"
       xlink:href="#linearGradient948"
       inkscape:collect="always" />
    <linearGradient
       y2="294.43243"
       x2="21.173063"
       y1="200.97632"
       x1="87.231384"
       gradientTransform="matrix(0.2495875,-0.48779748,-0.47850767,-0.24483425,206.20409,324.38248)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient1242"
       xlink:href="#linearGradient1065"
       inkscape:collect="always" />
    <linearGradient
       y2="256.55722"
       x2="-33.236736"
       y1="200.97632"
       x1="87.231384"
       gradientTransform="matrix(0.3706217,-0.33372516,-0.32736957,-0.36356343,159.35425,315.85853)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient889"
       xlink:href="#linearGradient1065"
       inkscape:collect="always" />
    <linearGradient
       y2="359.52042"
       x2="40.243279"
       y1="200.97632"
       x1="87.231384"
       gradientTransform="matrix(0.30909739,0.23129197,-0.22688716,0.30321082,116.95385,101.30056)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient897"
       xlink:href="#linearGradient948"
       inkscape:collect="always" />
    <linearGradient
       y2="174.58286"
       x2="224.31294"
       y1="169.29005"
       x1="251.71364"
       gradientTransform="matrix(0.64180243,0.02329472,-0.02329472,0.64180243,33.126008,90.139097)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient949"
       xlink:href="#linearGradient885"
       inkscape:collect="always" />
    <linearGradient
       y2="173.09845"
       x2="58.831417"
       y1="174.57767"
       x1="167.362"
       gradientTransform="matrix(0.79341241,0.07500221,-0.05743378,0.60756434,41.567583,116.44045)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient933"
       xlink:href="#linearGradient948"
       inkscape:collect="always" />
    <linearGradient
       y2="282.6713"
       x2="35.568813"
       y1="200.97632"
       x1="87.231384"
       gradientTransform="matrix(0.2495875,-0.48779748,-0.47850767,-0.24483425,206.20409,324.38248)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient942"
       xlink:href="#linearGradient1065"
       inkscape:collect="always" />
    <linearGradient
       y2="184.88733"
       x2="70.368225"
       y1="174.57767"
       x1="167.362"
       gradientTransform="matrix(0.73412482,0.31014405,-0.23749625,0.56216421,53.612722,91.438964)"
       gradientUnits="userSpaceOnUse"
       id="linearGradient987"
       xlink:href="#linearGradient948"
       inkscape:collect="always" />
    <linearGradient
       inkscape:collect="always"
       xlink:href="#linearGradient4644"
       id="linearGradient971"
       gradientUnits="userSpaceOnUse"
       gradientTransform="matrix(0.89701352,0,0,0.89701352,-26.46314,39.3595)"
       x1="292.8649"
       y1="100.48242"
       x2="291.61179"
       y2="449.68628"
       spreadMethod="pad" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect957-9"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect955-1"
       is_visible="true" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect967-1"
       is_visible="true" />
    <inkscape:path-effect
       effect="powerstroke"
       id="path-effect935-1"
       is_visible="true"
       offset_points="1.883869,1.0550237 | 5.7653801,0.97307405 | 12.116262,2.2481934"
       sort_points="true"
       interpolator_type="CubicBezierFit"
       interpolator_beta="0.2"
       start_linecap_type="butt"
       linejoin_type="extrp_arc"
       miter_limit="4"
       end_linecap_type="butt" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect869-7"
       is_visible="true" />
    <inkscape:path-effect
       effect="powerstroke"
       id="path-effect923-7"
       is_visible="true"
       offset_points="0.98612287,0.39279429 | 12.364312,1.9537707 | 20.350735,2.724972"
       sort_points="true"
       interpolator_type="CubicBezierFit"
       interpolator_beta="0.2"
       start_linecap_type="butt"
       linejoin_type="extrp_arc"
       miter_limit="4"
       end_linecap_type="butt" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1154-3"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1166-65"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1130-2"
       is_visible="true" />
    <inkscape:path-effect
       is_visible="true"
       id="path-effect1142-3"
       effect="spiro" />
    <inkscape:path-effect
       effect="spiro"
       id="path-effect1148-0"
       is_visible="true" />
  </defs>
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="1920"
     inkscape:window-height="1030"
     id="namedview6"
     showgrid="false"
     inkscape:zoom="1.391244"
     inkscape:cx="-50.837368"
     inkscape:cy="178.80513"
     inkscape:window-x="0"
     inkscape:window-y="0"
     inkscape:window-maximized="1"
     inkscape:current-layer="g901"
     fit-margin-top="0"
     fit-margin-left="0"
     fit-margin-right="0"
     fit-margin-bottom="0" />
  <g
     id="g918"
     transform="translate(-47.845021,-113.288)">
    <g
       id="g901"
       transform="matrix(1.0155689,0,0,1.0155689,-3.6240314,-12.274777)">
      <path
         d="m 367.52457,384.48339 c -8.52883,-66.6276 0,-220.68338 0,-220.68338 -88.60886,8.63387 -178.29477,8.15371 -268.887502,0 0,0 -7.4114,147.5412 0,220.68338 1.768412,17.45225 10.550042,51.46053 10.550042,51.46053 h 65.90444 l -3.5388,-35.49813 0.70776,-114.38985 c 0.23802,-38.46866 23.74416,-70.5755 60.11257,-70.5755 36.36839,0 59.23833,29.27505 59.23833,67.74444 l -0.70776,120.05195 -0.70776,32.66709 h 54.5857 L 329.65711,399.291 c 0,0 40.23774,-0.79528 37.86754,-14.80761 0,-3e-5 -8e-5,0 -8e-5,0 z"
         id="path865"
         inkscape:connector-curvature="0"
         sodipodi:nodetypes="cccaccssscscccac"
         style="fill:url(#linearGradient979);fill-opacity:1;stroke-width:0.70547247" />
      <path
         style="fill:url(#linearGradient971);fill-opacity:1;stroke-width:0.89701349"
         sodipodi:nodetypes="ccacccsccccccccc"
         inkscape:connector-curvature="0"
         id="path863"
         d="m 384.79679,145.16619 c -115.8755,10.67406 -186.6625,9.37269 -305.132323,-0.70776 0,0 3.989729,165.17423 0,247.61663 -0.538296,11.12316 -3.226001,33.25243 -3.226001,33.25243 l 71.943534,2.12328 c 0.15207,-18.55456 0.10992,-107.76595 0,-158.96661 0,-47.55965 35.9351,-82.01445 83.49474,-82.01445 47.55967,0 82.09722,31.62376 82.09722,79.18341 0.003,57.83147 -2.83105,110.5668 -2.83105,162.50542 l 75.71769,1.41552 c 0,0 -7.69009,-195.50673 -2.06381,-284.40787 z m 25.11638,-21.52832 -353.571053,-0.70776 17.94027,46.64471 C 203.4959,181.75279 267.94493,184.03228 391.9729,170.28258 Z" />
    </g>
  </g>
</svg>
