import Cookies from "js-cookie";
import moment from "moment";
import yup from "yup";

export const CookiesApi = Cookies.withConverter({
  read: function (value, name) {
    return decodeURIComponent(value);
  },
  write: function (value, name) {
    return encodeURIComponent(value);
  },
});

// HELPFUL FUNCTIONS FOR GLOBAL USE
export const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
];

// export const MAX_SIZE = 6 * 1048576; //6MB max file upload size

export const base64EncodeUnicode = (str) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  let utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode("0x" + p1);
  });

  return btoa(utf8Bytes);
};

//Dynamically fetch appropriate api based on the url the user has
export let organization = "";
export let gsisAuthUrl;

export const removeAccents = (value) =>
  value
    .toLowerCase()
    .replace(/ά/g, "α")
    .replace(/έ/g, "ε")
    .replace(/ύ/g, "υ")
    .replace(/ί/g, "ι")
    .replace(/ϊ/g, "ι")
    .replace(/ΐ/g, "ι")
    .replace(/ή/g, "η")
    .replace(/ό/g, "ο")
    .replace(/ϋ/g, "υ")
    .replace(/ΰ/g, "υ")
    .replace(/ς/g, "σ")
    .replace(/ώ/g, "ω");

export const sizeOf = (bytes) => {
  if (bytes == 0) {
    return "0.00 B";
  }
  let e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B";
};

export const checkFiles = (files_array, errors_array) => {
  // console.log("FILES ERRORS", file_errors);
  let file_errors = [];
  console.log("FILES ERRORS", file_errors);
  //check uploaded files (the ones deemed obligatory) for presence and format and size
  //be sure to grab the "file" attribute of each object in the array
  files_array.forEach((file) => {
    // console.log(file);
    if (file.file === "" || file.file === null || file.isInvisible) {
      if (file.obligatory) {
        let str = "Παρακαλώ επιλέξτε αρχείο";
        file_errors.push({ path: file.mapToVariableName, message: str });
      }
    } else {
      let doublicates = files_array.filter(
        (f) => f.file && f.mapToVariableName && f.file[0].name == file.file[0].name && f.file[0].size == file.file[0].size
      );

      if (doublicates.length > 1) {
        let str = "Το ίδιο αρχείο δεν επιτρέπεται να υποβληθεί δύο φορές";
        file_errors.push({ path: file.mapToVariableName, message: str });
      } else if (file.file[0].size > (process.env.MAX_SIZE * 1048576)) {
        let str = "Το μέγεθος του αρχείου δεν επιτρέπεται να ξεπερνάει τα " + process.env.MAX_SIZE + "MB";
        file_errors.push({ path: file.mapToVariableName, message: str });
      } else if (!SUPPORTED_FORMATS.includes(file.file[0].type)) {
        let str = "Επιτρεπόμενοι τύποι αρχείου: doc, docx, pdf, jpeg, png";
        file_errors.push({ path: file.mapToVariableName, message: str });
      }
    }
  });
  // console.log("Errors from files: "+ JSON.stringify(file_errors) );
  console.log("FILES ERRORS", file_errors);
  return file_errors;
};

export const checkFilesTotalSize = (files_array, sizeLimitMB) => {

  let tempSize = 0;
  let sizeLimit = sizeLimitMB * 1048576;
  files_array.forEach((file) => {
    // if (!(file.file === "" || file.file === null)) console.log("checkFilesTotalSize: ", file);
    if (!(file.file === "" || file.file === null || file.isInvisible)) tempSize += file.file[0].size;
  });
  //return true if the file size has not reached the max limit
  return (tempSize < sizeLimit);

}

export const findErrors = (validation_errors, field_path) => {
  // console.log(field_path);
  // console.log(validation_errors);
  let filter = validation_errors.filter((error) => error.path === field_path);
  // console.log(filter);
  return filter;
}

//access values of nested object without knowing name
export const finder = (obj) => {
  // for each property in the object passed into
  // the function...
  for (var p in obj) {
    // if its value is another object
    if (Object.prototype.toString.call(obj[p]).slice(8, -1) === "Object") {
      // ...log the key to the console and then call finder()
      // again with this current object (this is the recursive part)
      console.log("key: " + p + ", value: Object");
      finder(obj[p]);

      // otherwise log the key/value
    } else {
      console.log("key: " + p + ", value: ", obj[p]);
      return obj[p];
    }
  }
};

export const sortTableRows = (rows, filter_name, type) => {};

export const alphabetically = (a, b) => {
  const alphabet = "αάβγδεέζηήθιίκλμνξοόπρστυύφχψωώ";

  a = a.toLowerCase().trim().split(" ")[0];
  b = b.toLowerCase().trim().split(" ")[0];
  // Find the first position were the strings do not match
  let position = 0;
  while (a[position] === b[position]) {
    // If both are the same don't swap
    if (!a[position] && !b[position]) return 0;
    // Otherwise the shorter one goes first
    if (!a[position]) return 1;
    if (!b[position]) return -1;
    position++;
  }
  // Then sort by the characters position
  return alphabet.indexOf(a[position]) - alphabet.indexOf(b[position]);
};

export const formatMimeType = (mimeType) => {
  console.log("mimeType", mimeType);

  if (mimeType) {
    let parts = mimeType.split("/");
    let tmp = mimeType;
    if (parts.length > 1) {
      tmp = parts[1];
    }

    if (tmp) {
      tmp = tmp.toUpperCase();
      console.log("mimeType is", tmp);
      switch (tmp) {
        case "PLAIN":
          tmp = "TXT";
          break;
        case "VND.OASIS.OPENDOCUMENT.SPREADSHEET":
          tmp = "ODS";
          break;
        case "VND.OASIS.OPENDOCUMENT.TEXT":
          tmp = "ODT";
          break;
        case "X-DBF":
          tmp = "DBF";
          break;
        case "VND.MS-EXCEL":
          tmp = "XLS";
          break;
        case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET":
          tmp = "XLSX";
          break;
        case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT":
          tmp = "DOCX";
          break;
        case "VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION":
          tmp = "PPTX";
          break;
        case "VND.MS-POWERPOINT":
          tmp = "PPT";
          break;
        case "X-TIKA-OOXML":
          tmp = "DOCX";
          break;
        case "MSWORD":
          tmp = "DOC";
          break;
        case "X-TIKA-MSOFFICE":
          tmp = "DOC";
          break;
        case "SVG+XML":
          tmp = "SVG";
          break;
        default:
          if (tmp.length > 4) {
            tmp = "...";
          }
          break;
      }
    }
    return tmp;
  } else return "";
};

//CODE SNIPPET FROM jsvat library: https://github.com/se-panfilov/jsvat
export const greekVATMultipliers = [256, 128, 64, 32, 16, 8, 4, 2];

export const checkIfGreekVATisValid = (vatnumber) => {
  //if input is not a number, return false
  if (isNaN(vatnumber)) return false;

  let total = 0;

  // eight character numbers should be prefixed with an 0.
  let newVat = vatnumber.length === 8 ? "0" + vatnumber : vatnumber;

  // Extract the next digit and multiply by the counter.
  for (let i = 0; i < 8; i++) {
    total += Number(newVat.charAt(i)) * greekVATMultipliers[i];
  }

  // Establish check digit.
  total = total % 11;
  total = total > 9 ? 0 : total;

  // Compare it with the last character of the VAT number. If it's the same, then it's valid.
  const expect = Number(newVat.slice(8, 9));
  return total.toString() === expect.toString();
};

export const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;
	    
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
};

export const convertDateClassic = (dateIN) => {
  if (!dateIN) { return null; }
  return moment(dateIN).format('DD/MM/YYYY');
}

export const convertDateForVariable = (dateIN) => {
  if (!dateIN) { return null; }
  let momentObj = moment(dateIN, 'YYYY-MM-DD');
  return momentObj.format('YYYY-MM-DD');;
}

export const findNestedObjectByKeyValue = (obj, key, keyValue) => {
  // Base case
  if (obj[key] === keyValue) {
    return obj;
  } else {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      let k = Object.keys(obj)[i]; // use this key for iteration, instead of index "i"

      // add "obj[k] &&" to ignore null values
      if (obj[k] && typeof obj[k] == 'object') {
        var found = findNestedObjectByKeyValue(obj[k], key, keyValue);
        if (found) {
          // If the object was found in the recursive call, bubble it up.
          return found;
        }
      }
    }
  }
}

export const isObject = (obj) => {
  return obj === Object(obj);
}

  //  Validation rules
  const emailSchema = yup.string().email();
  const dateSchema = yup.date();
  const numberSchema = yup.number();
  const textareaSchema = yup.string().max(280);
  const requiredSchema = yup.string().required();

  //edit 15/10: added the invalidCharsSchema, not sure it will be used for now
  //And now, children, it's storytime:
  //this is a regex built by a string, characters like \,[,],-,^,{,} need special handling, as they are used as symbols to build the expression itself
  //So, how do we import them into the String as proper characters? Whatever processes this regex will look for a single backslash as an escape character.
  //They need escaping, but simply escaping with a single backslash, like \* doesn't work in this case:
  //you have to escape the backslash as well, so that it will be read before the character, and, in turn, escape the character
  //that's the readon why you need two backslashes before each of these: \,[,],-,^,{,}
  //I felt like I owed an explanation to any poor soul who stumbles upon this.
  const invalidCharsSchema = yup
    .string()
    .matches(
      new RegExp("^[^*$@&!:.,#  *\\-\\^~+_=<>()*\\]\\[\\{\\}\\\\'\"/;`%]*$"),
      "Δεν επιτρέπονται κενά και ειδικοί χαρακτήρες"
    );

  export const yupReturnValidationObject = (schema_name) => {
    return yup.object().shape({
      [schema_name] : yup.array().of(
        yup.object().shape({
          inputvalue: yup
            .string()
            .test(
              "email-test",
              "Μη έγκυρη διεύθυνση email",
              async function (value) {
                if (this.parent.type.toString() === "TYPE_EMAIL") {
                  try {
                    await emailSchema.validate(value);
                  } catch (err) {
                    if (err.name === "ValidationError") {
                      //cover case where we need a type validation, but the field is not required (or invisible)
                      //in this case, an empty string should be allowed as a value
                      if (!(this.parent.obligatory || !this.parent.isInvisible)) {
                        if (value === "") return true;
                        return false;
                      }
                      return false;
                    }
                    console.log("Something went wrong");
                  }
                  return true;
                }
                return true;
              }
            )
            .test(
              "integer-test",
              "Παρακαλώ εισάγετε ακέραιο αριθμό",
              async function (value) {
                if (this.parent.type.toString() === "TYPE_INTEGER") {
                  //cover case where we need a type validation, but the field is not required
                  //in this case, an empty string should be allowed as a value
                  //use + sign in argument to convert string input to number
                  if (!(this.parent.obligatory || !this.parent.isInvisible)) {
                    if ((value === "") || !value) return true;
                    else return Number.isInteger(+value);
                  } 
                  else return Number.isInteger(+value);
                }
                return true;
              }
            )
            .test(
              "vat-test",
              "O ΑΦΜ πρέπει να αποτελείται από τουλάχιστον 8 ψηφία",
              async function (value) {
                if (this.parent.type.toString() === "TYPE_VAT_NUMBER") {
                  //cover case where we need a type validation, but the field is not required
                  //in this case, an empty string should be allowed as a value
                  if (!(this.parent.obligatory || !this.parent.isInvisible)) {
                    if (value == "") return true;
                    else return !(value.length < 8);
                  } else {
                    //if field obligatory
                    if (!value) return false;
                    else return !(value.length < 8);
                  }
                }
                return true;
              }
            )
            .test("vat-test2", "Μη έγκυρος ΑΦΜ", async function (value) {
              if (this.parent.type.toString() === "TYPE_VAT_NUMBER") {
                //cover case where we need a type validation, but the field is not required
                //in this case, an empty string should be allowed as a value
                if (!(this.parent.obligatory || !this.parent.isInvisible)) {
                  if (value == "") return true;
                  return checkIfGreekVATisValid(value);
                } else return checkIfGreekVATisValid(value);
              }
              return true;
            })
            .test(
              "vat-test3",
              "O ΑΦΜ δεν μπορεί να αποτελείται από περισσότερα από 9 ψηφία",
              async function (value) {
                if (this.parent.type.toString() === "TYPE_VAT_NUMBER") {
                  //cover case where we need a type validation, but the field is not required
                  //in this case, an empty string should be allowed as a value
                  if (!(this.parent.obligatory || !this.parent.isInvisible)) {
                    if (value == "") return true;
                    else return !(value.length > 9);
                  } else {
                    //if field obligatory
                    if (!value) return false;
                    else return !(value.length > 9);
                  }
                }
                return true;
              }
            )
            .test(
              "double-test",
              "Παρακαλώ εισάγετε ακέραιο ή δεκαδικό αριθμό",
              async function (value) {
                if (this.parent.type.toString() === "TYPE_DOUBLE") {
                  //cover case where we need a type validation, but the field is not required
                  //in this case, an empty string should be allowed as a value
                  //use + sign in argument to convert string input to number
                  if (!(this.parent.obligatory || !this.parent.isInvisible)) {
                    if (value == "") return true;
                    else {
                      if (Number.isInteger(+value)) return true;
                      else if (Number(+value) === +value && +value % 1 !== 0)
                        return true;
                      else return false;
                    }
                  } else {
                    if (Number.isInteger(+value)) return true;
                    else if (Number(+value) === +value && +value % 1 !== 0)
                      return true;
                    else return false;
                  }
                }
                return true;
              }
            )
            .test("date-test", "Μη έγκυρη ημερομηνία", async function (value) {
              if (this.parent.type.toString() === "TYPE_DATE") {
                try {
                  await dateSchema.validate(value, { abortEarly: false });
                } catch (err) {
                  if (err.name === "ValidationError") {
                    //cover case where we need a type validation, but the field is not required
                    //in this case, an empty string should be allowed as a value
                    if (!(this.parent.obligatory || !this.parent.isInvisible)) {
                      if (value == "") return true;
                      return false;
                    }
                    return false;
                  }
                }
                return true;
              }
              return true;
            })
            .test(
              "textarea-test",
              "Έχετε υπερβεί το όριο χρακτήρων",
              async function (value) {
                if (this.parent.type.toString() === "TYPE_STRING") {
                  try {
                    await textareaSchema.validate(value);
                  } catch (err) {
                    if (err.name === "ValidationError") {
                      //cover case where we need a type validation, but the field is not required
                      //in this case, an empty string should be allowed as a value
                      if (this.parent.obligatory && !this.parent.isInvisible ) {
                        if (!value || value == "") return false;
                        else return (value.length < 281);
                      } else if (!this.parent.obligatory && !this.parent.isInvisible) {
                        //if field not obligatory
                        if (!value || (value === "")) return true;
                        else return (value.length < 281);
                      }
                      else if (this.parent.isInvisible) {
                        return true;
                      }
                    }
                    console.log("Something went wrong");
                  }
                  return true;
                }
                return true;
              }
            )
            .test(
              "obligatory-test",
              "Αυτό το πεδίο είναι υποχρεωτικό",
              async function (value) {
                // access obligatory from passed context
                // field groups are not validated! File fields are validated elsewhere
                if (this.parent.obligatory 
                  && !this.parent.isInvisible 
                  && !((this.parent.type.toString() === "TYPE_FILE") || (this.parent.type.toString() === "TYPE_LAYOUT"))) {
                  try {
                    await requiredSchema.validate(value);
                  } catch (err) {
                    console.log(err);
                    if (err.name === "ValidationError") {
                      return false;
                    }
                    else console.log("Something went wrong");
                  }
                  return true;
                }
                else return true;
              }
            )
        })
      ),
    });
  }
