<script>
 import { selectedOrgDTO, presentationMode } from "../../../ui-store.js";
 import { organizations } from "../../../_clients.js";
 import { fade, blur } from "svelte/transition";
 import { isActive, url, goto, params, ready } from "@sveltech/routify";
 import Select from "svelte-select";

  //once we get the logoURL parameter form org data, we need to build the url so that img src gets the content
  let logo_content;
  $: if ($selectedOrgDTO) logo_content = $selectedOrgDTO.logoURL + "/content";

 let orgId = null;
 $:console.log("orgId ", orgId);

 const activatePresentationInOrg = () => {
    // $presentationMode = true;
    localStorage.setItem("presentationMode", true);
    $goto("../../:id/welcome", { id: orgId });
 }

 const closePresentation = () => {
    localStorage.removeItem("presentationMode");
    $goto("../../:id/welcome", { id: $params.id });
 }

 const handleSelect = (event) => {
    orgId = event.detail.id;
 }

 const handleClear = (event) => {
     orgId = null;
    }
</script>
<style>
    :global(.selectContainer input::placeholder) {
		font-size: 1rem !important;
	}
</style>

<div in:fade|local class="container mt-1 pt-2 app-container">
    <div class="column pt-3">
      <div>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <div>
                {#if $selectedOrgDTO.title}
                  <img class="logo" src={logo_content} alt="Logo Φορέα - {$selectedOrgDTO.title}" title={$selectedOrgDTO.title} />
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="mt-1">
        {#if $selectedOrgDTO.welcomeMessage}
          <div class="is-size-6 mb-2 px-5 pb-5">
            {@html $selectedOrgDTO.welcomeMessage}
          </div>
        {/if}
  
        <div class="container px-3 my-2">
            <div class="columns">
                <div in:fade={{ delay: 500 }} class="column is-three-fifths is-offset-one-fifth
                "> 
                    {#if !localStorage.getItem("presentationMode")}
                        <div class="field is-horizontal has-addons">
                            <!-- <div class="field-label is-normal is-narrow">
                                <label class="label">Οργανισμός:</label>
                            </div> -->
                                <div class="control is-expanded">
                                    <Select items={$organizations.filter((org)=> org.id!=$params.id)}
                                        on:select={handleSelect}
                                        on:clear={handleClear}
                                        optionIdentifier='id' 
                                        getOptionLabel = {option => option.title}
                                        getSelectionLabel = {option => option.title}
                                        placeholder="Επιλογή οργανισμού..."
                                        containerStyles="height: 2.5em !important;"
                                        >
                                    </Select>
                                </div>
                                <div class="control">
                                    <button disabled={!orgId} class="button is-link has-background-link-dark is-size-6 " on:click={activatePresentationInOrg}>  
                                        Έναρξη παρουσίασης
                                    </button>
                                </div>
                        </div> 
                    {:else}
                        <button class="button is-link has-background-link-dark is-size-5" on:click={closePresentation}>  
                            Λήξη παρουσίασης
                        </button>
                    {/if}                  
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
