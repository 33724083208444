import { writable} from 'svelte/store';

const createWritableStore = (key, startValue) => {
    const { subscribe, set } = writable(startValue);
    
    return {
      subscribe,
      set,
      useLocalStorage: () => {
        const json = localStorage.getItem(key);
        if (json) {
          set(JSON.parse(json));
        }
        
        subscribe(current => {
          localStorage.setItem(key, JSON.stringify(current));
        });
      }
    };
  }

//if we enter a custom client url, save the client as a super client
export const superClient = writable('');
export const presentationMode = writable(false);

export const clientGsisId = createWritableStore('clientGsisId', '');

export const clientTerms = writable('');
export const clientPrivacy = writable('');
export const clientA11Y = writable('');
export const GSISClientName = createWritableStore('GSISClientName', '');
export const loggedFromClient = createWritableStore('loggedFromClient', '');
export const loggedFromClientName = createWritableStore('loggedFromClientName', '');
export const cookieConsent = createWritableStore('cookieConsent', false);
export const clientFooterName = writable('');
export const highContrast = createWritableStore('highContrast', false);
//export const highContrast = writable(false);
export const fifteenMinuteMark = createWritableStore('fifteenMinuteMark', false);
export const fiveMinuteMark = createWritableStore('fiveMinuteMark', false);
export const logging_out = createWritableStore('logging_out', false);
export const showCategories = createWritableStore('showCategories', true);

export const isSystemUp = createWritableStore('isSystemUp', true);
export const isUserInGroup = writable(true);
export const currentUserGroup = writable ('');
export const userCheckedIfInGroup = writable(false);
export const validationOngoing = writable(false);

export const selectedOrgDTO = writable('');
export const selectedClient = writable('');
export const mainValidationSchema = writable({});
export const validationErrors = writable({});
export const validationFileErrors = writable([]);

export const loggedUserName = writable('');
export const searchWorker = writable();

// dependencyChangesStore (every change that needs to be implemented at runtime, constantly changing)
// dependencyListStore (what are the dependent variables of each variable and what type of dependency do they have)
// these are objects and each property of that object is noted by a variableId 
// and an array of either the changes that need to be implemented on that variable
// or the dependent variables of that variable
// dependencyChangesStore = { varid1: [change1,change2], varid2: [change1, change2]};
// dependencyListStore = {varid1: {id: varid1, dependencies: [dep1, dep2]}, varid2: {id: varid2, dependencies: [dep1, dep2]}}
// so the checks are performed on upper property level by comparing variableIds
export const dependencyChangesStore = writable({});
export const dependencyListStore = writable({});
export const dependencyConditionsStore = writable({});

// all input fields are bound here, in the same pattern 
export const serviceFieldNodes = writable({});

export const fileSizeLimitOK = writable(true);

// all variables used in service
export const serviceVariables = writable([]);

// form parameters that may be used in a service
export const formUsed = writable();
export const flattenedFormVars = writable([]);
