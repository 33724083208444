<script>
  import { url } from "@sveltech/routify";
  import { clients } from "../_clients.js";
  import AutoComplete from "simple-svelte-autocomplete";
  import Carousel from "@beyonk/svelte-carousel";
  import Fa from "svelte-fa";
  import {
    faSearch,
    faArrowRight,
    faEnvelope,
  } from "@fortawesome/free-solid-svg-icons";
  import { SearchIcon, ArrowRightIcon, MailIcon } from "svelte-feather-icons";

  //high  contrast flag for accessibility, set by layout component
  import { highContrast } from "../ui-store.js";

  //$:console.log($highContrast);
  
  import Accordion from '../Accordion.svelte'
</script>

<div class="container pt-4 app-container">
  <div class="mt-6 mb-3">
    <!-- <h1 class="is-size-4">
          Open1 |  Entrance
        </h1> -->
    <h2 class="is-size-3-desktop is-size-5-tablet is-size-6-mobile px-4">
      <strong>Οδηγίες Χρήσης</strong>
    </h2>
  </div>

  <!-- <div class="border-black border-solid border-4 text-5xl">Hello world</div> -->

  <!-- <div class="hero { $highContrast ? '' : 'has-background-info-light' } py-6 px-4 mt-6"> -->
  <div class="mt-6">
    <div class="is-size-5-desktop is-size-6-touch">
      <!-- <p>
        Το Open1 | Entrance είναι μια διαδικτυακή πύλη που φιλοξενεί ψηφιακές
        υπηρεσίες φορέων του Δημοσίου, που παρέχονται ηλεκτρονικά στους πολίτες.
      </p> -->
      <!-- <div class="columns is-centered my-4">
        <div class="column is-narrow">
          <div class="is-flex is-justify-content-center">
              <SearchIcon size="2x" />
            </div>
            <h2 class="mt-1 is-flex is-justify-content-center is-size-6">Επιλέξτε έναν φορέα</h2>
        </div>
        <div class="column is-narrow">
         <div class="is-flex is-justify-content-center">
              <ArrowRightIcon size="2x" />
            </div>
            <h2 class="mt-1 is-flex is-justify-content-center is-size-6">Στείλτε την αίτηση σας</h2>
        </div>
        <div class="column is-narrow">
          <div class="is-flex is-justify-content-center">
              <MailIcon size="2x" />
          </div>
          <h2 class="mt-1 is-flex is-justify-content-center is-size-6">Λάβετε την απάντηση με e-mail</h2>
        </div>
      </div>
        <br /> -->
      <div class="columns is-vcentered">
        <div class="column">
          <Accordion
            title="Πώς αναζητώ τον Φορέα στον οποίο θέλω να υποβάλω αίτηση;"
          >
            
            <p class="pb-4">
              Για να δείτε τους Φορείς που παρέχουν τις υπηρεσίες τους μέσω της
              πλατφόρμας, μπορείτε από την <a
                class="is-link bold"
                href={$url("/")}>αρχική σελίδα</a
              >
              να κάνετε κλικ στο κουμπί "Ξεκινήστε εδώ". Επίσης, μπορείτε να δείτε
              τη λίστα των διαθέσιμων Φορέων κάνοντας κλικ
              <a class="is-link bold" href={$url("/client")}>εδώ</a>. Για να
              μεταφερθείτε στη σελίδα του Φορέα που επιθυμείτε, αρκεί να κάνετε
              κλικ στο όνομα του.
            </p>
            <br />
            <!-- <div class="columns is-centered">
              <figure class="image-container">
                <img
                  src="../../../images/tutorial/t-1.gif"
                  alt="Αναζήτηση Φορέα"
                  title="Αναζήτηση Φορέα"
                />
              </figure>
            </div> -->
            </Accordion
          >

          <Accordion
            title="Πώς μπορώ να αναζητήσω την υπηρεσία που επιθυμώ και να ενημερωθώ για τα απαιτούμενα δικαιολογητικά της;"
          >
            
            <p class=" pb-4">
              Οι διαθέσιμες υπηρεσίες που προσφέρει ο κάθε Φορέας εμφανίζονται
              στη σελίδα του ανά κατηγορία. Επιλέγοντας μια κατηγορία, θα
              εμφανιστούν όλες οι σχετικές με αυτήν υπηρεσίες. Κάνοντας κλικ
              στην υπηρεσία που επιθυμείτε, θα εμφανιστεί ένα παράθυρο με την
              περιγραφή της, τα απαιτούμενα δικαιολογητικά, καθώς και ενημέρωση
              για το ποια είναι υποχρεωτικά/προαιρετικά και (εάν υπάρχουν)
              πρότυπα αρχεία, π.χ. Υπεύθυνες Δηλώσεις. Τις πληροφορίες αυτές
              μπορείτε επίσης να τις εκτυπώσετε απευθείας ή να τις αποθηκεύσετε
              στη συσκευή σας, πατώντας το κουμπί "Εκτύπωση".
            </p>
            <br />
            <!-- <div class="columns is-centered">
              <figure class="column image-container">
                <img
                  src="../../../images/tutorial/t-2.gif"
                  alt="Αναζήτηση Υπηρεσίας"
                  title="Αναζήτηση Υπηρεσίας"
                />
              </figure>
              <figure class="column image-container">
                <img
                  src="../../../images/tutorial/t-3.gif"
                  alt="Προβολή διακαιολογητικών"
                  title="Προβολή διακαιολογητικών"
                />
              </figure>
            </div> -->
            </Accordion
          >

          <Accordion title="Πώς ταυτοποιούμαι;">
            
            <p class=" pb-4">
              Για να μπορέσετε να ξεκινήσετε τη διαδικασία υποβολής μιας αίτησης
              στον Φορέα που επιθυμείτε, είναι απαραίτητο να προηγηθεί
              ταυτοποίηση με κωδικούς TaxisNet. Για να ανακατευθυνθείτε στην
              υπηρεσία ταυτοποίησης έχετε δύο επιλογές:
            </p>
            <div class=" pb-4">
              <li>
                Είτε να κάνετε κλικ στο κουμπί "Σύνδεση με λογαριασμό TaxisNet"
                που εμφανίζεται στην σελίδα του Φορέα που έχετε επιλέξει.
              </li>
              <li>
                Είτε να κάνετε κλικ στο κουμπί "Σύνδεση μέσω TaxisNet" που
                εμφανίζεται στο παράθυρο πληροφοριών της υπηρεσίας που θέλετε να
                εκκινήσετε
              </li>
            </div>

            <p class=" pb-4">
              Μετά από επιτυχημένη ταυτοποίηση, θα μεταφερθείτε και πάλι στη
              σελίδα του Φορέα, ή στη σελίδα υποβολής στοιχείων της υπηρεσίας
              που επιλέξατε, αντιστοίχως.
            </p>
            <br />
            <div class="columns is-centered">
              <!-- <div class=""> -->
              <!-- <figure class="column image-container">
                    <img
                      src="../../../images/tutorial/t-4.png" alt="Πώς ταυτοποιούμαι 1" title="Πώς ταυτοποιούμαι 1"
                      />
                    </figure>
                    <figure class="column image-container">
                    <img
                      src="../../../images/tutorial/t-5.png" alt="Πώς ταυτοποιούμαι 2" title="Πώς ταυτοποιούμαι 2"
                      />
                    </figure> -->
              <!-- </div> -->
            </div>
            </Accordion
          >

          <Accordion title="Πώς μπορώ να υποβάλω μια αίτηση;">
            
            <p class=" pb-4">H διαδικασία υποβολής χωρίζεται σε 3 βήματα:</p>
            <br />
            <div class="columns is-centered">
              <!-- <div class=""> -->
              <!-- <figure class="column image-container"> -->
              <img
                src="../../../images/tutorial/t-6.png"
                alt="Διαδικασία υποβολής"
                title="Διαδικασία υποβολής"
              />
              <!-- </figure> -->
            </div>
            <br />
            <div class="columns  is-centered">
              <div class="column">
                <li>
                  Αρχικά συμπληρώνετε τα στοιχεία επικοινωνίας σας (λόγω της
                  ταυτοποίησης μέσω TaxisNet το όνομα και το επώνυμο
                  συμπληρώνονται αυτομάτως) όπου συμπληρώνετε και μια διεύθυνση
                  ηλεκτρονικού ταχυδρομείου (e-mail) στην οποία θα λαμβάνετε
                  ενημερώσεις για την πορεία της αίτησής σας. Στο βήμα αυτό
                  επίσης υποβάλετε τα απαραίτητα αρχεία και συμπληρώνετε
                  τυχόν πεδία που απαιτούνται. Πληροφορίες που αφορούν την
                  υποχρεωτικότητα και την ύπαρξη πρότυπων αρχείων, είναι επίσης
                  διαθέσιμες σε αυτό το βήμα. Εάν συμπληρώσετε επιτυχώς όλα τα
                  απαραίτητα πεδία, πατώντας το κουμπί "Επόμενο", θα
                  μεταφερθείτε στο επόμενο βήμα.
                </li>
              </div>
              <!-- <figure class="column image-container pr-6">
                <img
                  src="../../../images/tutorial/t-7.gif"
                  alt="Διαδικασία υποβολής"
                  title="Διαδικασία υποβολής"
                />
              </figure> -->
            </div>
            <br />
            <div class="columns  is-centered">
              <div class="column">
                <li>
                  Στο δεύτερο βήμα, και αφού έχετε συμπληρώσει (τουλάχιστον) όλα
                  τα απαραίτητα υποχρεωτικά πεδία, σας δίνεται η δυνατότητα να
                  κάνετε μια επισκόπηση των πληροφοριών που έχετε υποβάλει, και
                  εάν επιθυμείτε, πατώντας το κουμπί "Διόρθωση" να μεταφερθείτε
                  στο προηγούμενο βήμα και να κάνετε τις διορθώσεις που
                  επιθυμείτε. Σε διαφορετική περίπτωση, πατώντας το κουμπί
                  "Επόμενο", μεταφέρεστε στο τρίτο και τελευταίο βήμα.
                </li>
              </div>
              <!-- <figure class="column image-container pr-6">
                <img
                  src="../../../images/tutorial/t-8.gif"
                  alt="Διαδικασία υποβολής"
                  title="Διαδικασία υποβολής"
                />
              </figure> -->
            </div>
            <br />
            <div class="columns   is-centered">
              <div class="column">
                <li>
                  Στο τελευταίο βήμα της "Ολοκλήρωσης" πραγματοποιείται η
                  οριστική υποβολή της αίτησής σας. Με την επιτυχή υποβολή,
                  εμφανίζεται μήνυμα που σας ενημερώνει για τον Αριθμό Υπόθεσης
                  που έλαβε η αίτηση.
                </li>
              </div>
              <!-- <figure class="column image-container pr-6">
                <img
                  src="../../../images/tutorial/t-9.gif"
                  alt="Διαδικασία υποβολής"
                  title="Διαδικασία υποβολής"
                />
              </figure> -->
            </div>
            </Accordion
          >

          <Accordion title="Πώς μπορώ να ενημερωθώ για την πορεία της αίτησης;">
            
            Υπάρχουν 2 διαθέσιμες οδοί:
            <br />
            <div class="columns mt-2 is-centered">
              <div class="column ">
                <li>
                  Θα λαμβάνετε ενημερώσεις στην διεύθυνση ηλεκτρονικού
                  ταχυδρομείου που καταχωρίσατε κατά την υποβολή της αίτησης. Ο
                  Φορέας ορίζει πότε και για ποια γεγονότα θα σας αποστέλλεται
                  μήνυμα ηλεκτρονικού ταχυδρομείου, π.χ. απόδοση αριθμού
                  πρωτοκόλλου, διεκπεραίωση της αίτησης.
                </li>
              </div>
              <!-- <figure class="column image-container pr-6">
                    <img
                      src="../../../images/tutorial/t-10.png" alt="Ενημέρωση για την πορεία της αίτησης 1" title="Ενημέρωση για την πορεία της αίτησης 1"
                      />
                    </figure>                   -->
            </div>
            <br />
            <div class="columns is-centered">
              <div class="column ">
                <li>
                  Εφόσον έχετε πραγματοποιήσει ταυτοποίηση μέσω κωδικών
                  TaxisNet, όπως περιγράφεται παραπάνω, έχετε τη δυνατότητα στο
                  επάνω δεξιά μέρος της οθόνης σας να επιλέξετε τον σύνδεσμο "Οι
                  Υποθέσεις μου", όπου και διατηρείται ένα μητρώο όλων των
                  αιτήσεων που έχετε εκκινήσει, με πληροφορίες όπως η ημερομηνία
                  υποβολής, ο αριθμός πρωτοκόλλου που έχει αποδοθεί, και η
                  κατάσταση της αίτησης. Επιλέγοντας μια εγγραφή του μητρώου,
                  μπορείτε δείτε τα αρχεία που έχετε υποβάλει, καθώς και
                  τυχόν απαντητικά αρχεία που προέκυψαν από την διεκπεραίωση
                  της αίτησής σας.
                </li>
              </div>
            </div>

            <br />
            <!-- <div class="columns is-centered ">
              <div class="column">
                <figure class="column image-container pr-6">
                  <img
                    src="../../../images/tutorial/t-11.gif"
                    alt="Ενημέρωση για την πορεία της αίτησης 2"
                    title="Ενημέρωση για την πορεία της αίτησης 2"
                  />
                </figure>
              </div>
              <div class="column">
                <figure class="column image-container pr-6">
                  <img
                    src="../../../images/tutorial/t-12.gif"
                    alt="Ενημέρωση για την πορεία της αίτησης 2"
                    title="Ενημέρωση για την πορεία της αίτησης 2"
                  />
                </figure>
              </div>
            </div> -->
            </Accordion
          >

          <Accordion
            title="Πώς μπορώ να διορθώσω στοιχεία της αίτησης μετά την υποβολή της;"
          >
            
            <p class=" pb-4">
              Εφόσον ο Φορέας παρέχει αυτήν τη δυνατότητα, μπορείτε να
              πραγματοποιήσετε διορθώσεις των αρχείων που έχουν ήδη υποβληθεί, ή
              να υποβάλετε επιπλέον αρχεία, εάν αυτό απαιτείται. Στην
              περίπτωση που θέλετε να κάνετε μια τέτοια διόρθωση, είτε από δική
              σας πρωτοβουλία, είτε ύστερα από ενημέρωση του Φορέα, μπορείτε να
              πραγματοποιήσετε <b>Επανυποβολή</b>:
            </p>
            <div class="columns is-centered">
              <div class="column ">
                <li>
                  Εφόσον έχετε πραγματοποιήσει ταυτοποίηση μέσω κωδικών
                  TaxisNet, όπως περιγράφεται παραπάνω, στο επάνω δεξιά μέρος
                  της οθόνης σας επιλεγετε τον σύνδεσμο "Οι Υποθέσεις μου".
                </li>
                <li>
                  Επιλέγετε από τις αιτήσεις του μητρώου αυτήν την οποία θέλετε
                  να διορθώσετε.
                </li>
                <li>
                  Στο αναδυόμενο παράθυρο, θα εμφανιστεί ένα κουμπί με τίτλο
                  "Επανυποβολή". Πατώντας το, έχετε τη
                  δυνατότητα να μεταφορτώσετε αρχεία που απαιτεί η συγκεκριμένη υπηρεσία. Επίσης,
                  μπορείτε να ανεβάσετε και οποιοδήποτε άλλο αρχείο, επιλέγοντας
                  το κουμπί "Ανεβάστε επιπλέον αρχείο". Στην περίπτωση αυτή σας
                  δίνεται η δυνατότητα να δώσετε και τίτλο στο αρχείο αυτό.
                  Μπορείτε να μεταφορτώσετε όσα επιπλέον αρχεία
                  επιθυμείτε.
                </li>
                <li>
                  Για να υποβάλετε τα καινούρια αρχεία, κάνετε κλικ στο
                  κουμπί "Επανυποβολή" που βρίσκεται στο κάτω
                  μέρος της φόρμας με τα αρχεία που έχετε μεταφορτώσει. Εάν
                  επιθυμείτε να ακυρώσετε τη διαδικασία, κάνετε κλικ στο κουμπί
                  "Ακύρωση Επανυποβολής" που βρίσκεται στο επάνω μέρος της
                  φόρμας, δίπλα από τον τίτλο "Επισυναπτόμενα αρχεία".
                </li>
              </div>
              <!-- <div class="column">
                <figure class="column image-container pr-6">
                  <img
                    src="../../../images/tutorial/t-13.gif"
                    alt="Επανυποβολή δικαιολογητικών"
                    title="Επανυποβολή δικαιολογητικών"
                  />
                </figure>
              </div> -->
            </div>
            </Accordion
          >
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  li {
    margin-top: 1rem;
  }
  .image-container {
    max-width: 60vw;
  }
</style>
