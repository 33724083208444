<script>
    // this page is used to access files for public use. 
    // each file (artifact) can be accessed via an Access Record
    // which has info about password protection, expiration date, etc
    import { url, params, goto } from "@sveltech/routify";
    import { onMount } from "svelte";
    import { axiosInst } from "../../../../../../../credentials.js";
    import { fade } from "svelte/transition";
    import moment from "moment";
    import { formatMimeType } from '../../.../../../../../../../utils/helpers.js';
    import { validateEmailVerificationToken, requestEmailValidation } from "../../../../../../../services/newUserRequestService.js";
    import Spinner from "svelte-spinner";
    import Fa from "svelte-fa";
    
    let pwdInputRef;

    let artifactLink = null;
    let artifactType = null;
    let error = false;

    let loading = true;
    let accessFound = false;
    let verifiedAccessFound = false;
    let accessObj = null;
    let verifiedAccessObj = null;
    let linkIsExpired = false;
    let linkIsInvalid = false;
    let isPasswordProtected = true;

    let verifyingEmail = false;
    let emailVerificationBody = {
        "email": null,
        "token": null,
      }
    let openVerificationModal = false;

    let requestedToken = false;
    let passphrase = null;
    let errorWithEmail = false;
    let errorWithToken = false;
    let errorWithPassphrase = false;

    let autofocus1 = true;

    const howManyMinutesFromNow = (date) => {
      let now = moment();
      let this_date = moment(date);

      let minutes = this_date.diff(now, "minutes");
      console.log(minutes);
      return minutes;
    }

    $:if (accessObj && accessObj.expiryDate) {
      if ( howManyMinutesFromNow(accessObj.expiryDate) < 0 ) linkIsExpired = true;
      else linkIsExpired = false;
    }

    $:if (accessObj) {
      isPasswordProtected = accessObj.passwordProtected;
      linkIsInvalid = !crossCheckAccessAndArtifactParams();
    }

    $:if ((accessObj && !accessObj.passwordProtected))
      isPasswordProtected = false;
      else isPasswordProtected = true;

    // if everything ok and validation is needed
    $:if (!linkIsInvalid && !linkIsExpired && (accessObj && accessObj.passwordProtected)) openVerificationModal = true;

    // if everything ok and validation is not needed
    $:if (!linkIsInvalid && !linkIsExpired && !isPasswordProtected) {}
      // getArtifactInfoAndCreateDownloadLink();

    $:if (verifiedAccessFound) {
      console.log("found verified");
      createDownloadLinkForArtifact(verifiedAccessObj.artifact);
    }

    const crossCheckAccessAndArtifactParams = () => {
      return accessObj.artifactId.toString() === $params.artifactId.toString();
    }

    const getAccessRecord = async () => {
      try {
        await $axiosInst
          .get("artifact/" + $params.id + "/access/" + $params.accessId).then(function (response) {
            console.log(response);
            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              accessFound = false;
              loading = false;
            } else {
              //If status OK, see if we have errors
              if (response.data.errors.length > 0) {
                console.log("Error" + ": " + response.data.errors);
                // add more actions...
                accessFound = false;
                loading = false;
              } else {
                //no errors, move to next and final step
                console.log("Success!");
                accessFound = true;
                loading = false;
                accessObj = response.data.data;
                return accessObj;
              }
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        alert(err);
      }

    };

    const getAccessRecordFull = async () => {
      try {
        await $axiosInst
          .post(
              "artifact/" + $params.id + "/access/" + $params.accessId + "/verified", passphrase.trim())
              .then(function (response) {
            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              errorWithPassphrase = false;
              verifiedAccessFound = false;
              loading = false;
              openVerificationModal = false;
              error = true;
              //reset passphrase
              passphrase = null;
            } else {
              //If status OK, see if we have errors
              if (response.data.errors.length > 0) {
                console.log("Error" + ": " + response.data.errors);
                // add more actions...
                if (response.data.errors.includes("Invalid token")) errorWithPassphrase = true;
                verifiedAccessFound = false;
                loading = false;
                openVerificationModal = true;
                error = true;
                //reset passphrase, focus input
                passphrase = null;
                pwdInputRef.focus();
              } else {
                //no errors, move to next and final step
                console.log("Success!");
                errorWithPassphrase = false;
                loading = false;
                verifiedAccessObj = response.data.data;
                openVerificationModal = false;
                verifiedAccessFound = true;
                error = false;
                //reset passphrase
                passphrase = null;
              }
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        alert(err);
      }

    };


    const createDownloadLinkForArtifact = async (artifact) => {
    //response needs to be an arraybuffer, in order to build the blob url
      try {
        const response = await $axiosInst.get(
          artifact.artifactURL.concat('/content'),
          { responseType: "arraybuffer" }
        );
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return false;
        } else {
          console.log(response);
          //create the download URL, set the type of file it's sending
          let mimeType = formatMimeType(response.headers["content-type"]);
          artifactLink = URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          //if mimetype is of certain type, add property to file (we need it for a download bug)
          if (mimeType === "DOC" || mimeType === "DOCX" || mimeType === "ODT")
            artifactType = mimeType.toLowerCase();

          return artifactLink;
        }
      } catch (err) {
        console.log("Fetch Error :-S", err);
        return false;
      }

  };

    onMount(async () => {
      await getAccessRecord();
      console.log(accessObj);
      // we have access object, and no password is required, get artifact immediately
      if (accessObj && !accessObj.passwordProtected) {
        console.log("onMount find verified")
        await getAccessRecordFull();
      }
    });
</script>

<div class="container app-container">
  <div class="columns is-vcentered">
    {#if linkIsInvalid}
      <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
        <p class="is-size-4">
          Δυστυχώς ο σύνδεσμος δεν είναι έγκυρος.
        </p>
      </div>
    {:else if linkIsExpired}
      <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
        <p class="is-size-4">
          Δυστυχώς ο σύνδεσμος έχει λήξει.
        </p>
      </div>
    {:else if verifiedAccessFound && artifactLink}
      <!-- everything ok -->
      <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
        <div class="is-flex is-flex-direction-row is-justify-content-center">
          <button 
            class="button is-size-5-desktop is-size-6
            {!errorWithEmail ? ' is-link has-background-link-dark' : ' is-primary is-light' }"
            on:click={() => {
              var a = document.createElement("a");
              a.href = artifactLink;
              //if result.type has been set, add extension to filename (for download  bug)
              //NO NEED TO ADD EXTENSION IN THIS CASE
              //the extension is already part of the filename, bc we take our data from the input
              a.setAttribute("download", verifiedAccessObj.artifact.alias);
              a.click();
            }}>
              Κατεβάστε το {verifiedAccessObj.artifact.alias}         
          </button>
        </div>        
      </div>
    <!-- if an error unrelataed to authentication occured -->
    {:else if !loading && error && !errorWithPassphrase}
      <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
        <p class="is-size-4">
          Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.
        </p>
      </div>
    {/if}

    <div class="hero mt-6 is-hidden-mobile"></div>
    <div class="hero mt-6 is-hidden-mobile"></div>
    <div class="hero mt-6 is-hidden-mobile"></div>
  </div>
</div>

<!-- MODALS -->

{#if openVerificationModal}
  <div class="modal is-active">
    <div in:fade|local class="modal-background" />
    <div class="modal-card">
      <section class="modal-card-body is-size-5-desktop is-size-6" style="border-radius: 10px;">
        <div class="container">
          <div class="mt-4 my-4 mx-3">
            <div class="my-3 mt-4">
              {#if errorWithPassphrase}
                <div class="notification is-warning is-light">
                  Παρουσιάστηκε πρόβλημα κατά την επικύρωση. Παρακαλώ προσπαθήστε
                  ξανά.
                </div>
              <!-- {:else if requestedToken}
                Παρακαλώ εισάγετε τον 6-ψήφιο κωδικό επιβεβαίωσης -->
              {:else}
                Παρακαλώ εισάγετε τον κωδικό πρόσβασης.
              {/if}
            </div>
            <div class="mx-1 my-1 columns is-vcentered">
              <!-- {#if requestedToken} -->
                <input
                autofocus={autofocus1}
                bind:this={pwdInputRef}
                class="input column is-half"
                type="password"
                label="Κωδικός επιβεβαίωσης"
                bind:value={passphrase}
                />
                <div class="column is-half">
                  <button class="button is-size-6-desktop is-size-7 is-link has-background-link-dark"
                    on:click={() => {
                      getAccessRecordFull();
                    }}>
                    Υποβολή κωδικού 
                  </button>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
{/if}

<style>
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: rgb(160, 160, 160) !important;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: rgb(160, 160, 160) !important;
    opacity:  1 !important;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: rgb(160, 160, 160) !important;
    opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(160, 160, 160) !important;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(160, 160, 160) !important;
  }
  ::placeholder { /* Most modern browsers support this now. */
    color: rgb(160, 160, 160) !important;
  }
</style>