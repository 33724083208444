import { addMessages, init } from "svelte-i18n";
import en from "./en.json";
import el from "./el.json";
addMessages("en", en);
addMessages("el", el);

export let initi18n = () => {

    init({
        fallbackLocale: "el",
        initialLocale: "el",
    });

}