<script>
import {axiosAuth, commons, ready} from '../../auth.js';
import {charts, calledSave} from '../../components/settings-store.js';
import Toggle from '@beyonk/svelte-toggle'
import Fa from 'svelte-fa'
import { faSearch, faCog, faBrush, faBook, faLock, faEnvelope, faTag, faUser, faFolder, faEnvelopeOpenText, faPaperPlane , faTrash, faEdit, faPlus} from '@fortawesome/free-solid-svg-icons';
import {onMount} from 'svelte';
import ColorPicker from '../../components/ColorPicker.svelte';
import Formfield from '../../components/Formfield.svelte';
import EditServices from '../../components/EditServiceLists.svelte';
import TagsComponent from '../../components/TagsComponent.svelte';
import ConfirmTagDelete from '../../modals/ConfirmTagDelete.svelte';
import CommunicationTemplates from '../../components/CommunicationTemplates.svelte';
import Spinner from 'svelte-spinner';
import { fade } from 'svelte/transition';
import base64 from 'base-64';
import { base64EncodeUnicode } from "../../utils/helpers.js";


$:console.log(base64EncodeUnicode);
let picked_color;
let allServices; //an array of all the services that the organization has in open1Process
let allTukangaServices; //an array of all the services that the organization has published in open1Process
let publishedToEntrace; //an array of all the services that the organization has published from open1Process to open1Entrance


//layout has called saveParams
$:console.log($calledSave);
$:if($calledSave===true) {
    console.log("called Save");
    saveChangesTukanga(); 
    $calledSave = false;
    }

let startIncoming = false;
let loading = true;
let openEditListModal = false;

let confirmTagDelete = false;
let tagToDelete;

const handleTagDeletion = (event) => {
    confirmTagDelete = true; 
    tagToDelete = event.detail.tag;

}

//everytime we select a color from the picker, update the preview
$:if(document.getElementById('preview-bg')&&orgParams.hexOrganizationBaseColor) changePreviewColor(orgParams.hexOrganizationBaseColor);
$:console.log('selected color: ', picked_color);

onMount(()=>{
    picked_color = orgParams.hexOrganizationBaseColor;
    changePreviewColor(picked_color);

});

let activeMenu = 3;
let activeTab = 1;
$:if($commons) $axiosAuth.defaults.headers['Open1OrgApiData'] = base64EncodeUnicode($commons.orgapi_data);
$:console.log("defaults:", $axiosAuth.defaults.headers);
let orgParams = {
    "archiveSystemManufacturer": null,
    "archiveSystemProxy": null,
    "archiveSystemProxyPort": 0,
    "archiveSystemUrl": null,
    "communicationChannelTemplates": {
        "additionalProp1": {
        "assignmentNotificationTemplateId": null,
        "closeToDelayedNotificationTemplateId": null,
        "overdueNotificationTemplateId": null
        },
        "additionalProp2": {
        "assignmentNotificationTemplateId": null,
        "closeToDelayedNotificationTemplateId": null,
        "overdueNotificationTemplateId": null
        },
        "additionalProp3": {
        "assignmentNotificationTemplateId": null,
        "closeToDelayedNotificationTemplateId": null,
        "overdueNotificationTemplateId": null
        }
    },
    "dmsPropertiesList": [
        // {
        // "dmsId": "string",
        // "dmsName": "string",
        // "properties": {
        //     "additionalProp1": {},
        //     "additionalProp2": {},
        //     "additionalProp3": {}
        // }
        // }
    ],
    "hexOrganizationBaseColor": null,
    "id": null,
    "logoURL": null,
    "organizationId": null,
    "pop3InboxFolderName": null,
    "pop3PeekDuration": {
        "days": 0,
        "hours": 0,
        "iso8601formattedString": null,
        "min": 0,
        "sec": 0
    },
    "pop3UserName": null,
    "pop3UserPwd": null,
    "pop3host": null,
    "pop3port": 0,
    "pop3selectors": [
        // {
        // "actions": [
        //     {
        //     "actionType": "_STORE_FILE"
        //     }
        // ],
        // "selectorName": "string",
        // "tests": [
        //     {
        //     "operandName": "_SUBJECT",
        //     "operatorName": "_CONTAINS",
        //     "testValue": "string"
        //     }
        // ]
        // }
    ],
    "publicAccessParams": {
        "debugMode": true,
        "emailTemplate": null,
        "gdprMessage": null,
        "gsisClientId": null,
        "gsisClientSecret": null,
        "gsisRedirectUrl": null,
        "publicTitle": null,
        "open1ProcessServiceEndPoint": process.env.OPEN1PROCESS_SERVICE_END_POINT,
        "serviceRegistrationAccessKey": null,
        "servicesProvided": [
        {
            "authenticationRequired": "NO_VERIFICATION",
            "issuerResourceId": null,
            "processDefinitionId": null
        }
        ],
        "termsMessage": null,
        "tomokangaUrl": null,
        "welcomeMessage": null
    },
    "smtpEnableDebugging": true,
    "smtpEnableTLS": false,
    "smtpHost": null,
    "smtpPort": 0,
    "smtpUserName": null,
    "smtpUserPwd": null
    };


$:console.log(orgParams);
$:console.log(allServices);
$:console.log(allTukangaServices);
$:console.log(publishedToEntrace);
$:console.log($charts);

const unpublishService = (event) => {
    //issue when clicking button with fontawesome icon as child:
    //event.target can change depending on where in the button you click. 
    //Using event.currentTarget you get results based on the element the eventlistener is attached to
     orgParams.publicAccessParams.servicesProvided = orgParams.publicAccessParams.servicesProvided.filter( el => !(el.processDefinitionId.valueOf()===event.currentTarget.value.valueOf()) );
     
    //save orgParams in tukanga
   saveChangesTukanga()
        .then(result => {
        console.log(result);
        return publishToEntrance(result)
          .then(res => {
            console.log(res);
          })
        })
     
     //the provided services have been updated, filter again to get them
    getOpen1EntranceServices();
}

const publishNewService = (event) => {
        //console.log(event.detail.publishedService);
        //add service to organization params
        orgParams.publicAccessParams.servicesProvided.push(event.detail.publishedService);
        orgParams.publicAccessParams.servicesProvided =  orgParams.publicAccessParams.servicesProvided;

        //save orgParams in tukanga and publish to entrance
        saveChangesTukanga()
        .then(result => {
        console.log(result);
        return publishToEntrance(result)
          .then(res => {
            console.log(res);
          })
        })


        //the provided services have been updated, filter again to get them
        getOpen1EntranceServices();
        //close modal
        openEditListModal = !openEditListModal;
    }
    
const getCharts = async () => {
     //we need to use axios here
   try {
         const res = await $axiosAuth.get('/api/orgchart/charts/'+$commons.orgUuid);
         console.log(res.data);
         console.log(res.status);
         if(res.status===200){
            $charts = res.data;
            console.log("successful call");
            
         }
         else alert('something went wrong');
         
         } catch (e) {
         console.log(e);
    }
}


const getOrgSettings = async () => {
    //we need to use axios here
   try {
         const res = await $axiosAuth.get('/api/'+$commons.orgId+'/params');
         console.log(res.data);
         console.log(res.status);
         if(res.status===200){
            orgParams = res.data.data;
            console.log("successful call");
            
         }
         else alert('something went wrong');
         
         } catch (e) {
         console.log(e);
    }
}

const getAllServices = async () => {
    //we need to use axios here
    return new Promise(async (resolve, reject) => {
    try {
            const res = await $axiosAuth.get('/api/'+$commons.orgId+'/processdefs?byCategory=false');
            console.log(res.data);
            console.log(res.status);
            if(res.status===200){
                allServices = res.data.data;
                console.log("successful call");
                resolve(res.data.data);
            }
            else alert('something went wrong');
            reject('something went wrong');
            
            } catch (e) {
            console.log(e);
            reject('something went wrong');
        }
    })
}

const saveChangesTukanga = async () => {
    // if(picked_color)orgParams.hexOrganizationBaseColor = picked_color;
    return new Promise(async (resolve, reject) => {
            $axiosAuth.post('/api/'+$commons.orgId+'/params', orgParams)
           .then( (response) => {
            if (response.status !== 201) { //if not created 
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                reject('something went wrong');
                return;
                }
                // Examine the text in the response
                else 
                {
                    console.log(response);
                    console.log(response.data);
                    resolve(response.data);
                }
                }
            )
            .catch( (err) => {
                console.log('Fetch Error :-S', err);
                reject('something went wrong', err);
            });

     // --------------------------
     })
}

const publishToEntrance = async (organizationParameters) => {
        console.log("params: ", organizationParameters);
         $axiosAuth.post('/api/tomokanga/'+$commons.orgId+'/publish', )
           .then( (response) => {
            if (response.status !== 200) { //if not processed 
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                //reject('something went wrong');
                return;
                }
                // Examine the text in the response
                else 
                {
                    console.log(response);
                    console.log(response.data);
                    //resolve(response.data);
                }
                }
            )
            .catch( (err) => {
                console.log('Fetch Error :-S', err);
                //reject('something went wrong', err);
            });
}

const filterPublishedServices = async (all_services) => {
    let filter = all_services.filter(service => service.lastPublishedVersionId != "");
    console.log(filter);
    return filter;
  }


const filterOpen1EntranceServices = (tukanga_services, tomokanga_definitions) => {
    //reduce tomokanga_definitions only to their ids
    let td_ids = tomokanga_definitions.map(td => td.processDefinitionId);
    console.log(td_ids);
    console.log(tukanga_services);
    let filter = tukanga_services.filter(service => {return td_ids.indexOf(service.id)>=0;} );
    console.log(filter);
    publishedToEntrace = filter;
    loading = false; //we can now show the tables
    return filter;
  }

const getTukangaPublishedServices = async () => {
    return new Promise(async (resolve, reject) => {
    getAllServices() //first get all services definitions
      .then(result => {
        console.log(result); //and filter them to get the published ones
        return filterPublishedServices(result)
          .then(res => {
            allTukangaServices = res;
            resolve(allTukangaServices);
          })
        })
    })

  }

const getOpen1EntranceServices = async () => {
    getTukangaPublishedServices()
    .then(result => {
        publishedToEntrace = filterOpen1EntranceServices(result, orgParams.publicAccessParams.servicesProvided);
    })
}

//if the provided services have been updated, filter again to get them
// $:if(orgParams.publicAccessParams.servicesProvided) getOpen1EntranceServices();
//TODO maybe we need a promise in getOrgSettings and put all functions in an init() mama-function
$:if($ready){
    getOrgSettings();
    getOpen1EntranceServices();
}

$:if($commons.orgUuid){
    getCharts();
}

const changePreviewColor = (picked_color) =>{
    console.log("changning colors...");
    const root = document.documentElement ;
    const el = document.getElementById('preview-bg');
    root.style.setProperty('--preview-bg-color', picked_color);
}


</script>

<style>


:root {
    --preview-bg-color: white;
}
   .thumbnail {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px blue;
    background-color: var(--preview-bg-color, white);
}

.thumbnail img {
    max-width: 80%;
    max-height: 80%;
    display: block;
    margin: 0 auto;
    /* border: solid 1px red; */
}

/* A button that looks like a link. Useful for JS-only actions. */

.button-link {
    
	font-weight: 400;
	background-color: transparent;
	border-color: transparent;
  
}

.button-link:hover {
	  color: hsl(217, 71%, 53%);
	  text-decoration: underline;
	  background-color: white;
	  border-color: hsl(217, 71%, 53%);
	}
  
.button-link:focus {
      color: hsl(217, 71%, 53%);
	  text-decoration: underline;
	  border-color: transparent;
	  box-shadow: none;
	}
  
 /* Remove all padding and height so it's just plain, unpadded text. */
.button-link > text {
	  padding: 0;
	  height: unset;
	}
ul.menu-list > li {
    margin: 1.5rem;
}

/* .modal {
    modal-content-width: 70%;
} */

</style>

<div class="container box pt-4 px-4 mt-6 ">
    <!-- <div class="panel"> -->
    <p class="panel-heading">
        Ρυθμίσεις Οργανισμού
    </p>
    <div class="columns is-gapless is-mobile mt-3 ">
    <!-- ---------------------------------SIDEMENU ---------------------------------->

        <div class="column is-narrow">

            <aside class="menu" style="width: 300px;">
                
                <ul class="menu-list is-size-5 is-size-6-mobile">
                    <li>
                        <a on:click={()=>{ activeMenu = 1;}} class="px-1 {activeMenu===1?'has-text-link':''}">
                            <div class="icon is-medium level"> <Fa class="level-item mx-1" icon={faFolder} aria-hidden="true"/><p class="level-item pl-1 pr-6">Συστήματα DMS</p></div>
                        </a>
                    </li>
                    <li>
                        <a on:click={()=>{ activeMenu = 2;}} class="px-1 {activeMenu===2?'has-text-link':''}">
                            <span class="icon is-medium level"> <Fa class="level-item mx-1" icon={faBook} aria-hidden="true"/><p class="level-item pl-1 pr-6">Πρωτόκολλο</p></span>
                        </a>
                    </li>
                    <li>
                        <a on:click={()=>{ activeMenu = 3;}} class="px-1 {activeMenu===3?'has-text-link':''}">
                            <span class="icon is-medium level"> <Fa class="level-item mx-1" icon={faEnvelope} aria-hidden="true"/><p class="level-item pl-1 pr-6">Μηνύματα</p></span>
                        </a>
                    </li>
                    <li>
                        <a on:click={()=>{ activeMenu = 4;}} class="px-1 {activeMenu===4?'has-text-link':''}">
                            <span class="icon is-medium level"> <Fa class="level-item mx-1" icon={faTag} aria-hidden="true"/><p class="level-item  pl-1 pr-6">Ετικέτες</p></span>
                        </a>
                    </li>
                    <li>
                        <a on:click={()=>{ activeMenu = 5;}} class="px-1 {activeMenu===5?'has-text-link':''}">
                            <span class="icon is-medium level"> <Fa class="level-item mx-1" icon={faBrush} aria-hidden="true"/><p class="level-item pl-1 pr-6">Εμφάνιση</p></span>
                        </a>
                    </li>
                    <li>
                        <a on:click={()=>{ activeMenu = 6;}} class="px-1 {activeMenu===6?'has-text-link':''}">
                            <span class="icon is-medium level"> <Fa class="level-item mx-1" icon={faUser} aria-hidden="true"/><p class="level-item pl-1 pr-6">Δημόσια Πρόσβαση</p></span>
                        </a>
                    </li>
                </ul>
                
            </aside>
   
        </div>
     <!-- --------------------------------- /SIDEMENU ---------------------------------->
    {#if !loading}
        <div class="column mx-3">
             <!--------------------------------- TABS -------------------------------------------------->
           
    <!-------------------------------------- / TABS ------------------------------->
            <div class="px-5 pt-3 pb-6 {activeMenu===5? 'box has-background-light':''}" >
                {#if activeMenu===1} 
                <!-- DMS SYSTEMS -->
                {#each orgParams.dmsPropertiesList as dms}
                    <h1 class="is-size-3 my-2">{dms.dmsName}</h1>
                    <Formfield bind:value={dms.properties.api_url} type="text" label="Διεύθυνση (URL):" />
                    <div class="columns is-2">
                        <div class="column is-8"><Formfield bind:value={dms.properties.proxy_host}  type="text" label="Διαμεσολαβητής (Proxy):" /></div>
                        <div class="column is-4"><Formfield bind:value={dms.properties.proxy_port}  type="text" label="Θύρα (Port):" /></div>
                    </div>
                    <Formfield bind:value={dms.properties.organization_id} type="text" label="Οργανισμός (id):" />
                    <div class="columns is-2">
                        <div class="column is-6"><Formfield bind:value={dms.properties.api_user} type="text" label="Χρήστης (API):" /></div>
                        <div class="column is-6"><Formfield bind:value={dms.properties.api_user_pwd} type="password" label="Κωδικός (API):" /></div>
                    </div>
                {/each}
                {:else if activeMenu===2} 
                <!-- PROTOCOL -->
                <Formfield bind:value={orgParams.archiveSystemManufacturer} type="text" label="Κατασκευαστής:" />
                <Formfield bind:value={orgParams.archiveSystemUrl} type="text" label="Διεύθυνση (URL):" />
                <div class="columns is-2">
                    <div class="column is-8"><Formfield bind:value={orgParams.archiveSystemProxy} type="text" label="Διαμεσολαβητής (Proxy):" /></div>
                    <div class="column is-4"><Formfield bind:value={orgParams.archiveSystemProxyPort} type="text" label="Θύρα (Port):" /></div>
                </div>
                {:else if activeMenu===3} 
                    <!-- MESSAGES -->
                    <div class="tabs is-fullwidth is-medium ">
                    <ul>
                        <li on:click={()=>{ activeTab = 1;}} class=" {activeTab===1? 'is-active ': ''}">
                        <a>
                            <span class="icon is-medium"> <Fa icon={faEnvelopeOpenText} aria-hidden="true"/></span>
                            <span>Εισερχόμενα</span>
                        </a>
                        </li>
                        <li on:click={()=>{ activeTab = 2;}} class=" {activeTab===2? 'is-active ': ''}">
                        <a>
                            <span class="icon is-medium"> <Fa icon={faPaperPlane} aria-hidden="true"/></span>
                            <span>Εξερχόμενα</span>
                        </a>
                        </li>
                    </ul>
                    </div>
                    {#if activeTab===1}
                        <h1 class="is-size-4">Γενικά</h1>
                        <div class="columns is-flex is-2 level">
                            <div class="column level-item is-half" ><Formfield bind:value={orgParams.pop3host} type="text" label="Διεύθυνση (pop3):" /></div>
                            <div class="column level-item is-half" ><Formfield bind:value={orgParams.pop3port} type="text" label="Port (pop3):" /></div>
                        </div>
                        <div class="columns is-flex is-2 level">
                            <div class="column level-item is-half" ><Formfield bind:value={orgParams.pop3UserName} type="text" label="Χρήστης (pop3):" /></div>
                            <div class="column level-item is-half" ><Formfield bind:value={orgParams.pop3UserPwd} type="password" label="Κωδικός (pop3):" /></div>
                        </div>
                        <div class="columns is-flex is-2 level">
                            <div class="column level-item is-half"><Formfield bind:value={orgParams.pop3InboxFolderName} type="text" label="Κουτί εισερχομένων (pop3):" /></div>
                            <div class="column level-item is-half has-text-weight-bold"> <div style="margin-top:3.5rem;"><Toggle bind:startIncoming onLabel="Διακοπή λειτουργίας" offLabel="Εκκίνηση λειτουργίας" /></div></div>
                        </div>
                            
                            <hr class="has-background-grey">
                        
                            <div class="columns is-flex is-gapless">
                                <div class="column is-half px-5">
                                    <h1 class="is-size-4 has-text-centered">Επαναληψιμότητα λειτουργίας</h1>
                                    <div class="columns">
                                        <div class="column is-half">
                                        
                                        <!--  -->
                                        <div class="field is-horizontal is-narrow">
                                            <div class="field-label is-normal"><label class="label mt-5">Έτη</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control"><input class="input" bind:value={orgParams.pop3PeekDuration.years}></div>
                                            </div>
                                        </div>
                                        </div>
                                        <!--  -->
                                        <div class="field is-horizontal is-narrow">
                                            <div class="field-label is-normal"><label class="label mt-5">Μήνες</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control"><input class="input" bind:value={orgParams.pop3PeekDuration.months}></div>
                                            </div>
                                        </div>
                                        </div>
                                        <!--  -->
                                        <div class="field is-horizontal is-narrow">
                                            <div class="field-label is-normal"><label class="label mt-5">Εβδομάδες</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control"><input class="input" bind:value={orgParams.pop3PeekDuration.weeks}></div>
                                            </div>
                                        </div>
                                        </div>
                                        <!--  -->
                                        <div class="field is-horizontal is-narrow">
                                            <div class="field-label is-normal"><label class="label mt-5">Ημέρες</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control"><input class="input" bind:value={orgParams.pop3PeekDuration.days}></div>
                                            </div>
                                        </div>
                                        </div>
                                        <!--  -->
                                        </div>
                                        <div class="column is-half">
                                        <div class="field is-horizontal is-narrow">
                                            <div class="field-label is-normal"><label class="label mt-5">Ώρες</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control"><input class="input" bind:value={orgParams.pop3PeekDuration.hours}></div>
                                            </div>
                                        </div>
                                        </div>
                                        <!--  -->
                                        <div class="field is-horizontal is-narrow">
                                            <div class="field-label is-normal"><label class="label mt-5">Λεπτά</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control"><input class="input" bind:value={orgParams.pop3PeekDuration.min}></div>
                                            </div>
                                        </div>
                                        </div>
                                        <!--  -->
                                        <div class="field is-horizontal is-narrow">
                                            <div class="field-label is-normal"><label class="label mt-5">Δεύτερα</label>
                                        </div>
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control"><input class="input" bind:value={orgParams.pop3PeekDuration.sec}></div>
                                            </div>
                                        </div>
                                        </div>
                                        <!--  -->
                                        
                                        </div>
                                    </div>

                                </div>
                                <div class="is-divider-vertical" data-content="|"></div>
                                <div class="column is-half">
                                    <h1 class="is-size-4 has-text-centered">Επιλογείς</h1>


                                            <div class=" mt-6"><button class=" button has-background-link-dark has-text-white has-text-weight-semibold"> <span class="icon is-small"> <Fa icon={faPlus} aria-hidden="true"/></span> &nbsp Νέος Επιλογέας</button></div>
                                            {#each orgParams.pop3selectors as selector, i}
                            
                                            <a class="panel-block is-active">
                                                
                                                <p class="is-size-5 has-text-link">{selector.selectorName}</p>&nbsp 
                                                <span class="icon is-medium has-text-link">
                                                <Fa icon={faEdit} aria-hidden="true"/>
                                                </span>
                                            </a>
                                            {/each}


                                </div>
                                
                            </div>
                            <hr class="has-background-grey">
                    {:else if activeTab===2}
                    <!-- <div style="overflow:hidden;"> -->
                        <div class="columns">
                                <div class="column is-6">
                                    <h1 class="is-size-4 has-text-centered">Ρυθμίσεις</h1>
                                    
                                    <Formfield bind:value={orgParams.smtpHost} type="text" label="Διεύθυνση (smtp):" />
                                    <Formfield bind:value={orgParams.smtpPort} type="text" label="Port (smtp):" />
                                    <Formfield bind:value={orgParams.smtpUserName} type="text" label="Χρήστης (smtp):" />
                                    <Formfield bind:value={orgParams.smtpUserPwd} type="password" label="Κωδικός (smtp):" />
                                    <label class="checkbox"><b>TLS (smtp):</b>&nbsp</label><input bind:checked={orgParams.smtpEnableTLS} type="checkbox">

                                </div>
                                <div class="is-divider-vertical pl-0" data-content="|"></div>
                                <div class="column is-6">
                                    <h1 class="is-size-4 has-text-centered">Πρότυπα</h1>

                                        <CommunicationTemplates orgTemplates={orgParams.communicationChannelTemplates} />
                                    
                                </div>
                                
                            </div>
                    <!-- </div> -->
                    {/if}

                {:else if activeMenu===4} 
                <div class="mt-5">
                    <!-- when the tag component dispatches a delete event, open confirmation modal -->
                    <TagsComponent on:tag-delete={handleTagDeletion}></TagsComponent>
                </div>
                {:else if activeMenu===5} 
                <!-- FOR THE COLORPICKER: if weird issue with destroying component inside #if reappears-> toggle visibility class instead -->
                <Formfield  bind:value={orgParams.publicAccessParams.publicTitle}  type="text" label="Επωνυμία Φορέα:" />
                <!-- <div class="mt-6">
                    <div class="field">
                        <div class="level-left">
                            <p class="level-item"><strong>Επωνυμία Φορέα:</strong></p>
                            <input class="input is-medium" type="text"/>
                        </div> 
                    </div>
                </div> -->
                <div class="columns is-2">
                    <div class="column">
                        <Formfield bind:value={orgParams.logoURL} label="URL Λογότυπου:" type="text" />
                    </div>
                    <div class="column is-narrow has-text-centered">
                        <div class="field">
                            <label class="label has-text-centered"><strong>Χρώμα Θέματος:</strong></label>
                            <div class="pt-1" id="pickr"><ColorPicker  bind:value={orgParams.hexOrganizationBaseColor}></ColorPicker></div>
                        </div>
                    </div>
                    <div class="column has-text-centered">
                        <div class="field ">
                            <label class="label"><strong>Προεπισκόπηση:</strong></label>
                            <figure id="preview-bg" class="thumbnail is-128x128" >
                                <img src={orgParams.logoURL}>
                            </figure>
                        </div>
                    </div>
                    
              </div>
                <div>
                    <Formfield class="mt-6" bind:value={orgParams.publicAccessParams.welcomeMessage} type="textarea" label="Μήνυμα καλωσορίσματος:" />
                </div>
                
                {:else if activeMenu===6}
                <div class="tabs is-fullwidth is-medium ">
                    <ul>
                        <li on:click={()=>{ activeTab = 1;}} class=" {activeTab===1? 'is-active ': ''}">
                        <a>
                            <span class="icon is-medium"> <Fa icon={faEnvelopeOpenText} aria-hidden="true"/></span>
                            <span>Διασύνδεση</span>
                        </a>
                        </li>
                        <li on:click={()=>{ activeTab = 2;}} class=" {activeTab===2? 'is-active ': ''}">
                        <a>
                            <span class="icon is-medium"> <Fa icon={faPaperPlane} aria-hidden="true"/></span>
                            <span>Παρεχόμενες Υπηρεσίες</span>
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div class="pt-2">
                    {#if activeTab===1}
                        <Formfield bind:value={orgParams.publicAccessParams.tomokangaUrl} type="text" label="Open1 |  Entrance (URL):" />
                        <hr class="has-background-grey">
                        <h1 class="is-size-4">Διασύνδεση με Γ.Γ.Π.Σ</h1>
                        <div class="columns is-flex is-2 level">
                            <div class="column level-item is-one-quarter" style="margin-top: auto;"><Formfield bind:value={orgParams.publicAccessParams.gsisClientId} type="text" label="Αναγνωριστικό (client ID):" /></div>
                            <div class="column level-item is-one-quarter" style="margin-top: auto;"><Formfield bind:value={orgParams.publicAccessParams.gsisClientSecret} type="password" label="Κωδικός (client secret):" /></div>
                            <div class="column level-item is-half" style="margin-top: auto;"><Formfield bind:value={orgParams.publicAccessParams.gsisRedirectUrl} type="text" label="URL ανακατεύθυνσης (redirect URL):" /></div>
                        </div>
                        <hr class="has-background-grey">
                        <Formfield bind:value={orgParams.publicAccessParams.termsMessage} type="textarea" label="Όροι Χρήσης:" />
                        <Formfield bind:value={orgParams.publicAccessParams.gdprMessage} type="textarea" label="Πολιτική Απορρήτου:" />
                        
                        {:else if activeTab===2}
                        <!-- LOAD ALL PROCESS THAT ARE PUBLISHED TO ENTRANCE -->
                        {#if loading}
                            <div class="has-text-centered">
                            <div class="is-size-4-mobile is-size-3 ">
                                <Spinner 
                                size="100"
                                speed="750"
                                color="rgba(0, 255, 0, 0.3)"
                                thickness="5"
                                gap="40"/>          
                            </div>
                            </div>   
                        {:else if publishedToEntrace.length>0}
                            <table class="table is-fullwidth is-narrow is-hoverable">
                                    <thead>
                                        <th>Όνομα Υπηρεσίας </th>
                                        <th class="has-text-centered"><abbr title="Τελευταία Δημοσιευμένη Έκδοση">Έκδοση</th>
                                        <th class="has-text-centered"><abbr title="Δημοσιευμένη στο Open1Entrance">Ενέργειες</th>
                                    </thead>
                                    <tbody>
                                        {#each publishedToEntrace as service (service.id)}
                                        <tr><td>{service.name}</td><td class="has-text-centered">{service.lastPublishedVersionId}</td>
                                        <td class="has-text-centered">
                                        <button class="button" value={service.id} on:click={unpublishService} >
                                            <span class="icon is-small"> <Fa icon={faTrash} aria-hidden="true"/></span>
                                        </button>
                                        </td></tr>
                                        {/each}
                                    </tbody>
                                </table>
                        {:else}
                           <div class="has-text-centered my-1 mx-1">Δεν υπάρχει καμία δημοσιευμένη διαδικασία στο Open1Entrance</div> 
                        {/if}
                        <div class="has-text-centered"><button class="button has-background-link-dark has-text-white has-text-weight-semibold" on:click={() => {openEditListModal = true;} }>Προσθήκη Νέας</button></div>
                       
                        {/if}
                    </div>

                {/if}
                       <!-- <div class="has-text-centered"><button class="my-2 button has-background-link-dark has-text-white has-text-weight-semibold" on:click={saveChangesTukanga }>Αποθήκευση Αλλαγών</button></div> -->
 
           
        </div>
    
    </div>
    {/if}
<!-- ============================================================================================================== -->
    
    
    </div>
    <!-- </div> -->
</div>

<!-- ============================================= MODALS ======================================================== -->
<!-- {#if openEditListModal&&!loading}
<div class="modal is-active" >
  <div in:fade|local class="modal-background"></div>
    <div class="modal-content has-background-white" style="width:75%; height:80%;">
        <div section>
        <EditServices sourceArray={allTukangaServices} targetArray={publishedToEntrace}/>
        </div>         
    </div>      
</div> 
{/if} -->
{#if openEditListModal&&!loading}
<div class="modal is-active">
  <div in:fade|local class="modal-background"></div>
   <EditServices sourceArray={allTukangaServices} on:cancel={()=>{openEditListModal = !openEditListModal;}} on:saveAndPublish={publishNewService}/>
</div>
{/if}
{#if confirmTagDelete&&!loading}
<div class="modal is-active">
  <div in:fade|local class="modal-background"></div>
   <ConfirmTagDelete {tagToDelete} on:close-modal={()=>{confirmTagDelete=false;}}></ConfirmTagDelete>
</div>
{/if}