import { writable, derived } from "svelte/store";
import "whatwg-fetch";
import axios from "axios";
import moment from "moment";
import { logging_out } from "./ui-store.js";
import { CookiesApi } from "./utils/helpers.js";
import { isActive, url, goto, params } from "@sveltech/routify";
// axiosAuth store. To be used by every component that makes http requests
export const axiosAuth = writable([]);
// unused export const defaults = writable([]);
export const profile = writable([]);
export const commons = writable([]);
export const cookie_exists = writable(false);
export const errorWithSSO = writable(false);
export const errorWithCustomInfo = writable(false);
export const errorWithCustomInfoAttributes = writable(false);

export const loggedIn = writable(false);

//Prop to determine whether the stores holding auth and user info have been set.
//This prop should not be set from outside, but binded instead, in order to read its value from the main app component.

export const ready = derived(
  [loggedIn, errorWithSSO, errorWithCustomInfo, profile],
  ([$loggedIn, $errorWithSSO, $errorWithCustomInfo, $profile]) =>
    $loggedIn && !$errorWithSSO && !$errorWithCustomInfo && $profile
);

const CLIENT_ID = process.env.CLIENT_ID;
const APPLICATION = process.env.APPLICATION;
const SSO_URL = process.env.SSO_URL;
const BASE_URL = process.env.BASE_URL;
const TUKANGA_BASE_URL = "https://tukanga-staging.open1.eu/tukanga/";

const CURRENT_URL = window.location;
const HOMEPAGE_URL = window.location.origin + "/";

// gets the cookie with 'name' and returns it as an object, else returns false
export const getCookie = (name) => {
  let result = CookiesApi.get(name);
  try {
    if (result) {
      return JSON.parse(result);
    } else return null;
  } catch {
    CookiesApi.remove("gsisAuth", { path: "/" });
    CookiesApi.remove("sessionCookie", { path: "/" });
    location.reload();
  }
};

export const login = () => {
  console.log("Redirecting to login page....");
  //redirect to sso login page, declaring your CLIENT_ID, APPLICATION and CURRENT_URL
  window.location.href =
    SSO_URL +
    "authorize?response_type=token&client_id=" +
    CLIENT_ID +
    "&application=" +
    APPLICATION +
    "&redirect_uri=" +
    CURRENT_URL;

  // if you prefer to always redirect to the home page, use: HOMEPAGE_URL instead of CURRENT_URL
};

export const logout = (clientId, anonymousServicId) => {
  console.log("log out...");
  let minutes = 0;

  // 0. get SSO cookie
  let gsisAuth = getCookie("gsisAuth");
  let sessionCookie = getCookie("sessionCookie");
  let logoutFromTaxis = true;
  //get info about taxis.
  if (gsisAuth && gsisAuth.expires) {
    let now = moment();
    minutes = moment(gsisAuth.expires).diff(now, "minutes");
    console.log("minutes remaining:", minutes);
    if (!minutes) {
      logoutFromTaxis = false;
    }
  }

  if (gsisAuth) {
    // 1. expire cookie
    // document.cookie = "gsisAuth= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    CookiesApi.remove("gsisAuth", { path: "/" });
  } else {
    console.log("gsisAuth cookie was not found");
  }
  if (sessionCookie) {
    // 1. expire cookie
    // document.cookie = "sessionCookie= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    CookiesApi.remove("sessionCookie", { path: "/" });
  } else {
    console.log("sessionCookie cookie was not found");
  }
  logging_out.set(false);
  //force reload
  //where are we logging out from?
  //1. user profile -> redirect there, controls will throw us out
  //2. application process -> redirect there, controls will throw us out, unless it's anonymous
  //3. org page -> redirect there
  //4. origin -> redirect there
  if (!clientId) location.reload();
  else if (anonymousServicId) window.location.href = '/client/'+ clientId + '/action/' + anonymousServicId + '/user/unregistered/new';
  else window.location.href = '/client/'+ clientId + '/welcome';

};

const checkAuthCookie = () => {
  //console.log("checking cookie...");

  let ssoAuth = getCookie("ssoAuth");
  if (!ssoAuth) {
    console.log("sso cookie not found");
    loggedIn.set(false);
    authenticate();
  } else {
    // console.log("cookie is OK", ssoAuth);
  }

  // TODO: also check if it expires soon or is expired already: ssoAuth.expires
};

export const authenticate = () => {
  console.log("Running authenticate...");
  //
  // if ('serviceWorker' in navigator) {
  //
  //           navigator.serviceWorker
  //               .register('service-worker.js', {scope: '/'})
  //               .then((registration) => {
  //                 console.log("Service Worker Registered", registration);
  //                 //alert('There is a service worker in the background');
  //               })
  //           .catch((err) => {
  //             console.log("SW Registration failed");
  //             //alert('There is no service worker in the background');
  //           });
  //
  // }

  // look for 'ssoAuth'
  let ssoAuth = getCookie("ssoAuth");
  if (ssoAuth) {
    console.log("Got the cookie!");

    // create axios instance with authorization headers
    // DONE: make this instance available in a store. To be used by every component that makes http requests
    const tmpaxiosauth = axios.create({
      baseURL: TUKANGA_BASE_URL,
      headers: {
        Authorization:
          "Bearer " +
          ssoAuth.accessToken +
          "&organization=" +
          ssoAuth.organization,
        // 'Accept': 'application/json, text/plain, */*',
        "Content-Type": "application/json",
      },
    });

    axiosAuth.set(tmpaxiosauth);
    // defaults.set(tmpaxiosauth.defaults);
    loggedIn.set(true);

    //periodically check if cookie is still there, otherwise assume logout from another sso app
    // if (!once) {
    //     window.setInterval(checkAuthCookie, 3000); // check every 3 seconds
    //     once = true;
    // }

    if (ssoAuth.organization && ssoAuth.accessToken) {
      console.log("Getting user info from sso....");
      // get user info from SSO
      axios
        .get(SSO_URL + "custom/userinfo", {
          headers: {
            Authorization: "Bearer " + ssoAuth.accessToken,
          },
          params: {
            organizationId: ssoAuth.organization,
            application: APPLICATION,
          },
        })
        .then(
          (result) => {
            console.log("from sso profile", result.data);
            let userProfile = result.data; //DONE: make the profile (or parts of the profile) available in a store

            //   $: if (userProfile != undefined) $profile = userProfile; //store will be updated when userProfile info is set.
            if (userProfile) {
              profile.set(userProfile);
            }

            let userName = "...";

            //userName setup
            if (
              userProfile &&
              userProfile.userInfo &&
              userProfile.userInfo.name
            ) {
              userName = userProfile.userInfo.name;
            } else {
              // TODO: handle problem with userProfile - Interceptor?
            }

            // custom info setup
            if (userProfile && userProfile.customInfo) {
              let customInfo = userProfile.customInfo;

              if (
                customInfo.openonesso_scope &&
                customInfo.openonesso_authority &&
                customInfo.orgId &&
                customInfo.orgUuid
              ) {
                //common attributes are found

                //   $: if (userProfile != undefined) $commons = common_attr; //store will be updated when userProfile info is set.
                commons.set({
                  openonesso_scope: customInfo.openonesso_scope,
                  openonesso_authority: customInfo.openonesso_authority,
                  orgId: customInfo.orgId,
                  orgUuid: customInfo.orgUuid,
                  orgapi_data: customInfo.orgapi_data,
                });
              } else {
                errorWithSSO.set(true);
                errorWithCustomInfoAttributes.set(true);
                logout();
              }
            } else {
              // TODO: handle individual problems of missing profile attributes - Interceptor?
              errorWithSSO.set(true);
              errorWithCustomInfo.set(true);
              logout();
            }
          },
          (error) => {
            console.log("error getting user profile", error);
            // TODO: handle this error - Interceptor?
            logout();
          }
        );
    }
  } else {
    console.log("Logging in for first time...");
    // console.log(window.location.href);
    //logged in first time logic
    if (window.location.href.includes("token")) {
      // console.log(window.location.href);
      console.log("Logging in for first time - token detected...");
      loggedIn.set(true);

      // extract info from the url...

      let tmpArray = window.location.href.split("#");

      // extract organization
      let organization = tmpArray[0].split("organization=")[1];

      // extract parameters
      let tmpArray2 = tmpArray[1].split("&");

      let parameters = [];

      for (let i = 0; i < tmpArray2.length; i++) {
        let param = tmpArray2[i].split("=");
        parameters[param[0]] = param[1];
      }

      // if everything seems OK: save SSO cookie and redirect to redirectUrl
      if (
        organization &&
        parameters.id_token &&
        parameters.access_token &&
        parameters.expires_in
      ) {
        // convert expires_in to GMT String
        let expires = new Date();
        expires.setSeconds(
          expires.getSeconds() + parseInt(parameters.expires_in)
        );
        // expires = expires.toGMTString();

        let ssoAuth = {
          accessToken: parameters.access_token,
          idToken: parameters.id_token,
          organization: organization,
          // expires: expires
        };

        // save cookie
        // ** With a path parameter, you can tell the browser what path the cookie belongs to. By default, the cookie belongs to the current page. **
        // ** we set the cookie to root path: '/'

        // document.cookie = encodeURIComponent("ssoAuth=" + JSON.stringify(ssoAuth) + "; path=/");
        CookiesApi.set("ssoAuth", JSON.stringify(ssoAuth), {
          expires: expires,
          path: "/",
        });

        // redirect
        let redirectUrl = window.location.href.split("?")[0]; //
        console.log("redirectURL: " + redirectUrl);
        window.location.href = redirectUrl;
      } else {
        errorWithSSO.set(true);
        // console.log("error with sso:", window.location.href);
      }
    } else {
      // enable the following line, if you wish to auto redirect the user to the login page, when they are not logged in
      login();
    }
  }
};
