import { writable, derived } from 'svelte/store';
import { getCookie } from './auth.js';
import 'whatwg-fetch';
import axios from "axios";
// axiosAuth store. To be used by every component that makes http requests
export const axiosAuth = writable([]);
export const profile = writable([]);
export const axiosInst = writable([]);
export const gsisLogin = writable();
export const loggedIn = writable(false);
export const loggedTaxis = writable(false);
export const userId = writable();

export const ready = derived([loggedIn, profile], ([$loggedIn, $profile]) => ($loggedIn && $profile));

// const createWritableStore = (key, startValue) => {
//     const { subscribe, set } = writable(startValue);
    
//     return {
//       subscribe,
//       set,
//       useLocalStorage: () => {
//         const json = localStorage.getItem(key);
//         if (json) {
//           set(JSON.parse(json));
//         }
        
//         subscribe(current => {
//           localStorage.setItem(key, JSON.stringify(current));
//         });
//       }
//     };
//   }

const CLIENT_ID = process.env.CLIENT_ID;
const APPLICATION = process.env.APPLICATION;
const SSO_URL = process.env.SSO_URL;
const BASE_URL = process.env.BASE_URL;

const CURRENT_URL = window.location;
const HOMEPAGE_URL = window.location.origin + "/";

// testing:
const GSIS_AUTH_URL = process.env.GSIS_AUTH_URL;
// production: 
//const GSIS_AUTH_URL = 'https://www1.gsis.gr/oauth2server/oauth/authorize''

export const prepareGsisLoginUrl = (org, state) => {
    //idea 1: pass organization as an argument in order to get redirectUrl and id/secret
    //idea 2: keep organization data in a cookie
    //let org = $cookies.getObject('organization');
    
    let requrl = GSIS_AUTH_URL; 
    let reqscope = "read";
    let reqresponsetype = "code";
    let reqapproval = "auto";
    let reqredirect = org.gsisRedirectUrl;
    let reqclientid = org.gsisClientId;
    let reqState = state
    
    return ( requrl + 
        "?scope=" + reqscope + 
        "&response_type=" + reqresponsetype +
        "&approval_prompt=" + reqapproval + 
        "&redirect_uri=" + reqredirect + 
        "&client_id=" + reqclientid +
        "&state=" + reqState );
}

// create axios instance with headers
// instance available in a store. To be used by every component that makes http requests
    const tmpaxiosauth = axios.create({
            baseURL: BASE_URL,
            headers: {
                Authorization:
                    "Application " +
                    "Tukanga" +
                    "&organization=" +
                    3,
                // 'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json"
             },
              transformRequest: [function (data, headers) {
                // You may modify the headers object here
                if(process.env.BASE_URL_FRONT.indexOf('https://') === -1){
                    //running frontend locally
                    headers['SessionID'] = `local-test`;
                }
                else if (getCookie("sessionCookie")) {
                    headers['SessionID'] = getCookie("sessionCookie").id;
                }
                else {
                    //not developing locally, session info not available yet
                    headers['SessionID'] = 'not set';
                }
            
                // Do not change data
                // let formData = new FormData();
                // Object.keys(data).forEach(attr => {
                //     formData.append(attr, data[attr]);
                // });
                return JSON.stringify(data);
                //return data;
              }],

        });

        axiosAuth.set(tmpaxiosauth);
        // defaults.set(tmpaxiosauth.defaults);

const instance = axios.create({
    baseURL: BASE_URL
    });
    
axiosInst.set(tmpaxiosauth);