<script>
  import { url, goto } from "@sveltech/routify";
  import { onMount, setContext } from "svelte";
  import {
    organizations,
    clients,
    actions,
    categories,
  } from "../../_clients.js";
  import { axiosInst } from "../../credentials.js";
  import { highContrast, clientFooterName } from "../../ui-store.js";
  import AutoComplete from "simple-svelte-autocomplete";
  import A11Yautocomplete from "../../components/A11Y-autocomplete.svelte";
  import {
    alphabetically,
    removeAccents,
    organization,
  } from "../../utils/helpers.js";
  // import Fa from 'svelte-fa'
  import { faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
  // import Select from 'svelte-select';

  $: console.log("client footer name: ", $clientFooterName);
  let dropdownActive = false;
  let selectedClient = null;
  let selectedCategory = null;
  let action_types = [];
  // $: console.log(action_types);

  let activeDropdown = false;

  const getActionTypes = (action_id) => {
    let filter = $actions.filter((p) => p.id === action_id);
    console.log(filter);
    let name = "not found";

    if (filter.length) {
      action_types = filter[0].type;
    }

    return action_types;
  };

  function findClientGSISRedirect(id) {
    return new Promise(async (resolve, reject) => {
      $axiosInst
        .get("registeredOrganization/" + id)
        .then(function (response) {
          console.log("API call response: ", response);
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            reject();
          } else {
            //If status OK, see if we have errors
            if (response.data.errors.length > 0) {
              console.log("Unathorized User" + ": " + data.errors);
              // add more actions...
              reject();
            } else {
              //no errors, move to next and final step
              let gsisRedirectUrl = response.data.data.gsisRedirectUrl;
              resolve(gsisRedirectUrl);
            }
          }
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
          reject(err);
        });
      //end api call, continue
    });
  }

  function go(selectedClientId) {
    //get information about this org (we need the redirect url, to see if a custom domain has been set)
    findClientGSISRedirect(selectedClientId).then((redirect_url) => {
      //custom urls have a pattern of (clientname)-(base_url_front)/gsis/(clientname)
      //Remove "https://" and split url with "-", if first element exists and is not "entrance", it's the client name and we have a custom url
      if (
        redirect_url &&
        !redirect_url.split("//")[1].split("-")[0].includes("entrance")
      )
        window.location.href =
          redirect_url.split("/gsis")[0] +
          "/client/" +
          selectedClientId +
          "/welcome";
      else $goto("/client/:id/welcome", { id: selectedClientId });
    });
  }

  function findProcessName(id) {
    let filter = $actions.filter((p) => p.id === id);
    console.log(filter);
    let name = "not found";

    if (filter.length) {
      name = filter[0].name;
    }

    return name;
  }

  let clientsByCategory = [];

  const findClientsByCategory = (id) => {
    console.log(id);
    let result = null;
    selectedClient = null;

    clientsByCategory = $clients.filter((client) =>
      client.categories.includes(id)
    );
    result = clientsByCategory;
    console.log(result);

    return result;
  };

  //GET ORGANIZATIONS FROM DATABSE AND TO STORE ------------------------------
  //

  let isLoading = true;
  let error;

  const loadOrgs = async () => {
    $axiosInst
      .get(process.env.BASE_URL + "/registeredOrganization/")
      .then(function (response) {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        else {
          $organizations = response.data.data.sort(function (a, b) {
            return alphabetically(a.title, b.title);
          });
        }

        if ($organizations && $organizations.length == 1) {
          $goto("/client/:id/welcome", { id: $organizations[0].id });
        }

        isLoading = false;
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };

  onMount(() => {
    loadOrgs();
  });
</script>

{#if false}
  <div class="hero {$highContrast ? '' : 'has-background-info-light'} pb-6">
    <div class="column is-full">
      <div class="columns mt-6">
        <div class="column container has-text-left mb-6">
          <div class="is-size-3 mt-5">
            Επιλέξτε φορέα
            <!-- <b>γρήγορα</b> και <b>εύκολα</b> -->
          </div>
          {#if $organizations.length > 0}
            <div class="mt-5">
              <!-- <AutoComplete aria-label="αναζήτηση φορέα" className="is-size-6-mobile is-size-5 is-focused" onChange={go} items={$organizations} bind:selectedItem={selectedClient}
                  textCleanFunction={(text)=> {if
                  (text===undefined) selectedClient=null; else return removeAccents(text);}}
                  keywordsFunction={item => removeAccents(item.title)}
                  labelFunction={item => item.title}
                  placeholder="Αναζήτηση φορέα..." 
                  /> -->
              <!-- Testing screenreader-compatible autocomplete, still needs tweaking -->
              <A11Yautocomplete items={$organizations} />
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}
<!-- <hr class="container {$highContrast?'has-background-info':'has-background-white'} "
  style="height:0.2rem; border-radius:10px" /> -->

{#if !isLoading}
  <div class="container pt-4 app-container">
    <div class="container columns mt-5">
      <!-- <div class="column is-1"></div> -->
      <div class="column">
        <!-- <h1 class="mt-2 is-size-4-mobile is-size-3">
        Φορείς ανά κατηγορία:
      </h1> -->
        <div class="tile is-ancestor mb-5 mt-6">
          <div class="tile is-parent">
            <article class="tile is-child">
              <h2
                class="is-size-3-desktop is-size-5-tablet is-size-6-mobile px-4"
              >
                <strong>Φορείς</strong>
              </h2>
              <hr />
              <ul class="menu-list">
                {#each $organizations as org}
                  {#if org.title && !org.title.startsWith("!")}
                    <li
                      class="is-size-4-desktop is-size-5-tablet is-size-6-mobile"
                    >
                      <a on:click={go(org.id)} style="border-radius: 30px">
                        <div class="py-3  has-text-link">
                          {org.title}
                        </div>
                      </a>
                    </li>
                  {/if}
                {/each}
              </ul>
            </article>
          </div>
        </div>
        <!--------------------- FROM HERE ON IT'S ALL HARDCODED MOCK DATA LOL -------------------------------------------------->

        <!-- <div class="tile is-ancestor" style="margin-bottom:2rem;">
            <div class="tile is-parent">
              <article class="tile is-child">
                <p style="font-size: 1.75rem; margin-bottom:1rem;">
                  <strong>{$categories[3].name}</strong>
                </p>
                {#each findClientsByCategory($categories[3].id) as client}
                      <div class="is-size-5" >
                          <a href="{$url('/client/:id/welcome', { id: client.id })}">
                          <i class="fa fa-chevron-right has-text-info" />
                          &nbsp; {client.name}
                          </a>
                      </div>
                {/each}
              </article>
            </div>
           
          </div> -->
      </div>
    </div>
  </div>
{/if}

<style>
  .wrap {
    flex-wrap: wrap;
  }

  /* 	CSS variables can be used to control theming.
			https://github.com/rob-balfre/svelte-select/blob/master/docs/theming_variables.md
	*/
  /* .themed {
		border: 3px solid blue;
	}
  .not_themed {
    border: 3px solid white;
  } */
</style>
