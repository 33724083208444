<script>
import {axiosAuth, commons, ready} from '../auth.js';
import Fa from 'svelte-fa'
import { faSearch, faCog, faBrush, faBook, faLock, faEnvelope, faTag, faUser, faFolder, faEnvelopeOpenText, faPaperPlane , faTrash, faEdit, faPlus} from '@fortawesome/free-solid-svg-icons';
import {onMount} from 'svelte';
import Spinner from 'svelte-spinner';
import { fade } from 'svelte/transition';
import { createEventDispatcher } from 'svelte';
import {updateTags} from './settings-store.js';

const dispatch = createEventDispatcher();

const dispatchTagInfo = (tag) => {
		dispatch('tag-delete', {
			tag: tag
		});
	}


let loading = true;
let template_tags = [];
let file_tags = [];
let processdef_tags = [];
let processinst_tags = [];

let taxonomies = ["TEMPLATE_FILE","FILE","PROCESS_DEFINITION", "PROCESS_INSTANCE"];

$:console.log(template_tags);
$:console.log(file_tags);
$:console.log(processdef_tags);
$:console.log(processinst_tags);

const getTaxonomyTags = async (tagTarget) => {
     //we need to use axios here
     console.log(tagTarget);
  return new Promise(async (resolve, reject) => {
   try {
         const res = await $axiosAuth.get('/api/'+$commons.orgId+ '/taxonomies?tagTarget='+ tagTarget);
         console.log(res.data);
         console.log(res.status);
         if(res.status===200){
            resolve(res.data.data);            
            }
         else {
            alert('something went wrong');
            reject("something went wrong")
         }         
        } 
        catch (e) {
         reject(e);
        }
    });
}

const filterTags = (tags_array) => {
    //filter based on first eleemnt
    if(tags_array&&tags_array.length>0){
        switch(tags_array[0].tagTarget[0]){
            case "TEMPLATE_FILE":
                template_tags = tags_array;
                break;
            case "FILE":
                file_tags = tags_array;
                break;
            case "PROCESS_DEFINITION":
                processdef_tags = tags_array;
                break;
            case "PROCESS_INSTANCE":
                processinst_tags = tags_array;
                break;
        }
    }
}
const getAllTags = async () => {

    return new Promise(async (resolve, reject) => {

    let promises = [];
    let i;
      for (i = 0; i < taxonomies.length; i++) {
        promises.push(getTaxonomyTags(taxonomies[i]));
        promises = promises;
        console.log("resolving taxonomy: " + taxonomies[i]);
      }

    Promise.all(promises)
        //destructure each resolved promise results
        .then(([results1, results2, results3, results4]) => {
        //filter them based on first element
        filterTags(results1);
        filterTags(results2);
        filterTags(results3);
        filterTags(results4);
          loading = false;
          resolve(taxonomies);
        })
        .catch((e) => {
          // handle errors here
          reject(e);
        });

        $updateTags = false; //set the flag to false, after the tags have been retrieved
    });
}
//if tags have been modified (the modal has set the value to true), rerun the api call to retrieve them
$:if($updateTags)getAllTags();

onMount(()=>{
    getAllTags();
});
</script>

<div class="container">
    
{#if !loading}

    <h1 class="is-size-4 mb-2">Αρχεία Υπόθεσης</h1>
    {#if file_tags&&file_tags.length>0}
        {#each file_tags as ftag}
            <span class="tag is-medium mx-1 my-1">
            {(ftag.tag).toUpperCase()}
            <button class="delete is-small" on:click={()=>{dispatchTagInfo(ftag);}}></button>
            </span>
        {/each}
     {:else}
        <p class="is-size-6">Δεν έχουν οριστεί ετικέτες για αυτήν την κατηγορία</p>
    {/if}
    <hr class="has-background-grey">

    <h1 class="is-size-4 mb-2">Πρότυπα</h1>
    {#if template_tags&&template_tags.length>0}
        {#each template_tags as ttag}
            <span class="tag is-medium mx-1 my-1">
            {(ttag.tag).toUpperCase()}
            <button class="delete is-small" on:click={()=>{dispatchTagInfo(ttag);}}></button>
            </span>
        {/each}
    {:else}
        <p class="is-size-6">Δεν έχουν οριστεί ετικέτες για αυτήν την κατηγορία</p>
    {/if}
    <hr class="has-background-grey">

    <h1 class="is-size-4 mb-2">Υπόθεση</h1>
    {#if processinst_tags&&processinst_tags.length>0}        
        {#each processinst_tags as pitag}
            <span class="tag is-medium mx-1 my-1">
            {(pitag.tag).toUpperCase()}
            <button class="delete is-small" on:click={()=>{dispatchTagInfo(pitag)}}></button>
            </span>
        {/each}
    {:else}
        <p class="is-size-6">Δεν έχουν οριστεί ετικέτες για αυτήν την κατηγορία</p>
    {/if}
    <hr class="has-background-grey">

    <h1 class="is-size-4 mb-2">Διαδικασία</h1>
    {#if processdef_tags&&processdef_tags.length>0}
        {#each processdef_tags as pdtag}
            <span class="tag is-medium mx-1 my-1">
            {(pdtag.tag).toUpperCase()}
            <button class="delete is-small" on:click={()=>{dispatchTagInfo(pdtag)}}></button>
            </span>
        {/each}
    {:else}
        <p class="is-size-6">Δεν έχουν οριστεί ετικέτες για αυτήν την κατηγορία</p>
    {/if}
    <hr class="has-background-grey">
{:else}
    <div class="has-text-centered">
        <div class="is-size-4-mobile is-size-3 ">
         <Spinner 
            size="100"
            speed="750"
            color="rgba(0, 255, 0, 0.3)"
            thickness="5"
            gap="40"/>          
        </div>
    </div>   
{/if}
</div>
