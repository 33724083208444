//regenerator-runtime is needed for parcel build
import 'regenerator-runtime/runtime';

import App from './App.svelte';
import Bulma from 'bulma';
import '../node_modules/bulma-o-steps/bulma-steps.css';

const app = new App({
	target: document.body,
	props: {
		name: 'Open1 |  Entrance'
	}
});

export default app; 