<script>
  import Fa from "svelte-fa";
  import {
    faUser,
    faRedoAlt,
    faCheck,
    faUserLock,
  } from "@fortawesome/free-solid-svg-icons";
  import { UsersIcon } from "svelte-feather-icons";
  import {
    isUserInGroup,
    userCheckedIfInGroup,
    currentUserGroup,
  } from "../ui-store.js";
  export let activeStep;
  // export let selectedClient;
  export let selectedProcess;

  let serviceName;
  if (selectedProcess.serviceName.startsWith("!")) {
    serviceName = selectedProcess.serviceName.slice(1);
  } else {
    serviceName = selectedProcess.serviceName;
  }
</script>

<div style="margin:auto;">
  <!-- 

    <div class="steps-actions container">
    <div class="steps-action">
      <button on:click={()=>activeStep--} class="button is-primary" disabled={activeStep===1}>Previous</button>
    
      <button on:click={()=>activeStep++} class="button is-primary" disabled={activeStep===5}>Next</button>
    </div>
  </div>
  <hr/> -->

  <div class="mt-6">
    <!-- no need to show selectedClient any more because it is shown in the header -->
    <!-- <div class="is-size-5">{selectedClient}</div> -->
    <div class="is-size-5 is-size-6-mobile">
      {selectedProcess.serviceCategory}
    </div>
    <div class="is-size-1 is-size-2-mobile has-text-weight-bold">
      {serviceName}
    </div>
    {#if $currentUserGroup.groupName}
      <div class="columns mb-2 mt-2">
        <!-- Left side -->
        <div
          class="column is-narrow is-size-5 is-size-6-mobile notification is-warning is-light m-2 px-5 py-5 rows"
        >
          <div class="level is-shrinkable">
            <div class="level-left">
              <div class="icon mx-2 level-item">
                <!-- <span> -->
                <Fa icon={faUserLock} size="lg" aria-hidden="true" />
                <!-- </span>                 -->
              </div>
              <div class="mx-2 level-item">
                <p>
                  Υποβολή αιτήσεων μπορεί να γίνει μόνο από μέλη της ομάδας: {$currentUserGroup.groupName}.
                </p>
              </div>
            </div>
          </div>

          <!-- {#if !$isUserInGroup && authenticationServiceId}
            <div class="level is-shrinkable">
              <div class="level-left">
                <div class="icon mx-2 level-item">
                  <UserPlusIcon size="2x" />
                </div>
                <div class="mx-2 level-item">
                    <p>Είστε συμβολαιογράφος; Μπορείτε να αιτηθείτε την πιστοποίησή σας <strong>
                      <a href={$url('/client/:id/action/:serviceId/user/:userId/new', { id: $params.id, serviceId: authenticationServiceId, userId: $params.tid})}
                      target="_blank"> εδώ</a></strong>.</p>
                </div>
              </div>
            </div>
          {/if} -->
        </div>
      </div>
    {/if}
  </div>

  <div class="columns ml-0">
    <div class="is-column">
      <ul
        class="steps-options steps is-large has-gaps has-content-centered is-horizontal mt-6"
      >
        <li class="steps-segment {activeStep === 1 ? 'is-active' : ''}">
          <span
            class="steps-marker {activeStep === 1 ? '' : 'is-hollow'} is-size-5"
          >
            <!--
            <Fa icon={faUser} />
            -->
            1
          </span>
          <div class="steps-content is-size-7-mobile is-size-6">
            <p class={activeStep === 1 ? "has-text-weight-semibold" : ""}>
              Συμπλήρωση
            </p>
          </div>
        </li>
        <li class="steps-segment {activeStep === 2 ? 'is-active' : ''}">
          <span
            class="steps-marker {activeStep === 2 ? '' : 'is-hollow'} is-size-5"
          >
            <!--
            <Fa icon={faRedoAlt} />
            -->
            2
          </span>
          <div class="steps-content is-size-7-mobile is-size-6">
            <p class={activeStep === 2 ? "has-text-weight-semibold" : ""}>
              Επιβεβαίωση
            </p>
          </div>
        </li>
        <li class="steps-segment {activeStep === 3 ? 'is-active' : ''}">
          <span
            class="steps-marker {activeStep === 3 ? '' : 'is-hollow'} is-size-5"
          >
            <!--
            <Fa icon={faCheck} />
            -->
            3
          </span>
          <div class="steps-content is-size-7-mobile is-size-6">
            <p class={activeStep === 3 ? "has-text-weight-semibold" : ""}>
              Ολοκλήρωση
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
