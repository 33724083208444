<script>
  import { Router } from "@sveltech/routify";
  import { routes } from "@sveltech/routify/tmp/routes";
  import SessionCheck from "./sessioncheck.svelte";
  import { axiosInst } from "./credentials.js";
  import { searchWorker } from "./ui-store.js";
  import { initi18n } from "./i18n.js";

  initi18n();
  //preload searchWorker
  $searchWorker = new Worker("../build/searchWorker.js");

  // //preload organizations

  // // $: if ($organizations) console.log("organizations: ...", JSON.stringify($organizations));

  // let promise = $axiosInst.get('/registeredOrganization/', {
  //   method: 'get',
  //   headers: {
  //     'Accept': 'application/json, text/plain, */*',
  //     'Content-Type': 'application/json'
  //   }
  // })
  //   .then(
  //     function (response) {
  //       console.log(response);
  //       if (response.status !== 200) {
  //         console.log('Looks like there was a problem. Status Code: ' +
  //           response.status);
  //         isLoading = false;
  //         return;
  //       }
  //       else {
  //         //If status OK, see if we have errors
  //         if (response.data.errors.length > 0) {
  //           console.log('Unathorized User' + ': ' + response.data.errors);
  //           // add more actions...
  //           isLoading = false;
  //           return;
  //         }
  //         else {
  //           //no errors, move to next and final step

  //           $organizations = response.data.data;
  //           // let loadedOrgs = [];
  //           // loadedOrgs = response.data.data;
  //           // // setTimeout(() => {
  //           //   isLoading = false;
  //           //   organizations.setOrgs(loadedOrgs);
  //           // }, 1000);
  //         }
  //       }
  //     })
  //   .catch(function (err) {
  //     console.log('Fetch Error :-S', err);
  //   });
</script>

<svelte:head>
  <style src="sass/mystyles.scss"></style>
</svelte:head>

<!-- <TAXIS> -->
<Router {routes} />
<SessionCheck />
<!-- </TAXIS> -->
