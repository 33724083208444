<script>
  import { onMount } from "svelte";
  import { isActive, url, goto, params } from "@sveltech/routify";
  import yup from "yup";
  import {
    axiosInst,
    loggedIn,
    loggedTaxis,
    profile,
  } from "../../../credentials.js";
  import Spinner from "svelte-spinner";
  import Fa from "svelte-fa";
  import {
    faUser,
    faFolder,
    faDownload,
    faCheck,
    faChevronRight,
    faExclamationTriangle,
  } from "@fortawesome/free-solid-svg-icons";
  import {
    DownloadIcon,
    PaperclipIcon,
    PlusIcon,
    XIcon,
    CheckIcon,
    SearchIcon,
  } from "svelte-feather-icons";
  import Formfield from "../../../components/Formfield.svelte";
  import { fade, scale, blur } from "svelte/transition";
  import {
    sizeOf,
    SUPPORTED_FORMATS,
    formatMimeType,
    checkFiles,
    findErrors,
    removeAccents,
    debounce,
  } from "../../../utils/helpers.js";
  import { faFrown } from "@fortawesome/free-regular-svg-icons";
  import moment from "moment";
  import { slide } from "svelte/transition";
  import { searchWorker } from "../../../ui-store.js";
  import { Pulse } from "svelte-loading-spinners";
  import Search from "../../../components/SearchComponent.svelte";

  // let searchWorker;
  let loadingSearch = true;

  const toggle = (index) => {
    //if index is -1, close all open requests
    if (index === -1)
      user.requests.forEach((element) => {
        element.open = false;
      });
    else {
      user.requests[index].open = !user.requests[index].open;

      user.requests.forEach((element, i) => {
        if (i !== index) element.open = false;
      });
    }
  };

  console.log(process.env.MAX_SIZE, SUPPORTED_FORMATS);
  //get values from local storage
  // loggedin.useLocalStorage();
  // loggedUser.useLocalStorage();
  // loggedUserName.useLocalStorage();

  //local testing ONLY-------------------
  //$loggedIn = true;
  //$profile.id = 1;
  //$loggedTaxis = true;

  let searchCriteria = "";
  // {
  //   serviceName: "",
  //   orgTitle: "",
  //   protocolNumber: "",
  // };

  let filteredResults = [];

  function gotoClient() {
    $goto("/client");
  }

  function howManyMinutesFromNow(date) {
    let now = moment();
    let this_date = moment(date);

    let minutes = this_date.diff(now, "minutes");
    return minutes;
  }

  $: console.log("loggedIn: ", $loggedIn);
  $: console.log("profile.id: ", $profile.id);

  const reset = () => {
    errorWithProcess = false;
    successfulResubmission = false;
    resubmissionMode = false;
    resetFileList(selectedCase.documentsUploaded);
    resetFileList(documentsNotUploadedToCase);
    resetFileList(additionalDocuments);
    additionalDocuments = [];
    resubmissionData = {
      protocolNumber: null,
      processInstanceIdToResubmit: null,
      variableNameToPutCurrentPid: "submitFROM",
      resubmissionSignal: "__resubmit",
      resubmissionRelationshipList: [],
    };
    isFormValid = false;
  };

  let userFound = false;
  let errors = [];

  let user = null;
  $: console.log("user: ", user);

  let activeMenu = 1;
  let selectedCase = null; //selected case to view in modal
  let ready = false; //is the selectedcaseinfo ready to be shown?

  let resubmissionMode = false; //is the user resubmitting documents to the case?
  let loading = true;
  let loadingOrgInfo = true;
  let selectedOrgDTO = null;
  let issuerQuintuplet = null;
  let serviceRequestedFiles = null;
  // $: console.log("test serviceRequestedFiles :", serviceRequestedFiles);

  //if a case is selected, compare what the user has uploaded to what the service was asking for (needed for resubmission mode)
  let documentsNotUploadedToCase = null;
  $: console.log(
    "test documentsNotUploadedToCase :",
    documentsNotUploadedToCase
  );

  //this variable is needed in order to avoid infinite loops (mainly)
  //when a case is selected, a reactive statement runs, in which we run for loops
  //to assign variableNames to uploaded documents, in case the user wants to reupload files to his case
  //so, if we have completed this crosscheck, the reactive statement won't run.
  let calledCrosscheck = false;
  $: if (selectedCase)
    console.log("selectedCase.documentsUploaded ", selectedCase);

  //if a case is selected, a number of things need to be done:
  //1)get the url where each uploaded and produced documents are stored
  //2)create their download links
  //3)add them as a field in the json arrays (easy display)
  $: if (selectedCase && !calledCrosscheck) {
    ready = false;
    getIssuerFromOrgInfo().then(() => {
      //add variable name as a field to uploaded documents' object array (in case the user wants to reupload files to his case)
      //crosscheck with data from organization to get the variable name
      // console.log(selectedCase.documentsUploaded);
      if (
        selectedCase &&
        selectedCase.documentsUploaded &&
        selectedCase.documentsUploaded.length >= 0
      ) {
        console.log("DOC NUMBER: ", selectedCase.documentsUploaded.length);
        selectedCase.documentsUploaded = crossCheckFiles(
          selectedCase.documentsUploaded,
          serviceRequestedFiles
        );
      }
      ready = true;
    });
  }

  const crossCheckFiles = (uploadedDocuments, requestedFiles) => {
    //crosscheckFiles and add variableProperty to uploeadedFiles by user
    console.log("inside crossCheck files");
    calledCrosscheck = true;
    documentsNotUploadedToCase = null;
    for (let i = 0; i < uploadedDocuments.length; i++) {
      //let title = selectedCase.documentsUploaded[i].fileTitle;
      let result = [];
      if (requestedFiles) {
        result = requestedFiles.filter(
          (file) =>
            file.fileTitle.toString() ===
            uploadedDocuments[i].fileTitle.toString()
        );
      }
      console.log(result);
      console.log("in loop");
      if (result.length) {
        uploadedDocuments[i]["mapToVariableName"] = result[0].mapToVariableName;
      } else
        uploadedDocuments[i]["mapToVariableName"] = result.mapToVariableName;
    }
    if (!requestedFiles) {
      requestedFiles = [];
    }
    documentsNotUploadedToCase = requestedFiles.filter((file) => {
      return !uploadedDocuments.some((doc) => {
        return doc.fileTitle.toString() === file.fileTitle.toString();
      });
    });

    return uploadedDocuments;
  };

  $: console.log("additionalDocuments ", additionalDocuments);
  $: console.log("documentsNotUploadedToCase ", documentsNotUploadedToCase);

  const downloadFile = (doc) => {
    console.log(doc);
    getURL(doc).then((res) => {
      createDownloadLink(res.docId, res.versionFileId).then((result) => {
        var a = document.createElement("a");
        a.href = result.downloadLink;
        //if result.type has been set, add extension to filename (for download  bug)
        a.setAttribute(
          "download",
          result.type ? doc.fileTitle + "." + result.type : doc.fileTitle
        );
        a.click();
      });
    });
  };

  const createDownloadLink = (docId, versionFileId) => {
    //response needs to be an arraybuffer, in order to build the blob url
    let fileType;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await $axiosInst
          .get(
            "open1process/files/" +
              docId +
              "/version/" +
              versionFileId +
              "/content",
            {
              params: { registeredOrganizationId: selectedCase.orgId },
            },
            {
              responseType: "arraybuffer",
              transformRequest: (data, headers) => {
                delete headers["SessionID"];
                return data;
              },
            }
          )
          .then(function (response) {
            // console.log(response.status);
            if (response.status === 200) {
              console.log("successful download");
              var arr = response.data.data.content;
              var byteArray = Uint8Array.from(atob(arr), (c) =>
                c.charCodeAt(0)
              );
              var blob = new Blob([byteArray], {
                type: response.data.data.contentType,
              });
              let mimeType = formatMimeType(response.data.data.contentType);
              let downloadLink = URL.createObjectURL(blob, {
                type: response.data.data.contentType,
              });
              let result = {
                downloadLink: downloadLink,
                type: null,
              };
              //if mimetype is of certain type, add property to file (we need it for a download bug)
              if (
                mimeType === "DOC" ||
                mimeType === "DOCX" ||
                mimeType === "ODT"
              )
                result.type = mimeType.toLowerCase();
              resolve(result);
            } else {
              reject("something went wrong");
            }
          });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const getIssuerFromOrgInfo = () => {
    //get all org services
    return new Promise(async (resolve, reject) => {
      let serviceUuid = selectedCase ? selectedCase.serviceUuid : null;
      let orgId = selectedCase ? selectedCase.orgId : null;

      let promise = $axiosInst
        .get("registeredOrganization/" + orgId)
        .then(function (response) {
          console.log(response);
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            loadingOrgInfo = false;
            reject();
          } else {
            //If status OK, see if we have errors
            if (response.data.errors.length > 0) {
              console.log("Unathorized User" + ": " + data.errors);
              // add more actions...
              loadingOrgInfo = false;
              reject();
            } else {
              //no errors, move to next and final step
              selectedOrgDTO = response.data.data;
              console.log(selectedOrgDTO);
              console.log("success");
              //filter to find service and set issuer
              let filter = selectedOrgDTO.servicesProvided.filter(
                (service) =>
                  service.serviceUuid.toString() === serviceUuid.toString()
              );
              console.log(filter);
              if (filter.length) {
                issuerQuintuplet = filter[0].serviceIssuer;
                serviceRequestedFiles = filter[0].filesRequested;
                loadingOrgInfo = false;
                resolve();
              } else {
                issuerQuintuplet = filter.serviceIssuer;
                serviceRequestedFiles = filter.filesRequested;
                loadingOrgInfo = false;
                resolve();
              }
            }
          }
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
          reject();
        });
      //end api call, continue in
    });
  };

  const getURL = (document) => {
    return new Promise(async (resolve, reject) => {
      $axiosInst
        .get("open1process/files/" + document.versionedFileId, {
          params: {
            registeredOrganizationId: selectedCase.orgId,
            issuerQuintuplet,
          },
        })
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            reject();
          } else {
            console.log(response.data.data.currentVersion.url);
            console.log(response.data.data);
            let urltemp = response.data.data.currentVersion.url;
            if (process.env.BASE_URL.indexOf("https://") !== -1) {
              // this is a secure connection, convert the url to https:
              urltemp = urltemp.replace("http://", "https://");
            }
            let result = {
              docId: document.versionedFileId,
              versionFileId: response.data.data.currentVersion.fileId,
            };
            resolve(result);
          }
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
          reject(err);
        });
    });
  };

  // const createDownloadLinkOld = (res) => {

  //   console.log("calling create downloadLink....");
  //   //openModal = true;
  //   //response needs to be an arraybuffer, in order to build the blob url
  //   return new Promise(async (resolve, reject) => {
  //     $axiosInst
  //       .get(res.url, { responseType: "arraybuffer", transformRequest: (data, headers) => {
  //         delete headers['SessionID'];
  //         return data;
  //         }
  //       })
  //       .then(function (response) {
  //         if (response.status !== 200) {
  //           console.log(
  //             "Looks like there was a problem. Status Code: " + response.status
  //           );
  //           reject();
  //         } else {
  //           console.log(response);
  //           //create the download URL, set the type of file it's sending
  //           let downloadLink = URL.createObjectURL(
  //             new Blob([response.data], {
  //               type: response.headers["content-type"],
  //             })
  //           );
  //           let result = {
  //             docId: res.docId,
  //             downloadLink: downloadLink,
  //           };
  //           resolve(result);
  //         }
  //       })
  //       .catch(function (err) {
  //         console.log("Fetch Error :-S", err);
  //         reject(err);
  //       });
  //   });

  // };
  const addPropertyToDocument = (result) => {
    //create the download URL, set the type of file it's sending
    //let downloadLink = URL.createObjectURL(new Blob([response.data.data.currentVersion.url], {type: response.headers["content-type"]}));
    //add download link propery to document
    let filter = selectedCase.documentsUploaded.filter(
      (doc) => result.docId === doc.versionedFileId
    );
    console.log(filter);
    if (filter.length) {
      filter[0]["downloadLink"] = result.downloadLink;
    } else filter["downloadLink"] = result.downloadLink;

    console.log(selectedCase.documentsUploaded);
  };
  const getUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await $axiosInst
          .get("externalEntities/" + $params.id)
          .then(function (response) {
            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              userFound = false;
              loading = false;
              reject();
            } else {
              //If status OK, see if we have errors
              console.log(response.data);
              if (response.data.errors.length > 0) {
                console.log("Error" + ": " + response.data.errors);
                // add more actions...
                userFound = false;
                loading = false;
                reject();
              } else {
                //no errors, move to next and final step
                console.log("Success!");
                userFound = true;
                loading = false;
                user = response.data.data;
                console.log(response.data.data);
                //sort requests
                user.requests = user.requests.sort(
                  (a, b) => parseInt(b.id) - parseInt(a.id)
                );
                user.requests = user.requests.map((r) => {
                  if (r.status && r.status.length && r.status[0] == "!") {
                    r.warning = true;
                    r.status = r.status.substring(1);
                  } else {
                    r.warning = false;
                  }
                  return r;
                });

                user.requests.forEach((element) => {
                  element["open"] = false;
                });

                resolve();
              }
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  onMount(() => {
    // if(!$loggedIn) $goto("/");
    // if($profile.id!=$params.id) $goto("/");
    getUser().then(() => {
      // searchWithCriteria(searchCriteria);
    });
    //getOrgInfo();
  });

  // -------------------------------------FOR RESUBMISSION ------------------------------------------------------------
  let additionalDocuments = [];
  let allDocsUploaded = [];
  let isFormValid = false;

  const validateForm = () => {
    console.log("running validateForm");
    let filecounter = 0;
    let newArray = [
      ...selectedCase.documentsUploaded,
      ...documentsNotUploadedToCase,
      ...additionalDocuments,
    ];
    console.log("NEW ARRAY", newArray);

    newArray.forEach((item) => {
      console.log(item.fileTitle, item.file);

      if (item.file !== null && item.file.length) {
        //a file has been uploaded
        filecounter++;
      }
    });

    if (filecounter === 0) isFormValid = false;
    //form has no files, prevent resubmission
    else {
      isFormValid = true;
      //if files exist, check for errors
      let errors2 = checkFiles(newArray);
      console.log("HERE ERRORS", errors2);
      if (errors2.length > 0) isFormValid = false;
      //else isFormValid = true;
    }

    console.log(filecounter);
  };

  const addExtraDocument = () => {
    additionalDocuments.push({
      file: null,
      fileTitle: "new document" + (additionalDocuments.length + 1),
      fileDescription: "new description",
      hint: null,
      versionedFileId: null,
    });

    // hasErrors.push(false);
    // hasErrors = hasErrors;
    additionalDocuments = additionalDocuments;
  };

  const deleteFileFromList = (event, list, index) => {
    console.log("GOT INSIDE DELETE");

    list.forEach((file) => {
      if (file.fileTitle === event.detail.title) {
        file.file = null;
        file = file;
      }
    });

    errors = checkFiles(list);
    validateForm();
  };

  const resetFileList = (list) => {
    console.log(list);
    list.forEach((file) => {
      if (file.file) file.file = null;
    });
  };

  const resubmitToCase = (processInstanceId, protocolNumber) => {
    //filter the documants, only submit those that have an uploaded file
    // console.log(selectedCase.documentsUploaded);
    // console.log( documentsNotUploadedToCase);
    let newDocuments = [
      ...selectedCase.documentsUploaded,
      ...documentsNotUploadedToCase,
      ...additionalDocuments,
    ];

    newDocuments = newDocuments.filter((doc) => {
      return doc.file && doc.file.length !== 0;
    });

    //call resubmission process from Tukanga and pass these arguments:
    console.log(
      "Resubmit to case: processInstance " +
        processInstanceId +
        "\nnewDocs " +
        newDocuments +
        "\nprotocolNumber " +
        protocolNumber
    );
    console.log("new docs: ", newDocuments);

    //upload files + start process instance
    processIsStarting = true;
    successfulResubmission = false;

    chainUploads(newDocuments).then((result) => {
      console.log(result);
      //chain uploads resolves with array [fileId, variableName, previousVersionId]
      return startProcess(result).then((resolved_processId) => {
        //processNum = resolved_processId;
        // return createUserRequest(documentsUploaded, resolved_processId);
        resetFileList(selectedCase.documentsUploaded);
        resetFileList(documentsNotUploadedToCase);
      });
    });

    //resubmissionMode = false;
  };

  const fileUpload = async (filedata, vars_values_array) => {
    let url = "open1process/files";

    console.log(filedata);
    //  filedata =
    // {
    //   file: FileList {0: File, length: 1}
    //   fileDescription: "Φωτοτυπία ταυτότητας"
    //   fileTitle: "Φωτοτυπία ταυτότητας"
    //   mapToVariableName: "IDphoto"
    //   obligatory: false
    //   templateFileURI: null
    // }
    let formData = new FormData();
    if (filedata && filedata.file && issuerQuintuplet) {
      formData.append("file", filedata.file[0]);
      formData.append("alias", filedata.fileTitle);
      formData.append("orgUUId", issuerQuintuplet.split(":")[0]);
      formData.append("orgStructureUUId", issuerQuintuplet.split(":")[1]);
      formData.append("orgUnitUUId", issuerQuintuplet.split(":")[2]);
      formData.append("roleUUId", issuerQuintuplet.split(":")[3]);
      formData.append("personUUId", issuerQuintuplet.split(":")[4]);
    }

    formData.append("registeredOrganizationId ", selectedOrgDTO.id);

    //if there is a previous version, don't add it to the query params, keep it in another array instead
    let previousVersionId = null;
    if (filedata.versionedFileId) previousVersionId = filedata.versionedFileId;

    //console.log(url);
    return new Promise(async (resolve, reject) => {
      try {
        const res = await $axiosInst.post(url, formData, {
          transformRequest: (formData, headers) => {
            return formData;
          },
        });
        console.log(res.data);
        console.log(res.status);
        if (res.status === 200) {
          console.log("successful upload");
          // =================
          //set documentsUploadedArray which will be sent to the user's profile after the process instnce is started
          // let doc = {
          //   fileTitle: filedata.fileTitle,
          //   fileDescription: filedata.fileTitle,
          //   versionedFileId: res.data.data.versionedFileId,
          // };
          // documentsUploaded.push(doc);
          // ===============
          vars_values_array.push({
            value: res.data.data.versionedFileId,
            var: filedata.mapToVariableName,
            previousVersionId: previousVersionId,
          });
          vars_values_array = vars_values_array;
          resolve(res.data.data); //we need the fileId to start a new process later
        } else {
          reject("something went wrong");
        }
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const chainUploads = async (files_array) => {
    //resolves all file uploads. By the end of it, the [{id, var}] array is complete, and ready to be used by startProcess and updateVars

    var ids = [];
    var promises = [];
    var fileIds;
    var i;

    //get uploaded files
    let filter = files_array.filter((fileData) => fileData.file != null);

    console.log("CHAIN UPLOADS FILTER: ", filter);

    return new Promise(async (resolve, reject) => {
      for (i = 0; i < filter.length; i++) {
        promises.push(fileUpload(filter[i], ids));
        promises = promises;
        console.log("resolving file " + i);
      }

      Promise.all(promises)
        .then((results) => {
          console.log("Resolved all files " + JSON.stringify(results));
          console.log(ids);
          resolve(ids);
        })
        .catch((e) => {
          // handle errors here
          reject(e);
        });
    });
  };

  let processIsStarting = false;
  let errorWithProcess = false;
  let successfulResubmission = false;
  let resubmissionData = {
    protocolNumber: null,
    processInstanceIdToResubmit: null,
    variableNameToPutCurrentPid: "submitFROM",
    resubmissionSignal: "__resubmit",
    resubmissionRelationshipList: [],
  };

  $: if (resubmissionMode) {
    resubmissionData.protocolNumber = selectedCase.protocolNumber;
    resubmissionData.processInstanceIdToResubmit =
      selectedCase.processInstanceId;
  }

  const startProcess = async (data_array) => {
    //the data_array comes from file upload, it's fileIDs each associated with a variable value and previous version (not yet asscociated in open1Process)

    //this object holds the values needed for updateProcessVars which will be called after startProcess resolves
    let resolved = { processId: null, vars_values_array: [] };

    //push values regarding file variables
    resolved.vars_values_array.push(...data_array);

    //only pass values (ids) from uploaded files array (data_array, that is)
    let ids = data_array.map((el) => el.value);

    //set some attributes to request body first

    let request_body = {
      associatedFiles: ids,
      businessKey:
        "ΕΠΑΝΥΠΟΒΟΛΗ" +
        " > " +
        selectedCase.serviceName +
        " ( " +
        user.physicalEntityDTO.firstName +
        " " +
        user.physicalEntityDTO.lastName +
        " )",
      // comments: emailVerificationInfo.comments,
      onBehalfOfDTO: {
        emailAddress: user.email,
        firstName: user.physicalEntityDTO.firstName,
        lastName: user.physicalEntityDTO.lastName,
        phoneNumber: null,
        afm: user.taxId,
      },
      priority: "normal",
      registeredOrganizationId: selectedOrgDTO.id,
      serviceId: 0,
      variables: {},
    };
    //set file variables hashmap
    resolved.vars_values_array.forEach((entry) => {
      request_body.variables[entry.var] = entry.value;
    });

    //set resubmissionFileRelationsship
    data_array.forEach((entry) => {
      let tmp = {
        vFileId: entry.value,
        previousVFileId: entry.previousVersionId,
      };
      resubmissionData.resubmissionRelationshipList.push(tmp);
      resubmissionData.resubmissionRelationshipList =
        resubmissionData.resubmissionRelationshipList;
    });

    //add variable for resubmission data
    request_body.variables["resubmissionData"] =
      JSON.stringify(resubmissionData);
    console.log("STRINGIFY VARS: ", JSON.stringify(resubmissionData));
    // request_body.variables[selectedOrgDTO.variableNametoPutPid] = selectedCase.processInstanceId;
    // request_body.variables['protocolNumVar'] = selectedCase.protocolNumber;

    console.log("request_body ", request_body);

    return new Promise(async (resolve, reject) => {
      try {
        const res = await $axiosInst.post(
          "open1process/processes/start",
          request_body,
          {
            params: {
              serviceIssuer: issuerQuintuplet,
              serviceUuid: selectedOrgDTO.resubmissionServiceUuid,
              isResubmission: true,
            },
          }
        );
        console.log(res.data);
        console.log(res.status);
        if (res.status === 200) {
          {
            //If status OK, see if we have errors
            console.log(res.data);
            if (res.data.errors.length > 0) {
              console.log("An error occured" + ": " + res.data.errors);
              // add more actions...
              errorWithProcess = true;
              successfulResubmission = false;
              processIsStarting = false;
              reject("something went wrong");
            } else {
              //no errors
              console.log("Success!");
              errorWithProcess = false;
              processIsStarting = false;
              successfulResubmission = true;
              //resolved.processId = res.data.data[0].instanceId;
              //resolves sending the object with process instance Id, and the fileIDs-vars association array to updateProcessVars
              resolve(res.data.data[0].instanceId);
              console.log(res.data.data);
            }
          }
        } else {
          errorWithProcess = true;
          processIsStarting = false;
          successfulResubmission = true;
          reject("something went wrong");
        }
      } catch (e) {
        reject(e);
      }
    });
  };
</script>

{#if $loggedIn && $profile.id == $params.id}
  <div class="container app-container mt-6 p-0 ">
    <!-- <div class="panel"> -->
    <div class="container has-text-centered">
      <div class="is-size-3-desktop is-size-5 px-5">
        {#if userFound}
          {user.physicalEntityDTO.firstName}&nbsp;{user.physicalEntityDTO
            .lastName}{/if}
      </div>
    </div>
    <!-- <p class="panel-heading"> -->

    <!-- </p> -->

    {#if userFound}
      <div class="mt-4 tabs is-fullwidth">
        <ul>
          <li
            on:click={() => {
              activeMenu = 1;
            }}
            class={activeMenu === 1 ? "is-active" : ""}
          >
            <a>
              <!-- <span class="icon is-medium">
            <Fa class="mx-1" icon={faFolder} aria-hidden="true" />
          </span> -->
              Οι υποθέσεις μου
            </a>
          </li>
          <li
            on:click={() => {
              activeMenu = 2;
            }}
            class={activeMenu === 2 ? "is-active" : ""}
          >
            <a>
              <!-- <span class="icon is-medium">
            <Fa class="mx-1" icon={faUser} aria-hidden="true" />
          </span> -->
              <span>Το προφίλ μου</span>
            </a>
          </li>
        </ul>
      </div>
    {/if}

    <div class="px-4 mt-4">
      <!-- <div class="column is-5 px-6 mx-5">  -->

      <div class="column">
        {#if userFound}
          {#if activeMenu === 2}
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">
                  <!-- Last Name -->
                  Επώνυμο
                </label>
              </div>
              <div class="field-body">
                <div class="field mt-0">
                  <div class="control">
                    <input
                      disabled
                      value={user.physicalEntityDTO.lastName}
                      class="input"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">
                  <!-- Name -->
                  Όνομα
                </label>
              </div>
              <div class="field-body">
                <div class="field mt-0">
                  <div class="control">
                    <input
                      disabled
                      value={user.physicalEntityDTO.firstName}
                      class="input"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">e-mail</label>
              </div>
              <div class="field-body">
                <div class="field mt-0">
                  <div class="control">
                    <input
                      disabled
                      value={user.email}
                      class="input"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">ΑΦΜ</label>
              </div>
              <div class="field-body">
                <div class="field mt-0">
                  <div class="control">
                    <input
                      disabled
                      value={user.taxId}
                      class="input"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          {/if}
          {#if activeMenu === 1}
            <div class="mx-12 my-4">
              <Search
                items={user.requests}
                filtersArray={[
                  "serviceName",
                  "businessKey",
                  "orgTitle",
                  "processInstanceId",
                  "protocolNumber",
                  "status",
                ]}
                on:ready={(e) => {
                  console.log("search says it's ready");
                  filteredResults = e.detail.results;
                  //close all open requests
                  toggle(-1);
                  loadingSearch = false;
                }}
                on:loading={() => {
                  loadingSearch = true;
                }}
              />
            </div>
            {#if loading}
              <div class="is-size-5-mobile is-size-5 has-text-centered">
                <Spinner
                  size="100"
                  speed="750"
                  color="rgba(0, 255, 0, 0.3)"
                  thickness="5"
                  gap="40"
                />
              </div>
            {:else if user.requests && user.requests.length > 0}
              <table class="table is-fullwidth is-narrow responsive-table mt-3">
                <thead
                  style="position:sticky; top:0rem; z-index: 1;"
                  class="is-hidden-mobile rounded-white"
                >
                  <th class="has-text-centered" colspan="2">
                    <div class="">Υπόθεση</div>
                  </th>

                  <!-- <th class="has-text-centered">
                    <div class="">Υπηρεσία</div>
                  </th> -->

                  <th class="has-text-centered">
                    <div class="">Κατάσταση</div>
                  </th>

                  <th class="has-text-centered">
                    <div class="">Αριθμός Πρωτοκόλλου</div>
                  </th>

                  <th class="has-text-centered">
                    <div class="">Φορέας</div>
                  </th>

                  <th class="has-text-centered">
                    <div class="">Ημερομηνία αίτησης</div>
                  </th>

                  <th>&nbsp;</th>
                </thead>
                <tbody>
                  {#if loadingSearch}
                    <tr class="is-mobile has-text-centered">
                      <td
                        colspan="12"
                        class="has-text-centered has-text-centered-mobile"
                      >
                        <div class="stage">
                          <Spinner
                            size="100"
                            speed="750"
                            color="rgba(0, 255, 0, 0.3)"
                            thickness="5"
                            gap="40"
                          />
                        </div>
                      </td>
                    </tr>
                  {:else if filteredResults.length > 0}
                    {#each filteredResults as request, i}
                      <tr
                        on:click={() => {
                          toggle(i);
                          selectedCase = filteredResults[i];
                          calledCrosscheck = false;
                          if (resubmissionMode) reset();
                        }}
                        aria-expanded={filteredResults[i].open}
                        style="width:100%"
                        class="my-hoverable is-mobile is-vcentered is-size-5-desktop is-size-6-tablet has-text-weight-medium is-size-7-mobile p-4 has-text-left"
                        class:has-background-danger-light={request.warning}
                      >
                        <td
                          class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-centered p-3 pt-5"
                        >
                          <div
                            class="is-hidden-tablet has-text-weight-semibold"
                          >
                            Υπόθεση
                          </div>
                          <span style="color: grey">
                            {request.processInstanceId
                              ? request.processInstanceId
                              : "Δεν έχει οριστεί αριθμός υπόθεσης"}
                          </span>
                        </td>

                        <td
                          class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-centered p-3 pt-5"
                        >
                          <!-- style="--title-string: 'Υπηρεσία';" -->
                          <div
                            class="is-hidden-tablet has-text-weight-semibold"
                          >
                            Υπηρεσία
                          </div>
                          {request.businessKey
                            ? request.businessKey
                            : request.serviceName}
                        </td>

                        <td
                          class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-centered p-3 pt-5"
                        >
                          <!-- style="--title-string: 'Κατάσταση';" -->
                          <div
                            class="is-hidden-tablet has-text-weight-semibold"
                          >
                            Κατάσταση
                          </div>
                          {#if request.warning}
                            <Fa
                              class="has-text-danger"
                              icon={faExclamationTriangle}
                              size="lg"
                              aria-hidden="true"
                            />
                          {/if}
                          {request.status}
                        </td>

                        <td
                          class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-centered p-3 pt-5"
                        >
                          <!-- style="--title-string: 'Αρ. Πρωτοκόλλου';" -->
                          <div
                            class="is-hidden-tablet has-text-weight-semibold"
                          >
                            Αριθμός Πρωτοκόλλου
                          </div>
                          {request.protocolNumber
                            ? request.protocolNumber
                            : "-"}
                          <!-- Δεν έχει οριστεί αριθμός πρωτοκόλλου -->
                        </td>

                        <td
                          class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-centered p-3 pt-5"
                        >
                          <!-- style="--title-string: 'Φορέας';" -->
                          <div
                            class="is-hidden-tablet has-text-weight-semibold"
                          >
                            Φορέας
                          </div>
                          {request.orgTitle}
                        </td>

                        <td
                          class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-centered p-3 pt-5"
                        >
                          <!-- style="--title-string: 'Ημ. Αίτησης';" -->
                          <div
                            class="is-hidden-tablet has-text-weight-semibold"
                          >
                            Ημερομηνία αίτησης
                          </div>
                          {moment(
                            request.date.split(" ")[0],
                            "DD-MM-YYYY"
                          ).format("DD/MM/YYYY")}
                          {moment(request.date.split(" ")[1], "H:m:s").format(
                            "HH:mm:ss"
                          )}
                          <!-- {request.date} -->
                        </td>

                        <td
                          class="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-link has-text-centered p-3 pt-5 "
                        >
                          <!-- style="--title-string: 'Ημ. Αίτησης';" -->
                          <span
                            class="is-hidden-tablet has-text-weight-semibold"
                          >
                            Δείτε περισσότερα...
                          </span>
                          <span
                            class="is-hidden-mobile has-text-weight-semibold"
                          >
                            <svg
                              class:svg-turned={user.requests[i].open &&
                                selectedCase == request}
                              width="20"
                              height="20"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path d="M9 5l7 7-7 7" />
                            </svg>
                          </span>
                        </td>
                      </tr>

                      {#if user.requests[i].open && selectedCase == request}
                        {#if !ready}
                          <tr>
                            <td colspan="7" class="p-0">
                              <div
                                class="is-size-5-mobile is-size-5 has-text-centered"
                              >
                                <Spinner
                                  size="100"
                                  speed="750"
                                  color="rgba(0, 255, 0, 0.3)"
                                  thickness="5"
                                  gap="40"
                                />
                              </div>
                            </td>
                          </tr>
                        {:else}
                          <tr>
                            <td
                              colspan="7"
                              class:has-background-danger-light={selectedCase.warning}
                              class="p-4"
                            >
                              <div
                                style="border-radius: 5px;"
                                in:slide={{ duration: 300 }}
                              >
                                <!-- <div class="columns"> -->
                                <section class="my-3 py-3">
                                  {#if resubmissionMode && processIsStarting}
                                    <div class="has-text-centered mt-5">
                                      Πραγματοποιείται επανυποβολή....
                                      <div class="is-size-4-mobile is-size-3 ">
                                        <Spinner
                                          size="100"
                                          speed="750"
                                          color="rgba(0, 255, 0, 0.3)"
                                          thickness="5"
                                          gap="40"
                                        />
                                      </div>
                                    </div>
                                  {:else if resubmissionMode && errorWithProcess && !successfulResubmission}
                                    <div
                                      in:fade|local
                                      class="has-text-centered"
                                    >
                                      <div class="is-size-4-mobile is-size-3">
                                        <Fa
                                          icon={faFrown}
                                          size="4x"
                                          aria-hidden="true"
                                        />
                                      </div>
                                      <div class="columns is-centered mt-5">
                                        <div class="column">
                                          <div
                                            class="is-size-6-mobile is-size-5"
                                          >
                                            Παρουσιάστηκε πρόβλημα κατά την
                                            επανυποβολή.
                                          </div>
                                          <div
                                            class="is-size-6-mobile is-size-5"
                                          >
                                            Παρακαλώ προσπαθήστε ξανά.
                                          </div>
                                        </div>
                                      </div>
                                      <div class="is-size-4-mobile is-size-3 ">
                                        <button
                                          class="button is-link is-size-5-desktop is-size-6"
                                          on:click={reset}>OK</button
                                        >
                                      </div>
                                    </div>
                                  {:else if resubmissionMode && !errorWithProcess && successfulResubmission}
                                    <div
                                      in:fade|local
                                      class="has-text-centered mt-5"
                                    >
                                      <div class="has-text-success">
                                        <!-- <Fa
                        icon={faCheck}
                        size="4x"
                        class="has-text-success"
                        aria-hidden="true"
                      /> -->
                                        <CheckIcon size="150" />
                                      </div>
                                      <div class="columns is-centered mt-5">
                                        <div class="column">
                                          <div
                                            class="is-size-6-mobile is-size-5"
                                          >
                                            H επανυποβολή επισυναπτόμενων
                                            αρχείων πραγματοποιήθηκε επιτυχώς!
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <button
                                          style="white-space: normal;"
                                          class="button is-link is-size-5-desktop is-size-6-mobile"
                                          on:click={reset}>ΟΚ</button
                                        >
                                      </div>
                                    </div>
                                  {:else if ready && selectedCase.documentsUploaded && selectedCase.documentsUploaded.length > 0}
                                    <div class="level mb-4">
                                      <div class="level-left">
                                        <h2 class="subtitle is-size-5 mb-0">
                                          Επισυναπτόμενα αρχεία
                                        </h2>

                                        {#if selectedOrgDTO.resubmissionServiceUuid && selectedCase.status.toString() === "Σε εξέλιξη"}
                                          <button
                                            class="button is-link is-light ml-5"
                                            on:click={() => {
                                              if (resubmissionMode) {
                                                reset();
                                              } else {
                                                resubmissionMode =
                                                  !resubmissionMode;
                                              }
                                            }}
                                          >
                                            {resubmissionMode
                                              ? "Ακύρωση επανυποβολής"
                                              : "Επανυποβολή"}
                                          </button>
                                        {/if}
                                      </div>
                                    </div>

                                    <ul>
                                      {#each selectedCase.documentsUploaded as upload, i}
                                        <li
                                          class="mt-5 mb-1"
                                          data-tooltip="tooltip with long text"
                                        >
                                          {#if resubmissionMode}
                                            <form
                                              class="container is-centered"
                                              on:change|preventDefault={() => {
                                                validateForm();
                                                if (
                                                  !allDocsUploaded.includes(
                                                    selectedCase
                                                      .documentsUploaded[i]
                                                  )
                                                ) {
                                                  allDocsUploaded.push(
                                                    selectedCase
                                                      .documentsUploaded[i]
                                                  );
                                                  allDocsUploaded =
                                                    allDocsUploaded;
                                                }
                                                errors =
                                                  checkFiles(allDocsUploaded);

                                                console.log("ERRORS", errors);

                                                // hasErrorsIndex = i;
                                                // checkFileSizeAndType(i, i, selectedCase.documentsUploaded, event);
                                              }}
                                            >
                                              <Formfield
                                                type="file"
                                                bind:value={selectedCase
                                                  .documentsUploaded[i].file}
                                                errors={errors &&
                                                errors.length > 0
                                                  ? findErrors(
                                                      errors,
                                                      upload.fileTitle.toString()
                                                    )
                                                  : []}
                                                label={upload.fileTitle}
                                                hasTemplate={upload.templateFileURI !=
                                                  null}
                                                templateFileURI={upload.templateFileURI}
                                                on:deletefile={(event) =>
                                                  deleteFileFromList(
                                                    event,
                                                    allDocsUploaded
                                                  )}
                                              />
                                            </form>
                                            <!-- on:input={checkFileSizeAndType(i, selectedCase.documentsUploaded)} -->

                                            <!-- on:deletefile={deleteFileFromList} -->
                                          {:else}
                                            <a on:click={downloadFile(upload)}>
                                              <span class="my-icon">
                                                <DownloadIcon size="1.5x" />
                                              </span>&nbsp;
                                              <b>{upload.fileTitle}</b>
                                            </a>
                                          {/if}
                                        </li>
                                      {/each}
                                      {#if resubmissionMode}
                                        {#if documentsNotUploadedToCase && documentsNotUploadedToCase.length > 0}
                                          {#each documentsNotUploadedToCase as doc, j}
                                            <li class="mt-5 mb-1">
                                              <!-- <b>{doc.fileTitle}</b>&nbsp;
                                              <input
                                                class="is-size-6"
                                                style="border:none;"
                                                type="file"
                                                on:change={(event) => {
                                                  checkFileSizeAndType(j, documentsNotUploadedToCase, event);
                                                }}
                                                bind:files={documentsNotUploadedToCase[j].file} /> -->
                                              <!-- <input class="is-size-6" style="border:none;" type="file"
                                    bind:files={doc.file} value=""/> -->
                                              <!-- {#if doc.hint}
                                                <p class="help is-danger">
                                                  {doc.hint}
                                                </p>
                                              {/if} -->
                                              <form
                                                class="container is-centered"
                                                on:change|preventDefault={() => {
                                                  validateForm();
                                                  // hasErrorsIndex = length1 + (j + 1);
                                                  // checkFileSizeAndType(j, hasErrorsIndex, documentsNotUploadedToCase, event);

                                                  if (
                                                    !allDocsUploaded.includes(
                                                      selectedCase
                                                        .documentsUploaded[i]
                                                    )
                                                  ) {
                                                    allDocsUploaded.push(
                                                      selectedCase
                                                        .documentsUploaded[i]
                                                    );
                                                  }
                                                  errors =
                                                    checkFiles(allDocsUploaded);
                                                }}
                                              >
                                                <Formfield
                                                  type="file"
                                                  bind:value={documentsNotUploadedToCase[
                                                    j
                                                  ].file}
                                                  errors={errors &&
                                                  errors.length > 0
                                                    ? findErrors(
                                                        errors,
                                                        doc.fileTitle.toString()
                                                      )
                                                    : []}
                                                  label={doc.fileTitle}
                                                  hasTemplate={doc.templateFileURI !=
                                                    null}
                                                  templateFileURI={doc.templateFileURI}
                                                  on:deletefile={(event) =>
                                                    deleteFileFromList(
                                                      event,
                                                      allDocsUploaded
                                                    )}
                                                />
                                              </form>
                                              <!-- <Formfield
                                  isResubmission={false}
                                  obligatory={false}
                                  bind:value={documentsNotUploadedToCase[j].file}
                                  type="file"
                                  label={doc.fileTitle}
                                  hasTemplate={doc.templateFileURI != null}
                                  templateFileURI={doc.templateFileURI}
                                  on:deletefile={(event) => {deleteFileFromList(documentsNotUploadedToCase);}}
                                /> -->
                                              <!-- on:deletefile={deleteFileFromList} -->
                                            </li>
                                          {/each}
                                        {/if}

                                        {#if additionalDocuments && additionalDocuments.length > 0}
                                          {#each additionalDocuments as extradoc, k}
                                            <li class="mt-1 mb-1">
                                              <div class="level mb-0">
                                                <div
                                                  class="level-left field is-horizontal"
                                                >
                                                  <div
                                                    class="field-label is-normal"
                                                  >
                                                    <label class="label"
                                                      >Τίτλος</label
                                                    >
                                                  </div>
                                                  <div class="field-body">
                                                    <div class="field mt-0">
                                                      <p class="control">
                                                        <input
                                                          class="input"
                                                          bind:value={additionalDocuments[
                                                            k
                                                          ].fileTitle}
                                                        />
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="level-right" />
                                              </div>

                                              <form
                                                class="container is-centered"
                                                on:change|preventDefault={() => {
                                                  // hasErrorsIndex = length1 + length2 + (k + 1);
                                                  // checkFileSizeAndType(k, hasErrorsIndex, additionalDocuments, event);
                                                  // additionalDocuments = additionalDocuments;
                                                  // console.log(
                                                  //   "HAS ERRORS, INDEX, HINT, K",
                                                  //   hasErrors[hasErrorsIndex],
                                                  //   hasErrorsIndex,
                                                  //   additionalDocuments[k].hint,
                                                  //   k
                                                  // );
                                                  validateForm();
                                                  if (
                                                    !allDocsUploaded.includes(
                                                      additionalDocuments[k]
                                                    )
                                                  ) {
                                                    allDocsUploaded.push(
                                                      additionalDocuments[k]
                                                    );
                                                  }
                                                  errors =
                                                    checkFiles(allDocsUploaded);
                                                }}
                                              >
                                                <Formfield
                                                  type="file"
                                                  bind:value={additionalDocuments[
                                                    k
                                                  ].file}
                                                  errors={errors &&
                                                  errors.length > 0
                                                    ? findErrors(
                                                        errors,
                                                        extradoc.fileTitle.toString()
                                                      )
                                                    : []}
                                                  label={extradoc.fileTitle}
                                                  hasTemplate={extradoc.templateFileURI !=
                                                    null}
                                                  templateFileURI={extradoc.templateFileURI}
                                                  on:deletefile={(event) =>
                                                    deleteFileFromList(
                                                      event,
                                                      allDocsUploaded
                                                    )}
                                                />
                                              </form>

                                              <!-- <input
                                                class="is-size-6 mb-0"
                                                style="border:none;"
                                                type="file"
                                                on:change={(event) => {
                                                  checkFileSizeAndType(k, additionalDocuments, event);
                                                }}
                                                bind:files={additionalDocuments[k].file} /> -->

                                              <!-- <input class="is-size-6" style="border:none;" type="file" bind:files={extradoc.file} value="" /> -->

                                              <!-- {#if extradoc.hint}
                                                <p class="help is-danger">
                                                  {extradoc.hint}
                                                </p>
                                              {/if} -->
                                              <!-- <div class=" is-narrow my-2 { (additionalDocuments[k].file) ? (additionalDocuments[k].file.size > 2097152) ?
                                                                  'mx-2 notification is-danger is-light' :
                                                                  'mx-2 notification is-success is-light' : ''} ">
                                <div class="level is-mobile">
                                  <label class="file-label">
                                    <input class="file-input" type="file"
                                      bind:files={additionalDocuments[k].file} onclick="this.value=null" name="attachment">


                                    {#if additionalDocuments[k].file && additionalDocuments[k].file[0]}
                                    <span class="has-text-weight-bold">
                                      {additionalDocuments[k].file[0].name}
                                      ({sizeOf(additionalDocuments[k].file[0].size)})
                                    </span>
                                    <span class="icon is-small is-right my-1 mx-2">
                                      {#if additionalDocuments[k].file.size > 2097152 }
                                      <Fa icon={faExclamationTriangle} size="lg" aria-hidden="true" />
                                      {:else }
                                      <Fa icon={faCheck} size="lg" aria-hidden="true" />
                                      {/if}
                                    </span>
                                    {:else}
                                    <div class="level is-mobile">
                                      <span class="button" title="Επισύναψη αρχείου">

                                        <div class="icon">
                                          <PaperclipIcon size="1.5x" />
                                        </div>
                                      </span>
                                      <span class="file-label mx-2">
                                          Επιλογή αρχείου...
                                      </span>
                                    </div>
                                    {/if}
                                  </label>
                                  {#if additionalDocuments[k].file && additionalDocuments[k].file[0]}
                                  <a class="delete is-large" on:click="{()=>{ value=null; additionalDocuments[k].file = null;}}" />
                                  {/if}
                                </div>
                              </div> -->
                                              <!-- <Formfield
                                    isResubmission={false}
                                    obligatory={false}
                                    bind:value={additionalDocuments[k].file}
                                    type="file"
                                    label=""
                                    hasTemplate={false}
                                    templateFileURI={null}
                                    on:deletefile={(event) => {deleteFileFromList(additionalDocuments);}}
                                  /> -->
                                              <!-- on:deletefile={deleteFileFromList} -->
                                            </li>
                                          {/each}
                                        {/if}
                                        <button
                                          class="button mt-5 my-1"
                                          on:click={addExtraDocument}
                                        >
                                          <PlusIcon
                                            size="24"
                                          />&nbsp;&nbsp;Ανεβάστε επιπλέον
                                          αρχείο...
                                        </button>
                                      {/if}
                                    </ul>
                                  {/if}
                                  {#if ready && selectedCase.documentsUploaded.length == 0 && selectedCase.documentsProduced.length == 0}
                                    <em
                                      >Χωρίς επισυναπτόμενα ή απαντητικά αρχεία</em
                                    >
                                  {/if}
                                </section>

                                {#if selectedCase.documentsProduced && selectedCase.documentsProduced.length > 0}
                                  <section class="my-3 py-3">
                                    <h2 class="subtitle is-size-5 mb-4">
                                      Απαντητικά Αρχεία
                                    </h2>
                                    <ul>
                                      {#each selectedCase.documentsProduced as doc, i}
                                        <li
                                          class="mt-5 mb-1"
                                          data-tooltip="tooltip with long text"
                                        >
                                          <a on:click={downloadFile(doc)}>
                                            <span class="my-icon">
                                              <DownloadIcon size="1.5x" />
                                            </span>&nbsp;
                                            <b>{doc.fileTitle}</b>
                                          </a>
                                        </li>
                                      {/each}
                                    </ul>
                                  </section>
                                {/if}

                                <!-- </div> -->
                                <footer>
                                  {#if resubmissionMode && !successfulResubmission}
                                    <button
                                      class="button is-link"
                                      class:is-loading={processIsStarting}
                                      disabled={!isFormValid}
                                      on:click={() => {
                                        resubmitToCase(
                                          selectedCase.processInstanceId,
                                          selectedCase.protocolNumber
                                        );
                                      }}
                                    >
                                      Επανυποβολή
                                    </button>
                                  {/if}
                                </footer>
                              </div>
                            </td>
                          </tr>
                        {/if}
                      {/if}
                    {/each}
                  {:else}
                    <tr class="is-mobile has-text-centered">
                      <td
                        colspan="12"
                        class="is-size-5-mobile is-size-5 has-text-centered p-4"
                      >
                        Η αναζήτησή σας δεν επέστρεψε αποτελέσματα!
                      </td>
                    </tr>
                  {/if}
                </tbody>
              </table>
            {:else}
              Δεν έχετε καταχωρισμένες υποθέσεις
            {/if}
          {/if}
        {:else}
          <div class="is-size-5-mobile is-size-5 has-text-centered">
            <Spinner
              size="100"
              speed="750"
              color="rgba(0, 255, 0, 0.3)"
              thickness="5"
              gap="40"
            />
          </div>
        {/if}
      </div>
    </div>
  </div>
{:else}
  <div in:fade={{ delay: 1200 }} class="container pt-4 px-4 mt-6">
    <p class="is-size-4">
      Για να δείτε αυτή τη σελίδα χρειάζεται Σύνδεση με λογαριασμό TaxisNet.
    </p>
    <button
      class="button is-link  is-size-5-desktop is-size-6  mt-2"
      on:click={gotoClient}>Ξεκινήστε από την αρχή</button
    >
  </div>
{/if}

<style>
  .rounded-white {
    border-radius: 8px;
    /* background: rgba(255,255,255,0.8); */
    background: white;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 10px;
  }

  .margin-top-1 {
    margin-top: 1rem;
  }

  :global(.my-hoverable:hover) {
    cursor: pointer;
    background-color: #fafafa;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* color: #2563eb; */
  }

  svg {
    transition: transform 0.2s ease-in;
  }

  .svg-turned {
    transition: transform 0.2s ease-in;
    transform: rotate(0.25turn);
  }

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem;
    margin: 0 auto; /* or margin: 0 auto 0 auto */
    overflow: hidden;
  }
</style>
