<script>
  // import "@fontsource/roboto/100.css";
  // import "@fontsource/roboto/400.css";
  import { isActive, url, goto, params, ready } from "@sveltech/routify";
  import { logout, getCookie } from "../auth.js";
  import { fade, slide, fly } from "svelte/transition";
  import {
    CookiesApi,
    alphabetically,
    organization,
  } from "../utils/helpers.js";
  import { axiosInst, loggedIn, loggedTaxis, profile } from "../credentials.js";
  import {
    loggedFromClient,
    loggedFromClientName,
    highContrast,
    clientFooterName,
    clientGsisId,
    GSISClientName,
    clientTerms,
    clientPrivacy,
    clientA11Y,
    cookieConsent,
    fifteenMinuteMark,
    fiveMinuteMark,
    logging_out,
    showCategories,
    isSystemUp,
    selectedOrgDTO,
    selectedClient,
    loggedUserName,
    superClient,
    isUserInGroup,
    userCheckedIfInGroup,
    currentUserGroup,
    // presentationMode
  } from "../ui-store.js";
  import {
    organizations,
    clients,
    actions,
    currentCategories,
    selectedCategory,
  } from "../_clients.js";
  import Taxis from "../components/taxis-login.svelte";
  import Open1Logo from "../components/Open1Logo.svelte";
  import Fa from "svelte-fa";
  import {
    faArchway,
    faUniversalAccess,
    faExclamationTriangle,
    faQuestion,
  } from "@fortawesome/free-solid-svg-icons";
  import { onMount } from "svelte";
  // Import the toast function
  import bulmaToast from "bulma-toast";
  import axios from "axios";

  //local testing ONLY-------------------
  // $loggedIn = true;
  // $profile.id = 1;
  // $loggedTaxis = true;
  // $profile.fathername = "ΕΜΜΑΝΟΥΗΛ";
  // $profile.mothername = "ΑΝΝΑ";
  // $profile.birthyear = "1950";
  // $profile.firstname = "ΕΥΤΥΧΙΑ";
  // $profile.lastname = "ΒΑΒΟΥΛΑ";
  // $superClient = "1";
  $: console.log("presentationMode", localStorage.getItem("presentationMode"));

  let isLoading = true;
  // let selectedOrgDTO = null;

  //get values from local storage
  GSISClientName.useLocalStorage();
  clientGsisId.useLocalStorage();
  cookieConsent.useLocalStorage();
  loggedFromClient.useLocalStorage();
  loggedFromClientName.useLocalStorage();

  //preload organizations
  const loadOrgs = async () => {
    calledLoadOrgs = true;
    return new Promise(async (resolve, reject) => {
      $axiosInst
        .get("registeredOrganization/")
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            isLoading = false;
            reject();
          }
          // Examine the text in the response
          else {
            console.log("loadOrgs response: ", response.data.data);
            $organizations = response.data.data.sort(function (a, b) {
              return alphabetically(a.title, b.title);
            });
            isLoading = false;
            resolve();
          }
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
          isLoading = false;
          reject();
        });
    });
  };

  $: console.log("currentCategories: ", $currentCategories);

  // $showCategories = false;

  const toggleCategories = () => {
    console.log("toggle");
    $showCategories = !$showCategories;
  };

  //to be used in Avatar
  // let loggedUserName = null;
  let initialsReady = false;
  $: if ($loggedIn && $profile && $profile.firstname && $profile.lastname) {
    $loggedUserName = JSON.stringify($profile.firstname).concat(
      " ",
      JSON.stringify($profile.lastname)
    );
    initialsReady = true;
  }
  let dropActive = false;
  const toggleMenu = () => {
    dropActive = !dropActive;
  };

  //get values from local storage

  let loading = true;
  // let selectedClient;
  let name;
  $: console.log("client footer name :", $clientFooterName);
  $: if ($selectedClient)
    name = $selectedClient.name ? $selectedClient.name : $selectedClient.title;

  $: console.log("Layout says we're logged in: ", $loggedIn);
  $: console.log("Layout says we're logged in with Taxis: ", $loggedTaxis);
  $: console.log("Layout says we're this person: ", $profile);
  $: console.log("Layout says this is the username: ", $loggedUserName);
  $: console.log(
    "Layout says this is the clientFooterName: ",
    $clientFooterName
  );
  $: console.log("Layout says this is the clientGsisId : ", $clientGsisId);
  $: console.log("Layout says this is the GSISClientId: ", $GSISClientName);
  $: console.log("Layout says this is the superClient: ", $superClient);

  bulmaToast.setDoc(window.document);
  let message;
  let orgpath = "false";

  $: if ($params.id) {
    orgpath = "true";
    console.log("$params.id: ", $params.id);
  }

  $: if (message)
    bulmaToast.toast({
      message: message,
      position: "top-right",
      dismissible: false,
      animate: { in: "fadeIn", out: "fadeOut" },
    });

  //toggle high contrast flag for accessibility
  const toggleContrast = () => {
    $highContrast = !$highContrast;
    if ($highContrast)
      message = "<b>Λειτουργία Υψηλής Αντίθεσης: Ενεργοποιημένη</b>";
    else message = "<b>Λειτουργία Υψηλής Αντίθεσης: Απενεργοποιημένη</b>";
  };

  let cookieinfoshow = true;

  const toggleCookieInfo = () => {
    $cookieConsent = true;
  };

  let isOpen = false;
  let sideMenuOpen = false;

  const findUserId = () => {
    let entityTaxId = $profile.taxId;
    return new Promise(async (resolve, reject) => {
      try {
        await $axiosInst
          .get("externalEntities/byTax", {
            params: { entityTaxId: entityTaxId },
          })
          .then(function (response) {
            if (response.status === 204) {
              console.log("No content found. Status Code: " + response.status);
              reject();
            }
            // Examine the text in the response
            else if (response.status === 200) {
              console.log(response.data);
              $profile.id = response.data;
              console.log("SUCCESSFULLY GOT USER", $profile.id);
              resolve();
            } else {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );

              reject();
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  let authenticate = () => {
    console.log("Running authenticate...");
    // look for 'gsisAuth' (if we're logged in with Taxis, that is)
    let gsisAuth = getCookie("gsisAuth");
    if (gsisAuth && gsisAuth.accessToken) {
      console.log("Got the cookie!");
      //get user info

      console.log("Getting user info....");

      $profile = gsisAuth.info;
      $loggedTaxis = true;
      $loggedIn = true;
      findUserId().then($ready);
    }
  };

  const logEntranceLogout = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await $axiosInst
          .get("externalEntities/logEntranceLogout")
          .then(function (response) {
            if (response.status === 200) {
              //If status OK, see if we have errors
              console.log(res.data);
              if (response.data.errors && response.data.errors.length > 0) {
                console.log("An error occured" + ": " + response.data.errors);
                //logout anyway
                resolve();
              } else {
                //no errors
                console.log("log event success");
                //no errors, remove sessionId
                resolve();
              }
            } else {
              console.log("something went wrong");
              //logout anyway
              resolve();
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        resolve();
      }
    });
  };

  const logoutnow = () => {
    let answer = confirm("Είστε σίγουροι πώς θέλετε να αποσυνδεθείτε;");
    if (answer) {
      $logging_out = true;
      $loggedIn = false;
      $loggedTaxis = false;
      let orgUuid = $loggedFromClient;
      $profile = null;
      localStorage.removeItem("clientGsisId");
      localStorage.removeItem("GSISClientName");
      localStorage.removeItem("loggedFromClient");
      localStorage.removeItem("loggedFromClientName");
      CookiesApi.remove("gsisAuth", { path: "/" });
      CookiesApi.remove("sessionCookie", { path: "/" });
      //clientGsisId.useLocalStorage();
      //log the event first
      logEntranceLogout()
        .then(() => {
          return logout(orgUuid, null);
          //history.pushState({}, null, 'http://localhost:9999/');
          // $goto("/");
        })
        .then(() => {
          if (orgUuid) {
            $goto("/client/:id/welcome", { id: orgUuid });
          }
        });
    }
  };

  // FIND CLIENT/ ORGANIZATION DATA-----------------------------------------------------------------------
  let isLoadingClient = true;
  let error;

  function findClient() {
    return new Promise(async (resolve, reject) => {
      let filter1 = $clients.filter((client) => client.id === $params.id);
      let filter2 = $organizations.filter(
        (client) => client.id.toString() === $params.id
      );
      console.log("filter1: ", filter1);
      console.log("filter2: ", filter2);
      if (filter1.length > 0) {
        console.log("found client in mock data");
        $selectedClient = filter1[0];
        // isMock = true; //the selected client is from mock clients
        isLoadingClient = false;

        console.log($selectedClient.actions);
      } else if (filter2.length > 0) {
        console.log("found client in registered organizations");
        $selectedClient = filter2[0];

        // isMock = false; //the selected client is from registered organizations in local db

        //api call to get registered organization data
        let promise = $axiosInst
          .get("registeredOrganization/" + $selectedClient.id)
          .then(function (response) {
            console.log("API call response: ", response);
            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              isLoadingClient = false;
              reject();
            } else {
              //If status OK, see if we have errors
              if (response.data.errors.length > 0) {
                console.log("Unathorized User" + ": " + data.errors);
                // add more actions...
                isLoadingClient = false;
                reject();
              } else {
                //no errors, move to next and final step
                $selectedOrgDTO = response.data.data;
                //set values in local storage
                // localStorage.setItem(
                //     "clientTerms",
                //     JSON.stringify($selectedOrgDTO.termsMessage)
                //   );
                $clientTerms = $selectedOrgDTO.termsMessage;
                // localStorage.setItem(
                //   "clientPrivacy",
                //   JSON.stringify($selectedOrgDTO.gdprMessage)
                // );
                $clientPrivacy = $selectedOrgDTO.gdprMessage;
                // localStorage.setItem(
                //   "clientFooterName",
                //   JSON.stringify($selectedOrgDTO.title)
                // );
                $clientFooterName = $selectedOrgDTO.title;
                //  localStorage.setItem(
                //   "clientGsisId",
                //   JSON.stringify($selectedOrgDTO.gsisClientId)
                // );
                $clientGsisId = $selectedOrgDTO.gsisClientId;
                $clientA11Y = $selectedOrgDTO.accessibilityMessage;

                // //check if there is a custom url, and set superClient store
                // if ( $selectedOrgDTO.gsisRedirectUrl && $selectedOrgDTO.gsisRedirectUrl.split("//")[1].split("-")[0]!=="entrance") {
                //   // if a super client is not set, change it
                //   if (!$superClient) $superClient = $selectedClient.id.toString();
                //   //if a super client has indeed been already set, the check will redirect us to the superclient landing page
                // }
                isLoadingClient = false;
                resolve();
              }
            }
          })
          .catch(function (err) {
            console.log("Fetch Error :-S", err);
            reject(err);
          });
        //end api call, continue in
      }
      // else throw new Error("an error occured"); //disabled because of routing trouble after the error is thrown
    });
  }

  const clickOutside = (node) => {
    //checks if what was clicked was the element, or not. Used to toggle focus from dropdown menu.
    //we need to add the mobile ui case where clicking the burger menu results in firing this event
    const handleClick = (event) => {
      //console.log(event.srcElement.id);
      if (
        node &&
        !node.contains(event.target) &&
        !event.defaultPrevented &&
        !event.srcElement.classList.contains("burger-mobile-menu")
      ) {
        //console.log("clickedoutside!");
        node.dispatchEvent(new CustomEvent("clickoutside", node));
      }
    };

    document.addEventListener("click", handleClick, true);

    return {
      destroy() {
        document.removeEventListener("click", handleClick, true);
      },
    };
  };

  const handleClickOutside = (event) => {
    //if the click happened outside the node, and it was focused, make it lose its focus
    //console.log(event);
    if (isOpen) isOpen = false;
  };

  const checkForSuperClient = () => {
    // console.log("checking superClient");
    // console.log("checking superClient:", window.location.href.split(".open1.eu")[0], process.env.BASE_URL_FRONT.split(".open1.eu")[0]);
    //we need to check the current domain name, against all the clients redirect url
    if (
      window.location.href.split(".open1.eu")[0] ===
      process.env.BASE_URL_FRONT.split(".open1.eu")[0]
    ) {
      //there is no superClient
      $superClient = "";
      // console.log("checking superClient: noClient");
    } else {
      $organizations.forEach((org) => {
        if (org.gsisRedirectUrl) {
          if (
            window.location.href.split(".open1.eu")[0] ===
            org.gsisRedirectUrl.split(".open1.eu")[0]
          ) {
            $superClient = org.id;
            // console.log("checking superClient: found Client ", $superClient);
          }
        }
      });
    }
  };

  const checkIfURLisCustom = () => {
    if (
      ($selectedOrgDTO.gsisRedirectUrl &&
        !$selectedOrgDTO.gsisRedirectUrl
          .split("//")[1]
          .split("-")[0]
          .includes("entrance") &&
        window.location.hostname.includes(
          $selectedOrgDTO.gsisRedirectUrl.split("//")[1].split("-")[0]
        )) ||
      window.location.hostname.includes("localhost") ||
      window.location.pathname.includes("action")
    ) {
      console.log("IS CUSTOM");
      return true;
    } else if (
      $selectedOrgDTO.gsisRedirectUrl &&
      !$selectedOrgDTO.gsisRedirectUrl
        .split("//")[1]
        .split("-")[0]
        .includes("entrance") &&
      !window.location.hostname.includes(
        $selectedOrgDTO.gsisRedirectUrl.split("//")[1].split("-")[0]
      )
    ) {
      console.log("NOT CUSTOM");
      return false;
    } else {
      console.log("LAST ELSE - IS CUSTOM");
      return true;
    }
  };

  let calledLoadOrgs = false;
  let checkedURLifCustom = false;

  //find where user has logged from
  // $:console.log($organizations, $loggedFromClient, $isActive("/user/:id"))
  // $:if( $organizations.length>0 && $loggedFromClient && $isActive("/user/:id")) {
  //   let target  = $organizations.filter((org) => org.id.toString() === $loggedFromClient);
  //   console.log("SHHH: ", target);
  //   if (target.length && target[0].title) localStorage.setItem('loggedFromClientName', JSON.stringify(target[0].title));
  //   loggedFromClientName.useLocalStorage();
  // }
  //always check for superClient
  $: if (
    $organizations.length > 0 &&
    !localStorage.getItem("presentationMode")
  ) {
    checkForSuperClient();
  }
  $: if (
    calledLoadOrgs == false &&
    ($isActive("/client/:id") ||
      $isActive("/fairuse/:id") ||
      $isActive("/userguide/:id"))
  ) {
    loadOrgs().then(() => {
      if (
        $isActive("/client/:id") ||
        $isActive("/fairuse/:id") ||
        ($isActive("/userguide/:id") && $params.id != "general")
      ) {
        orgpath = "true";

        findClient().then(() => {
          if ($superClient && !localStorage.getItem("presentationMode")) {
            //compare super client and current client id
            if ($superClient.toString() !== $params.id.toString()) {
              //if they're different, send user back to superclient
              window.location.href =
                window.location.href.split("/client")[0] +
                "/client/" +
                $superClient +
                "/welcome";
            }
          }
          //there also needs to be a logic to redirect users to custom urls if they paste the "central" client url insto their browser
          //meaning they type "entrance.open1.eu/client/1/welcome" instead of eg. "client1-entrance.open1.eu/client/1/welcome"
          else if (!$superClient || localStorage.getItem("presentationMode")) {
            //if there is no superClient
            //also check if the client has a custom url, in that case, redirect there
            //but not if they are in an action url
            checkIfURLisCustom();
            // if (!checkIfURLisCustom()) window.location.href = $selectedOrgDTO.gsisRedirectUrl.split("/gsis")[0] + window.location.pathname;
          }
        });
      } else {
        $selectedOrgDTO = null;
      }
      authenticate();
    });
  } else if (calledLoadOrgs == true) {
    calledLoadOrgs = false;
  } else {
    authenticate();
  }
</script>

<svelte:head>
  <title>
    {($isActive("/client/:id") ||
      $isActive("/fairuse/:id") ||
      $isActive("/userguide/:id")) &&
    $clientFooterName
      ? $clientFooterName
      : "Open1 |  Entrance"}
  </title>
</svelte:head>

<!-- wrapper is used to ensure the footer stays at the bootom at all times -->
<!-- {!$isSystemUp ? 'extended-navbar' : ''}   -->
<div class="wrapper">
  <nav
    class="navbar bg-gov-primary bottom-border-gov-secondary has-shadow 
      {orgpath === 'true' ? '' : ''}"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container app-container">
      <div class="navbar-brand" transition:fade={{ delay: 200, duration: 300 }}>
        <div class="navbar-item color-white">
          <div class="is-size-4 is-size-6-mobile level">
            <p class="level-item">
              {#if ($isActive("/client/:id") || $isActive("/fairuse/:id") || $isActive("/userguide/:id")) && $clientFooterName}
                {#if $selectedOrgDTO.officialURL}
                  <a
                    class="ghost-link"
                    href={$url($selectedOrgDTO.officialURL)}
                    target="_blank"
                    >{$clientFooterName}
                  </a>
                {:else}
                  {$clientFooterName}
                {/if}
              {:else if $isActive("/user/:id/welcome")}
                Οι υποθέσεις μου
              {:else}
                <Open1Logo name="Entrance" />
              {/if}
            </p>
          </div>
        </div>
        {#if (window.location.href.indexOf("shared") === -1)}
          <a
            role="button"
            class="navbar-burger has-text-white burger-mobile-menu {isOpen
              ? 'is-active'
              : ''}"
            aria-label="menu"
            on:click={() => {
              isOpen = !isOpen;
              sideMenuOpen = !sideMenuOpen;
            }}
            aria-expanded="false"
            data-target="navbarBasic"
          >
            <span class="burger-mobile-menu" aria-hidden="true" />
            <span class="burger-mobile-menu" aria-hidden="true" />
            <span class="burger-mobile-menu" aria-hidden="true" />
          </a>
        {/if}
      </div>

      {#if (window.location.href.indexOf("shared") === -1)}
        <div
          id="navbarBasic"
          class="navbar-menu {isOpen ? 'is-active' : ''}"
          use:clickOutside
          on:clickoutside={handleClickOutside}
        >
          <div class="navbar-start" />

          <div class="navbar-end">
            <!-- <div class="navbar-item" /> -->
            <!-- <div class="navbar-item is-hidden-touch">
                    <a
                      class="is-link bold"
                      href={$url("/userguide")}
                      >Οδηγίες Χρήσης</a
                    >
                  </div> -->

            {#if isLoadingClient == false && !$loggedTaxis && $isActive("/client/:id/welcome")}
              <div class="navbar-item is-hidden-touch">
                <Taxis
                  label="Σύνδεση με κωδικούς TaxisNet"
                  state={$selectedClient.id.toString().concat("_").concat(0)}
                />
              </div>
            {/if}

            {#if $loggedTaxis}
              {#if !$isActive("/user/:id/welcome")}
                <div class="navbar-item is-hidden-touch">
                  <a
                    class="is-info has-text-weight-bold button"
                    href={$url("/user/:id/welcome", { id: $profile.id })}
                    >
                      Οι υποθέσεις μου
                    </a
                  >
                </div>
              {/if}
            {/if}
            {#if $loggedTaxis && initialsReady}
              <nav
                class="navbar-item has-text-white has-dropdown {isOpen
                  ? 'is-active'
                  : ''}"
                on:click={() => (isOpen = !isOpen)}
              >
                <a class="navbar-link is-hidden-mobile is-hidden-touch">
                  <span title={$loggedUserName.replaceAll('"', "")}>
                    <avatar-component
                      width="2.5"
                      styletype="new"
                      name={$loggedUserName.replaceAll('"', "")}
                    />
                  </span>
                </a>
                <!-- </div> -->

                <div
                  class="navbar-dropdown is-right"
                  id="dropdown-menu2"
                  role="menu"
                >
                  <!-- <div class="dropdown-content"> -->
                  <a
                    class="is-hidden-desktop"
                    on:click={$goto("/user/:id/welcome", { id: $profile.id })}
                  >
                    <div class="navbar-item has-text-centered is-size-6">
                      Οι υποθέσεις μου
                    </div>
                  </a>
                  <!-- <a class="is-hidden-desktop"
                        on:click={$goto("/userguide")}
                      >
                        <div class="navbar-item has-text-centered is-size-6">
                          Οδηγίες Χρήσης
                        </div>
                      </a> -->
                  <a on:click={logoutnow}>
                    <div class="navbar-item has-text-centered is-size-6">
                      Αποσύνδεση
                    </div>
                  </a>
                  <!-- <hr class="dropdown-divider" /> -->
                  <!-- </div> -->
                </div>
              </nav>
            {:else if isLoadingClient == false && !$loggedTaxis && $isActive("/client/:id/welcome")}
              <nav
                class="navbar-item has-text-white has-dropdown {isOpen
                  ? 'is-active'
                  : ''}"
                on:click={() => (isOpen = !isOpen)}
              >
                <div class="navbar-item is-hidden-desktop">
                  <Taxis
                    label="Σύνδεση με κωδικούς TaxisNet"
                    state={$selectedClient.id.toString().concat("_").concat(0)}
                  />
                </div>
              </nav>
            {/if}
          </div>
        </div>
      {/if}
    </div>
    {#if !$isSystemUp && $isActive("/client/:id/action")}
      <div
        class="fixed-notification-top notification is-danger is-light is-size-5 is-size-6-mobile mb-5"
      >
        <div class="container">
          <div class="p-5 is-flex is-align-items-top">
            <div class="pr-2">
              <Fa icon={faExclamationTriangle} size="lg" aria-hidden="true" />
            </div>


            Η δυνατότητα υποβολής αιτήσεων δεν είναι διάθεσιμη προσωρινά. 
            <br/>
            Θα μπορέσετε να υποβάλετε την αίτηση εφόσον εξαφανιστεί αυτό το μήνυμα.
          </div>
        </div>
        <!-- Θα επιστρέψει σύντομα στην κανονική λειτουργία 
του.
        -->
      </div>
    {/if}

     <!-- ------------------------TAXIS CONNECTIVITY ERROR MESSAGE -->

     <!-- <div
     class="fixed-notification-margined notification is-danger is-light is-size-5 is-size-6-mobile mb-5 mt-5"
   >
     <div class="container">
       <div class="p-2 is-flex is-align-items-top">
         <div class="pr-2">
           <Fa icon={faExclamationTriangle} size="lg" aria-hidden="true" />
         </div>
         Λόγω αναβάθμισης των παραγωγικών server Υπηρεσιών Αυθεντικοποίησης της ΓΓΠΣΨΔ ενδεχομένως οι υπηρεσίες να μην είναι διάθεσιμες προσωρινά. 
         <br/>
         Σας ευχαριστούμε για την κατανόηση.
       </div>
     </div>
   </div> -->
 
    <!--  
    {#if !$isUserInGroup && $isActive("/client/:id/action")}
      <div
        class="fixed-notification-top notification is-danger is-light is-size-5 is-size-6-mobile mb-5"
      >
        <div class="container">
          <div class="is-flex is-align-items-top">
            <div class="pr-2">
              <Fa icon={faExclamationTriangle} size="lg" aria-hidden="true" />
            </div>
              Δεν έχετε την απαραίτητη πιστοποίηση για να υποβάλετε αυτήν την αίτηση. Aπαιτείται η καταχώρησή σας στην ομάδα χρηστών "{$currentUserGroup.groupName}"
          </div>
        </div>

      </div>
    {/if}
    -->
    <!--  
    {#if $currentUserGroup && $currentUserGroup.groupName && !$userCheckedIfInGroup && $isActive("/client/:id/action")}
      <div
        class="fixed-notification-top notification is-primary is-light is-size-5 is-size-6-mobile mb-5"
      >
        <div class="container">
          <div class="is-flex is-align-items-top">
              Για να υποβάλετε την παρούσα αίτηση, θα πρέπει να έχετε πιστοποιηθεί ως μέλος της ομάδας: {$currentUserGroup.groupName}.
          </div>
        </div>
      </div>
    {/if}
  -->
  </nav>
  {#if $isActive("/client/:id")}
    <!-- <header id="toggles" class="client-header my-4 level is-mobile is-hidden-desktop is-hidden-tablet">
      <div class="level-item">
        <button class="button is-info is-small" on:click={toggleCategories}>Κατηγορίες Υπηρεσιών</button>
      </div>
      <div class="level-item">
        <button class="button is-info is-small">Σύνδεση μέσω Taxisnet</button>
      </div>           
    </header> -->
    <!-- {#if $showCategories}
      <aside class="is-fullheight app-sidebar">      

        <ul class="menu-list">
          {#each $currentCategories as cat, i}
            <li class="is-size-6">
               <a
                on:click={() => {
                  $selectedCategory = i;
                  toggleCategories();
                }}
                class={i === $selectedCategory
                  ? "is-active has-text-black has-text-weight-semibold has-background-light"
                  : ""}>{cat}</a
              >
            </li>
          {/each}
        </ul>
      </aside>
      <div class="aside-background is-fullheight is-fullwidth" on:click={toggleCategories}></div>
     
    {/if} -->
  {/if}
  {#if $loggedTaxis && $isActive("/user/:id")}
    <div
      class="container notification level is-link is-light my-2 is-hidden-mobile"
    >
      <div lass="level-left">
        {#if $loggedFromClient && $loggedFromClientName}
          <div class="level-item">
            Συνδεθήκατε μέσω του Φορέα: &nbsp; <a
              id="clientName"
              href={$url("/client/:id/welcome", { id: $loggedFromClient })}
              >{$loggedFromClientName}</a
            >
          </div>
        {/if}
      </div>
      <div lass="level-right">
        <div class="level">
          <!-- <div class="level-item mx-1">
          <button
            on:click={() => {
              $goto();
            }}
            class="button is-link has-background-link-dark">{$clientFooterName}</button
          >
        </div> -->
          <div class="level-item mx-1">
            <button on:click={logoutnow} class="button">Αποσύνδεση</button>
          </div>
        </div>
      </div>
    </div>
  {/if}

  <!-- <div class="columns is-centered"><div class="column is-10"> -->
  <slot />
  <!-- push: has the same height as the footer -->
  <div class="push" />
</div>
<!-- </div></div> -->
<!-- class:my-fixed={$isActive("/client/:id")} -->

{#if (window.location.href.indexOf("shared") === -1)}
<div class="has-background-light top-border-gov-primary">
  <div class="py-5">
    <div class="level">
      <!-- to be continued: -->
      <!-- <div class="level-item">
        <span class="lang-item selected-lang-item">Ελληνικά</span> &nbsp;
        <span class="lang-item">English</span>
      </div> -->
      {#if $isActive("/client/:id") || $isActive("/fairuse/:id") || $isActive("/userguide/:id")}
        <a
          class="button is-ghost has-text-black level-item"
          title="Οδηγίες Χρήσης"
          href={$url("/userguide/:id", { id: $params.id })}
          target="_blank"
        >
          <span class="has-text-grey">
            <Fa icon={faQuestion} size="1x" />
          </span>
          &nbsp; Οδηγίες
        </a>
        <p class="has-text-grey is-hidden-mobile">·</p>
      {:else if $superClient}
        <a
          class="button is-ghost has-text-black level-item"
          title="Οδηγίες Χρήσης"
          href={$url("/userguide/:id", { id: $superClient })}
          target="_blank"
        >
          <span class="has-text-grey">
            <Fa icon={faQuestion} size="1x" />
          </span>
          &nbsp; Οδηγίες
        </a>
        <p class="has-text-grey is-hidden-mobile">·</p>
      {:else if $loggedFromClient}
        <a
          class="button is-ghost has-text-black level-item"
          title="Οδηγίες Χρήσης"
          href={$url("/userguide/:id", { id: $loggedFromClient })}
          target="_blank"
        >
          <span class="has-text-grey">
            <Fa icon={faQuestion} size="1x" />
          </span>
          &nbsp; Οδηγίες
        </a>
        <p class="has-text-grey is-hidden-mobile">·</p>
      {:else}
        <a
          class="button is-ghost has-text-black level-item"
          title="Οδηγίες Χρήσης"
          href={$url("/userguide/general")}
          target="_blank"
        >
          <span class="has-text-grey">
            <Fa icon={faQuestion} size="1x" />
          </span>
          &nbsp; Οδηγίες
        </a>
        <p class="has-text-grey is-hidden-mobile">·</p>
      {/if}

      {#if ($isActive("/client/:id") || $isActive("/fairuse/:id") || $isActive("/userguide/:id")) && !($params.id === "general")}
        <a
          class="button is-ghost has-text-black level-item"
          href={$url("/client/:id/privacypolicy", { id: $params.id })}
          target="_blank">Απόρρητο</a
        >
        <p class="has-text-grey is-hidden-mobile">·</p>
        <a
          class="button is-ghost has-text-black level-item"
          href={$url("/client/:id/termsofservice", { id: $params.id })}
          target="_blank">Όροι</a
        >
        <p class="has-text-grey is-hidden-mobile">·</p>
        <a
          class="button is-ghost has-text-black level-item"
          href={$url("/client/:id/accessibility-statement", { id: $params.id })}
          target="_blank">Δήλωση Προσβασιμότητας</a
        >
        <p class="has-text-grey is-hidden-mobile">·</p>
      {/if}

      {#if $isActive("/client/:id") || $isActive("/fairuse/:id") || $isActive("/userguide/:id")}
        <a
          class="button is-ghost has-text-black level-item"
          href={$url("/fairuse/:id", { id: $params.id })}
          target="_blank">Ορθή χρήση</a
        >
      {:else if $superClient}
        <a
          class="button is-ghost has-text-black level-item"
          href={$url("/fairuse/:id", { id: $superClient })}
          target="_blank">Ορθή χρήση</a
        >
      {:else if $loggedFromClient}
        <a
          class="button is-ghost has-text-black level-item"
          href={$url("/fairuse/:id", { id: $loggedFromClient })}
          target="_blank">Ορθή χρήση</a
        >
      {:else}
        <a
          class="button is-ghost has-text-black level-item"
          href={$url("/fairuse/general")}
          target="_blank">Ορθή χρήση</a
        >
      {/if}

      <p class="has-text-grey is-hidden-mobile">·</p>
      <a
        class="button is-ghost has-text-black level-item"
        href={$url("/cookies")}
        target="_blank">Cookies</a
      >
      <p class="has-text-grey is-hidden-mobile">·</p>

      {#if $isActive("/client/:id")}
        <div class="level-item" />
        <p class="level-item has-text-grey" style="font-size: 13px;">
          <!-- on:click={()=> {
        $goto("/client/:id/welcome", { id: $params.id });
        }} -->
          {#if $selectedOrgDTO.officialURL}
            <a
              class="ghost-link level-item"
              href={$url($selectedOrgDTO.officialURL)}
              target="_blank"
              >{$clientFooterName}
            </a>
          {:else}
            {$clientFooterName}
          {/if}
          &nbsp; ›&nbsp; <Open1Logo name="Entrance" />
        </p>
      {/if}
    </div>
  </div>
</div>
{/if}

<!-- <button role="button" on:click={toggleContrast} class="button fixed align-middle">
  <Fa class="icon" icon={faUniversalAccess} size="6x" />
</button> -->

<!--  COOKIE CONSENT ////////////////////////////// -->
<div
  class="cookie-consent is-size-6 is-size-8-mobile"
  style="z-index: 2;"
  hidden={$cookieConsent}
>
  <p>Αυτός ο ιστότοπος χρησιμοποιεί <strong>cookies</strong>.</p>
  <div class="level mt-4">
    <div class="level-left">
      <p>
        <a target="_blank" href={$url("/cookies")}>Πολιτική cookies</a>
      </p>
    </div>
    <div class="level-right">
      <button on:click={toggleCookieInfo} class="button is-outlined is-link"
        >Το κατάλαβα</button
      >
    </div>
  </div>
</div>

<!-- /////// COOKIE CONSENT ////////////////////////////// -->

<!--  15 or 5  MINUTES MARK ////////////////////////////// -->
<div
  class="fixed-notification-bottom-right notification is-danger is-light is-size-6 is-size-6-mobile is-hidden-mobile"
  hidden={!($fifteenMinuteMark || $fiveMinuteMark)}
>
  <!-- <button on:click={toggleCookieInfo} class="delete"></button> -->
  <section class="my-2">
    <p>
      Η συνεδρία σας λήγει σε λίγοτερο από 15 λεπτά, παρακαλώ ολοκληρώστε τις
      εργασίες σας!
    </p>
  </section>
  <section>
    <div class="level">
      <div class="level-right">
        <button
          on:click={() => {
            $fifteenMinuteMark = false;
            $fiveMinuteMark = false;
          }}
          class="button is-outlined
          is-danger">ΟΚ</button
        >
      </div>
    </div>
  </section>
</div>

<!-- /////// or 5 MINUTES MARK ////////////////////////////// -->
<style>
  :global(body, html) {
    height: 100%;
    font-family: "Roboto";
  }

  :global(input:not([type="radio"]), select) {
    width: 100%;
  }

  :global(.my-icon) {
    display: inline-block;
    vertical-align: middle;
  }

  .push {
    height: 120px;
  }

  .wrapper {
    min-height: 100%;
    margin-bottom: -80px;
    /* height of footer */
  }


  .my-fixed {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .icon {
    position: relative;
  }

  .fixed {
    right: 0;
    bottom: 0;
    margin: 3rem;
    position: fixed;
    display: flex;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    line-height: 3rem;
    justify-content: center;
    align-items: center;
  }

  .fixed-notification-margined {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    transform: translateY(0);

  }
</style>
