<script>
  import { _, addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
  import { url } from "@sveltech/routify";
  import { clients } from "../_clients.js";
  import AutoComplete from "simple-svelte-autocomplete";
  import Carousel from "@beyonk/svelte-carousel";
  import Fa from "svelte-fa";
  import {
    SmileIcon,
    UserIcon,
    MailIcon,
    FileIcon,
  } from "svelte-feather-icons";

  import EntranceImage from "../components/EntranceImage.svelte";

  //high  contrast flag for accessibility, set by layout component
  import { highContrast } from "../ui-store.js";

  //$:console.log($highContrast);
</script>

<div class="container app-container">
  <div class="columns p-5 is-vcentered">
    <div class="column">
      <div
        class="mt-2 is-size-1-desktop is-size-3-tablet is-size-4-mobile has-text-link has-text-weight-bold"
      >
        Entrance
      </div>
      <div
        class="mt-2 is-size-1-desktop is-size-3-tablet is-size-4-mobile has-text-weight-medium"
      >
        {$_("welcome.subtitle", {
          default: "Η πύλη στις δημόσιες υπηρεσίες",
        })}
      </div>

      <div class="mt-3 is-size-5-desktop is-size-6-mobile">
        Τώρα πιο εύκολα, με άμεση ενημέρωση για την εξέλιξη της υπόθεσής σας.
      </div>

      <div class="is-flex mt-5 is-justify-content-space-between">
        <div class="if-flex is-hidden-desktop is-hidden-tablet">&nbsp;</div>
        <a
          role="button"
          class="is-size-5-desktop is-size-6-tablet is-size-6-mobile button is-link bg-gov-primary color-white"
          href={$url("/client")}
        >
          Ξεκινήστε εδώ
        </a>
        <div class="if-flex is-hidden-desktop is-hidden-tablet">&nbsp;</div>
        <!-- div below only exists to push the text divs up a bit -->
        <div class="hero my-6 is-hidden-mobile" />
      </div>
    </div>

    <div class="column is-two-thirds position-relative">
      <a href={$url("/client")} tabindex="-1">
        <EntranceImage />
      </a>

      <!-- <div style="display:flex; justify:center; height:100px; z-index:-100; overflow:hidden">
        {#each Array(3) as _, i}
          <div
            class="{i % 6 == 0
              ? 'rotate-right'
              : i % 6 == 1
              ? 'rotate-left-2'
              : i % 6 == 2
              ? 'rotate-right-2'
              : 'rotate-left'} p-5 {i % 2 == 0 ? 'has-text-link' : 'has-text-link'}"
          >
            {#if i % 5 == 0}
              <SmileIcon size={i % 3 == 0 ? "64" : "48"} />
            {:else if i % 5 == 1}
              <FileIcon size={i % 3 == 0 ? "64" : "48"} />
            {:else if i % 5 == 2}
              <MailIcon size={i % 3 == 0 ? "64" : "48"} />
            {:else}
              <FileIcon size={i % 3 == 0 ? "64" : "48"} />
            {/if}
          </div>
        {/each}
      </div> -->
    </div>
  </div>
</div>

<style>
  .rotate-left {
    transform: rotate(10deg);
    display: inline-block;
  }

  .rotate-right {
    transform: rotate(-10deg);
    display: inline-block;
  }

  .rotate-left-2 {
    transform: rotate(25deg);
    display: inline-block;
  }

  .rotate-right-2 {
    transform: rotate(-20deg);
    display: inline-block;
  }
</style>
