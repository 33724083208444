<script>
import {axiosAuth, commons, ready} from '../auth.js';
import { createEventDispatcher, onMount } from 'svelte';
import Select from 'svelte-select';
import Spinner from 'svelte-spinner';

export let orgTemplates;

let currentTemplates;
let allTemplates;
let loading = true;

$:console.log(allTemplates);

const getTemplateTitle = (templateId) => {

    let filter = allTemplates.filter(t => t.templateId === templateId);

    let title = "not found";

    if (filter.length) title = filter[0].title;
    else title = filter;

    return title;

}

const getAllTemplates = async () => {
     try {
         const res = await $axiosAuth.get('/api/'+$commons.orgId+'/communication/template?templateType=MESSAGE_TEMPLATE');
         console.log(res.data);
         console.log(res.status);
         if(res.status===200){
             if(!res.data.errors.length>0){
               
                allTemplates = res.data.data;
                loading = false;
                console.log("successful call");
            
               }
               else {
                loading = false;
                console.log("An error occured");
                console.log(res.errorMessage);
                console.log(res.errors);
               }
            
         }
         else {
             alert('something went wrong');        
            loading = false;
            }
         
         } catch (e) {
         console.log(e);
    }
}

onMount( () => {
    getAllTemplates();
});

</script>

<style>
.panel-block {
    flex-direction: column;

}
</style>
<div class="container mx-3 my-5">
{#if loading}
    <div class="has-text-centered">
            <div class="is-size-4-mobile is-size-3 ">
            <Spinner 
                size="100"
                speed="750"
                color="rgba(0, 255, 0, 0.3)"
                thickness="5"
                gap="40"/>          
            </div>
        </div>   
{:else}
{#each Object.entries(orgTemplates) as [channelName, channelTemplate]}
        <article class="panel px-3 py-3">
            <p class="panel-heading">
                {channelName}
            </p>
            <a class="panel-block">
                <p class="has-text-left">Πρότυπο ειδοποίησης για νέα ανάθεση εργασίας:</p> <p class="has-text-link-dark has-text-left">{getTemplateTitle(channelTemplate.assignmentNotificationTemplateId)}</p>
            </a>
            <a class="panel-block has-text-left">
                <p class="has-text-left">Πρότυπο ειδοποίησης για καθυστέρηση εργασίας:</p> <p class="has-text-link-dark has-text-left">{getTemplateTitle(channelTemplate.overdueNotificationTemplateId)}</p>
            </a>
            <a class="panel-block has-text-left">
                <p class="has-text-left">Πρότυπο ειδοποίησης για εργασία που πλησιάζει η προθεσμία της:</p> <p class="has-text-link-dark has-text-left">{getTemplateTitle(channelTemplate.closeToDelayedNotificationTemplateId)}</p>
            </a>
                                      
        </article>
    {/each}
    
{/if}
</div>
