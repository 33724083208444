import { writable, readable } from 'svelte/store';

export const fields = readable([
    {
        id:1,
        name: "Αριθμός Δελτίου Ταυτότητας"
    },
    
    {
        id:2,
        name: "Όνομα Πατρός"
    },
    
    {
        id: 3,
        name: "Όνομα Μητρός"
    },
    
    {
        id:4,
        name: "ΑΦΜ"
    }
])
export const actions = readable([

{
    id:1,
    name: "Πιστοποιητικά Δημοτολογίου",
    type: [
        {id:1 , name: "Γέννησης"},
        {id:2 , name: "Πλησιεστέρων Συγγενών"},
        {id:3 , name: "Οικογενειακής Κατάστασης"},
        {id:4 , name: "Ιθαγένειας"},
        {id:5 , name: "Εντοπιότητας"},
        {id:6 , name: "Οικογενειακής Κατάστασης Μεταδημότευσης"},
        {id:7 , name: "Ταυτοπροσωπίας"}
    ],
    fields: [1,2,3]
},

{
    id:2,
    name: "Γενικές αιτήσεις",
    type: [{id:1 , name: "Γενική Αίτηση"}],
    fields: [1,2,3 ]
},

{
    id: 3,
    name: "Βεβαιώσεις μη οφειλής",
    type: [
        {id:1 , name: "Τελών Κυκλοφορίας"},
        {id:2 , name: "Εισφοράς σε Χρήμα"},
    ],
    fields: [1,2,3,4 ]
}
]);

export const categories = readable([

    {
        id:1,
        name: "Δήμοι"
    },
    
    {
        id:2,
        name: "Περιφέρειες"
    },
    
    {
        id: 3,
        name: "Επιχειρήσεις"
    },
    {
        id: 4,
        name: "Δημόσιες Υπηρεσίες"
    }


]);



export const clients = readable([

    // τα id έιναι ψεύτικα
    {
        id: "dfiadfja-dfadfadf-adfdafa-dd-b68bf2",
        name: "Δήμος Γλυφάδας",
        icon: "fas fa-2x fa-archway has-text-info",
        info: "info για τον Δήμο Γλυφάδας <i>lorem</i> ipsum lorem ipsum lorem ipsum",
        welcome: "welcome lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem <b>ipsum</b>",
        actions: [1,2,3],
        categories: [1]
    },
    {
        id: "3sdfadfadfasdfa-fjkadjfasf-eb9db68bf2",
        name: "Δήμος Βέροιας",
        icon: "far fa-2x fa-building has-text-danger",
        info: "info για τον Δήμο Βέροιας <b>lorem</b> ipsum lorem ipsum lorem ipsum",
        welcome: "Στην Ημαθία, την Ερατεινή γη, στην καρδιά της Ελλάδας και της Κεντρικής Μακεδονίας, «διασταυρώνονται» μοναδικά και διαχρονικά, χιλιάδες χρόνια τώρα, οι αισθήσεις, οι μνήμες, οι αξίες και οι παραδόσεις ενός ολόκληρου κόσμου, του οποίου η ταυτότητα και η φυσιογνωμία αντανακλά στα τέσσερα σημεία του ορίζοντα βρίσκοντας ιδανική έκφραση σε δύο απλές λέξεις: ΔΗΜΟΣ ΒΕΡΟΙΑΣ",
        actions: [2,3],
        categories: [1]
    },
    {
        id: "555-12223-3434asdf-3434343-90",
        name: "Υπηρεσία Πολιτικής Αεροπορίας",
        icon: "fas fa-2x fa-plane has-text-info",
        info: "info για την ΥΠΑ <i>lorem ipsum lorem ipsum lorem ipsum</i> lorem lorem ipsum",
        welcome: "welcome lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem <b>ipsum</b>",
        actions: [1],
        categories: [4]
    },
    {
        id: "addddd-sdfkkjladjfklajfkljaldk-df",
        name: "ΔΕΤΗΠ",
        icon: "fas fa-2x fa-industry has-text-primary",
        info: "info για την ΔΕΤΗΠ <i>lorem ipsum lorem ipsum lorem ipsum</i> lorem lorem ipsum",
        welcome: "welcome lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem <b>ipsum</b>",
        actions: [2, 1],
        categories: [3]
    },
    {
        id: "akfjk-dfknjadfkladfj-akjsk0",
        name: "Περιφέρεια Π",
        icon: "far fa-2x fa-building has-text-success",
        info: "info για την ΠΝΑ <i>lorem ipsum lorem ipsum lorem ipsum</i> lorem lorem ipsum",
        welcome: "welcome lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem <b>ipsum</b>",
        actions: [1],
        categories: [2]

    },
    {
        id: "oijsdfd=ddd",
        name: "Δήμος Ιωαννιτών",
        icon: "fas fa-2x fa-archway has-text-primary",
        info: "info για Δήμο Ιωαννιτών <i>lorem ipsum lorem ipsum lorem ipsum</i> lorem lorem ipsum",
        welcome: "welcome lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem <b>ipsum</b>",
        actions: [2],
        categories: [1]
    },
    {
        id: "oisfsjsfd880dd",
        name: "Δήμος Ζακύνθου",
        icon: "fas fa-2x fa-archway has-text-success",
        info: "info για Δήμο Ζακύνθου <i>lorem ipsum lorem ipsum lorem ipsum</i> lorem lorem ipsum",
        welcome: "welcome lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem <b>ipsum</b>",
        actions: [1,3],
        categories: [1]
    }

]);

//READS ORGANIZATION ENTRIES FROM LOCAL DATABASE
const orgs = writable ([]);

// export const organizations = {
//     subscribe: orgs.subscribe,
//     setOrgs: (orgsArray) => {
//         orgs.set(orgsArray);
//     }
// };

export const organizations = writable([]);

//service categories from selected org, to be displayed in sidebar menus and such
export const currentCategories = writable([]);

//selected category from selected org, to be chosen from sidebar menu
export const selectedCategory = writable([]);

