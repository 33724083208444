<script>
  import { url, params, goto, beforeUrlChange } from "@sveltech/routify";
  import { onMount } from "svelte";
  import { fade, blur } from "svelte/transition";
  import Spinner from "svelte-spinner";
  import { CookiesApi } from "../../../utils/helpers.js";
  import { clientGsisId, GSISClientName, clientFooterName, loggedFromClient, loggedFromClientName } from "../../../ui-store.js";

  //this component checks parameters after redirects from gsis login and
  //then makes the necessary api calls to get user data
  //sets the appropriate context to redirect to where the user began the gsis login process from

  import {
    axiosInst,
    prepareGsisLoginUrl,
    loggedIn,
    loggedTaxis,
    profile,
  } from "../../../credentials.js";

  const gotoClient = () => {
    $goto("/client");
  };

  const gotoClientAfterGSISError = ()  => {
    let org_id = state.split("_")[0];
    window.location.href =
          process.env.BASE_URL_FRONT +
          "/client/" + org_id + "/welcome";
  }

  //if the user login isn't complete, let user know before they leave the page
  $beforeUrlChange((event, store) => {
    // disabled: ask for confirmation once
    // if (!loggedTaxis) confirmuser();
    // else return true;
  });

  let errorMessage = null;
  $:console.log("errorMessage: ", errorMessage);
  let error = null; //gsis sends an error code
  let state; //in the form of orgid_serviceid, or just 'login'. Will be used to redirect to the process started by the user, or the account menu
  let code; // will be sent in api call to retrieve token and user data from gsis
  let userdata = null;
  let redirecturl = null;
  let errorWithLogin = false;
  let message = null;
  let userFound = false;

  let confirmedUserId = false;

  //get values from local storage
  GSISClientName.useLocalStorage();
  console.log("GSISClientName: ", $GSISClientName);
  let userId = null;
  let logUser = () => {
    $loggedIn = true;
    $loggedTaxis = true;
    $profile = userdata;
    //set loggedFromClient in local storage
    localStorage.setItem(
      'loggedFromClient', userdata.orgUuid
    );
    localStorage.setItem(
      'loggedFromClientName', JSON.stringify($GSISClientName)
    );
    
    setCookie($profile);
    go().then((res) => {
      //add userid to redirect url
      let url = prepareRedirectUrl(res);
      console.log(res);
      console.log(url);
      //logout user from taxis. We got the data we needed
      //redirect to taxis logout url and return to where needed.
      //log gsis session logout event first
      $axiosInst
        .get("gsis/user/logGSISlogout")
        .then(function (response) {
          if (res.status === 200) {
            //If status OK, see if we have errors
            console.log(res.data);
            if (res.data.errors && res.data.errors.length > 0) {
              console.log("An error occured" + ": " + res.data.errors);
            } else {
              //no errors
              console.log("log event success");
            }
          } else {
            console.log("something went wrong");
          }
        })
        .catch(function (e) {
          console.log(e);
        });

      if (state === "login")
        window.location.href =
          process.env.GSIS_LOGOUT_URL +
          "/" +
          $clientGsisId +
          "/?url=" +
          //process.env.BASE_URL_FRONT +
          window.location.href.split("/gsis")[0] +
          redirecturl +
          res.user +
          "/";
      else
        window.location.href =
          process.env.GSIS_LOGOUT_URL +
          "/" +
          $clientGsisId +
          "/?url=" +
           //process.env.BASE_URL_FRONT +
           window.location.href.split("/gsis")[0] +
          url +
          "/";
    });
  };

  let leave = () => {
      //redirect to client page, do not log in
      let url;
      let base_url = "/client";
      let org_id = state.split("_")[0];

      url = base_url + "/" + org_id + "/welcome";
      //logout user from taxis
      //redirect to taxis logout url and return to where needed.

      //log cancellation event first
      $axiosInst
        .get("gsis/user/logGSIScancel", {
            params: { reason: logError },
          })
        .then(function (response) {
          if (res.status === 200) {
            //If status OK, see if we have errors
            console.log(res.data);
            if (res.data.errors && res.data.errors.length > 0) {
              console.log("An error occured" + ": " + res.data.errors);
            } else {
              //no errors
              console.log("log event success");
              //no errors, remove sessionId from axios headers
              let sessionCookie = getCookie("sessionCookie");
              if (sessionCookie) {
                // 1. expire cookie
                // document.cookie = "sessionCookie= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
                CookiesApi.remove("sessionCookie", { path: "/" });
              } else {
                console.log("sessionCookie cookie was not found");
              }
              delete $axiosInst.defaults.headers.common["SessionID"];
            }
          } else {
            console.log("something went wrong");
          }
        })
        .catch(function (e) {
          console.log(e);
        });

      window.location.href =
        process.env.GSIS_LOGOUT_URL +
        "/" +
        $clientGsisId +
        "/?url=" +
         //process.env.BASE_URL_FRONT +
         window.location.href.split("/gsis")[0] +
        url +
        "/";
  };

  // $:if(userId&&confirmedUserId) $goto(prepareRedirectUrl(userId));

  let externalEntityDTO = {
    email: null,
    id: null,
    legalEntityDTO: {
      address: null,
      title: null,
    },
    physicalEntityDTO: {
      address: null,
      firstName: null,
      lastName: null,
    },
    requests: [],
    taxId: null,
  };

  const createUser = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await $axiosInst.post("externalEntities/", externalEntityDTO);
        if (res.status === 200) {
          //If status OK, see if we have errors
          console.log(res.data);
          if (res.data.errors && res.data.errors.length > 0) {
            console.log("An error occured" + ": " + res.data.errors);
            // add more actions...
            reject("something went wrong");
          } else {
            //no errors, move to next and final step
            console.log("Success!");
            //resolve newly created user data
            $profile.id = res.data.data.id;
            resolve(res.data.data);
          }
        } else {
          reject("something went wrong");
        }
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };
  const findUserId = () => {
    let entityTaxId = userdata.taxId;
    return new Promise(async (resolve, reject) => {
      try {
        await $axiosInst
          .get("externalEntities/byTax", {
            params: { entityTaxId: entityTaxId },
          })
          .then(function (response) {
            if (response.status === 204) {
              console.log("No content found. Status Code: " + response.status);
              userFound = false;
              errorWithLogin = false;
              message =
                "Πατώντας 'Επιβεβαίωση' θα πραγματοποιήσετε εγγραφή στην υπηρεσία μας. Επιθυμείτε να συνεχίσετε;";
              resolve({ userFound: userFound, user: null });
            }
            // Examine the text in the response
            else if (response.status === 200) {
              console.log(response.data);
              userFound = true;
              errorWithLogin = false;
              $profile.id = response.data;
              //if the user is found do stuff
              resolve({
                userFound: userFound,
                user: response.data,
                userName: JSON.stringify(userdata.firstname).concat(
                  " ",
                  JSON.stringify(userdata.lastname)
                ),
              });
            } else {
              console.log("Looks like there was a problem. Status Code: " + response.status);
              userFound = false;
              errorWithLogin = true;
              message = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
              reject();
            }
          });
      } catch (err) {
        console.log("Fetch Error :-S", err);
        reject(err);
      }
    });
  };

  const prepareRedirectUrl = (userid) => {
    //2 cases: either we've logged in from a new request, or we've logged in from header menu
    //this will be visible in the "state" parameter that is returned in url
    if (state === "login") {
      return "/user/";
    } else {
      console.log("inside redirecturl method");
      let url;
      let base_url = "/client";
      let org_id = state.split("_")[0];
      let process_id = state.split("_")[1];
      console.log(
        "redirecturl",
        base_url + "/" + org_id + "/action/" + process_id + "/user/" + userid + "/new"
      );
      if (process_id === "0") {
        url = base_url + "/" + org_id + "/welcome";
      } else {
        url = base_url + "/" + org_id + "/action/" + process_id + "/user/" + userid + "/new";
      }

      return url;
    }
  };

  const setCookie = (profile_info) => {
    // if everything seems OK: save in cookie and continue
    if (
      userdata.orgUuid &&
      userdata.gsisTokenInfo &&
      userdata.gsisTokenInfo.access_token &&
      userdata.gsisTokenInfo.expires_in
    ) {
      // convert expires_in to GMT String
      let expires = new Date();
      expires.setMinutes(expires.getMinutes() + 59);
      // expires.setSeconds(
      //     expires.getSeconds() + parseInt(userdata.gsisTokenInfo.expires_in)
      // );
      // expires = expires.toGMTString();

      let gsisAuth = {
        accessToken: userdata.gsisTokenInfo.access_token,
        organization: userdata.orgUuid,
        // expires: expires,
        duration: userdata.gsisTokenInfo.expires_in,
        info: profile_info,
      };

      // save cookie
      // ** With a path parameter, you can tell the browser what path the cookie belongs to. By default, the cookie belongs to the current page. **
      // ** we set the cookie to root path: '/'

      // document.cookie = "gsisAuth=" + encodeURIComponent(JSON.stringify(gsisAuth)) + "; path=/";
      CookiesApi.set("gsisAuth", JSON.stringify(gsisAuth), { expires: expires, path: "/" });
    }
  };

  const go = () => {
    return new Promise(async (resolve, reject) => {
      //we have the taxid, find the user (οr register them)
      findUserId().then((res) => {
        if (res.userFound) {
          //redirect to user/{id} if user logged in from menu
          // set store values
          //$profile.id = res.user;
          resolve(res.user);
          //$goto(redirecturl);
          confirmedUserId = true;
          // if (state === "login") $goto(redirecturl + res.user);
          // //redirect to service page if user logged in from new service
          // else $goto(redirecturl);
        } else {
          //create new user from userdata. Set values and make api call
          externalEntityDTO.physicalEntityDTO.firstName = userdata.firstname;
          externalEntityDTO.physicalEntityDTO.lastName = userdata.lastname;
          externalEntityDTO.taxId = userdata.taxId;
          createUser().then((res) => {
            // set store values
            //$profile.id = res.id;
            resolve(res.id);
            //$goto(redirecturl);
            confirmedUserId = true;
            // //...and redirect to where needed
            // if (state === "login") $goto(redirecturl + res.id);
            // //redirect to service page if user logged in from new service
            // else $goto(redirecturl);
          });
        }
      });
      //resolve();
    });
    //$goto(redirecturl);
  };

  let fetchError = false;
  let fetchErrorMessage = "Παρουσιάστηκε πρόβλημα κατά την ανάκτηση πληροφοριών του πολίτη. Παρακαλώ προσπαθήστε αργότερα.";
  //used for logging purposes
  let logError = "";

  const init = () => {
    console.log("Running init...");
    //check url for redirect_uri from gsis (redirect uri: open1.entrance.eu/:orgUuid/gsis?code=xxxxx&state=XXXXXX)
    if (window.location.href.includes("code")) {
      error = false;
      console.log(window.location.href);
      console.log("Code detected...");

      //extract code and state
      //split url at '?' to get parameters (spliturl[1])
      let spliturl = window.location.href.split("?");
      if (spliturl.length != 2) {
        console.log("An error occured =  malformed url");
        errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
        return;
      }

      let params = spliturl[1].split("="); //will most likely result in ("code", "xxxx&state", "XXXXX");
      if (params.length != 3) {
        console.log("An error occured =  malformed url");
        errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
        return;
      }

      //should the redirect url be in the form of 'open1.entrance.eu/:orgUuid/gsis?state=XXXXXX&code=xxxxx' instead of 'open1.entrance.eu/gsis?code=xxxxx&state=XXXXXX'
      //we are checking - again. params[1] is either the code value or the state value, with '&state' or '&code' attached.
      //params[0] is either "code" or "state" - a good start to make cases
      if (params[0] === "code") {
        code = params[1].split("&")[0];
        state = params[2];
      } else if (params[0] === "state") {
        state = params[1].split("&")[0];
        code = params[2];
      } else {
        //something went REALLY wrong
        errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
        console.log("You've fed me a malformed url");
        return;
      }

      //make api call with code, get user data, set in context
      return new Promise(async (resolve, reject) => {
        try {
          const res = await $axiosInst.get("gsis/user/gsisinfo", {
            params: { code: code, orgId: state.split("_")[0] },
          });
          console.log(res.data);
          console.log(res.status);
          if (res.status === 200) {
            {
              //If status OK, see if we have errors
              console.log(res.data);
              if (res.data.errors && res.data.errors.length > 0) {
                console.log("An error occured" + ": " + res.data.errors);
                errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
                reject("something went wrong");
              } else {
                //no errors, move to next and final step
                console.log("Success!");
                userdata = res.data.data;
                if (!res.data.data) {
                  console.log("gsis fetch error!");
                  logError = "NULL DATA";
                  fetchError = true;
                  return;
                }
                else if (!res.data.data.taxId || !res.data.data.userid || !res.data.data.lastname) {
                  console.log("gsis incomplete data!");
                  fetchErrorMessage = "Αδυναμία πρόσβασης λόγω ελλιπών στοιχείων. Παρακαλώ προσπαθήστε αργότερα.";
                  logError = "INCOMPLETE DATA";
                  fetchError = true;
                  return;
                }
                else if (!res.data.data.gsisTokenInfo || (res.data.data.gsisTokenInfo && !res.data.data.gsisTokenInfo.access_token)) {
                  console.log("gsis token info missing!");
                  fetchErrorMessage = "Αδυναμία πρόσβασης λόγω ελλιπών διαπιστευτηρίων. Παρακαλώ προσπαθήστε αργότερα.";
                  logError = "TOKEN MISSING";
                  fetchError = true;
                  return;
                }
                else resolve(res.data);
              }
            }
          } else {
            errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
            reject("something went wrong");
          }
        } catch (e) {
          errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
          reject(e);
        }
      });
    } else if (window.location.href.includes("error")) {
      error = true;
      console.log("error detected");
      //get error
      //split url at '?' to get parameters (spliturl[1])
      let spliturl = window.location.href.split("?");
      if (spliturl.length != 2) {
        console.log("An error occured =  malformed url");
        errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
        return;
      }

      let params = spliturl[1].split("="); //will most likely result in ("error", "xxxx&error_description", "XXXXX&state", "yyyyyy");
      //we'll use the state parameter to return user to client page
      state = params[3];

      //if error=access_denied return user to client page
      if (params[1].split("&")[0] === "access_denied") {
        errorMessage = "Αδυναμία πρόσβασης λόγω ελλιπών δικαιωμάτων. Παρακαλώ προσπαθήστε ξανά.";
        let url;
        let base_url = "/client";
        let org_id = state.split("_")[0];

        url = base_url + "/" + org_id + "/welcome";
        //logout user from taxis
        //redirect to taxis logout url and return to where needed.

        //log cancellation event first
        $axiosInst
          .get("gsis/user/logGSIScancel", {
            params: { reason: logError },
          })
          .then(function (response) {
            if (res.status === 200) {
              //If status OK, see if we have errors
              console.log(res.data);
              if (res.data.errors && res.data.errors.length > 0) {
                console.log("An error occured" + ": " + res.data.errors);
              } else {
                //no errors
                console.log("log event success");
                //no errors, remove sessionId from axios headers
                let sessionCookie = getCookie("sessionCookie");
                if (sessionCookie) {
                  // 1. expire cookie
                  // document.cookie = "sessionCookie= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
                  CookiesApi.remove("sessionCookie", { path: "/" });
                } else {
                  console.log("sessionCookie cookie was not found");
                }
                delete $axiosInst.defaults.headers.common["SessionID"];
              }
            } else {
              console.log("something went wrong");
            }
          })
          .catch(function (e) {
            console.log(e);
          });

        window.location.href =
          process.env.GSIS_LOGOUT_URL +
          "/" +
          $clientGsisId +
          "/?url=" +
           //process.env.BASE_URL_FRONT +
           window.location.href.split("/gsis")[0] +
          url +
          "/";
      }
    } else {
      errorMessage = "Παρουσιάστηκε πρόβλημα. Παρακαλώ προσπαθήστε αργότερα.";
      console.log("Something went terribly wrong. Will self-destruct shortly");
    }
  };

  onMount(() => {
    init();
  });
</script>

<div class="container pt-4">
  {#if error}
    <div in:fade={{ delay: 1200 }} class="container pt-4 px-5 mt-6">
      <p class="is-size-4">
        Για να δείτε αυτή τη σελίδα χρειάζεται Σύνδεση με λογαριασμό TaxisNet.
      </p>
      <button
        class="button is-link has-background-link-dark  is-size-5-desktop is-size-6  mt-2"
        on:click={gotoClient}>Ξεκινήστε από την αρχή</button
      >
    </div>
  {:else if error === false}
    <div class="container app-container">
      <div class="columns is-vcentered">
        <div class="column">
          <!-- <div class="is-size-3-mobile is-size-2 has-text-weight-bold">Πύλη στις δημόσιες υπηρεσίες</div> -->

          {#if !fetchError}
          <div class="is-size-5-mobile is-size-4 mt-6">
            Συνδεθήκατε με τα στοιχεία:
          </div>
          {/if}

          <!-- --------------------------------------------------------------------------------------------------------- -->
          {#if userdata && !fetchError}
            <table class="table is-bordered is-narrow mt-6">
              <tbody>
                <tr>
                  <td>ΑΦΜ</td>
                  <td>{userdata.taxId}</td>
                </tr>
                <tr>
                  <td>ΕΠΩΝΥΜΟ/ΕΠΩΝΥΜΙΑ</td>
                  <td>{userdata.lastname}</td>
                </tr>
                <tr>
                  <td>ΟΝΟΜΑ</td>
                  <td>{userdata.firstname}</td>
                </tr>
                <tr>
                  <td>ΠΑΤΡΩΝΥΜΟ</td>
                  <td>{userdata.fathername}</td>
                </tr>
                <tr>
                  <td>ΜΗΤΡΩΝΥΜΟ</td>
                  <td>{userdata.mothername}</td>
                </tr>
                <tr>
                  <td>ΕΤΟΣ ΓΕΝΝΗΣΗΣ</td>
                  <td>{userdata.birthyear}</td>
                </tr>
              </tbody>
            </table>
          {:else if fetchError}
            <div in:fade={{ delay: 1200 }} class="container pt-4 px-5 mt-6">
              <p class="is-size-4">
                {fetchErrorMessage}
              </p>
              <button
                class="button is-link has-background-link-dark  is-size-5-desktop is-size-6  mt-2"
                on:click={gotoClientAfterGSISError}>Ξεκινήστε από την αρχή</button
              >
            </div>
          {:else}
            <div class="is-size-4-mobile is-size-3 ">
              <Spinner
                size="100"
                speed="750"
                color="rgba(0, 255, 0, 0.3)"
                thickness="5"
                gap="40"
              />
            </div>
          {/if}

          <!-- --------------------------------------------------------------------------------------------------------- -->

          <!-- redirects to where user was before logging in -->
          {#if userdata}
            <button
              id="btn-confirm"
              class="button is-size-5-mobile is-size-5 is-link mt-3"
              on:click={logUser}
            >
              Επιβεβαίωση
            </button>

            <button
              id="btn-cancel"
              class="button is-size-5-mobile is-size-5 mt-3"
              on:click={leave}
            >
              Ακύρωση
            </button>
          {/if}
          <!-- div below only exists to push the text divs up a bit -->
          <div class="hero my-6 is-hidden-mobile" />
        </div>

        <!-- <div class="column position-relative is-hidden-mobile mt-6">
              <figure class="image is-square">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Unlock_icon.svg/1200px-Unlock_icon.svg.png">
              </figure>
        </div> -->
      </div>
    </div>
  {:else if errorMessage === undefined}
    <!-- loading -->
    <div class="is-size-4-mobile is-size-3 ">
      <Spinner
        size="100"
        speed="750"
        color="rgba(0, 255, 0, 0.3)"
        thickness="5"
        gap="40"
      />
    </div>
  {:else if errorMessage}
    <div in:fade={{ delay: 1200 }} class="container pt-4 px-5 mt-6">
      <p class="is-size-4">
        {errorMessage}
      </p>
      <button
        class="button is-link has-background-link-dark  is-size-5-desktop is-size-6  mt-2"
        on:click={gotoClient}>Ξεκινήστε από την αρχή</button
      >
    </div>
  {/if}
</div>

<style>
  th {
    font-weight: normal !important;
  }

  td {
    font-weight: bold !important;
  }

  .cancel-button {
    border-color: rgba(243, 244, 246);
  }

  .cancel-button:hover {
    background-color: rgb(243 244 246);
    border-color: rgba(243, 244, 246);
  }
</style>
