<script>
import { fade } from 'svelte/transition';

export let file = null;
$:console.log(file);
let w; //component width
let h; //component height

//mouse pointer coordinates
let mouse = { x: 0, y: 0 };
$:console.log("The mouse position is "+ mouse.x +','+ mouse.y );

let visible = false;
let dragging = false;
let filedropped = false;

let current_fname = null;

let files = [];

// $:console.log(visible);
// $:console.log("Files: " + files);
// $:console.log(w);
// $:console.log(h);

const recordCoordinates = (event) => {
    //bind mouse positon to variables
    mouse.x = event.clientX;
    mouse.y = event.clientY;
    

}
const handleDragenter = (event) => {
    //bind event to the window object instead of the dropzone div
    //helps with clearing out event propagation
    visible = true;
    dragging = true;
    filedropped = false;
}

const handleDragleave = (event) => {
    //dragleave can fire 'unexpectedly', ie when hovering in front of child elements 
    //that's an issue when you have a big ass area as a dropzone
    //in our case, the whole window is the dropzone
    //BUT binding the event to the dropzone div instead of the window object solves the issue
    //we added the event modifier 'self' to make sure it won't fire from a child element 
    visible = false;
    dragging = false;
    filedropped = false;
    
}

const handleDrop = (event) => {
    
    event.dataTransfer.dropEffect = "copy";
    files.push(event.dataTransfer.files);
    file = event.dataTransfer.files;
    // console.log(event.dataTransfer.files[0].name);
    // console.log(files.length);
    visible = false;
    dragging = false;
    filedropped = true;
    current_fname = event.dataTransfer.files[0].name.split('.')[0];

}

const reset = () => {
    //TODO remove latest file from dataTransfer files array, nuill the files array (we upload one at a time)
    files.pop();
    files = files;
    file = null;
    //reset all variables
    dragging = false;
    filedropped  = false;
    visible = false;
    current_fname = null;

}

const softreset = () => {
    //basically, attach the file, and reset view
    //keep file for uploading, reset other variables
    dragging = false;
    filedropped  = false;
    visible = false;

}

</script>

<style>
.disable-children * {
    pointer-events: none !important;
}

.dropzone
{
    /* positions to point 0,0 - required for z-index */
    position: fixed; top: 0; left: 0; 
    /* above all elements, even if z-index is used elsewhere */
    z-index: 9999999999;               
    /* takes up 100% of page */
    width: 100%; height: 100%;         
    /* dim the page with 50% black background when visible */
    background-color: rgba(255, 255, 255, 0.5);

}

</style>
<!-- both visibility:hidden and display:none can be used,
     but the former can be used in CSS animations -->
<svelte:window 
on:dragenter={handleDragenter}
/>

<!-- DRAGGING FILE TO DROP VIEW -->
{#if visible}
<div transition:fade style="opacity:1" class="disable-children dropzone" 
on:dragleave|self={handleDragleave} 
on:dragover|stopPropagation|preventDefault
on:drop|self={handleDrop}
bind:clientWidth={w} bind:clientHeight={h}>
    <div class="container">
        <div class="columns is-desktop is-vcentered" style="height:100vh;">
            <div class="column is-5" style="margin: auto;">
                    {#if dragging}
                        <nav class="level is-desktop">
                        <div class="level-item has-text-centered">
                        <div>
                            <i class="fas fa-paperclip fa-10x has-text-link" style="margin:1rem;"></i>
                            <p class="title has-text-link has-text-weight-bold">Επισύναψη αρχείου</p>
                        </div>
                        </div>
                        </nav>
                    {/if}                    
            </div>
        </div>
    </div>
</div>
{/if}

<!-- FILE DROPPED, CONFIRM ATTACHMENT-->
<!-- outside dropzone div, because we need click events and they are disabled in dropzone's children -->
{#if filedropped}
    <div class="modal {filedropped?'is-active':''}">
    <div class="modal-background" on:click={reset}></div>


    <div class="modal-content">
        <div class="bd-notification is-primary box is-narrow">
        <div class="container" style="margin:1.25rem;">
            <nav class="level">
             <!-- Left side -->
                <div class="level-left">
                <div class="level-item title is-5">Επισύναψη αρχείου</div>
                </div>
             <!-- Right side -->
            <div class="level-right">
                <button class="delete is-medium" aria-label="close" on:click={reset}></button>
            </div>
            </nav>

            <div style="margin: 3rem 0rem;"> 
            <label class="label">Όνομα</label>
            <input class="input is-medium" type="text" bind:value={current_fname} placeholder="Rounded input">
            </div>

            <nav class="level">
            <!-- Left side -->
            <div class="level-left"></div>
            <!-- Right side -->
            <div class="level-right">
                <button class="level-item button is-link has-background-link-dark" on:click={softreset}>Επισύναψη</button>
            </div>
            </nav>
        </div>
        </div>
    </div>
    <!-- <button class="modal-close is-large" aria-label="close" on:click={reset}></button> -->
    </div>
{/if}