<script>
  import L from "leaflet";
  import { axiosAuth } from "../credentials.js";
  import { selectedOrgDTO } from "../ui-store.js";
  import { createEventDispatcher } from "svelte";
  import * as markerIcons from "../utils/markers.js";

  const dispatch = createEventDispatcher();

  export let mapId = null;
  export let disabled = false;

  let map, marker;
  let allMaps;
  let requestedMap;
  let shownMap = null;
  let chosenMapMarkers = [];
  let mapClickedFirstTime = false;

  // const getMaps = async () => {
  //   await $axiosAuth
  //     .get("open1process/" + $selectedOrgDTO.id + "/geoMap/all")
  //     .then(function (response) {
  //       if (response.status !== 200) {
  //         console.log(
  //           "Looks like there was a problem. Status Code: " + response.status
  //         );
  //       } else {
  //         //If status OK, see if we have errors
  //         if (response.data.errors.length > 0) {
  //           console.log("Unathorized User" + ": " + data.errors);
  //           // add more actions...
  //         } else {
  //           //no errors, move to next and final step
  //           allMaps = response.data.data;
  //           console.log("success");
  //         }
  //       }
  //     })
  //     .catch(function (err) {
  //       console.log("Fetch Error :-S", err);
  //     });
  // };
  //
  // const updateMap = async () => {
  //   const res = await $axiosAuth
  //     .put("open1process/" + $selectedOrgDTO.id + "/geoMap/update", {
  //       description: shownMap.description,
  //       geometries: [
  //         {
  //           name: "point",
  //           description: "point description",
  //           x: chosenMapMarkers[chosenMapMarkers.length - 1]._latlng.lat,
  //           y: chosenMapMarkers[chosenMapMarkers.length - 1]._latlng.lng,
  //         },
  //       ],

  //       id: shownMap.id,
  //       name: shownMap.name,
  //       properties: shownMap.properties,
  //     })
  //     .then(function (response) {
  //       if (response.status !== 200) {
  //         console.log(
  //           "Looks like there was a problem. Status Code: " + response.status
  //         );
  //       } else {
  //         //If status OK, see if we have errors
  //         if (response.data.errors.length > 0) {
  //           console.log("Unathorized User" + ": " + data.errors);
  //           // add more actions...
  //         } else {
  //           //no errors, move to next and final step
  //           shownMap = response.data.data;
  //           console.log("success");
  //         }
  //       }
  //     })
  //     .catch(function (err) {
  //       console.log("Fetch Error :-S", err);
  //     });
  // };

  const userMarker = L.divIcon({
    html: markerIcons.svg,
    className: "",
    iconSize: [24, 40],
    iconAnchor: [12, 40],
  });

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
    );
  }

  const getMapById = async (mapId) => {
    await $axiosAuth
      .get("open1process/" + $selectedOrgDTO.id + "/geoMap/" + mapId) // TODO: requestedMap.id not implemented yet
      .then(function (response) {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
        } else {
          //If status OK, see if we have errors
          if (response.data.errors.length > 0) {
            console.log("Unathorized User" + ": " + data.errors);
            // add more actions...
          } else {
            //no errors, move to next and final step
            shownMap = response.data.data;
            console.log("success");
            console.log("got single map: ", shownMap);
          }
        }
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };

  var initialView = [38.275283, 23.810343];
  var initialZoom = 6;
  async function createMap(container) {
    console.log("KOSTAS CREATE");
    await getMapById(mapId);

    let properties = JSON.parse(shownMap.properties);
    initialView = [properties.lat, properties.lng];
    initialZoom = properties.zoom;

    map = L.map(container, { preferCanvas: true }).setView(
      initialView,
      initialZoom
    );

    shownMap.geometries.forEach((marker) => {
      console.log("kostas ", marker);
      let newMarker = new L.marker([marker.x, marker.y]);

      let popup = L.popup([marker.x, marker.y], {
        content: "<p><strong>Ημ/νία: </strong>" + marker.name + "</p>",
      });
      newMarker.bindPopup(popup);
      newMarker.addTo(map);
    });

    map.locate({ setView: true });

    L.tileLayer(
      "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga",
      {
        attribution: `Google`,
        subdomains: "abcd",
        maxZoom: 18,
        minZoom: 6,
      }
    ).addTo(map);

    map.clicked = false;

    map.on("click", function (event) {
      if (!disabled) {
        console.log("KOSTAS GOT INSIDE");
        marker = L.marker(event.latlng, {
          icon: userMarker,
          interactive: false,
        }); // , {icon: greenIcon}
        map.clicked = true;
        setTimeout(function () {
          if (mapClickedFirstTime) removeMarker();
          if (map.clicked) {
            marker.addTo(map);

            chosenMapMarkers.push(marker);

            dispatch("getvalue", {
              value: [
                {
                  name: formatDate(new Date()),
                  description: "description",
                  x: chosenMapMarkers[chosenMapMarkers.length - 1]._latlng.lat,
                  y: chosenMapMarkers[chosenMapMarkers.length - 1]._latlng.lng,
                },
              ],
            });

            mapClickedFirstTime = true;

            // Add only one or more markers?
            // map.clicked = false;
            // mapClickedFirstTime = false;
          }
        }, 300);
      }
    });

    map.on("dblclick", function (event) {
      map.clicked = false;
      map.zoomIn();
    });

    return map;
  }

  const removeMarker = () => {
    let lastMarker = chosenMapMarkers[chosenMapMarkers.length - 1];
    map.removeLayer(lastMarker);
    chosenMapMarkers.pop();
  };
</script>

<svelte:window />

<link
  rel="stylesheet"
  href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
  integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
  crossorigin=""
/>

<div class="container my-5">
  <div class="map" style="height:450px; width:100%; z-index:0;" use:createMap />

  <!-- needed in order to not take up space below the map -->
  <!-- <div style="position: relative; height: 0;"> -->
  <!-- map bottom nav bar
    <div
      class:is-hidden={hideBar}
      class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between"
      style="position: relative; top: -64px; padding: 12px; background-color: hsl(0, 0%, 100%, 75%);"
    >
      <div
        class="button is-light is-size-6 is-absolute"
        style="border-radius: 5% !important; background-color: #ffffff;"
        on:click={removeMarker}
      >
        <span
          class="is-flex is-flex-direction-row is-align-items-center"
          title="Αναίρεση προσθήκης σημείου"
          ><Fa icon={faUndo} aria-hidden="true" /></span
        >
      </div>
      <div
        class="button is-link is-size-6 is-absolute"
        style="border-radius: 5% !important;"
        on:click={updateMap}
        title="Αποθήκευση αλλαγών χάρτη"
      >
        <Fa icon={faSave} aria-hidden="true" />
      </div>
    </div> -->
  <!-- </div> -->
</div>

<style>
  .map :global(.marker-text) {
    width: 100%;
    text-align: center;
    font-weight: 600;
    background-color: #444;
    color: #eee;
    border-radius: 0.5rem;
  }

  .map :global(.map-marker) {
    width: 30px;
    transform: translateX(-50%) translateY(-25%);
  }
</style>
