<script>
  import {clientFooterName, clientA11Y} from "../../../ui-store.js";
</script>
<style>
  li {
    margin-top: 1rem;
  }
</style>
<div class="container app-container">
  <div class="columns is-vcentered">

    <div class="column mt-2">


      <!-- <h2 class="subtitile is-size-4-mobile is-size-3 my-6">
          Περιφέρεια Στερεάς Ελλάδας: Πολιτική απορρήτου
        </h2> -->
      <div class=" my-6">
        <div class="is-size-4">
          {@html $clientFooterName}
        </div>
        <h3 class="title">Δήλωση Προσβασιμότητας</h3>
      </div>

    <div class="columns is-vcentered">
        <div class="column">
            <p> Ο Φορέας δεσμεύεται να καθιστά τον οικείο ιστότοπο: <b>{process.env.BASE_URL_FRONT}</b> προσβάσιμο, σύμφωνα με τις προβλέψεις του ν. 4727/2020 (ΦΕΚ 184 Α’ 23-09-2020), 
                Κεφάλαιο Η’ «ΨΗΦΙΑΚΗ ΠΡΟΣΒΑΣΙΜΟΤΗΤΑ (ΕΝΣΩΜΑΤΩΣΗ ΣΤΗΝ ΕΛΛΗΝΙΚΗ ΝΟΜΟΘΕΣΙΑ ΤΗΣ ΟΔΗΓΙΑΣ (ΕΕ) 2016/2102 ΤΟΥ ΕΥΡΩΠΑΪΚΟΥ ΚΟΙΝΟΒΟΥΛΙΟΥ ΚΑΙ ΤΟΥ ΣΥΜΒΟΥΛΙΟΥ, 
                ΤΗΣ 26ΗΣ ΟΚΤΩΒΡΙΟΥ 2016, ΓΙΑ ΤΗΝ ΠΡΟΣΒΑΣΙΜΟΤΗΤΑ ΤΩΝ ΙΣΤΟΤΟΠΩΝ ΚΑΙ ΤΩΝ ΕΦΑΡΜΟΓΩΝ ΓΙΑ ΦΟΡΗΤΕΣ ΣΥΣΚΕΥΕΣ ΤΩΝ ΟΡΓΑΝΙΣΜΩΝ ΤΟΥ ΔΗΜΟΣΙΟΥ ΤΟΜΕΑ)» 
                που ενσωματώνει στην εθνική έννομη τάξη την Οδηγία (ΕΕ) 2016/2012.</p>
              <br>
            <p>Η παρούσα δήλωση προσβασιμότητας εφαρμόζεται στα εξής: 
                <li>στον ιστότοπο: <b>{process.env.BASE_URL_FRONT}</b></li></p>
            <hr />
            <p><b>Κατάσταση συμμόρφωσης</b></p>
            <p>Οι οδηγίες προσβασιμότητας για περιεχόμενο που διατίθεται στον παγκόσμιο ιστό (Web Content Accessibility Guidelines – WCAG) ορίζουν προδιαγραφές για τους σχεδιαστές ιστοτόπων με στόχο τη γενική βελτίωση της προσβασιμότητας. 
                Ορίζονται τρία επίπεδα συμμόρφωσης: Το επίπεδο Α, το επίπεδο ΑΑ και το επίπεδο ΑΑΑ. Στόχος είναι ο ιστότοπος του Φορέα να έχει ένα ικανοποιητικό βαθμό συμμόρφωσης με το WCAG 2.1, Επίπεδο ΑΑ. 
                Σε ορισμένες περιπτώσεις, ενδέχεται να υπάρχουν τμήματα του περιεχομένου που δεν είναι πλήρως εναρμονισμένα με το συγκεκριμένο επίπεδο συμμόρφωσης.</p>
            <hr />
            <p><b>Κατάρτιση της παρούσας δήλωσης προσβασιμότητας</b></p>
            <p></p>
            <hr />
            <p><b>Υποβολή παρατηρήσεων και στοιχεία επικοινωνίας</b></p>
            <p>Σύμφωνα με τις προβλέψεις του ν. 4727/2020 (ΦΕΚ 184 Α’ 23-09-2020), άρθρο 45, κάθε ενδιαφερόμενος, έχει δικαίωμα να υποβάλλει παρατηρήσεις ή αίτηση για ενημέρωση ως προς την κατάσταση συμμόρφωσης 
                ή αίτηση για πληροφορίες που ελλείπουν.</p>
                <br>
                {@html $clientA11Y}
            <hr />
            <p><b>Διαδικασία εκτέλεσης</b></p>
            <p>Αν το αίτημα που αποστέλλεται μέσα από τον μηχανισμό υποβολής παρατηρήσεων δεν απαντηθεί εντός της
                προβλεπόμενης ημερομηνίας ή δεν απαντηθεί ικανοποιητικά, ο/η ενδιαφερόμενος/η δύναται να επικοινωνήσει
                με τον Συνήγορο του Πολίτη (<a href="https://www.synigoros.gr" target="_blank">https://www.synigoros.gr</a>).</p>
            
            <hr />
            

        <!-- <div class="column is-half">
            <h3 class="subtitile is-size-6-mobile is-size-4 mt-6"><b> Είστε Φορέας; </b></h3>
            <a role="button" class="{$highContrast?'has-background-link-dark has-text-white':'is-info'} button is-size-5-mobile is-size-4 mt-1" href="{$url('/admin')}">
              <strong>Συνδεθείτε εδώ</strong>
            </a>
          </div> -->
      </div>


      <!-- div below only exists to push the text divs up a bit -->
      
    </div>
    <div class="hero mt-6 is-hidden-mobile"></div>
      <div class="hero mt-6 is-hidden-mobile"></div>
      <div class="hero mt-6 is-hidden-mobile"></div>



  </div>
</div>
</div>

<!-- <hr> -->